import React, { ReactElement } from 'react';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyYenOutlinedIcon from '@mui/icons-material/CurrencyYenOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';

export const currency_icon: Record<string, ReactElement> = {
  '$ Dollar': <AttachMoneyOutlinedIcon />,
  'SR Saudi riyal': <AttachMoneyOutlinedIcon />,
  '£ Saint Helenian Pound': <CurrencyPoundOutlinedIcon />,
  '₹ Indian Rupee': <CurrencyRupeeIcon />,
  '£ Pound Sterling': <CurrencyPoundOutlinedIcon />,
  '￥ Japanese yen': <CurrencyYenOutlinedIcon />,
  '€ Euro': <EuroOutlinedIcon />,
};

// export const status_icon: Record<string, ReactElement> = {
//   'Not Started': <TimerOutlinedIcon />,
//   'In Progress': <AccessTimeIcon />,
//   Completed: <CheckCircleOutlineIcon />,
//   'Past Due Date': <ErrorOutlineIcon />,
// };
