import React, { useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import {
  CLAUSE_DATA,
  deleteSingleColumn,
  getPara,
} from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function IndemnityClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    currencyList,
    sentenceData,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddCap, setShowAddCap] = useState(false);
  const [showAddExtentCost, setShowAddExtentCost] = useState(false);
  const [showAddTriggeringEvents, setShowAddTriggeringEvents] = useState(false);
  const [showAddPayer, setShowAddPayer] = useState(false);
  const [showAddPayee, setShowAddPayee] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'indemnity');
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.indemnity && clauseData.indemnity,
    [clauseData?.indemnity]
  );

  const updates = useMemo(
    () => updatedClauseData?.indemnity || {},
    [updatedClauseData]
  );

  const indemnity = useMemo(() => {
    if (updates?.indemnity) {
      const sortData = updates.indemnity.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payer = useMemo(() => {
    if (updates?.transaction) {
      const payer = updates.transaction.filter(
        (data: any) => data.type === 'payer'
      );
      const sortData = payer.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payee = useMemo(() => {
    if (updates?.transaction) {
      const payee = updates.transaction.filter(
        (data: any) => data.type === 'payee'
      );
      const sortData = payee.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const cap = useMemo(() => {
    if (updates?.amounts) {
      const sortData = updates.amounts.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = currencyList.findIndex(
          (list) => list.currencyTypeId === data.currency_type_id
        );
        const typeIndex = currencyList.findIndex(
          (list) => list.currencyName === data.currency_type
        );
        if (index > -1) {
          const type = currencyList[index].currencyName;
          data.currency_type = type;
        }
        if (!data.currency_type_id && typeIndex > -1) {
          const id = currencyList[typeIndex].currencyTypeId;
          data.currency_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [currencyList, updates]);

  const extent_cost = useMemo(() => {
    if (updates?.bi_1) {
      const sortData = updates.bi_1.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const trigger_events = useMemo(() => {
    if (updates?.bi_2) {
      const sortData = updates.bi_2.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let indemnity_data: any;
      let amounts: any;
      let transaction: any;
      let bi_1: any;
      let bi_2: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        indemnity_data = deleteSingleColumn(indemnity, tableData);

        amounts = deleteSingleColumn(cap, tableData);

        transaction = deleteSingleColumn(updates?.transaction, tableData);

        bi_1 = deleteSingleColumn(extent_cost, tableData);

        bi_2 = deleteSingleColumn(trigger_events, tableData);
      } else {
        indemnity_data = indemnity?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        amounts = cap?.filter((data: any) => data.para_id !== item.para_id);

        transaction = updates.transaction?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        bi_1 = extent_cost.filter((data: any) => data.para_id !== item.para_id);

        bi_2 = trigger_events.filter(
          (data: any) => data.para_id !== item.para_id
        );
      }

      const updatedData = {
        ...updates,
        indemnity: indemnity_data,
        amounts,
        transaction,
        bi_1,
        bi_2,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'indemnity', diff, updatedData);
      }
    },
    [
      indemnity,
      cap,
      updates,
      extent_cost,
      trigger_events,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteCap = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let amounts: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        amounts = deleteSingleColumn(cap, tableData);
      } else {
        amounts = cap?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }

      const updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'indemnity', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, cap, data, fileId]
  );

  const deleteTransaction = React.useCallback(
    (item: any, type: string) => {
      const tableData = item?.table?.[0];
      let transaction: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        transaction = deleteSingleColumn(updates?.transaction, tableData);
      } else {
        transaction = updates?.transaction?.filter(
          (data: any) =>
            type !== data.type ||
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }
      const updatedData = {
        ...updates,
        transaction,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'indemnity', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, data, fileId]
  );

  const deleteExtentCost = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let bi_1: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        bi_1 = deleteSingleColumn(extent_cost, tableData);
      } else {
        bi_1 = extent_cost?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }

      const updatedData = {
        ...updates,
        bi_1,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'indemnity', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, extent_cost, data, fileId]
  );

  const deleteTriggerEvents = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let bi_2: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        bi_2 = deleteSingleColumn(trigger_events, tableData);
      } else {
        bi_2 = trigger_events?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }
      const updatedData = {
        ...updates,
        bi_2,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'indemnity', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, trigger_events, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="indemnity"
          onClick={() =>
            handleAddEdit(
              props,
              CLAUSE_DATA.indemnity_clause.heading,
              indemnity,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="indemnity"
          savedInsight={indemnity ? indemnity : []}
          savedParentClauseDataPoint={indemnity}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {indemnity?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'indemnity', index });
              props.onClickHighlight(index, item, 'indemnity');
            }}
            index={index}
            clauseItem={item}
            sentenceData={sentenceData}
            isActive={
              activeClause?.type === 'indemnity' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddCap ? (
        <ClauseHeader
          title="Amount"
          buttonText="amount"
          onClick={() =>
            handleAddEdit(
              props,
              'Indemnity/Reimbursements/Costs Amount',
              cap,
              setShowAddCap
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Indemnity/Reimbursements/Costs Amount"
          savedInsight={cap ? cap : []}
          savedParentClauseDataPoint={cap}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddCap(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {cap?.map((capItem: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'amount', index });
              props.onClickHighlight(
                index,
                capItem,
                'Limitation Of Liability Amount'
              );
            }}
            index={index}
            clauseItem={capItem}
            sentenceData={sentenceData}
            isActive={
              activeClause?.type === 'amount' && activeClause?.index === index
            }
            deleteClause={() => deleteCap(capItem)}
            para={
              capItem.amountString ||
              getPara(
                sentenceData,
                capItem.para_id,
                capItem.sentence_id,
                capItem.table && capItem.table[0]?.row,
                capItem.table && capItem.table[0]?.column
              )
            }
            subTitle={`${capItem.currency || '_'} ${capItem.total || '_'} `}
          />
        ))}
      </Scrollable>

      {!showAddExtentCost ? (
        <ClauseHeader
          title={CLAUSE_DATA.indemnity_clause.extent_cost}
          buttonText="cost"
          onClick={() =>
            handleAddEdit(
              props,
              'Indemnity/Reimbursements/Costs Extent of Cost',
              extent_cost,
              setShowAddExtentCost
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Indemnity/Reimbursements/Costs Extent of Cost"
          savedInsight={extent_cost ? extent_cost : []}
          savedParentClauseDataPoint={extent_cost}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddExtentCost(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {extent_cost?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'cost', index });
              props.onClickHighlight(
                index,
                item,
                CLAUSE_DATA.indemnity_clause.extent_cost
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={sentenceData}
            isActive={
              activeClause?.type === 'cost' && activeClause?.index === index
            }
            deleteClause={() => deleteExtentCost(item)}
            para={item.string}
          />
        ))}
      </Scrollable>
      {!showAddTriggeringEvents ? (
        <ClauseHeader
          title={CLAUSE_DATA.indemnity_clause.triggering_events}
          buttonText="events"
          onClick={() =>
            handleAddEdit(
              props,
              'Indemnity/Reimbursements/Costs Triggering Event',
              trigger_events,
              setShowAddTriggeringEvents
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Indemnity/Reimbursements/Costs Triggering Event"
          savedInsight={trigger_events ? trigger_events : []}
          savedParentClauseDataPoint={trigger_events}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddTriggeringEvents(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {trigger_events?.map((triggerItem: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'event', index });
              props.onClickHighlight(
                index,
                triggerItem,
                CLAUSE_DATA.indemnity_clause.triggering_events
              );
            }}
            index={index}
            clauseItem={triggerItem}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'event' && activeClause?.index === index
            }
            deleteClause={() => deleteTriggerEvents(triggerItem)}
            para={triggerItem.string}
          />
        ))}
      </Scrollable>

      {!showAddPayer ? (
        <ClauseHeader
          title="Payer"
          buttonText="payer"
          onClick={() => handleAddEdit(props, 'payer', payer, setShowAddPayer)}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Indemnity/Reimbursements/Costs Payer"
          savedInsight={payer ? payer : []}
          savedParentClauseDataPoint={payer}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddPayer(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {payer?.map((payerItem: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'payer', index });
              props.onClickHighlight(index, payerItem, 'Payer');
            }}
            index={index}
            clauseItem={payerItem}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'payer' && activeClause?.index === index
            }
            deleteClause={() => deleteTransaction(payerItem, payerItem.type)}
            para={payerItem.string}
          />
        ))}
      </Scrollable>
      {!showAddPayee ? (
        <ClauseHeader
          title="Payee"
          buttonText="payee"
          onClick={() => handleAddEdit(props, 'payee', payee, setShowAddPayee)}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Indemnity/Reimbursements/Costs Payee"
          savedInsight={payee ? payee : []}
          savedParentClauseDataPoint={payee}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddPayee(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="indemnity"
        />
      )}
      <Scrollable maxHeight={200}>
        {payee?.map((payeeItem: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'payee', index });
              props.onClickHighlight(index, payeeItem, 'Payee');
            }}
            index={index}
            clauseItem={payeeItem}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'payee' && activeClause?.index === index
            }
            deleteClause={() => deleteTransaction(payeeItem, payeeItem.type)}
            para={payeeItem.string}
          />
        ))}
      </Scrollable>
    </>
  );
}
