import React, { Dispatch, FC, SetStateAction } from 'react';

import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';

interface IProps {
  searchTerm: { search: string[] };
  setSearchTerm: Dispatch<SetStateAction<{ search: string[] }>>;
}
const SearchComponent: FC<IProps> = ({ searchTerm, setSearchTerm }) => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit } = methods;

  const onSubmit = (data: any) => {
    const searchData = searchTerm.search;
    const index = searchData.findIndex(
      (term: string) => term.toLowerCase() === data.search_text.toLowerCase()
    );
    if (index === -1) {
      searchData.push(data.search_text);
      const newSearch = {
        search: searchData,
      };
      setSearchTerm(newSearch);
      reset();
    } else {
      enqueueSnackbar('Search term already exist!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack width="100%" direction="row">
          <ControlledTextField
            name="search_text"
            label="Type here to search"
            control={control}
            fullWidth
          />
          <Button variant="contained">Search</Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SearchComponent;
