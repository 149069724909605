import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, Modal } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';

interface IProps {
  platform: string;
}

const SearchComponent: React.FC<IProps> = (props) => {
  const { platform } = props;
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { query } = useParams();
  const { handleSubmit, control } = methods;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data: any) => {
    if (data.search_term.length) {
      let searchQuery = {
        search: [data.search_term],
      };
      if (location.pathname.includes('search')) {
        searchQuery = JSON.parse(atob(query as string));
        const index = searchQuery.search.findIndex(
          (item: string) =>
            item.toLowerCase() === data.search_term.toLowerCase()
        );
        if (index === -1) {
          searchQuery.search.push(data.search_term);
        }
      }
      const url = `/search/${btoa(JSON.stringify(searchQuery))}`;
      navigate(url);
      handleClose();
    }
  };

  if (platform === 'UMBRELLA')
    return (
      <React.Fragment>
        <IconButton onClick={handleOpen} sx={{ color: '#88305F' }}>
          <SearchIcon />
        </IconButton>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '30%',
              left: '50%',
              width: '60%',
              transform: 'translate(-50%, -50%)',
              boxShadow: 24,
              backgroundColor: 'white',
              borderRadius: '65px',
              padding: '10px 15px',
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ControlledTextField
                  name="search_term"
                  control={control}
                  label="Search Query"
                  fullWidth
                  required
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none !important',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          sx={{ color: '#88305F' }}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </FormProvider>
          </Box>
        </Modal>
      </React.Fragment>
    );
  else return null;
};

export default SearchComponent;
