import { authApi } from '../../../../Services/authApi';

export default class ClauseComponentApi {
  public static getClausesByType = async (
    fileID: string,
    clauseType: string
  ) => {
    return authApi.get(`contracts/insights/${fileID}/${clauseType}/`);
  };

  public static postClauseDataByType = async (
    fileID: string,
    type: string,
    payload: any
  ) => {
    return authApi.patch(`contracts/insights/${fileID}/${type}/`, payload);
  };

  public static updateEsClauseDataByType = async (payload: any) => {
    return await authApi.post('contracts/update-es/', payload);
  };
}
