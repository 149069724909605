import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import MapField from './MapField';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import ControlledCheckbox from '../../../RiverusUI/Components/ControlledCheckbox';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import {
  addFields,
  deleteField,
  getDataTypeList,
} from '../../../Services/dictionary';
import { camelize } from '../../../Utils/DataModifierUtil/dataModUtil';

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  padding: '8px 15px',
  color: '#1D1B20',
};

interface IProps {
  id: string;
  fieldData?: any;
  activeSection?: any;
}

const FieldForm: FC<IProps> = (props) => {
  const { id, fieldData, activeSection } = props;

  const [isEdit, setIsEdit] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm();

  const { handleSubmit, control, watch, reset } = methods;

  const displayName = watch('display_name');
  const data_type = watch('data_type');
  const is_linked = watch('is_linked');
  const parent = watch('parent');

  const queryClient = useQueryClient();

  const { data: dataList, isLoading: dataLoading } = useQuery({
    queryKey: ['data_list'],
    queryFn: getDataTypeList,
    select: (response) => response.results,
  });

  const { mutate: addFieldMutation } = useMutation({
    mutationFn: addFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dictionary-fields'] });
      enqueueSnackbar('Field added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setIsEdit(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to create Field!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const dataType = useMemo(() => {
    const selectedType = dataList?.filter((data: any) => data.id === data_type);
    return selectedType?.[0];
  }, [dataList, data_type]);

  const { mutate: deleteFieldMutation } = useMutation({
    mutationFn: deleteField,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dictionary-fields'] });
      enqueueSnackbar('Field deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Field!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (fieldData?.field_id) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [fieldData]);

  useEffect(() => {
    const dataType = dataList?.filter(
      (data: any) => data.custom_data_type === fieldData?.data_type
    );
    if (fieldData) {
      reset({
        display_name: fieldData.display_name,
        data_type: dataType?.[0]?.id || '',
        description: fieldData.description,
        required: fieldData.required,
        tags: fieldData?.tags?.map((data: any) => data.id),
        default_field_unit:
          fieldData?.set_recommended_value?.default_field_unit || '',
        default_field_value:
          fieldData?.set_recommended_value?.default_field_value || '',
        is_linked: fieldData?.parent ? true : false,
        parent: fieldData?.parent,
        parent_field_value: fieldData?.parent_field_value,
      });
    }
  }, [dataList, fieldData, reset]);

  const onSubmit = useCallback(
    (data: any) => {
      const {
        data_type,
        optional_data,
        default_field_unit,
        default_field_value,
        ...rest
      } = data;

      let optionalData;
      if (dataType?.ui_component === 'Table') {
        optionalData = optional_data.map((option: any) => {
          const selectedDataType = dataList.filter(
            (item: any) => item.id === option.data_type_id
          );
          return {
            ...option,
            field_name: `{${camelize(option.display_name)}`,
            data_type: selectedDataType?.[0]?.data_type || '',
            ui_component: selectedDataType?.[0]?.ui_component || '',
          };
        });
      } else {
        optionalData = optional_data;
      }

      const payload = {
        creation_type: 'custom',
        field_name: `{${camelize(data.display_name)}}`,
        data_type: [data_type],
        section_dictionary: [id],
        optional_data: optionalData,
        set_recommended_value: {
          default_field_unit: default_field_unit || '',
          default_field_value:
            dataType.custom_data_type.toLowerCase() === 'select'
              ? optionalData
              : default_field_value || '',
        },
        ...rest,
      };

      addFieldMutation({
        body: payload,
        ...(fieldData && { id: fieldData.field_id }),
      });
    },
    [addFieldMutation, dataList, dataType, fieldData, id]
  );

  const fields = useMemo(() => {
    if (activeSection.field_data.length) {
      const filteredFields = activeSection.field_data.filter(
        (field: any) =>
          field.data_type === 'Select' || field.data_type == 'Boolean'
      );
      if (fieldData) {
        return filteredFields.filter(
          (field: any) => field.field_id !== fieldData.field_id
        );
      }
      return filteredFields;
    }
    return [];
  }, [activeSection, fieldData]);

  const activeParentField = useMemo(() => {
    return fields.find((field: any) => field.field_id === parent);
  }, [parent, fields]);

  const getRadioOption = (options: any) => {
    if (options.length) {
      return [
        {
          value: options[0].value1,
          title: options[0].value1,
        },
        {
          value: options[0].value2,
          title: options[0].value2,
        },
      ];
    }
    return [];
  };

  return (
    <Box>
      <Stack
        direction="row"
        style={innerTabStyle}
        justifyContent="space-between"
        alignItems="center"
      >
        Field: {fieldData?.display_name || displayName}
        {fieldData?.field_id && (
          <IconButton onClick={() => deleteFieldMutation(fieldData.field_id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="insights-form">
          <Stack sx={{ padding: '30px 10px' }} gap={2}>
            <Stack direction="row" alignItems="center" alignSelf="flex-end">
              <Typography>Required Field:</Typography>
              <Controller
                name="required"
                control={control}
                defaultValue={false}
                render={({ field: { value, ...rest } }) => (
                  <Switch {...rest} checked={value} disabled={!isEdit} />
                )}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <ControlledTextField
                name="display_name"
                control={control}
                label="Display Name"
                fullWidth
                sx={{ flex: 1 }}
                required
                inputProps={{
                  readOnly: !isEdit,
                }}
              />
              <Stack
                flex={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Typography sx={{ flex: 1 }}>
                  {`Merge Tag: {${camelize(displayName || '')}} `}{' '}
                </Typography>
                {displayName && (
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `{${camelize(displayName || '')}}`
                      )
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
            <RISelectComponent
              readOnly={!isEdit}
              name="data_type"
              control={control}
              label="Field type"
              options={dataList || []}
              loading={dataLoading}
              labelKey="custom_data_type"
              required
            />
            {dataType && (
              <MapField
                dataType={dataType}
                dataList={dataList}
                fieldData={fieldData}
                isEdit={isEdit}
              />
            )}
            {fields.length > 0 && (
              <>
                <ControlledCheckbox
                  disabled={!isEdit}
                  name="is_linked"
                  label="Has Parent field"
                  control={control}
                />
                {is_linked && (
                  <Stack direction="row" gap={2}>
                    <Box sx={{ flex: 1 }}>
                      <RISelectComponent
                        readOnly={!isEdit}
                        name="parent"
                        control={control}
                        label="Select Field"
                        options={fields}
                        labelKey="display_name"
                        valueKey="field_id"
                      />
                    </Box>

                    <Box flex={1}>
                      {activeParentField?.data_type === 'Select' ? (
                        <RISelectComponent
                          readOnly={!isEdit}
                          name="parent_field_value"
                          label="Select value to match"
                          control={control}
                          options={activeParentField?.optional_data || []}
                          labelKey="name"
                          valueKey="name"
                          isMultiselect
                          renderCustomComponent={(value: any) => (
                            <CustomChip key={value?.id} label={value?.name} />
                          )}
                        />
                      ) : activeParentField?.data_type === 'Boolean' ? (
                        <RadioButtonGroup
                          row
                          label="Select value to match"
                          name="parent_field_value"
                          options={getRadioOption(
                            activeParentField.optional_data
                          )}
                          valueKey="value"
                          isDescription
                          control={control}
                        />
                      ) : null}
                    </Box>
                  </Stack>
                )}
              </>
            )}
            <ControlledTextField
              inputProps={{
                readOnly: !isEdit,
              }}
              name="description"
              multiline
              rows={4}
              control={control}
              label="Description"
              fullWidth
            />

            {(!fieldData?.field_id || isEdit) && (
              <Button form="insights-form" type="submit" variant="contained">
                Submit
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
      {fieldData?.field_id && !isEdit && (
        <Button
          type="button"
          onClick={() => setIsEdit(true)}
          variant="contained"
          sx={{ width: '-webkit-fill-available' }}
        >
          Edit
        </Button>
      )}
    </Box>
  );
};

export default FieldForm;
