import React, { useMemo, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import CompleteDraft from '../../../Draft/Component/CompleteDraft/CompleteDraft';
import PdfView from '../../../Draft/Component/PdfView';
import { fetchTemplateById } from '../../../Services/Template';

const ViewTemplate = () => {
  const { id, page } = useParams<{
    id: string;
    page: string;
  }>();

  const [instance, setInstance] = useState<any>();

  const { data: templateData, isLoading } = useQuery({
    queryKey: ['get_template_by_id', id],
    queryFn: async () => await fetchTemplateById(id as string),
    enabled: !!id,
  });

  const fileLink = useMemo(() => templateData?.access_url, [templateData]);

  return (
    <Stack>
      <Box>
        <Toolbar>
          <Link
            href={page === 'admin' ? '/admin/templates' : '/templates'}
            color="riPrimary.500"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
            Close
          </Link>

          <Typography
            textAlign="center"
            fontSize="16px"
            fontWeight="500"
            whiteSpace="nowrap"
            width="85%"
          >
            {templateData?.name || templateData?.file_name}
          </Typography>
        </Toolbar>
        <Divider />
      </Box>
      <Box padding="0 20px">
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item sm={6}>
            {!isLoading && (
              <PdfView
                key={fileLink}
                draftData={templateData}
                fileLink={templateData?.access_url}
                instance={instance}
                setInstance={setInstance}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            <Stack
              spacing={1}
              sx={{
                height: '90%',
                overflowY: 'auto',
                backgroundColor: 'riTertiary.50',
                borderRadius: '24px',
                padding: '8px',
                mt: '8px',
              }}
            >
              <CompleteDraft
                draftData={templateData}
                instance={instance}
                isTemplateView
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default ViewTemplate;
