const tooltipTheme = {
  styleOverrides: {
    tooltip: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: '50px',
      fontSize: '12px',
      fontWeight: '400',
      border: '1px solid',
      WebkitBoxShadow: '2px 4px #888888',
    },
  },
};

export default tooltipTheme;
