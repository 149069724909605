import React from 'react';

import { Avatar, AvatarProps, Fade, Tooltip } from '@mui/material';

interface IProps extends AvatarProps {
  firstName: string;
  lastName?: string;
  avatarStyle?: any;
}

const NameAvatar = ({ firstName, lastName, avatarStyle, ...props }: IProps) => {
  const tooltipTitle = lastName ? `${firstName} ${lastName}` : firstName;

  return (
    <Tooltip title={tooltipTitle} arrow TransitionComponent={Fade}>
      <Avatar
        alt={`${firstName}${lastName || ' '}`}
        sx={{
          width: 32,
          height: 32,
          fontSize: '12px',
          backgroundColor: 'riPrimary.300',
          ...avatarStyle,
        }}
        {...props}
      >
        {firstName?.charAt(0)?.toUpperCase()}
        {lastName ? lastName?.charAt(0)?.toUpperCase() : ''}
      </Avatar>
    </Tooltip>
  );
};

export default NameAvatar;
