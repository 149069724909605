import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ReminderConfirmationModal from './ReminderConfirmationModal';
import { reminderStatus } from './ReminderStatic';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import {
  formatPayloadDate,
  sortListAlphabetical,
} from '../../../Draft/Component/Helper';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import SelectElement from '../../../RiverusUI/Components/SelectElement';
import {
  createReminder,
  editReminder,
} from '../../../Services/DocumentLibrary';
import { fetchCollaborators } from '../../../Services/Draft';

interface Props {
  onClose: VoidFunction;
  approvalData: any;
  reminderData: any;
  approvals: any;
  draftData: any;
  reminderID: string;
  queryParamsTypeValue: any;
  handleButtonClick: (users: any) => void;
  setIsButtonClicked: Dispatch<SetStateAction<boolean>>;
  isButtonClicked: boolean;
}

const ReminderFormStylus: React.FC<Props> = ({
  onClose,
  approvalData,
  reminderData,
  approvals,
  draftData,
  reminderID,
  queryParamsTypeValue,
  handleButtonClick,
  setIsButtonClicked,
  isButtonClicked,
}) => {
  const methods = useForm();

  const { handleSubmit, control, setValue, watch } = methods;
  const queryClient = useQueryClient();
  const users = watch('users');

  // login user data
  const user_data = useMemo(() => getSessionStorage('user_profile'), []);

  //login user is owner
  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  const isCustomReminder = useMemo(
    () => approvals?.deviation?.deviation_type === 'custom',
    [approvals?.deviation]
  );

  const isCustomIssueOwner = useMemo(() => {
    if (approvals?.deviation?.deviation_type === 'custom') {
      const isCustomCreateBY = approvals?.created_by?.id === user_data?.id;
      return isCustomCreateBY;
    }
    return false;
  }, [approvals]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: fetchCollaborators,
    select: (response: any) => {
      return response.results.map((data: any) => ({
        ...data,
        name: `${data.first_name} ${data.last_name}`,
      }));
    },
  });

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_approvals_data'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_custom_deviations'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    setValue(
      'users',
      reminderData
        ? reminderData?.users?.map((user: any) => user?.id)
        : [approvalData?.approvers?.id]
    );
    setValue(
      'draft_name',
      reminderData ? reminderData?.draft?.contractName : draftData?.contractName
    );
    setValue(
      'name',
      reminderData
        ? reminderData?.name
        : draftData?.approval_type === 'contract_condition' ||
            (draftData?.approval_type === 'pre_signatory_approval' &&
              !draftData?.pre_signatories)
          ? `${draftData?.contractName} - Conditional Approval`
          : draftData?.approval_type === 'pre_signatory_approval' &&
              draftData?.pre_signatories
            ? `${draftData?.contractName} - Pre Signatory Approval`
            : draftData?.approval_type === 'contract_request'
              ? `${draftData?.contractName} - Requisition Approval`
              : draftData?.contractName
    );
    setValue('status', reminderData ? reminderData?.status : 'Not Started');
    setValue('due_date', reminderData ? dayjs(reminderData?.due_date) : '');
  }, [approvalData, draftData, reminderData, setValue, watch]);

  const getPayload = useCallback(
    (data: any) => {
      const payload = {
        ...data,
        draft: draftData?.id,
        reminder_type: isCustomReminder
          ? 'custom_deviation'
          : draftData?.approval_type === 'contract_condition' ||
              (draftData?.approval_type === 'pre_signatory_approval' &&
                !draftData?.pre_signatories)
            ? 'deviation'
            : draftData?.approval_type === 'pre_signatory_approval' &&
                draftData?.pre_signatories
              ? 'pre_signatory_approval'
              : 'approval',
        app: 'Stylus',
        due_date: data?.due_date
          ? formatPayloadDate(new Date(data?.due_date))
          : '',
      };
      if (
        draftData?.approval_type === 'contract_condition' ||
        (draftData?.approval_type === 'pre_signatory_approval' &&
          !draftData?.pre_signatories)
      ) {
        payload.deviation_name = 'Deviation';
        payload.deviation = approvals?.deviation?.id;
      }
      return payload;
    },
    [draftData, approvals]
  );

  const onSubmit = useCallback(
    (data: any) => {
      const payload = getPayload(data);
      if (reminderID || approvalData?.reminders?.[0]) {
        const updatedReminderPayload = {
          id: reminderID || approvalData?.reminders?.[0],
          body: payload,
        };
        update_reminder(updatedReminderPayload);
      } else {
        createReminderMutation(payload);
      }
    },
    [
      getPayload,
      approvalData?.reminders?.[0],
      reminderData?.reminder_id,
      update_reminder,
      createReminderMutation,
    ]
  );

  const isDisabledField = useMemo(
    () =>
      (!isEdit && reminderData) ||
      (userIsOwner ? false : isCustomIssueOwner ? false : true),
    [userIsOwner, isEdit, reminderData]
  );

  const filteredReminderStatus = useMemo(() => {
    return reminderStatus?.filter(
      (status) =>
        status?.id !== 'Completed' &&
        (reminderData?.status === 'Not Started' || status?.id !== 'Not Started')
    );
  }, [reminderData?.status, reminderStatus]);

  const onButtonClick = useCallback(() => {
    if (isButtonClicked) {
      setIsButtonClicked(false);
    } else {
      setIsButtonClicked(true);
    }
    handleButtonClick(users);
  }, [handleButtonClick, users, isButtonClicked]);

  const commentsCount = useMemo(() => {
    const filteredApprover = approvals?.approvers?.filter(
      (approver: any) => approver?.reminders[0] === reminderData?.id
    );
    return filteredApprover?.[0]?.comments?.length || 0;
  }, [approvals, reminderData]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '700px', padding: '30px 10px 4px' }}>
          <ControlledTextField
            name="name"
            control={control}
            label="Reminder Name"
            fullWidth
            disabled={isDisabledField}
          />
          <DatePickerElement
            name="due_date"
            control={control}
            label="Due Date"
            required
            disablePast
            sx={{ width: '100%' }}
            disabled={isDisabledField}
          />
          <Stack spacing={2}>
            <SelectElement
              fullWidth
              name="status"
              control={control}
              label="Status"
              options={sortListAlphabetical(filteredReminderStatus)}
              labelKey="name"
              disabled={!isEdit && reminderData}
            />
            <Stack width="100%" spacing={2}>
              <Typography fontWeight="700">Associated document</Typography>
              <ControlledTextField
                name="draft_name"
                control={control}
                label="Draft/Contract"
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { pointerEvents: 'none' },
                }}
              />
            </Stack>
            <Stack width="100%" spacing={2}>
              <Typography fontWeight="700">Assigned to: </Typography>
              <RISelectComponent
                name="users"
                readOnly
                control={control}
                label="Select Users"
                options={sortListAlphabetical(approversData)}
                loading={approversLoading}
                isMultiselect
                renderCustomComponent={(value: any, props) => (
                  <ShareChip
                    key={value?.id}
                    {...props}
                    label={value?.first_name}
                  />
                )}
                textFieldProps={{
                  helperText: 'You cannot modify these users',
                }}
              />
            </Stack>
          </Stack>
          {queryParamsTypeValue && (
            <Stack
              width="100%"
              sx={{
                border: '2px solid #6D264C',
                borderStyle: 'dashed',
                borderRadius: '4px',
                cursor: 'pointer',
                alignItems: 'center',
                '&:hover': {
                  border: '2px solid #6D264C',
                },
              }}
              onClick={onButtonClick}
            >
              <Badge
                badgeContent={commentsCount}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#A0597F',
                    color: '#fff',
                    top: '0.8rem',
                    right: '1rem',
                  },
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    textTransform: 'unset',
                    height: '40px',
                    alignSelf: 'center',
                    width: 'auto',
                    '&:hover': {
                      backgroundColor: 'unset',
                    },
                  }}
                  startIcon={<ChatBubbleOutlineIcon />}
                >
                  Comments
                </Button>
              </Badge>
            </Stack>
          )}
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" spacing={1}>
                {!reminderData && (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ height: '40px' }}
                  >
                    Save
                  </Button>
                )}
                {!isEdit && reminderData && (
                  <Button
                    variant="contained"
                    sx={{ height: '40px' }}
                    onClick={() => setIsEdit(true)}
                  >
                    Edit
                  </Button>
                )}
                {isEdit && (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ height: '40px' }}
                  >
                    Save
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={onClose}
                  startIcon={<CloseIcon />}
                  sx={{ height: '40px' }}
                >
                  Cancel
                </Button>
              </Stack>
              {(userIsOwner || isCustomIssueOwner) && (
                <Stack alignItems="center">
                  <Button
                    variant="text"
                    sx={{ textTransform: 'unset', height: '40px' }}
                    startIcon={<NotificationsIcon />}
                    disabled={isEdit}
                    onClick={() => {
                      if (!reminderData) {
                        setOpenForm(false);
                        setErrorMessage(
                          'Save the form to send a reminder now.'
                        );
                      } else {
                        setOpenForm(true);
                      }
                    }}
                  >
                    Send a reminder now
                  </Button>
                  {errorMessage && (
                    <Typography variant="caption" color="error">
                      {errorMessage}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <Typography component="p" variant="caption" fontWeight={600}>
                Created by:
                <Typography variant="caption" ml={1} fontWeight={400}>
                  {`${approvalData?.created_by?.first_name} ${approvalData?.created_by?.last_name}`}
                </Typography>
              </Typography>

              <Typography component="p" variant="caption" fontWeight={600}>
                Last Modified:
                <Typography variant="caption" ml={1} fontWeight={400}>
                  {dayjs(approvalData?.modified_on).format('MMMM D, YYYY')}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
      <ReminderConfirmationModal
        open={openForm}
        handleClose={() => setOpenForm(false)}
        approvalData={approvalData}
        users={users}
        reminderData={reminderData}
        approverReminderId={approvalData?.reminders?.[0]}
        onClose={onClose}
        reminderById={reminderData}
      />
    </FormProvider>
  );
};

export default ReminderFormStylus;
