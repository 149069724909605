import { ClauseType } from '../../../Constants/ClauseType';
import { SentencesData } from '../../../State/documentState';

export const getPara = (
  sentenceData: SentencesData,
  paraID: number,
  sentenceId?: number,
  row = -1,
  col = -1
) => {
  if (col > -1 && row > -1) {
    let para = '';
    const table = sentenceData?.hierarchy?.[paraID]?.table?.slice() || [];
    if (table.length > 0) {
      for (let i = 0; i < table.length; i++) {
        const rowData = table[i]?.Row?.slice() || [];
        if (rowData.length > 0) {
          for (let j = 0; j < rowData.length; j++) {
            if (rowData[j]?.Column === col && rowData[j]?.Row === row) {
              para = rowData[j]?.Content;
            }
          }
        }
      }
    } else {
      para = sentenceData?.hierarchy?.[paraID]?.para;
    }
    return para;
  }

  if (sentenceId && sentenceId > -1) {
    return sentenceData?.hierarchy?.[paraID]?.sentences?.[sentenceId]?.sentence;
  }
  return sentenceData?.hierarchy?.[paraID]?.para;
};

export const deleteSingleColumn = (clauseData: any, tableData: any) => {
  const filterColumns = clauseData?.filter(
    (data: any) =>
      data?.table?.[0]?.row !== tableData?.row ||
      data?.table?.[0]?.column !== tableData?.column
  );

  return filterColumns;
};

export const deletePhrase = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData.filter((data: any) => {
    const sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id > -1
          ? data.start_sentence_id
          : -1;
    if (deletedData.rowId > -1 && deletedData.columnId > -1) {
      return (
        data.para_id !== deletedData.paraId ||
        sentence_id !== deletedData.startSentenceId ||
        data.start_word_id !== deletedData.startWordId ||
        data.row_id !== deletedData.rowId ||
        data.column_id !== deletedData.columnId
      );
    } else {
      return (
        data.para_id !== deletedData.paraId ||
        sentence_id !== deletedData.startSentenceId ||
        data.start_word_id !== deletedData.startWordId
      );
    }
  });

  return filteredData;
};

export const deletePara = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData.filter((data: any) => {
    if (deletedData.rowId > -1 && deletedData.columnId > -1) {
      return (
        data.para_id !== deletedData.paraId ||
        data.row_id !== deletedData.rowId ||
        data.column_id !== deletedData.columnId
      );
    } else {
      return data.para_id !== deletedData.paraId;
    }
  });
  return filteredData;
};

export const deleteSentence = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData?.filter((data: any) => {
    const sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id > -1
          ? data.start_sentence_id
          : -1;
    const sentenceId =
      deletedData.sentenceId > -1
        ? deletedData.sentenceId
        : deletedData.start_sentenceId > -1
          ? deletedData.start_sentenceId
          : -1;
    if (deletedData.rowId > -1 && deletedData.columnId > -1) {
      return (
        data.para_id !== deletedData.paraId ||
        data.row_id !== deletedData.rowId ||
        data.column_id !== deletedData.columnId
      );
    } else {
      return data.para_id !== deletedData.paraId || sentence_id !== sentenceId;
    }
  });
  return filteredData;
};

export const deleteColumnData = (clauseData: any, addedData: any) => {
  const filterColumns = clauseData?.filter(
    (data: any) =>
      data.para_id !== addedData.paraId ||
      data?.table?.[0]?.row !== addedData.rowId ||
      data?.table?.[0]?.column !== addedData.columnId
  );
  return filterColumns;
};

export const isParaExist = (obj: any, list: any) => {
  let x;
  for (x in list) {
    if (list[x].para_id === obj.paraId) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
};

export const isColumnExist = (obj: any, list: any) => {
  let x;
  for (x in list) {
    if (list[x].para_id === obj.paraId) {
      if (list[x].table?.length > 0) {
        const tableData = list[x].table?.[0];
        if (
          tableData?.row === obj?.rowId &&
          tableData?.column === obj.columnId
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isSentenceExist = (obj: any, list: any) => {
  let x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === (obj?.sentenceId || 0)
    ) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
};

function containsPresentObject(obj: any, list: any) {
  let x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === obj.startSentenceId
    ) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}

export function containsPhrase(obj: any, list: any) {
  let x;
  for (x in list) {
    const sentenceId =
      obj.sentenceId > -1
        ? obj.sentenceId
        : obj.startSentenceId > -1
          ? obj.startSentenceId
          : -1;
    const sentence_id =
      list[x].sentence_id > -1
        ? list[x].sentence_id
        : list[x].start_sentence_id > -1
          ? list[x].start_sentence_id
          : -1;
    if (list[x].para_id === obj.paraId && sentence_id === sentenceId) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}

export function containIndemnityPayerPayee(
  obj: any,
  list: any,
  type: string
): boolean {
  let x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === obj.startSentenceId &&
      list[x].type === type
    ) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}

export function containsDefinitions(obj: any, list: any) {
  let x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentences_id === obj.startSentenceId
    ) {
      return true;
    }
  }
  return false;
}

export function containsPhraseWithWord(obj: any, list: any) {
  let x;
  for (x in list) {
    const sentence_id =
      list[x].sentence_id > -1
        ? list[x].sentence_id
        : list[x].start_sentence_id > -1
          ? list[x].start_sentence_id
          : -1;
    if (
      list[x].para_id === obj.paraId &&
      sentence_id === obj.startSentenceId &&
      list[x].start_word_id === obj.startWordId &&
      list[x].end_word_id === obj.endWordId
    ) {
      if (obj.rowId > -1 && obj.columnId > -1) {
        if (list[x].table?.length > 0) {
          const tableData = list[x].table?.[0];
          if (
            tableData?.row === obj?.rowId &&
            tableData?.column === obj.columnId
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}

export const getClauseObjects = (type: string, data?: any) => {
  switch (type) {
    case 'notice': {
      return { notice: [], notice_renewal: [] };
    }
    case 'non_compete': {
      return { non_compete: [] };
    }
    case 'non_solicitation': {
      return { non_solicitation: [], duration: [] };
    }
    case 'insurance': {
      return { insurance: [] };
    }
    case 'exclusivity': {
      return { exclusivity: [] };
    }
    case 'indemnity': {
      return {
        indemnity: [],
        bi_1: [],
        bi_2: [],
        transaction: [],
        amounts: [],
      };
    }
    case 'dispute_resolution': {
      return {
        dispute_resolution: [],
        panel: [],
        venue: [],
        legal_entities: [],
      };
    }
    case 'change_of_control': {
      return {
        change_of_control: [],
        definitions: [],
        termination: [],
        payment: [],
        consent: [],
        notice: [],
      };
    }
    case 'payment': {
      return {
        payment: [],
        amounts: [],
        timeline: [],
      };
    }
    case 'data_breach': {
      return {
        data_breach: [],
        notify: [],
        regulation: [],
      };
    }
    case 'events_of_default': {
      return {
        events_of_default: [],
        events: [],
      };
    }
    case 'force_majeure': {
      return {
        force_majeure: [],
        fm_events: [],
      };
    }
    case 'general_definitions': {
      return {
        general_definitions: [],
      };
    }
    case 'governing_law': {
      return {
        governing_law: [],
        jurisdiction: [],
      };
    }
    case 'limited_liability': {
      return {
        limited_liability: [],
        amounts: [],
        amount_string: [],
      };
    }
    case 'confidentiality': {
      return { confidentiality: [], nature: [], duration: [], c_except: [] };
    }
    case 'term': {
      return {
        term: [],
        duration: [],
        end: [],
        start: [],
        effective_date: [],
      };
    }
    case 'Change of Control Definition': {
      return {
        ...data,
        definitions: [],
      };
    }
    case 'Change of Control Termination': {
      return {
        ...data,
        termination: [],
      };
    }
    case 'Change of Control Notice': {
      return {
        ...data,
        notice: [],
      };
    }
    case 'Change of Control Consent': {
      return {
        ...data,
        consent: [],
      };
    }
    case 'Change of Control Payment': {
      return {
        ...data,
        payment: [],
      };
    }
    case 'Auto-Renewal': {
      return {
        ...data,
        auto_renewal: [],
      };
    }
    case 'Subletting/Assignment Consent Info': {
      return {
        ...data,
        consent: [],
      };
    }
    case 'Subletting/Assignment Notice Info': {
      return {
        ...data,
        notice: [],
      };
    }
    case 'Exception to Confidentiality': {
      return {
        ...data,
        c_except: [],
      };
    }
    default: {
      return [];
    }
  }
};

export const getEditedPayload = (diff: any) => {
  return {
    edited_content: {
      update: diff,
    },
  };
};

export const deleteDataFromObligation = (
  level: string,
  addedData: any,
  updatedData: any
) => {
  if (level === 'para') {
    const obligation_statements = deletePara(
      updatedData.obligation_statements,
      addedData
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
  if (level === 'sentence') {
    const obligation_statements = deleteSentence(
      updatedData.obligation_statements,
      addedData
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
  if (level === 'phrase') {
    const obligation_statements = deleteSentence(
      updatedData?.obligation_statements,
      addedData
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
};

export const getClauseDataFormat = (
  action: string,
  type: ClauseType | string,
  addedData: any,
  updatedData: any,
  sentenceData?: any,
  clauseItem?: any,
  addedDataIndex?: number,
  rawData?: any
) => {
  switch (type) {
    case 'notice': {
      const isExist = isSentenceExist(addedData, updatedData?.notice);

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const notice = updatedData?.notice?.slice() || [];
        notice.push(newAddedData);
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const notice = deleteSentence(updatedData.notice, addedData);
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case 'non_compete': {
      const isExist = isSentenceExist(addedData, updatedData?.non_compete);
      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const non_compete = updatedData?.non_compete?.slice() || [];
        non_compete.push(newAddedData);
        updatedData = {
          ...updatedData,
          non_compete,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const non_compete: any = deleteSentence(
          updatedData.non_compete,
          addedData
        );
        const duration: any = deleteSentence(updatedData.duration, addedData);
        const territory: any = deleteSentence(updatedData.territory, addedData);

        updatedData = {
          ...updatedData,
          non_compete,
          duration,
          territory,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Non Compete Duration': {
      const newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.duration
      );
      const duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.duration.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            duration[index] = newDurationData;
          } else {
            duration.push(newDurationData);
          }
        } else {
          const index = updatedData?.duration.findIndex((data: any) => {
            const sentence_id =
              data.sentence_id > -1
                ? data.sentence_id
                : data.start_sentence_id > -1
                  ? data.start_sentence_id
                  : -1;
            return (
              data.para_id === addedData.paraId &&
              sentence_id === addedData.startSentenceId
            );
          });
          duration[index] = newDurationData;
        }
      } else {
        duration.push(newDurationData);
      }

      const isExist = containsPresentObject(
        addedData,
        updatedData?.non_compete
      );

      const non_compete = updatedData?.non_compete?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedNonCompeteData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        non_compete.push(addedNonCompeteData);
      }
      updatedData = {
        ...updatedData,
        non_compete,
        duration,
      };
      return updatedData;
    }
    case 'Non Compete Territory': {
      if (action === 'add') {
        const newTerritoryData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          phrase: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.territory
        );

        const territory = updatedData?.territory?.slice() || [];
        if (!isPhraseExist) {
          territory.push(newTerritoryData);
        }

        const isExist = containsPresentObject(
          addedData,
          updatedData?.non_compete
        );

        const non_compete = updatedData?.non_compete?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.startSentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addedNonCompeteData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.startSentenceId,
          };
          non_compete.push(addedNonCompeteData);
        }
        updatedData = {
          ...updatedData,
          non_compete,
          territory,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const territory = deletePhrase(updatedData?.territory, addedData);
        updatedData = {
          ...updatedData,
          territory,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'payment': {
      const isExist = containsPhrase(addedData, updatedData?.payment);

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          start_sentence_id: addedData.sentenceId,
        };
        const payment = updatedData?.payment?.slice() || [];
        payment.push(newAddedData);
        updatedData = {
          ...updatedData,
          payment,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const payment: any = deleteSentence(updatedData.payment, addedData);
        const timeline = deleteSentence(updatedData.timeline, addedData);
        const amounts = deleteSentence(updatedData.amounts, addedData);
        updatedData = {
          ...updatedData,
          payment,
          timeline,
          amounts,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Payment Timeline': {
      const newTimelineData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.timeline);

      const timeline = updatedData?.timeline?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.timeline.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId &&
            data.start_sentence_id === addedData.startSentenceId
        );
        timeline[index] = newTimelineData;
      } else {
        timeline.push(newTimelineData);
      }

      const isExist = containsPhrase(addedData, updatedData?.payment);
      const payment = updatedData?.payment?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          start_sentence_id: addedData.startSentenceId,
          sentence: sentence,
        };
        payment.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        payment,
        timeline,
      };
      return updatedData;
    }
    case 'Payment Amounts': {
      const newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.value,
        amountString: addedData.phrase,
        tags: addedData.tags,
      };
      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);

      const amounts = updatedData?.amounts?.slice() || [];
      if (isAmountExist) {
        const index = updatedData?.amounts.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId &&
            data.start_sentence_id === addedData.startSentenceId
        );
        amounts[index] = newAmountData;
      } else {
        amounts.push(newAmountData);
      }
      //update Parent
      const isExist = containsPhrase(addedData, updatedData?.payment);

      const payment = updatedData?.payment?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          start_sentence_id: addedData.startSentenceId,
          sentence: sentence,
        };
        payment.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        payment,
        amounts,
      };
      return updatedData;
    }
    case 'confidentiality': {
      const isExist = isSentenceExist(addedData, updatedData?.confidentiality);

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const confidentiality = updatedData?.confidentiality?.slice() || [];
        confidentiality.push(newAddedData);
        updatedData = {
          ...updatedData,
          confidentiality,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const confidentiality: any = deleteSentence(
          updatedData.confidentiality,
          addedData
        );
        const duration: any = deleteSentence(updatedData.duration, addedData);
        const nature: any = deleteSentence(updatedData.nature, addedData);
        const c_except: any = deleteSentence(updatedData.c_except, addedData);

        updatedData = {
          ...updatedData,
          confidentiality,
          duration,
          nature,
          c_except,
        };
        return updatedData;
      }
      return;
    }
    case 'Confidentiality Duration': {
      const newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.duration
      );

      const duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.duration.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            duration[index] = newDurationData;
          } else {
            duration.push(newDurationData);
          }
        } else {
          const index = updatedData?.duration.findIndex(
            (data: any) =>
              data.para_id === addedData.paraId &&
              data.start_sentence_id === addedData.sentenceId
          );
          duration[index] = newDurationData;
        }
      } else {
        duration.push(newDurationData);
      }

      const isExist = containsPhrase(addedData, updatedData?.confidentiality);

      const confidentiality = updatedData?.confidentiality?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedConfidentialityData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        confidentiality.push(addedConfidentialityData);
      }
      updatedData = {
        ...updatedData,
        confidentiality,
        duration,
      };
      return updatedData;
    }
    case 'Confidentiality Nature': {
      if (action === 'add') {
        const newNatureData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          phrase: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.nature
        );

        const nature = updatedData?.nature?.slice() || [];
        if (!isPhraseExist) {
          nature.push(newNatureData);
        }

        const isExist = containsPresentObject(
          addedData,
          updatedData?.confidentiality
        );

        const confidentiality = updatedData?.confidentiality?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.startSentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addedConfidentialityData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.startSentenceId,
          };
          confidentiality.push(addedConfidentialityData);
        }

        updatedData = {
          ...updatedData,
          confidentiality,
          nature,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const nature = deletePhrase(updatedData?.nature, addedData);
        updatedData = {
          ...updatedData,
          nature,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Exception to Confidentiality': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const exceptionToConfidentiality = updatedData?.c_except?.slice() || [];
        exceptionToConfidentiality.push(newAddedData);

        const isExist = containsPhrase(addedData, updatedData?.confidentiality);

        const confidentiality = updatedData?.confidentiality?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addconfidentialityData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          confidentiality.push(addconfidentialityData);
        }

        updatedData = {
          ...updatedData,
          c_except: exceptionToConfidentiality,
          confidentiality,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const exceptionToConfidentiality: any = deleteSentence(
          updatedData.c_except,
          addedData
        );

        updatedData = {
          ...updatedData,
          c_except: exceptionToConfidentiality,
        };
        return updatedData;
      }
      return;
    }
    case 'non_solicitation': {
      const isExist = isSentenceExist(addedData, updatedData?.non_solicitation);
      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const non_solicitation = updatedData?.non_solicitation?.slice() || [];
        non_solicitation.push(newAddedData);
        updatedData = {
          ...updatedData,
          non_solicitation,
        };
        return updatedData;
      } else if (action === 'remove') {
        const non_solicitation: any = deleteSentence(
          updatedData.non_solicitation,
          addedData
        );
        const duration: any = deleteSentence(updatedData.duration, addedData);
        updatedData = {
          ...updatedData,
          non_solicitation,
          duration,
        };
        return updatedData;
      }
      return;
    }
    case 'Non Solicitation Duration': {
      const newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.duration
      );

      const duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.duration.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            duration[index] = newDurationData;
          } else {
            duration.push(newDurationData);
          }
        } else {
          const index = updatedData?.duration.findIndex(
            (data: any) =>
              data.para_id === addedData.paraId &&
              data.start_sentence_id === addedData.sentenceId
          );
          duration[index] = newDurationData;
        }
      } else {
        duration.push(newDurationData);
      }

      const isExist = containsPresentObject(
        addedData,
        updatedData?.non_solicitation
      );
      const non_solicitation = updatedData?.non_solicitation?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedNonSolicitationData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        non_solicitation.push(addedNonSolicitationData);
      }
      updatedData = {
        ...updatedData,
        non_solicitation,
        duration,
      };
      return updatedData;
    }
    case 'insurance': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const insurance = updatedData?.insurance?.slice() || [];
        insurance.push(newAddedData);
        updatedData = {
          ...updatedData,
          insurance,
        };
        return updatedData;
      } else if (action === 'remove') {
        const insurance = deletePara(updatedData.insurance, addedData);
        updatedData = {
          ...updatedData,
          insurance,
        };
        return updatedData;
      }

      return;
    }
    case 'governing_law': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const governing_law = updatedData?.governing_law?.slice() || [];
        governing_law.push(newAddedData);
        updatedData = {
          ...updatedData,
          governing_law,
        };
        return updatedData;
      } else if (action === 'remove') {
        let governing_law: any;
        let jurisdiction: any;
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          governing_law = deleteColumnData(
            updatedData.governing_law,
            addedData
          );
          jurisdiction = deleteColumnData(updatedData?.jurisdiction, addedData);
        } else {
          governing_law = deletePara(updatedData.governing_law, addedData);
          jurisdiction = deletePara(updatedData.jurisdiction, addedData);
        }
        updatedData = {
          ...updatedData,
          governing_law,
          jurisdiction,
        };
        return updatedData;
      }
      return;
    }
    case 'Jurisdiction': {
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.jurisdiction
      );
      if (action === 'add') {
        const newJurisdictionData = {
          clean_jurisdiction: addedData.phrase,
          end_sentence_id: addedData.startSentenceId,
          end_word_id: addedData.endWordId,
          jurisdiction: addedData.phrase,
          para_id: addedData.paraId,
          start_sentence_id: addedData.startSentenceId,
          start_word_id: addedData.startWordId,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };

        const jurisdiction = updatedData?.jurisdiction?.slice() || [];
        if (!isPhraseExist) {
          jurisdiction.push(newJurisdictionData);
        }
        const isExist = isParaExist(addedData, updatedData?.governing_law);

        const governing_law = updatedData?.governing_law?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          governing_law.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          governing_law,
          jurisdiction,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const jurisdiction = deletePhrase(updatedData?.jurisdiction, addedData);
        updatedData = {
          ...updatedData,
          jurisdiction,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'exclusivity': {
      const isExist = isSentenceExist(addedData, updatedData?.exclusivity);

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const exclusivity = updatedData?.exclusivity?.slice() || [];
        exclusivity.push(newAddedData);
        updatedData = {
          ...updatedData,
          exclusivity,
        };
        return updatedData;
      } else if (isExist && action === 'remove') {
        const exclusivity = deleteSentence(updatedData.exclusivity, addedData);

        updatedData = {
          ...updatedData,
          exclusivity,
        };
        return updatedData;
      }
      return;
    }
    case 'force_majeure': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          para: para,
          para_id: addedData.paraId,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const force_majeure = updatedData?.force_majeure?.slice() || [];
        force_majeure.push(newAddedData);
        updatedData = {
          ...updatedData,
          force_majeure,
        };
        return updatedData;
      } else if (action === 'remove') {
        const force_majeure = deletePara(updatedData.force_majeure, addedData);
        const fm_events = deletePara(updatedData.fm_events, addedData);

        updatedData = {
          ...updatedData,
          force_majeure,
          fm_events,
        };
        return updatedData;
      }
      return;
    }
    case 'Events Covered': {
      if (action === 'add') {
        const newEventData = {
          end_word_id: addedData.endWordId,
          events: addedData.phrase,
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          status: 'Approved',
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.fm_events
        );
        const fm_events = updatedData?.fm_events?.slice() || [];
        if (!isPhraseExist) {
          fm_events.push(newEventData);
        }

        const isExist = isParaExist(addedData, updatedData?.force_majeure);

        const force_majeure = updatedData?.force_majeure?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          force_majeure.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          force_majeure,
          fm_events,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const fm_events = deletePhrase(updatedData?.fm_events, addedData);
        updatedData = {
          ...updatedData,
          fm_events,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'limited_liability': {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.limited_liability
      );
      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const limited_liability = updatedData?.limited_liability?.slice() || [];
        limited_liability.push(newAddedData);
        updatedData = {
          ...updatedData,
          limited_liability,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const limited_liability = deleteSentence(
          updatedData.limited_liability,
          addedData
        );
        const amount_string = deleteSentence(
          updatedData.amount_string,
          addedData
        );
        const amounts = deleteSentence(updatedData.amounts, addedData);
        updatedData = {
          ...updatedData,
          limited_liability,
          amount_string,
          amounts,
        };
        return updatedData;
      }
      return;
    }
    case 'Limitation Of Liability Amount': {
      const newAddedData = {
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        para_id: addedData.paraId,
        amountString: addedData.phrase,
        sentence_id: addedData.startSentenceId,
        end_word_id: addedData.endWordId,
        start_word_id: addedData.startWordId,
      };
      const newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.startSentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.total,
      };
      const isPhraseExist = containsPhrase(
        addedData,
        updatedData?.amount_string
      );
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.amount_string
      );

      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);
      const amount_string = updatedData?.amount_string?.slice() || [];
      const amounts = updatedData?.amounts?.slice() || [];
      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.amount_string.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            amount_string[index] = newAddedData;
          } else {
            amount_string.push(newAddedData);
          }
        } else {
          const index = updatedData?.amount_string.findIndex(
            (data: any) =>
              data.para_id === addedData.paraId &&
              data.sentence_id === addedData.sentenceId
          );
          amount_string[index] = newAddedData;
        }
      } else {
        amount_string.push(newAddedData);
      }
      if (isAmountExist) {
        const index = updatedData?.amounts.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId &&
            data.sentence_id === addedData.sentenceId
        );
        amounts[index] = newAmountData;
      } else {
        amounts.push(newAmountData);
      }
      const isExist = containsPresentObject(
        addedData,
        updatedData?.limited_liability
      );
      const limited_liability = updatedData?.limited_liability?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedLiabilityData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        limited_liability.push(addedLiabilityData);
      }
      updatedData = {
        ...updatedData,
        limited_liability,
        amounts,
        amount_string,
      };
      return updatedData;
    }
    case 'general_definitions': {
      const sentence = getPara(
        sentenceData,
        addedData.paraId,
        addedData.startSentenceId,
        addedData?.rowId,
        addedData?.columnId
      );
      const newData = {
        para_id: addedData.paraId,
        sentence: sentence,
        end_word_id: addedData.endWordId,
        defined_word: addedData.definedword,
        definition: addedData.phrase,
        start_word_id: addedData.startWordId,
        sentences_id: addedData.startSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsDefinitions(
        addedData,
        updatedData?.general_definitions
      );
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.general_definitions
      );
      const general_definitions =
        updatedData?.general_definitions?.slice() || [];
      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.general_definitions.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            general_definitions[index] = newData;
          } else {
            general_definitions.push(newData);
          }
        } else {
          const index = updatedData?.general_definitions.findIndex(
            (data: any) =>
              data.para_id === addedData.paraId &&
              data.sentence_id === addedData.sentenceId
          );
          general_definitions[index] = newData;
        }
      } else {
        general_definitions.push(newData);
      }
      updatedData = {
        ...updatedData,
        general_definitions,
      };
      return updatedData;
    }
    case 'term': {
      const isExist = isParaExist(addedData, updatedData?.term);

      if (!isExist && action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.sentence_id,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        const term_data = updatedData?.term?.slice() || [];
        term_data.push(newAddedData);
        updatedData = {
          ...updatedData,
          term: term_data,
        };
        return updatedData;
      }

      if (isExist && action === 'remove') {
        const term = deleteSentence(updatedData.term, addedData);
        const duration = deleteSentence(updatedData.duration, addedData);
        const start = deleteSentence(updatedData.start, addedData);
        const end = deleteSentence(updatedData.end, addedData);

        updatedData = {
          ...updatedData,
          term,
          duration,
          start,
          end,
        };
        return updatedData;
      }
      return;
    }
    case 'Term Start Dates': {
      let newData = { ...updatedData };
      const newStartData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, start: [newStartData] };
      const isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        const newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case 'Term End Dates': {
      let newData = { ...updatedData };
      const newEndData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, end: [newEndData] };
      const isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        const newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case 'Term Duration': {
      let newData = { ...updatedData };
      const newDurationData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, duration: [newDurationData] };
      const isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        const newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case 'data_breach': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          para_id: addedData.paraId,
          para: para,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const data_breach = updatedData?.data_breach?.slice() || [];
        data_breach.push(newAddedData);
        updatedData = {
          ...updatedData,
          data_breach,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const data_breach = deletePara(updatedData.data_breach, addedData);
        const notify = deletePara(updatedData.notify, addedData);

        let regulation = updatedData.regulation;
        if (regulation?.para_id === addedData.paraId) {
          regulation = null;
        }

        updatedData = {
          ...updatedData,
          data_breach,
          notify,
          regulation,
        };
        return updatedData;
      }
      return;
    }
    case 'Data Breach Notify': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        const newNotifyData = {
          notify: [{ ext_: addedData.phrase }],
          para: para,
          end_word_id: addedData.endWordId,
          para_id: addedData.paraId,
          sentence_id: addedData.startSentenceId,
          start_word_id: addedData.startWordId,
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.notify
        );

        const notify = updatedData?.notify?.slice() || [];
        if (!isPhraseExist) {
          notify.push(newNotifyData);
        }

        const isExist = isParaExist(addedData, updatedData?.data_breach);

        const data_breach = updatedData?.data_breach?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          data_breach.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          data_breach,
          notify,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const notify = deletePhrase(updatedData?.newNotifyData, addedData);
        updatedData = {
          ...updatedData,
          notify,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Data Breach Regulation': {
      if (action === 'add') {
        const newRegulationData = {
          extraction: [{ ext: addedData.phrase }],
          para_id: addedData.paraId,
          end_word_id: addedData.endWordId,
          sentence_id: addedData.startSentenceId,
          start_word_id: addedData.startWordId,
        };
        const regulation = newRegulationData;

        const isExist = isParaExist(addedData, updatedData?.data_breach);

        const data_breach = updatedData?.data_breach?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          data_breach.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          data_breach,
          regulation,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const regulation = deletePhrase(updatedData?.regulation, addedData);
        updatedData = {
          ...updatedData,
          regulation,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'indemnity': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.sentence_id,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const indemnity = updatedData?.indemnity?.slice() || [];
        indemnity.push(newAddedData);
        updatedData = {
          ...updatedData,
          indemnity,
        };
        return updatedData;
      } else if (action === 'remove') {
        const indemnity = deletePara(updatedData.indemnity, addedData);
        const amounts = deletePara(updatedData.amounts, addedData);
        const transaction = deletePara(updatedData.transaction, addedData);
        const bi_1 = deletePara(updatedData.bi_1, addedData);
        const bi_2 = deletePara(updatedData.bi_2, addedData);

        updatedData = {
          ...updatedData,
          indemnity,
          amounts,
          transaction,
          bi_1,
          bi_2,
        };
        return updatedData;
      }
      return;
    }
    case 'Indemnity/Reimbursements/Costs Amount': {
      const newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.value,
        amountString: addedData.phrase,
      };
      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);
      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.amounts
      );

      const amounts = updatedData?.amounts?.slice() || [];
      if (isAmountExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.amounts.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            amounts[index] = newAmountData;
          } else {
            amounts.push(newAmountData);
          }
        } else {
          const index = updatedData?.amounts.findIndex(
            (data: any) =>
              data.para_id === addedData.paraId &&
              data.sentence_id === addedData.sentenceId
          );
          amounts[index] = newAmountData;
        }
      } else {
        amounts.push(newAmountData);
      }

      //update Parent
      const isExist = isParaExist(addedData, updatedData?.indemnity);
      const indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        amounts,
      };
      return updatedData;
    }
    case 'Indemnity/Reimbursements/Costs Extent of Cost': {
      if (action === 'add') {
        const newExtentData = {
          end_word_id: addedData.endWordId,
          para_id: addedData.paraId,
          sentence_id: addedData.startSentenceId,
          start_word_id: addedData.startWordId,
          string: addedData.phrase,
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData.bi_1
        );

        const bi_1 = updatedData?.bi_1?.slice() || [];
        if (!isPhraseExist) {
          bi_1.push(newExtentData);
        }

        //update Parent
        const isExist = isParaExist(addedData, updatedData?.indemnity);
        const indemnity = updatedData?.indemnity?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          indemnity.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          indemnity,
          bi_1,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const bi_1 = deletePhrase(updatedData?.bi_1, addedData);
        updatedData = {
          ...updatedData,
          bi_1,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Indemnity/Reimbursements/Costs Triggering Event': {
      if (action === 'add') {
        const newExtentData = {
          end_word_id: addedData.endWordId,
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          start_word_id: addedData.startWordId,
          string: addedData.phrase,
        };
        const isPhraseExist = containsPhrase(addedData, updatedData.bi_2);

        const bi_2 = updatedData?.bi_2?.slice() || [];
        if (!isPhraseExist) {
          bi_2.push(newExtentData);
        }

        //update Parent
        const isExist = isParaExist(addedData, updatedData?.indemnity);
        const indemnity = updatedData?.indemnity?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          indemnity.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          indemnity,
          bi_2,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const bi_2 = deletePhrase(updatedData?.bi_2, addedData);
        updatedData = {
          ...updatedData,
          bi_2,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Indemnity/Reimbursements/Costs Payer': {
      if (action === 'add') {
        const newExtentData = {
          end_word_id: addedData.endWordId,
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          start_word_id: addedData.startWordId,
          string: addedData.phrase,
          type: 'payer',
        };
        const isPhraseExist = containIndemnityPayerPayee(
          addedData,
          updatedData.transaction,
          'payer'
        );
        const transaction = updatedData?.transaction?.slice() || [];
        if (!isPhraseExist) {
          transaction.push(newExtentData);
        }
        //update Parent
        const isExist = isParaExist(addedData, updatedData?.indemnity);
        const indemnity = updatedData?.indemnity?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          indemnity.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          indemnity,
          transaction,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const transaction = deletePhrase(updatedData?.transaction, addedData);
        updatedData = {
          ...updatedData,
          transaction,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Indemnity/Reimbursements/Costs Payee': {
      if (action === 'add') {
        const newExtentData = {
          end_word_id: addedData.endWordId,
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          start_word_id: addedData.startWordId,
          string: addedData.phrase,
          type: 'payee',
        };
        const isPhraseExist = containIndemnityPayerPayee(
          addedData,
          updatedData.transaction,
          'payee'
        );
        const transaction = updatedData?.transaction?.slice() || [];
        if (!isPhraseExist) {
          transaction.push(newExtentData);
        }
        //update Parent
        const isExist = isParaExist(addedData, updatedData?.indemnity);
        const indemnity = updatedData?.indemnity?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          indemnity.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          indemnity,
          transaction,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const transaction = deletePhrase(updatedData?.transaction, addedData);
        updatedData = {
          ...updatedData,
          transaction,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'renewal': {
      const isExist = isParaExist(addedData, updatedData?.renewal);

      if (!isExist && action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const renewal = updatedData?.renewal?.slice() || [];
        renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const renewal = deletePara(updatedData.renewal, addedData);
        const renewal_duration = deletePara(
          updatedData.renewal_duration,
          addedData
        );
        const reference_date = deletePara(
          updatedData.reference_date,
          addedData
        );
        const auto_renewal = deletePara(updatedData.auto_renewal, addedData);
        const notice_auto_renewal = deletePara(
          updatedData.notice_auto_renewal,
          addedData
        );

        updatedData = {
          ...updatedData,
          renewal,
          auto_renewal,
          notice_auto_renewal,
          reference_date,
          renewal_duration,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Renewal clause': {
      const isExist = isParaExist(addedData, updatedData?.renewal);

      if (!isExist && action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.sentence_id,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const renewal = updatedData?.renewal?.slice() || [];
        renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const renewal = deleteSentence(updatedData.renewal, addedData);
        const renewal_duration = deleteSentence(
          updatedData.renewal_duration,
          addedData
        );
        const reference_date = deleteSentence(
          updatedData.reference_date,
          addedData
        );
        const auto_renewal = deleteSentence(
          updatedData.auto_renewal,
          addedData
        );
        const notice_auto_renewal = deleteSentence(
          updatedData.notice_auto_renewal,
          addedData
        );

        updatedData = {
          ...updatedData,
          renewal,
          auto_renewal,
          notice_auto_renewal,
          reference_date,
          renewal_duration,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Auto-Renewal': {
      const isExist = isParaExist(addedData, updatedData?.auto_renewal);

      const renewal = [...(updatedData?.renewal || [])];

      if (!isExist && action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.sentence_id,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const auto_renewal = updatedData?.auto_renewal?.slice() || [];
        const isParaExistInParent = isParaExist(
          addedData,
          updatedData?.renewal
        );
        if (!isParaExistInParent) {
          const newParentObj = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          renewal.push(newParentObj);
        }
        auto_renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          auto_renewal,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const renewal = deleteSentence(updatedData.renewal, addedData);

        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Renewal Notice Duration': {
      const newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };

      const isPhraseExist = containsPhrase(
        addedData,
        updatedData?.renewal_duration
      );

      const is_Column_Phrase_Exist = isColumnExist(
        addedData,
        updatedData?.renewal_duration
      );

      const renewal_duration = updatedData?.renewal_duration?.slice() || [];

      if (isPhraseExist) {
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          if (is_Column_Phrase_Exist) {
            const index = updatedData?.renewal_duration.findIndex(
              (data: any) =>
                data?.table?.[0]?.row === addedData.rowId &&
                data?.table?.[0]?.column === addedData.columnId
            );
            renewal_duration[index] = newDurationData;
          } else {
            renewal_duration.push(newDurationData);
          }
        } else {
          const index = updatedData?.renewal_duration.findIndex((data: any) => {
            const sentence_id =
              data.sentence_id > -1
                ? data.sentence_id
                : data.start_sentence_id > -1
                  ? data.start_sentence_id
                  : -1;
            return (
              data.para_id === addedData.paraId &&
              sentence_id === addedData.startSentenceId
            );
          });
          renewal_duration[index] = newDurationData;
        }
      } else {
        renewal_duration.push(newDurationData);
      }
      const isExist = isParaExist(addedData, updatedData?.renewal);
      const renewal = updatedData?.renewal?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedRenewalData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence_id: addedData.startSentenceId,
        };
        renewal.push(addedRenewalData);
      }
      updatedData = {
        ...updatedData,
        renewal,
        renewal_duration,
      };
      return updatedData;
    }
    case 'Notice for Auto-Renewal': {
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.notice_auto_renewal
      );
      if (action === 'add') {
        const newAutoRenewalData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          phrase: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          date: addedData.date,
        };
        const notice_auto_renewal =
          updatedData?.notice_auto_renewal?.slice() || [];
        if (!isPhraseExist) {
          notice_auto_renewal.push(newAutoRenewalData);
        }
        const isExist = isParaExist(addedData, updatedData?.renewal);
        const renewal = updatedData?.renewal?.slice() || [];
        if (!isExist) {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const addedRenewalData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          renewal.push(addedRenewalData);
        }
        updatedData = {
          ...updatedData,
          renewal,
          notice_auto_renewal,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const notice_auto_renewal = deletePhrase(
          updatedData?.notice_auto_renewal,
          addedData
        );
        updatedData = {
          ...updatedData,
          notice_auto_renewal,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Renewal Notice Reference Date': {
      const newNoticeRenewalData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        date: addedData.date,
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.reference_date
      );

      const reference_date = updatedData?.reference_date?.slice() || [];
      if (!isPhraseExist) {
        reference_date.push(newNoticeRenewalData);
      }
      const isExist = isParaExist(addedData, updatedData?.renewal);
      const renewal = updatedData?.renewal?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const addedRenewalData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence_id: addedData.startSentenceId,
        };
        renewal.push(addedRenewalData);
      }
      updatedData = {
        ...updatedData,
        renewal,
        reference_date,
      };
      return updatedData;
    }
    case 'events_of_default': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          para: para,
          para_id: addedData.paraId,
          type: 'para',
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const events_of_default = updatedData?.events_of_default?.slice() || [];
        events_of_default.push(newAddedData);
        updatedData = {
          ...updatedData,
          events_of_default,
        };
        return updatedData;
      } else if (action === 'remove') {
        const events_of_default = deletePara(
          updatedData.events_of_default,
          addedData
        );
        const events = deletePara(updatedData.events, addedData);

        updatedData = {
          ...updatedData,
          events_of_default,
          events,
        };
        return updatedData;
      }
      return;
    }
    case 'Events Of Default Event': {
      if (action === 'add') {
        const newEventData = {
          end_word_id: addedData.endWordId,
          events: addedData.phrase,
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          status: 'Approved',
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          eventId: addedData.eventId,
          sentence_id: addedData.sentenceId,
          id: addedData.id,
          eventHighlightId: addedData.eventHighlightId,
          eventName: addedData.eventName,
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.events
        );

        const events = updatedData?.events?.slice() || [];
        if (!isPhraseExist) {
          events.push(newEventData);
        }

        const isExist = isParaExist(addedData, updatedData?.events_of_default);

        const events_of_default = updatedData?.events_of_default?.slice() || [];
        if (!isExist) {
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
          };
          events_of_default.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          events_of_default,
          events,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const events_of_default = deletePhrase(
          updatedData?.events_of_default,
          addedData
        );
        updatedData = {
          ...updatedData,
          events_of_default,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'change_of_control': {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.change_of_control
      );

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const change_of_control = updatedData?.change_of_control?.slice() || [];
        change_of_control.push(newAddedData);
        updatedData = {
          ...updatedData,
          change_of_control,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const change_of_control = deleteSentence(
          updatedData.change_of_control,
          addedData
        );
        const definitions = deleteSentence(updatedData.definitions, addedData);
        const notice = deleteSentence(updatedData.notice, addedData);
        const termination = deleteSentence(updatedData.termination, addedData);
        const consent = deleteSentence(updatedData.consent, addedData);
        const payment = deleteSentence(updatedData.payment, addedData);

        updatedData = {
          ...updatedData,
          change_of_control,
          definitions,
          termination,
          consent,
          payment,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case 'Change of Control Definition': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        const definitions = updatedData?.definitions?.slice() || [];
        definitions.push(newAddedData);

        const isExist = isSentenceExist(
          addedData,
          updatedData?.change_of_control
        );

        const change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId || 0,
          };
          change_of_control.push(addChangeOfControlData);
        }
        updatedData = {
          ...updatedData,
          definitions,
          change_of_control,
        };
        return updatedData;
      } else if (action === 'remove') {
        const definitions = deletePara(updatedData.definitions, addedData);

        updatedData = {
          ...updatedData,
          definitions,
        };
        return updatedData;
      }
      return;
    }
    case 'Change of Control Termination': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const termination = updatedData?.termination?.slice() || [];
        termination.push(newAddedData);

        const isExist = isSentenceExist(
          addedData,
          updatedData?.change_of_control
        );

        const change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          termination,
          change_of_control,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const termination = deleteSentence(updatedData.termination, addedData);

        updatedData = {
          ...updatedData,
          termination,
        };
        return updatedData;
      }
      return;
    }
    case 'Change of Control Consent': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const consent = updatedData?.consent?.slice() || [];
        consent.push(newAddedData);

        const isExist = isSentenceExist(
          addedData,
          updatedData?.change_of_control
        );
        const change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          consent,
          change_of_control,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const consent = deleteSentence(updatedData.consent, addedData);

        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case 'Change of Control Notice': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const notice = updatedData?.notice?.slice() || [];
        notice.push(newAddedData);

        const isExist = isSentenceExist(
          addedData,
          updatedData?.change_of_control
        );
        const change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          notice,
          change_of_control,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const notice = deleteSentence(updatedData.notice, addedData);

        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case 'Change of Control Payment': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const payment = updatedData?.payment?.slice() || [];
        payment.push(newAddedData);

        const isExist = isSentenceExist(
          addedData,
          updatedData?.change_of_control
        );
        const change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          payment,
          change_of_control,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const payment = deleteSentence(updatedData.payment, addedData);

        updatedData = {
          ...updatedData,
          payment,
        };
        return updatedData;
      }
      return;
    }
    case 'dispute_resolution': {
      if (action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          modes: [],
        };
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        dispute_resolution.push(newAddedData);
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      } else if (action === 'remove') {
        const dispute_resolution = deletePara(
          updatedData.dispute_resolution,
          addedData
        );
        const venue = deletePara(updatedData.venue, addedData);
        const panel = deletePara(updatedData.panel, addedData);
        const legal_entities = deletePara(
          updatedData.legal_entities,
          addedData
        );

        updatedData = {
          ...updatedData,
          dispute_resolution,
          venue,
          panel,
          legal_entities,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Arbitration': {
      if (action === 'add') {
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];

        const isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: 'Arbitration' });
              } else {
                data.modes = [{ key: 'Arbitration' }];
              }
            }
          });
        } else {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: 'Arbitration' }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const disputeResolution =
          updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.paraId) {
            const modes = data.modes.filter(
              (item: any) => item.key !== 'Arbitration'
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Conciliation': {
      if (action === 'add') {
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        const isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: 'Conciliation' });
              } else {
                data.modes = [{ key: 'Conciliation' }];
              }
            }
          });
        } else {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: 'Conciliation' }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const disputeResolution =
          updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.paraId) {
            const modes = data.modes.filter(
              (item: any) => item.key !== 'Conciliation'
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Mediation': {
      if (action === 'add') {
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        const isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: 'Mediation' });
              } else {
                data.modes = [{ key: 'Mediation' }];
              }
            }
          });
        } else {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: 'Mediation' }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const disputeResolution =
          updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.paraId) {
            const modes = data.modes.filter(
              (item: any) => item.key !== 'Mediation'
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Negotiation': {
      if (action === 'add') {
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        const isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: 'Negotiation' });
              } else {
                data.modes = [{ key: 'Negotiation' }];
              }
            }
          });
        } else {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: 'Negotiation' }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const disputeResolution =
          updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.paraId) {
            const modes = data.modes.filter(
              (item: any) => item.key !== 'Negotiation'
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Others': {
      if (action === 'add') {
        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        const isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: 'Others' });
              } else {
                data.modes = [{ key: 'Others' }];
              }
            }
          });
        } else {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: 'Others' }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const disputeResolution =
          updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.paraId) {
            const modes = data.modes.filter(
              (item: any) => item.key !== 'Others'
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case 'Dispute Resolution Panel': {
      if (action === 'add') {
        const newPanelData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          capture: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.panel
        );
        const panel = updatedData?.panel?.slice() || [];
        if (!isPhraseExist) {
          panel.push(newPanelData);
        }
        const isExist = isParaExist(addedData, updatedData?.dispute_resolution);

        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        if (!isExist) {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addedDisputeResolution = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          dispute_resolution.push(addedDisputeResolution);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
          panel,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const panel = deletePhrase(updatedData?.panel, addedData);
        updatedData = {
          ...updatedData,
          panel,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Dispute Resolution Venue': {
      if (action === 'add') {
        const newVenueData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          capture: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.venue
        );
        const venue = updatedData?.venue?.slice() || [];
        if (!isPhraseExist) {
          venue.push(newVenueData);
        }
        const isExist = isParaExist(addedData, updatedData?.dispute_resolution);

        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        if (!isExist) {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addedDisputeResolution = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          dispute_resolution.push(addedDisputeResolution);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
          venue,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const venue = deletePhrase(updatedData?.venue, addedData);
        updatedData = {
          ...updatedData,
          venue,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'Dispute Resolution Act/Statute': {
      if (action === 'add') {
        const newActData = {
          para_id: addedData.paraId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          start_sentence_id: addedData.startSentenceId,
          end_sentence_id: addedData.endSentenceId,
          capture: addedData.phrase,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        const isPhraseExist = containsPhraseWithWord(
          addedData,
          updatedData?.legal_entities
        );
        const legal_entities = updatedData?.legal_entities?.slice() || [];
        if (!isPhraseExist) {
          legal_entities.push(newActData);
        }
        const isExist = isParaExist(addedData, updatedData?.dispute_resolution);

        const dispute_resolution =
          updatedData?.dispute_resolution?.slice() || [];
        if (!isExist) {
          const para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );

          const addedDisputeResolution = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          dispute_resolution.push(addedDisputeResolution);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
          legal_entities,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const legal_entities = deletePhrase(
          updatedData?.legal_entities,
          addedData
        );
        updatedData = {
          ...updatedData,
          legal_entities,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'obligation_statements': {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.obligation_statements
      );
      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const obligation_statements =
          updatedData?.obligation_statements?.slice() || [];
        obligation_statements.push(newAddedData);

        updatedData = {
          ...updatedData,
          obligation_statements,
        };
        return updatedData;
      }
      if (isExist && action === 'remove') {
        const obligation_statements = deleteSentence(
          updatedData.obligation_statements,
          addedData
        );

        updatedData = {
          ...updatedData,
          obligation_statements,
        };
        return updatedData;
      }
      return;
    }
    case 'Consent Authority': {
      if (action === 'add') {
        const consent_regulatory_bi =
          updatedData.consent_regulatory_bi?.slice() || [];
        const sentence = getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.sentence_id,
          clauseItem.table && clauseItem.table[0].row,
          clauseItem.table && clauseItem.table[0].column
        );
        let existingParaId = -1;
        for (let i = 0; i < consent_regulatory_bi.length; i++) {
          if (
            consent_regulatory_bi[i].para_id === clauseItem.para_id &&
            consent_regulatory_bi[i].sentence_id === clauseItem.sentence_id
          ) {
            existingParaId = i;
            if (addedDataIndex === 0) {
              consent_regulatory_bi[existingParaId].extraction = [];
              if (
                consent_regulatory_bi[existingParaId]?.extraction?.length ===
                  0 &&
                addedData === null
              ) {
                consent_regulatory_bi.splice(existingParaId, 1);
                const consent = updatedData?.consent?.slice() || [];
                for (let i = 0; i < consent.length; i++) {
                  if (
                    consent[i].para_id === clauseItem.para_id &&
                    consent[i].sentence_id === clauseItem.sentence_id
                  ) {
                    consent[i].type = 'Non-Regulatory';
                  }
                }
              }
            }
          }
        }
        if (addedData !== null) {
          if (existingParaId === -1) {
            let extractionAdded: any[] = [];
            if (addedData.entity) {
              extractionAdded = [
                {
                  start_word_id: -1,
                  end_word_id: -1,
                  entity: addedData.entity,
                },
              ];
            } else if (addedData.phrase) {
              extractionAdded = [
                {
                  start_word_id: -1,
                  end_word_id: -1,
                  entity: addedData.phrase,
                },
              ];
            }
            const newAddedData = {
              table: [{ row: addedData.rowId, column: addedData.columnId }],
              para_id: clauseItem.para_id,
              sentence: sentence,
              sentence_id: clauseItem.sentence_id,
              extraction: extractionAdded,
            };

            const consent = updatedData?.consent?.slice() || [];
            for (let i = 0; i < consent.length; i++) {
              if (
                consent[i].para_id === clauseItem.para_id &&
                consent[i].sentence_id === clauseItem.sentence_id
              ) {
                consent[i].type = 'Regulatory';
              }
            }
            consent_regulatory_bi.push(newAddedData);
            updatedData = {
              ...updatedData,
              consent,
              consent_regulatory_bi,
            };
          } else {
            let extraction: any = {};
            if (addedData.entity) {
              extraction = {
                start_word_id: -1,
                end_word_id: -1,
                entity: addedData.entity,
              };
            } else if (addedData.phrase) {
              extraction = {
                start_word_id: -1,
                end_word_id: -1,
                entity: addedData.phrase,
              };
            }
            updatedData.consent_regulatory_bi[existingParaId].extraction.push(
              extraction
            );
          }
        }

        return updatedData;
      }
      return;
    }
    case 'Consent Clause': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
          type: 'Non-Regulatory',
        };
        const consent = updatedData?.consent?.slice() || [];
        consent.push(newAddedData);
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const consent = deleteSentence(updatedData.consent, addedData);

        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case 'Consent Regulatory': {
      if (action === 'add') {
        let existingParaId = -1;
        for (let i = 0; i < updatedData.consent.length; i++) {
          if (
            updatedData.consent[i].para_id === clauseItem.para_id &&
            updatedData.consent[i].sentence_id === clauseItem.sentence_id
          ) {
            existingParaId = i;
          }
        }
        const consent = updatedData?.consent?.slice() || [];
        consent[existingParaId].type = addedData.typestring;
        const consent_regulatory_bi =
          updatedData?.consent_regulatory_bi?.slice() || [];
        for (let i = 0; i < consent_regulatory_bi.length; i++) {
          if (
            consent_regulatory_bi[i].para_id === clauseItem.para_id &&
            consent_regulatory_bi[i].sentence_id === clauseItem.sentence_id
          ) {
            if (addedData.typestring === 'Non-Regulatory') {
              updatedData.consent_regulatory_bi.splice(i, 1);
            }
          }
        }
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const consent = deleteSentence(updatedData.consent, addedData);
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case 'Subletting/Assignment': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
        };
        const subletting = updatedData?.subletting?.slice() || [];

        subletting.push(newAddedData);

        updatedData = {
          ...updatedData,
          subletting,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const subletting = deletePara(updatedData.subletting, addedData);

        let savedRequired = updatedData.notice?.required;
        const notice = deletePara(updatedData.notice, addedData);

        if (savedRequired) {
          notice.required = savedRequired;
        }
        if (notice?.length === 0) {
          notice.required = false;
        }

        savedRequired = updatedData.consent?.required;
        const consent = deletePara(updatedData.consent, addedData);

        if (savedRequired) {
          consent.required = savedRequired;
        }
        if (consent?.length === 0) {
          consent.required = false;
        }
        updatedData = {
          ...updatedData,
          subletting,
          notice,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case 'Subletting/Assignment Notice Info': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          sentence: sentence,
        };
        let notice = updatedData?.notice?.slice() || [];

        notice.required = addedData.requirement;
        if (notice.required === true) {
          const isExistsInNotice = isSentenceExist(
            addedData,
            updatedData?.notice
          );

          if (!isExistsInNotice) {
            notice.push(newAddedData);
            updatedData = {
              ...updatedData,
              notice,
            };
          }
          const subletting = updatedData?.subletting?.slice() || [];
          const isExist = isParaExist(addedData, updatedData?.subletting);

          if (!isExist) {
            subletting.push(newAddedData);
            updatedData = {
              ...updatedData,
              subletting,
            };
          }
        } else if (notice.required === false) {
          notice = [];
          notice.required = false;
          updatedData = {
            ...updatedData,
            notice,
          };
        }
        return updatedData;
      }
      if (action === 'remove') {
        const notice = deleteSentence(updatedData.notice, addedData);

        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case 'Subletting/Assignment Consent Info': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          sentence: sentence,
        };
        let consent = updatedData?.consent?.slice() || [];

        consent.required = addedData.requirement;
        if (consent.required) {
          const isExistsInConsent = isSentenceExist(
            addedData,
            updatedData?.consent
          );
          if (!isExistsInConsent) {
            consent.push(newAddedData);
            updatedData = {
              ...updatedData,
              consent,
            };
          }
          const subletting = updatedData?.subletting?.slice() || [];
          const isExist = isParaExist(addedData, updatedData?.subletting);

          if (!isExist) {
            subletting.push(newAddedData);
            updatedData = {
              ...updatedData,
              subletting,
            };
          }
        } else if (consent.required === false) {
          consent = [];
          consent.required = false;
          updatedData = {
            ...updatedData,
            consent,
          };
        }
        return updatedData;
      }
      if (action === 'remove') {
        const consent = deleteSentence(updatedData.consent, addedData);

        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case 'Termination clause': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const termination = updatedData?.termination?.slice() || [];
        termination.push(newAddedData);
        updatedData = {
          ...updatedData,
          termination,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const termination = deleteSentence(updatedData.termination, addedData);

        const savedPresent = updatedData.convenience?.present;
        const convenience = deleteSentence(updatedData.convenience, addedData);

        if (savedPresent) {
          convenience.present = savedPresent;
        }
        if (convenience?.length === 0) {
          convenience.present = 'No';
        }

        const lockin = deleteSentence(updatedData.lockin, addedData);
        const event = deleteSentence(updatedData.event, addedData);

        updatedData = {
          ...updatedData,
          termination,
          convenience,
          lockin,
          event,
        };
        return updatedData;
      }
      return;
    }

    case 'Termination at Convenience': {
      const isExist = isParaExist(
        addedData,
        updatedData?.termination_at_convenience
      );

      if (!isExist && action === 'add') {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        let newAddedData;
        let filteredData = [];

        if (rawData?.termination_at_convenience.length > 0) {
          filteredData = rawData?.termination_at_convenience.filter(
            (data: any) => {
              if (data.para_id === addedData.paraId) {
                const tableData = data.table?.[0];
                if (
                  tableData.row === addedData.rowId &&
                  tableData.column === addedData.columnId
                ) {
                  return data;
                } else {
                  return data;
                }
              }
            }
          );
        }
        if (filteredData.length > 0) {
          newAddedData = filteredData[0];
        } else {
          newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para || '',
            status: 'Approved',
            sub_key: { matches: '[]' },
          };
        }

        const termination_at_convenience =
          updatedData?.termination_at_convenience?.slice() || [];
        termination_at_convenience.push(newAddedData);

        updatedData = {
          ...updatedData,
          termination_at_convenience,
        };
        return updatedData;
      }

      if (isExist && action === 'remove') {
        const termination_at_convenience = deletePara(
          updatedData.termination_at_convenience,
          addedData
        );

        updatedData = {
          ...updatedData,
          termination_at_convenience,
        };
        return updatedData;
      }
      return;
    }

    case 'termination': {
      const isExist = isSentenceExist(addedData, updatedData?.termination);

      if (!isExist && action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        const newAddedDataTermination = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence || '',
          sentence_id: 0,
        };

        const termination = updatedData?.termination?.slice() || [];
        termination.push(newAddedDataTermination);
        updatedData = {
          ...updatedData,
          termination,
        };
        return updatedData;
      }
      return updatedData;
    }

    case 'Lock-in Period': {
      if (action === 'add') {
        if (addedData !== null) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          const newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            phrase: addedData.phrase,
          };

          const lockin = [];
          if (addedData !== null) {
            lockin.push(newAddedData);
          }
          updatedData = {
            ...updatedData,
            lockin,
          };
          const termination = updatedData?.termination?.slice() || [];

          const isExists = isParaExist(addedData, updatedData?.termination);

          if (!isExists) {
            termination.push(newAddedData);
            updatedData = {
              ...updatedData,
              termination,
            };
          }
          return updatedData;
        } else {
          updatedData = {
            ...updatedData,
            lockin: [],
          };
          return updatedData;
        }
      }
      if (action === 'remove') {
        const lockin = deleteSentence(updatedData.lockin, addedData);

        updatedData = {
          ...updatedData,
          lockin,
        };
        return updatedData;
      }
      return;
    }
    case 'Termination Event': {
      if (action === 'add') {
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          phrase: addedData.phrase,
          eventName: addedData.eventName,
          sentence_id: addedData.sentenceId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          eventHighlightId: addedData.eventHighlightId,
          eventId: addedData.eventId,
          id: addedData.id,
        };
        const event = updatedData?.event?.slice() || [];

        let isExistsInEvent = false;
        for (let i = 0; i < event.length; i++) {
          if (
            event[i].para_id === newAddedData.para_id &&
            event[i].phrase === newAddedData.phrase &&
            event[i].eventName === newAddedData.eventName
          ) {
            isExistsInEvent = true;
          }
        }
        const isColumnExistInEvent = isColumnExist(
          addedData,
          updatedData?.event
        );
        if (!isExistsInEvent || !isColumnExistInEvent) {
          event.push(newAddedData);
          updatedData = {
            ...updatedData,
            event,
          };
        }
        const termination = updatedData?.termination?.slice() || [];

        const isExists = isSentenceExist(addedData, updatedData?.termination);

        if (!isExists) {
          termination.push(newAddedData);
          updatedData = {
            ...updatedData,
            termination,
          };
        }
        return updatedData;
      }
      if (action === 'remove') {
        let event: any;
        if (addedData?.rowId > -1 && addedData?.columnId > -1) {
          event = deleteSentence(updatedData?.event, addedData);
        } else {
          event = updatedData.event.filter(
            (data: any) =>
              data.eventName !== addedData.eventName ||
              data.phrase !== addedData.phrase
          );
        }
        updatedData = {
          ...updatedData,
          event,
        };
        return updatedData;
      }
      return;
    }
    case 'Contracting Parties': {
      if (action === 'add') {
        const newAddedData = {
          partyName: addedData.partyName
            ? addedData.partyName
            : addedData.party,
          partyType: addedData.partyType ? addedData.partyType : '',
          id: addedData.id ? addedData.id : '',
        };

        let isExist = false;
        const contracting_parties =
          updatedData?.contracting_parties?.slice() || [];
        for (let i = 0; i < contracting_parties.length; i++) {
          if (contracting_parties[i]?.party === newAddedData.partyName) {
            contracting_parties[i].partyType = newAddedData.partyType;
            isExist = true;
          }
          if (contracting_parties[i]?.partyName === newAddedData.partyName) {
            isExist = true;
          }
        }
        if (!isExist) {
          contracting_parties.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          contracting_parties,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const contracting_parties = updatedData.contracting_parties.filter(
          (data: any) =>
            data.partyName
              ? data.partyName !== addedData.partyName
              : data.party !== addedData.party
        );
        updatedData = {
          ...updatedData,
          contracting_parties,
        };
        return updatedData;
      }
      return;
    }
  }
};

export const CLAUSE_DATA = {
  term_clause: {
    heading: 'Term Clause',
    start_date: 'Start Dates',
    end_date: 'End Dates',
    duration: 'Duration',
  },
  renewal_clause: {
    heading: 'Renewal Clause',
    auto_renewal: 'Auto Renewal',
    notice_auto_renewal: 'Notice for Auto-Renewal',
    notice_reference_date: 'Notice Reference Date',
    duration: 'Duration',
  },
  indemnity_clause: {
    heading: 'Indemnity/Reimbursements/Costs',
    extent_cost: 'Extent of costs',
    triggering_events: 'Triggering Events',
  },
  confidentiality_clause: {
    heading: 'Confidentiality Clause',
    post_contract: 'Post Contract Duration',
    nature: 'Nature',
    exception_confidentiality: 'Exception to Confidentiality',
  },
};
