import React from 'react';

export default function BarLoader() {
  return (
    <div className="loader-bar-container">
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
      <div className="loader-bar"></div>
    </div>
  );
}
