import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export interface AnimatedLines {
  width: number;
  height: number;
}

export interface Props {
  animatedLines: AnimatedLines[];
}

export default class LinesLoader extends React.Component<Props> {
  render() {
    const { animatedLines } = this.props;
    return (
      <Stack gap={1}>
        {animatedLines.map((line, index) => (
          <Skeleton key={index} width={line.width} height={line.height} />
        ))}
      </Stack>
    );
  }
}
