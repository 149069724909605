import { UMBRELLA } from '../Actions/definitions';

export default interface HeimdallState {
  platform: string;
}

export function getDefaultHeimdallState(): HeimdallState {
  return {
    platform: UMBRELLA,
  };
}
