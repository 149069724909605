import colors from './colors';

const drawerTheme = {
  styleOverrides: {
    root: {
      zIndex: 1300,
    },
    paper: {
      width: '450px',
      padding: '2px',
      background: colors?.riTertiary?.[50],
      color: colors?.primary?.main,
    },
  },
  defaultProps: {
    role: 'presentation',
  },
};

export default drawerTheme;
