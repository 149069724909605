import React from 'react';

import { Box, ChipProps, Stack, TextFieldProps } from '@mui/material';

import AutocompleteElement from './AutoCompleteElement';
import { FormInputProps } from '../Interfaces/FormInputProps';

interface IProps extends FormInputProps {
  isMultiselect?: boolean;
  chipStyle?: any;
  name: string;
  control: any;
  renderCustomComponent?: (value: string, props?: ChipProps) => React.ReactNode;
  loading?: boolean;
  showCheckbox?: boolean;
  defaultValue?: any;
  required?: boolean;
  labelKey?: string;
  valueKey?: string;
  countKey?: string;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
  fixedValues?: any;
  readOnly?: boolean;
  canCreateNew?: boolean;
  canSelectAll?: boolean;
  addNewValue?: (value: string) => void;
  CustomInput?: React.ElementType;
  disableCloseOnSelect?: boolean;
  isFilterCustomComponent?: boolean;
  isShowDropDown?: boolean;
  renderOption?: () => any;
  disableOptionCondition?: (option: any) => boolean;
  renderProgress?: (value: number) => React.ReactElement;
  renderAction?: (value: string | any) => React.ReactElement;
  isGroupByOptions?: boolean;
  groupValue?: string;
}

export default function RISelectComponent({
  isMultiselect = false,
  label,
  options,
  name,
  loading = false,
  showCheckbox = true,
  control,
  renderCustomComponent,
  required = false,
  fixedValues,
  readOnly,
  canCreateNew,
  canSelectAll,
  CustomInput,
  addNewValue,
  disableCloseOnSelect,
  isShowDropDown,
  renderOption,
  disableOptionCondition,
  isFilterCustomComponent = false,
  isGroupByOptions,
  groupValue,
  ...props
}: IProps) {
  return (
    <AutocompleteElement
      readOnly={readOnly}
      label={label}
      name={name}
      multiple={isMultiselect}
      options={options || []}
      matchId
      showCheckbox={showCheckbox}
      control={control}
      loading={loading}
      required={required}
      fixedValues={fixedValues}
      canCreateNew={canCreateNew}
      canSelectAll={canSelectAll}
      addNewValue={addNewValue}
      CustomInput={CustomInput}
      isGroupByOptions={isGroupByOptions}
      groupValue={groupValue}
      autocompleteProps={{
        disableCloseOnSelect: disableCloseOnSelect,
        renderOption,
        getOptionDisabled: disableOptionCondition,
        freeSolo: isShowDropDown,
        renderTags: (value, getTagProps) =>
          renderCustomComponent ? (
            isFilterCustomComponent ? (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: 0.5,
                  height: '55px',
                  alignItems: 'center',
                  overflow: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {value.map((option: any, index) => (
                  <React.Fragment key={index}>
                    {renderCustomComponent(option, {
                      ...getTagProps({ index }),
                    })}
                  </React.Fragment>
                ))}
              </Box>
            ) : (
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1}
                key={Math.random()}
              >
                {value.map((option: any, index) => (
                  <React.Fragment key={index}>
                    {renderCustomComponent(option, {
                      ...getTagProps({ index }),
                    })}
                  </React.Fragment>
                ))}
              </Stack>
            )
          ) : null,
      }}
      {...props}
    />
  );
}
