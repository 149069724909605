import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import * as changesets from 'json-diff-ts';

import { ClauseType } from '../../../../Constants/ClauseType';
import {
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  SentencesData,
  tableInfo,
} from '../../../../State/documentState';
import { getClauseDataFormat } from '../../../ClauseComponent/utils/ClauseTypeUtils';
import {
  filterPhrasesFromPhrasesArray,
  filterTableCellsFromPhraseArray,
  getPhrasesFromChild,
  isTableCell,
  phraseBiMap,
  phraseLevelMapping,
} from '../../../Utils/docUtils';

interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: VoidFunction;
  clauseData: any;
}

const EditPhrase: React.FC<Props> = (props) => {
  const {
    clauseDataByType,
    postClauseDataByType,
    fileId,
    parentClauseType,
    saveAddPhrase,
    saveDeletePhrase,
    saveHighlightedTableCells,
    saveHighlightedPhrases,
    savedHighlightedPhrases,
    savePhraseEditOption,
    clauseType,
    saveHighlightedDataPoint,
    phraseEditOptionSelected,
    editOptionSelected,
    onClose,
    savedInsight,
    updatedClauseDataByType,
    sentenceData,
    phraseInAddMode,
    dataPointName,
  } = props;
  const [isAddingPhrase, setIsAddingPhrase] = useState(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] =
    useState<phraseInfo | null>(null);

  useEffect(() => {
    const oldPhrases = getPhrasesFromChild(savedInsight, clauseType);
    saveHighlightedPhrases(oldPhrases);
    saveHighlightedDataPoint(dataPointName);
  }, []);

  useEffect(() => {
    if (phraseInAddMode !== null) {
      setIsAddingPhrase(false);
    }
  }, [phraseInAddMode]);

  const getAddedAndDeletedPhrases = (
    previousLinkedPhrases: phraseInfo[],
    changedLinkedPhrases: phraseInfo[]
  ): editedPhrases => {
    const addedPhrases: phraseInfo[] = [];
    const deletedPhrases: phraseInfo[] = [];
    if (previousLinkedPhrases.length !== 0) {
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        //get newly added phrases
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < previousLinkedPhrases.length; j++) {
            if (
              changedLinkedPhrases[i].paraId === previousLinkedPhrases[j].paraId
            ) {
              if (
                changedLinkedPhrases[i].startWordId ===
                  previousLinkedPhrases[j].startWordId &&
                changedLinkedPhrases[i].endWordId ===
                  previousLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            addedPhrases.push(changedLinkedPhrases[i]);
          }
        }

        //get Deleted phrases
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < changedLinkedPhrases.length; j++) {
            if (
              previousLinkedPhrases[i].paraId === changedLinkedPhrases[j].paraId
            ) {
              if (
                previousLinkedPhrases[i].startWordId ===
                  changedLinkedPhrases[j].startWordId &&
                previousLinkedPhrases[i].endWordId ===
                  changedLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            deletedPhrases.push(previousLinkedPhrases[i]);
          }
        }
        //all deleted
      } else if (changedLinkedPhrases.length === 0) {
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          deletedPhrases.push(previousLinkedPhrases[i]);
        }
      }
    } else {
      //newly added
      if (changedLinkedPhrases !== null && changedLinkedPhrases.length !== 0) {
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          addedPhrases.push(changedLinkedPhrases[i]);
        }
      }
    }
    const biType = phraseBiMap[dataPointName];

    const tempEditedPhrases: editedPhrases = {
      upsert: addedPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return tempEditedPhrases;
  };

  const getEditedTableCellPhrases = (
    previousLinkedTableCells: phraseInfo[],
    changedLinkedTableCells: phraseInfo[]
  ) => {
    const addedTableCells: phraseInfo[] = [];
    const deletedTableCells: phraseInfo[] = [];
    if (previousLinkedTableCells.length > 0) {
      if (changedLinkedTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push(changedLinkedTableCells[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push(previousLinkedTableCells[i]);
          }
        }
      } else {
        //previous deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push(previousLinkedTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedLinkedTableCells.length > 0) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          addedTableCells.push(changedLinkedTableCells[i]);
        }
      }
    }

    const editedTableCells: editedPhrases = {
      upsert: addedTableCells,
      deleted: deletedTableCells,
      bi: '',
    };
    return editedTableCells;
  };

  const mergePhrases = (
    firstEditedPhraseArray: editedPhrases,
    secondEditedPhraseArray: editedPhrases
  ) => {
    const upsertPhrases: phraseInfo[] = firstEditedPhraseArray.upsert.concat(
      secondEditedPhraseArray.upsert
    );
    const deletedPhrases: phraseInfo[] = firstEditedPhraseArray.deleted.concat(
      secondEditedPhraseArray.deleted
    );
    const biType = phraseBiMap[dataPointName];
    const mergedPhrases: editedPhrases = {
      upsert: upsertPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return mergedPhrases;
  };

  const linkToPhrase = () => {
    savePhraseEditOption(true);
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    saveDeletePhrase(null, false);
    saveHighlightedTableCells(null);
    editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = '';

    if (isTableCell(phraseInfo)) {
      const tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        'p' +
        phraseInfo.paraId +
        ';r' +
        phraseInfo.rowId +
        ';c' +
        phraseInfo.columnId;
      saveHighlightedTableCells([tempTableCell]);
    } else {
      deletePhraseElement =
        'p' + phraseInfo.paraId + ';w' + phraseInfo.startWordId;
      saveHighlightedTableCells(null);
    }
    const phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = 'smooth';

    saveDeletePhrase(phraseInfo, true);
    saveHighlightedDataPoint(dataPointName);
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    editOptionSelected(true);
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack alignItems="start" spacing={1} width="100%">
          <Button
            sx={{
              color:
                isAddingPhrase !== true
                  ? '#88305F'
                  : phraseEditOptionSelected === true
                    ? '#C1C1C1'
                    : '#88305F',
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Button
            sx={{
              color:
                currentEditingPhrase === phraseInfo ? '#C1C1C1' : '#88305F',
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  const addOrRemovePhrase = (action: string, phraseInfo: phraseInfo | null) => {
    if (action === 'add') {
      saveAddPhrase(null, false);
      saveDeletePhrase(null, false);
      saveHighlightedTableCells(null);
      const tempPhrases = savedHighlightedPhrases;
      if (tempPhrases !== null && phraseInfo !== null) {
        tempPhrases.push(phraseInfo);
        saveHighlightedPhrases(tempPhrases);
      } else {
        if (phraseInfo === null) {
          saveHighlightedPhrases(phraseInfo);
        } else {
          saveHighlightedPhrases([phraseInfo]);
        }
      }
      setIsAddingPhrase(false);
      setCurrentEditingPhrase(null);
    } else if (action === 'remove') {
      if (phraseInfo) {
        const tempPhrases =
          savedHighlightedPhrases?.filter(
            (data: any) =>
              data.paraId !== phraseInfo.paraId ||
              data.startSentenceId !== phraseInfo.startSentenceId ||
              data.startWordId !== phraseInfo.startWordId
          ) || [];
        saveHighlightedPhrases(tempPhrases?.length === 0 ? null : tempPhrases);
      } else {
        saveHighlightedPhrases(null);
      }
      saveDeletePhrase(null, false);
      saveHighlightedTableCells(null);
    }
    savePhraseEditOption(false);
  };

  const getPhraseEdit = () => {
    if (
      savedHighlightedPhrases !== null &&
      savedHighlightedPhrases.length > 0
    ) {
      return (
        <>
          {savedHighlightedPhrases.map((phraseIter: any, index: number) => (
            <Stack key={index} width="100%" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                spacing={1}
              >
                <textarea
                  rows={2}
                  cols={50}
                  name="text"
                  maxLength={50}
                  className="tag-input"
                  value={phraseIter.phrase}
                  style={{
                    minHeight: '131px',
                    lineHeight: '15px',
                    width: '80%',
                  }}
                  readOnly
                />
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => addOrRemovePhrase('remove', phraseIter)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>

              {linkPhraseOnDoc(phraseIter)}
            </Stack>
          ))}
          <Stack width="100%" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <textarea
                rows={2}
                cols={50}
                name="text"
                maxLength={50}
                className="tag-input"
                readOnly
                value={phraseInAddMode !== null ? phraseInAddMode.phrase : ''}
                style={{
                  minHeight: '131px',
                  lineHeight: '15px',
                  width: '80%',
                }}
              />
              <IconButton
                disabled={phraseInAddMode === null}
                sx={{ padding: 0 }}
                onClick={() => addOrRemovePhrase('add', phraseInAddMode)}
              >
                <AddIcon />
              </IconButton>
            </Stack>
            {linkPhraseOnDoc(phraseInAddMode)}
          </Stack>
        </>
      );
    } else {
      return (
        <Stack spacing={1} width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            spacing={1}
          >
            <textarea
              rows={3}
              cols={70}
              name="text"
              maxLength={50}
              className="tag-input"
              value={phraseInAddMode !== null ? phraseInAddMode.phrase : ''}
              style={{ minHeight: '131px', lineHeight: '15px', width: '80%' }}
            />
            <IconButton
              disabled={phraseInAddMode === null}
              sx={{ padding: 0 }}
              onClick={() => addOrRemovePhrase('add', phraseInAddMode)}
            >
              <AddIcon />
            </IconButton>
          </Stack>
          {linkPhraseOnDoc(phraseInAddMode)}
        </Stack>
      );
    }
  };

  const onCancel = () => {
    onClose();
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    saveHighlightedPhrases(null);
    savePhraseEditOption(false);
    editOptionSelected(false);
    saveHighlightedTableCells(null);
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };

    const oldPhrasesArray = getPhrasesFromChild(savedInsight, clauseType);

    const oldPhrases = filterPhrasesFromPhrasesArray(oldPhrasesArray);
    const oldTableCells = filterTableCellsFromPhraseArray(oldPhrasesArray);
    const changedPhrasesArray =
      savedHighlightedPhrases !== null ? savedHighlightedPhrases : [];
    if (phraseInAddMode !== null) {
      changedPhrasesArray.push(phraseInAddMode);
    }
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };

    const changedPhrases = filterPhrasesFromPhrasesArray(changedPhrasesArray);
    const changedTableCells =
      filterTableCellsFromPhraseArray(changedPhrasesArray);

    const editedPhrases = getAddedAndDeletedPhrases(oldPhrases, changedPhrases);
    const editedTableCells = getEditedTableCellPhrases(
      oldTableCells,
      changedTableCells
    );
    addedDeletedPhrases = mergePhrases(editedPhrases, editedTableCells);

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };

    return tempPhraseRequest;
  };

  const onSave = () => {
    const tempPhraseRequest = getTempPhrase();

    const updatedData = updatedClauseDataByType;
    let newData = updatedData;

    const addedData = tempPhraseRequest.editedPhrases.upsert;
    const deletedData = tempPhraseRequest.editedPhrases.deleted;

    if (addedData.length > 0) {
      for (let i = 0; i < addedData.length; i++) {
        if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
          newData = getClauseDataFormat(
            'add',
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
    }
    if (deletedData.length > 0) {
      for (let i = 0; i < deletedData.length; i++) {
        newData = getClauseDataFormat(
          'remove',
          clauseType as ClauseType,
          deletedData[i],
          newData,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }

    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    savePhraseEditOption(false);
    saveHighlightedPhrases(null);
    editOptionSelected(false);
    onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600}>
        Add/edit {phraseLevelMapping[dataPointName]}
      </Typography>
      <Stack className="edit-clause-select" spacing={2}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ paddingRight: '20px' }}
        >
          {getPhraseEdit()}
        </Grid>
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to link a phrase ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Click on `&quot;`Link Phrase`&quot;`.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Select and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Click on the + icon besides the box where text is copied.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            6). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditPhrase;
