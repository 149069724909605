import React from 'react';

import { Button, Grid, Link, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import { ReactComponent as RiverusLogo } from '../../RiverusUI/Components/Icons/web-menubar-logo.svg';

interface IProps {
  handleKeycloakRealmNameChange: (realmName: string) => void;
}

interface IFormInput {
  realmName: string;
}

const Login: React.FC<IProps> = ({ handleKeycloakRealmNameChange }) => {
  const methods = useForm<IFormInput>();
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    handleKeycloakRealmNameChange(data.realmName);
  };

  return (
    <Box className="cover-background">
      <Grid container sx={{ height: '100%', padding: '20px 50px' }}>
        <Grid item sm={8} className="image-background" />
        <Grid
          item
          sm={4}
          sx={{ maxWidth: '480px', alignSelf: 'center', position: 'right' }}
        >
          <Box
            sx={{
              alignContent: 'center',
              margin: '0 auto 30px',
              width: '180px',
            }}
          >
            <RiverusLogo />
          </Box>
          <Box
            sx={{
              marginBottom: 4,
              padding: '32px',
              background: 'white',
              borderRadius: '6px',
              boxShadow: '8px 8px 16px 0px rgba(8, 13, 37, 0.25)',
            }}
          >
            <FormProvider {...methods}>
              <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: '#2B3C7F', fontWeight: 600 }}
                >
                  Name of Organization
                </Typography>
                <ControlledTextField
                  name="realmName"
                  fullWidth={true}
                  id="outlined-basic"
                  variant="outlined"
                />
                <Button
                  type="submit"
                  className="org-continue-btn"
                  sx={{ width: '100%', borderRadius: '5px', color: 'white' }}
                >
                  Continue
                </Button>
              </Stack>
            </FormProvider>
          </Box>
          <Typography
            component={Stack}
            spacing={2}
            direction="row"
            sx={{ color: '#818181' }}
          >
            <Link href="https://www.riverus.co" target="_blank">
              Home
            </Link>
            <Link href="https://www.riverus.co/privacy-policy" target="_blank">
              Terms of Service
            </Link>
            <Link href="https://www.riverus.co/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
