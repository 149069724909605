import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import ViewDocumentDrawer from '../../../DocumentLibrary/Component/TableComponents/ViewDocumentDrawer';

interface Props {
  supportDocuments: any[];
  downloadFile: any;
}

const SupportDocuments: React.FC<Props> = ({
  supportDocuments,
  downloadFile,
}) => {
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);

  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
    setViewDocumentId('');
  };

  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography fontSize="14px" fontWeight={600}>
              Support Documents
            </Typography>
            <Typography variant="caption">
              {supportDocuments?.length}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2} padding="0 10px">
            {supportDocuments?.map((item: any) => (
              <Stack
                key={item?.id}
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontWeight={600}>{item?.file_name}</Typography>
                <Stack spacing={1} direction="row" alignItems="center">
                  <IconButton onClick={() => downloadFile(item?.id)}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setOpenViewDrawer(true);
                      setViewDocumentId(item?.id);
                    }}
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
            {supportDocuments?.length === 0 && (
              <Typography>No support document in this contract</Typography>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
    </React.Fragment>
  );
};

export default SupportDocuments;
