/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import WebViewer from '@pdftron/webviewer';

import { getLocalStorage } from '../../Authentication/Actions/authentication';
import SettingIcon from '../../RiverusUI/Components/Icons/SettingIcon';
import { getdraftbyid } from '../DA';

interface Props {
  setComparisonDrawer: any;
  openComparisonDrawer: any;
  draftDetails: any;
  selectedDraftsLink: any;
  onClose: any;
  compareFileName: any;
}

export default function ComparisonDialog(props: Props) {
  const [inta, seInsta] = useState<any>('');
  // const [enableKDP] = React.useState(false);
  // const [annotationIds, setannotationIds] = React.useState<any>([]);
  const [isSearch, setSearch] = useState<any>(false);
  const [link1, setLink1] = useState<string>('');
  const [link2, setLink2] = useState<string>('');

  const documentContainer = useRef<HTMLDivElement>(null);

  const convertPDF = async (Core: any, fileURL: any) => {
    // perform the conversion with no optional parameters
    try {
      const buf = await Core.officeToPDFBuffer(fileURL);

      //optionally save the blob to a file or upload to a server
      const blob = new Blob([buf], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const draftAccessURL = async (id: string) => {
    const response = await getdraftbyid(id);
    if (response) {
      return response.access_url;
    }
    return '';
  };

  useEffect(() => {
    draftAccessURL(props.selectedDraftsLink[0]).then((value) => {
      if (value) {
        setLink1(value);
      }
    });
    draftAccessURL(props.selectedDraftsLink[1]).then((value) => {
      if (value) {
        setLink2(value);
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      if (documentContainer.current != null && link1 != '' && link2 != '') {
        WebViewer(
          {
            fullAPI: true,
            disableMultiViewerComparison: true,
            path: '/webviewer',
            disabledElements: ['header', 'toolsHeader'],
            loadAsPDF: true,
          },
          documentContainer.current
        ).then((instance: any) => {
          const { UI, Core } = instance;
          const { Annotations } = Core;
          const { Color } = Annotations;

          seInsta(instance);
          instance.UI.openElements(['loadingModal']);

          UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
            const [documentViewer1, documentViewer2] =
              Core.getDocumentViewers();

            const startCompare = async () => {
              const shouldCompare =
                documentViewer1.getDocument() && documentViewer2.getDocument();

              if (shouldCompare) {
                // Check if both documents loaded before comparing
                const beforeColor = new Color(255, 73, 73, 0.4);
                const afterColor = new Color(21, 205, 131, 0.4);
                const options = {
                  beforeColor,
                  afterColor,
                };

                await documentViewer1.startSemanticDiff(
                  documentViewer2,
                  options
                );
              }
            };

            const options1 = { filename: `${props.compareFileName[0]}.pdf` };
            const options2 = { filename: `${props.compareFileName[1]}.pdf` };

            documentViewer1.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );
            documentViewer2.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );

            const iframeDoc = instance.UI.iframeWindow.document;

            //access the container element by id of each view
            const leftView = iframeDoc.getElementById('container1');
            const rightView = iframeDoc.getElementById('container2');

            //drop area is the first child of the container when document is not loaded
            leftView.firstChild.style.display = 'none';
            rightView.firstChild.style.display = 'none';

            const leftCross = iframeDoc.getElementById('header1');
            const rightCross = iframeDoc.getElementById('header2');

            leftCross.lastChild.lastChild.style.display = 'none';
            rightCross.lastChild.lastChild.style.display = 'none';

            const blob1 = await convertPDF(Core, link1);
            const blob2 = await convertPDF(Core, link2);

            documentViewer1.loadDocument(blob1, options1);
            documentViewer2.loadDocument(blob2, options2);

            instance.UI.closeElements(['loadingModal']);
          });
          UI.enableFeatures([UI.Feature.MultiViewerMode]);
        });
      }
    }, 200);
  }, [documentContainer.current, props.selectedDraftsLink, link1, link2]);

  const [isDark, setTheme] = useState(false);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };

  const search = () => {
    setSearch(true);
    inta.UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  };

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={props.openComparisonDrawer}
      className="draft-main"
    >
      <DialogTitle>
        <Box p={0}>
          <Stack
            direction="row"
            gap={1}
            style={{ fontSize: '16px' }}
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                props.setComparisonDrawer(false);
                props.onClose();
              }}
            >
              <ArrowBackIcon sx={{ width: '15px' }} />
            </IconButton>
            Compare Draft
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ margin: 0, padding: '16px 0' }}>
        <Box height="80vh">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="0 15px"
          >
            <IconButton
              onClick={() => {
                setTheme(!isDark);
                if (isDark) {
                  lightTheme();
                } else {
                  darkTheme();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
            <IconButton
              onClick={() => {
                if (isSearch) {
                  inta.UI.closeElements(['searchPanel']);
                  setSearch(false);
                } else {
                  search();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
          </Stack>
          <Box
            ref={documentContainer}
            id="document-viewer"
            sx={{ height: '100%', padding: 2 }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
