/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { CLAUSE_RENDER_DATA } from './ClauseTypeRender';
import { getLocalStorage } from '../../../Authentication/Actions/authentication';
import { getPara } from '../ClauseComponent/utils/ClauseTypeUtils';

const lg = '#D8E4BC';
const p16 = '16px';
const userName = getLocalStorage('user_name');

const DownloadInsights = (props) => {
  const { insightsData, quickIntel, contracting_parties, sentenceData } = props;
  const [insights, setInsights] = useState([]);
  const [tableTopInfo, setTableTopInfo] = useState([]);

  useEffect(() => {
    let insights = [];

    if (insightsData) {
      for (const [key, values] of Object.entries(CLAUSE_RENDER_DATA)) {
        const getClauseData = insightsData?.[key];
        for (const [keyValue, value] of Object.entries(values)) {
          const getChildData = getClauseData?.[keyValue];
          if (getChildData?.length > 0) {
            const getParas = getChildData?.map((item, index) => {
              const para = getPara(sentenceData, item?.para_id);
              return `${index + 1}). ${para}`;
            });

            const getExtractedValue = getChildData?.map(
              (item) => item?.date || item?.value
            );

            const filteredExtractedValue = getExtractedValue?.filter(
              (value) => value
            );

            insights.push({
              'Clause Type': value,
              'BI Value': filteredExtractedValue.join(', '),
              'Clause Text': getParas.join(', '),
            });
          }
        }
      }
    }

    setInsights(insights);
  }, [props.insightsData]);

  useEffect(() => {
    const partyName = contracting_parties?.map(
      (item, index) => `${index + 1}). ${item?.party || item?.partyName}`
    );

    const date = new Date().toISOString();

    setTableTopInfo([
      {
        key: 'Downloaded On',
        value: `${dayjs(date).format('DD/MM/YYYY, h:mm A')} IST`,
      },
      { key: 'Downloaded By:', value: userName ? userName : '' },
      { key: 'File Name', value: quickIntel.fileName },
      {
        key: 'Parties ',
        value: partyName?.length > 0 ? partyName.join(', ') : '',
      },
    ]);
  }, [quickIntel, contracting_parties]);

  return (
    <>
      <Typography
        marginLeft={2}
        onClick={() => {
          let link = document.getElementById('test-table-xls-button');
          link && link.click();
        }}
      >
        Insights as XLS
      </Typography>
      <div style={{ display: 'None' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-warning btn-sm"
          table="table-to-xls"
          filename={`${props.quickIntel.fileName}_insights`}
          sheet="insights"
          buttonText="Download Insights"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ display: 'none', fontFamily: 'Cambria Math' }}
      >
        <tbody>
          <RenderTableTopInfo tableTopInfo={tableTopInfo} />

          <tr>
            <td style={{ width: '469px' }}></td>
            <td style={{ width: '224px' }}></td>
            <td style={{ width: '1000px' }}></td>
          </tr>
          <RenderHeader />
          {insights.map((ins, i) => {
            return (
              <tr key={i}>
                <td>{ins['Clause Type']}</td>
                <td>{ins['BI Value']}</td>
                <td
                  style={{
                    padding: '10px',
                    width: '1000px',
                    textAlign: 'center',
                    wordWrap: 'break-word !important',
                  }}
                >
                  {ins['Clause Text']}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default DownloadInsights;

const RenderTableTopInfo = ({ tableTopInfo }) => {
  return (
    <>
      {tableTopInfo.map((info, i) => {
        return (
          <tr key={i}>
            <td></td>
            <td
              style={{
                backgroundColor: lg,
                fontSize: p16,
                fontWeight: 'bold',
                textAlign: 'center',
                color: info.color ? info.color : 'black',
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
              }}
            >
              {info.key}
            </td>
            <td
              style={{
                backgroundColor: lg,
                fontSize: p16,
                fontWeight: 'bold',
                textAlign: 'center',
                color: info.color ? info.color : 'black',
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
              }}
            >
              {info.value}
            </td>
          </tr>
        );
      })}
    </>
  );
};

const header = [
  'Type of Clause',
  'Extracted Business Intelligence from this Clause ',
  'Corresponding Clause Text from Contract',
];

const RenderHeader = () => {
  const lb = '#8DB4E2';
  const p14 = '14px';
  return (
    <tr>
      {header.map((head, i) => {
        return (
          <td
            style={{
              backgroundColor: lb,
              fontSize: p14,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            key={i}
          >
            {head}
          </td>
        );
      })}
    </tr>
  );
};
