import React, { FC } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Stack, Typography } from '@mui/material';

interface IProps {
  title: string;
  onClick: VoidFunction;
  buttonText: string;
}

const ClauseHeader: FC<IProps> = ({ onClick, title, buttonText }) => {
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'space-between', alignItems: 'center', my: 1 }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      <Button
        variant="text"
        onClick={onClick}
        sx={{ padding: 0, color: '#88305F' }}
        startIcon={<EditOutlinedIcon />}
      >
        Add/Edit {buttonText}
      </Button>
    </Stack>
  );
};

export default ClauseHeader;
