export const assignOptions = [
  {
    value: 'internal',
    title: 'Internal user',
  },
  {
    value: 'external',
    title: 'External users (counterparty)',
  },
];
