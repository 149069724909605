import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { RouterProvider } from 'react-router';

import Login from './Login';
import {
  getKeyCloakRealmFromLS,
  setKeyCloakRealmOnLS,
} from '../../Authentication/Actions/authentication';
import { setLocalStorage } from '../../Authentication/Actions/authentication';
import { getCustomKeyCloakByRealm } from '../../Configuration/keycloak';
import PrivateRoute from '../../Keycloak/Helpers/privateRoute';
import {
  externalRouter,
  privateRouter,
} from '../../UniversalComponents/routes/route';

const App = () => {
  const keyCloakRealm = getKeyCloakRealmFromLS();

  const [width] = useState(window.screen.width);

  // const [realmName, setRealmName] = useState("");
  const [keycloakConfig, setKeycloakConfig] = useState(
    keyCloakRealm && keyCloakRealm.length > 0
      ? getCustomKeyCloakByRealm(keyCloakRealm)
      : null
  );

  const handleWindowSizeChange = () => {
    if (window.screen.width !== width) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  const onKeycloakTokens = (tokens: any) => {
    if (tokens && tokens.idToken) {
      setLocalStorage('accessToken', tokens.token);
      setLocalStorage('refreshToken', tokens.refreshToken);
    }
  };

  const handleKeycloakRealmNameChange = (realmName: string) => {
    setKeyCloakRealmOnLS(realmName.toString());
    setKeycloakConfig(
      realmName && realmName.length > 0
        ? getCustomKeyCloakByRealm(realmName)
        : null
    );
  };

  const isUserExternal = useMemo(
    () => window.location.href.includes('external'),
    []
  );

  if (isUserExternal) {
    return <RouterProvider router={externalRouter} />;
  }
  return (
    <Box>
      {keycloakConfig ? (
        <ReactKeycloakProvider
          authClient={keycloakConfig}
          onTokens={onKeycloakTokens}
        >
          <PrivateRoute
            handleKeycloakRealmNameChange={handleKeycloakRealmNameChange}
          >
            <RouterProvider router={privateRouter} />
          </PrivateRoute>
        </ReactKeycloakProvider>
      ) : (
        <Login handleKeycloakRealmNameChange={handleKeycloakRealmNameChange} />
      )}
    </Box>
  );
};

export default App;
