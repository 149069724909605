import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { deleteSingleColumn, getPara } from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function OtherObligationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    hasData,
    updatedClauseData,
    postClauseDataByType,
    otherClauses,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [updateObligation, setUpdateObligation] = React.useState(true);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'obligation_statements');
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.obligationStatements && clauseData.obligationStatements,
    [clauseData?.obligationStatements]
  );

  const isClauseProperty = useCallback(
    (clauseType: string) => {
      // eslint-disable-next-line no-prototype-builtins
      return clauseData?.hasOwnProperty(clauseType);
    },
    [clauseData]
  );

  const updates = useMemo(
    () => updatedClauseData?.obligationStatements,
    [updatedClauseData]
  );

  const obligationStatements = useMemo(() => {
    if (updates?.obligation_statements) {
      const sortData = updates.obligation_statements.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const getClauseData = React.useCallback(
    (clauseType: string) => {
      switch (clauseType) {
        case 'contractingParties': {
          return (
            updatedClauseData?.contractingParties?.contracting_parties || []
          );
        }
        case 'nonCompete': {
          return updatedClauseData?.nonCompete?.non_compete || [];
        }
        case 'changeOfControl': {
          return updatedClauseData?.changeOfControl?.change_of_control || [];
        }
        case 'confidentiality': {
          return updatedClauseData?.confidentiality?.confidentiality || [];
        }
        case 'consent': {
          return updatedClauseData?.consent?.consent || [];
        }
        case 'dataBreach': {
          return updatedClauseData?.dataBreach?.data_breach || [];
        }
        case 'disputeResolution': {
          return updatedClauseData?.disputeResolution?.dispute_resolution || [];
        }
        case 'effectiveDate': {
          return updatedClauseData?.effectiveDate?.effective_date || [];
        }
        case 'eventsOfDefault': {
          return updatedClauseData?.eventsOfDefault?.events_of_default || [];
        }
        case 'exclusivity': {
          return updatedClauseData?.exclusivity?.exclusivity || [];
        }
        case 'forceMajeure': {
          return updatedClauseData?.forceMajeure?.force_majeure || [];
        }
        case 'generalDefinitions': {
          return (
            updatedClauseData?.generalDefinitions?.general_definitions || []
          );
        }
        case 'governingLaw': {
          return updatedClauseData?.governingLaw?.governing_law || [];
        }
        case 'indemnity': {
          return updatedClauseData?.indemnity?.indemnity || [];
        }
        case 'insurance': {
          return updatedClauseData?.insurance?.insurance || [];
        }
        case 'limitedLiability': {
          return updatedClauseData?.limitedLiability?.limited_liability || [];
        }
        case 'term': {
          return updatedClauseData?.term?.term || [];
        }
        case 'renewal': {
          return updatedClauseData?.renewal?.renewal || [];
        }
        case 'payment': {
          return updatedClauseData?.payment?.payment || [];
        }
        case 'termination': {
          return updatedClauseData?.termination?.termination || [];
        }
        case 'notice': {
          return updatedClauseData?.notice?.notice || [];
        }
        case 'nonSolicitation': {
          return updatedClauseData?.nonSolicitation?.non_solicitation || [];
        }
        case 'subletting': {
          return updatedClauseData?.subletting?.subletting || [];
        }
      }
    },
    [updatedClauseData]
  );

  const getOtherClausesData = React.useCallback(() => {
    let clauseData: any[] = [];

    if (isClauseProperty('contractingParties')) {
      const data = getClauseData('contractingParties');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('nonCompete')) {
      const data = getClauseData('nonCompete');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('changeOfControl')) {
      const data = getClauseData('changeOfControl');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('confidentiality')) {
      const data = getClauseData('confidentiality');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('consent')) {
      const data = getClauseData('consent');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('dataBreach')) {
      const data = getClauseData('dataBreach');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('disputeResolution')) {
      const data = getClauseData('disputeResolution');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('effectiveDate')) {
      const data = getClauseData('effectiveDate');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('eventsOfDefault')) {
      const data = getClauseData('eventsOfDefault');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('exclusivity')) {
      const data = getClauseData('exclusivity');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('forceMejeure')) {
      const data = getClauseData('forceMejeure');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('generalDefinitions')) {
      const data = getClauseData('generalDefinitions');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('governingLaw')) {
      const data = getClauseData('governingLaw');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('indemnity')) {
      const data = getClauseData('indemnity');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('insurance')) {
      const data = getClauseData('insurance');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('limitedLiability')) {
      const data = getClauseData('limitedLiability');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('term')) {
      const data = getClauseData('term');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('renewal')) {
      const data = getClauseData('renewal');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('payment')) {
      const data = getClauseData('payment');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('termination')) {
      const data = getClauseData('termination');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('notice')) {
      const data = getClauseData('notice');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('nonSolicitation')) {
      const data = getClauseData('nonSolicitation');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty('subletting')) {
      const data = getClauseData('subletting');
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    return clauseData;
  }, [getClauseData, isClauseProperty]);

  React.useEffect(() => {
    if (
      !data?.edited_content &&
      obligationStatements.length > 0 &&
      updateObligation
    ) {
      const otherClausesData = getOtherClausesData();
      let obligation_statements = obligationStatements.slice();
      let updatedData = updates;
      otherClausesData.filter((data: any) => {
        obligation_statements = obligation_statements.filter(
          (obligation: any) =>
            obligation.para_id !== data.para_id ||
            obligation.sentence_id !== data.sentence_id
        );
      });

      setUpdateObligation(false);
      updatedData = {
        ...updates,
        obligation_statements,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(
          fileId,
          'obligation_statements',
          diff,
          updatedData
        );
      }
    }
  }, [
    data?.edited_content,
    data?.raw_content,
    fileId,
    getOtherClausesData,
    obligationStatements,
    postClauseDataByType,
    updateObligation,
    updates,
  ]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let obligation_statements: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        obligation_statements = deleteSingleColumn(
          obligationStatements,
          tableData
        );
      } else {
        obligation_statements = obligationStatements?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id
        );
      }
      const updatedData = {
        ...updates,
        obligation_statements,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(
          fileId,
          'obligation_statements',
          diff,
          updatedData
        );
      }
    },
    [updates, postClauseDataByType, obligationStatements, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="obligation"
          onClick={() => {
            handleAddEdit(
              props,
              'obligation_statements',
              obligationStatements,
              setShowAdd
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="obligation_statements"
          savedInsight={obligationStatements ? obligationStatements : []}
          savedParentClauseDataPoint={obligationStatements}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          otherClauses={(type: string) => otherClauses(type)}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}

      <Scrollable maxHeight={200}>
        {obligationStatements?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'obligation', index });
              props.onClickHighlight(index, item, 'obligation_statements');
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'obligation' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
    </>
  );
}
