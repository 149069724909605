import * as React from 'react';

import { IconButton, Popover, Stack } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

interface IProps {
  renderIcon: () => React.ReactNode;
  children: React.ReactNode;
  id: string;
}

export default function PopoverPopupState({
  renderIcon,
  children,
  id,
}: IProps) {
  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>{renderIcon()}</IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Stack
              sx={{
                p: 2,
                maxWidth: '24rem',
                maxHeight: '24rem',
                overflowY: 'auto',
              }}
            >
              {children}
            </Stack>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
