import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Button, Slider, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { similarityArray } from '../../../Approvals/Component/CreateApprovalForm/CreateApproval';
import LinkCLauseTextField from '../../../Approvals/Component/CreateApprovalForm/LinkClauseTextField';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import { updateSection } from '../../../Services/dictionary';

export const methodsOption = [
  {
    value: 'reference_clause',
    title: 'Use reference clauses',
    description: 'This insight will be available where this template is used',
  },
  {
    value: 'riverus_ai',
    title: 'Use Riverus AI',
    description: 'This insight will be available for all other contracts',
  },
];

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  padding: '10px 15px',
  color: '#1D1B20',
};

interface IProps {
  sectionData: any;
}

const InsightsForm: FC<IProps> = ({ sectionData }) => {
  const [clauseValue, setClauseValue] = useState<any>([]);

  const methods = useForm();
  const { handleSubmit, control, watch, reset } = methods;

  const method = watch('method');

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: updateSectionMutation } = useMutation({
    mutationKey: ['update_section'],
    mutationFn: updateSection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dictionary-fields'] });
      enqueueSnackbar('Section Updated successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (sectionData) {
      reset({
        section_description: sectionData.section_description,
        method: sectionData.method,
        similarity: sectionData.similarity,
        question_one: sectionData.question_one,
        question_two: sectionData.question_two,
        search_query: sectionData.search_query,
      });
      setClauseValue(sectionData.reference_clauses || []);
    }
  }, [reset, sectionData]);

  const clearField = () => {
    reset({
      similarity: '100',
      method: '',
      question_one: '',
      question_two: '',
      search_query: '',
      section_description: '',
    });
    setClauseValue([]);
  };

  const onSubmit = useCallback(
    (data: any) => {
      const payload = {
        id: sectionData.section_id,
        data: {
          ...data,
          reference_clauses: clauseValue,
        },
      };
      updateSectionMutation(payload);
    },
    [clauseValue, sectionData, updateSectionMutation]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          sx={innerTabStyle}
          justifyContent="space-between"
          alignItems="center"
        >
          Insights: {sectionData.section_name}
        </Stack>
        <Stack sx={{ padding: '30px 10px' }} gap={2}>
          <Typography fontWeight={'700'}>Text to add as insight</Typography>
          <ControlledTextField
            name="section_description"
            control={control}
            multiline
            rows={4}
          />

          <Typography fontWeight={'700'}>Method to extract</Typography>
          <RadioButtonGroup
            row
            name="method"
            options={methodsOption}
            valueKey="value"
            isDescription
            control={control}
          />
          {method === 'reference_clause' && (
            <>
              <Typography fontWeight={'700'}>
                Add reference clauses (at least three)
              </Typography>
              <LinkCLauseTextField
                name="reference_clauses"
                control={control}
                key="entire-contract-text"
                clauseValue={clauseValue}
                handleClauseValue={(value: any) => setClauseValue(value)}
              />
              <Typography fontWeight={'700'}>
                How similar should the insight be from the references?
              </Typography>
              <Box padding="0 20px" width="70%">
                <Controller
                  name="similarity"
                  control={control}
                  defaultValue={100}
                  render={({ field }) => (
                    <Slider
                      defaultValue={100}
                      marks={similarityArray}
                      valueLabelDisplay="auto"
                      {...field}
                    />
                  )}
                />
              </Box>
            </>
          )}
          {method === 'riverus_ai' && (
            <>
              <Typography>Add a search query to identify the clause</Typography>
              <ControlledTextField
                name="search_query"
                control={control}
                multiline
                rows={2}
                label="Search Query"
              />
              <Typography>
                Add at least one question to further help identify the clause
              </Typography>
              <ControlledTextField
                name="question_one"
                control={control}
                multiline
                rows={2}
                label="Question#1"
              />
              <ControlledTextField
                name="question_two"
                control={control}
                multiline
                rows={2}
                label="Question#2"
              />
            </>
          )}

          <Stack mt="20px">
            <Button
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              type="submit"
            >
              Save
            </Button>
            <Button variant="outlined" onClick={() => clearField()}>
              Clear all fields
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default InsightsForm;
