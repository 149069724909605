import React, { Suspense } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Navigate, Route, Routes } from 'react-router-dom';

import Admin from './Admin';
import ApprovalsComponent from './Approvals/Component/ApprovalsComponent';
import ClauseLibraryAdminComponent from './ClauseLibrary/Admin/Component/ClauseLibraryAdminComponent';
import DataDictionaryAdminContainer from './DataDictionary/Admin';
import DataDictionaryCreateType from './DataDictionary/Admin/Component/DataDictionaryCreateType';
import DocumentLibraryComponent from './DocumentLibrary/Component/DocumentLibraryComponent';
import DocumentView from './DocumentView/Container/documentCon';
import DraftComponent from './Draft/Component/DraftComponent';
import ViewDraft from './Draft/Component/ViewDraft';
import PageNotFound from './ErrorPages/404Page';
import FilterComponent from './Filter/Components/FilterComponent';
import ReminderComponent from './Reminder/ReminderComponent';
import Layout from './RiverusUI/Components/Layouts/layout';
import Search from './Search/Components/search';
import StampBank from './StampBank';
import AdminTemplate from './Templates/Admin';
import ViewTemplate from './Templates/Admin/Component/ViewTemplate';

function Root() {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="documentlibrary" element={<DocumentLibraryComponent />} />
        <Route path="filter" element={<FilterComponent />} />
        <Route path="search/:query" element={<Search />} />
        <Route path="reminders" element={<ReminderComponent />} />
        <Route path="templates" element={<AdminTemplate />} />
        <Route path="admin/templates" element={<AdminTemplate />} />
        <Route path="admin/stamp-bank" element={<StampBank />} />
        <Route
          path="draftingreview"
          element={
            <Suspense fallback={<p>loading...</p>}>
              <DraftComponent />
            </Suspense>
          }
        />
        <Route
          path="draftingreview/:id"
          element={
            <Suspense fallback={<p>loading...</p>}>
              <DraftComponent />
            </Suspense>
          }
        />
        <Route path="admin/approvals" element={<ApprovalsComponent />} />

        <Route
          path="admin/data-dictionary"
          element={<DataDictionaryAdminContainer />}
        />

        <Route
          path="admin/clause-library-admin"
          element={<ClauseLibraryAdminComponent />}
        />
        <Route path="admin" element={<Admin />} />

        {isLoggedIn && (
          <Route path="/" element={<Navigate replace to="documentlibrary" />} />
        )}
        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="template/:id/:page" element={<ViewTemplate />} />
      <Route
        path="document/:page/:id/:requestid?"
        element={
          <Suspense fallback={<p>loading...</p>}>
            <DocumentView />
          </Suspense>
        }
      />
      <Route
        path="draft/:version/:id"
        element={
          <Suspense fallback={<p>loading...</p>}>
            <ViewDraft />
          </Suspense>
        }
      />
      <Route
        path="admin/data-dictionary/:id/"
        element={<DataDictionaryCreateType />}
      />
    </Routes>
  );
}

export default Root;
