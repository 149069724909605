import {
  DocumentAction,
  EDITDATAPOINT,
  EDITDATAPOINT_FAILURE,
  EDITDATAPOINT_SUCCESS,
  GETCONTRACTDATA_SUCCESS,
  GETCURRENCYTYPES_SUCCESS,
  GETDURATIONTYPES_SUCCESS,
  GETINSIGHTS,
  GETINSIGHTS_FAILURE,
  GETINSIGHTS_SUCCESS,
  GETSENTENCES,
  GETSENTENCES_FAILURE,
  GETSENTENCES_SUCCESS,
  SAVEADDPHRASE,
  SAVEBIPOINTDATAMODE,
  SAVECLAUSEMODESTATUS,
  SAVEDATEEDITINGSTATUS,
  SAVEDATEINADDEDITMODE,
  SAVEDELETEPHRASE,
  SAVEEDITPHRASEOPTION,
  SAVEEVENTEDITINGSTATUS,
  SAVEEVENTINADDEDIT,
  SAVEHIGHLIGHTEDDATAPOINT,
  SAVEHIGHLIGHTEDDATES,
  SAVEHIGHLIGHTEDEVENTS,
  SAVEHIGHLIGHTEDID,
  SAVEHIGHLIGHTEDPHRASES,
  SAVEHIGHLIGHTEDSENTENCES,
  SAVEHIGHLIGHTEDTABLECELLS,
  SAVEINSIGHTTODELETE,
} from '../Actions/def';
import DocumentState, { defaultDocumentState } from '../State/documentState';

export default function documentReducer(
  state: DocumentState = defaultDocumentState(),
  action: DocumentAction
): DocumentState {
  switch (action.type) {
    case GETSENTENCES: {
      return { ...state, sentenceLoader: true };
    }
    case GETSENTENCES_SUCCESS: {
      return {
        ...state,
        sentenceData: action.payload.sentenceData,
        sentenceLoader: false,
      };
    }
    case GETSENTENCES_FAILURE: {
      return { ...state, sentenceLoader: false };
    }
    case GETINSIGHTS: {
      return { ...state, insightsLoader: true, clauseInsightsLoader: true };
    }

    case GETINSIGHTS_FAILURE: {
      return {
        ...state,
        editSuccessLoader: false,
        //  editFailureLoader: false
      };
    }

    case EDITDATAPOINT_FAILURE: {
      return { ...state, editSuccessLoader: false, editFailureLoader: true };
    }
    case GETINSIGHTS_SUCCESS: {
      return {
        ...state,
        insightsData: JSON.parse(JSON.stringify(action.payload.insightsData)),
        insightsLoader: false,
        editSuccessLoader: false,
        clauseInsightsLoader: false,
        // editFailureLoader: false,
      };
    }
    case GETCONTRACTDATA_SUCCESS: {
      return {
        ...state,
        contractData: JSON.parse(JSON.stringify(action.payload.contractData)),
        // insightsLoader: false,
      };
    }
    case EDITDATAPOINT: {
      return { ...state, editLoader: true };
    }
    case EDITDATAPOINT_SUCCESS: {
      return {
        ...state,
        editLoader: false,
        editSuccessLoader: true,
        editFailureLoader: false,
      };
    }
    case SAVEHIGHLIGHTEDID: {
      return { ...state, highlightedId: action.payload.highlightedId };
    }
    case SAVEHIGHLIGHTEDDATAPOINT: {
      return {
        ...state,
        dataPointName: action.payload.dataPointName,
        editOptionSelection: action.payload.editOptionSelection,
      };
    }
    case SAVEBIPOINTDATAMODE: {
      return {
        ...state,
        storedBiPointDataModes: action.payload.storedBiPointDataModes,
      };
    }
    case SAVEHIGHLIGHTEDSENTENCES: {
      return {
        ...state,
        savedHighlightedSentences: action.payload.savedHighlightedSentences,
      };
    }
    case SAVEHIGHLIGHTEDPHRASES: {
      return {
        ...state,
        savedHighlightedPhrases: action.payload.savedHighlightedPhrases,
      };
    }
    case SAVEEDITPHRASEOPTION: {
      return {
        ...state,
        phraseEditOptionSelected: action.payload.phraseEditOptionSelected,
      };
    }
    case SAVEDELETEPHRASE: {
      return {
        ...state,
        phraseInDeleteMode: action.payload.phraseInDeleteMode,
        phraseDeleteStatus: action.payload.phraseDeleteStatus,
      };
    }
    case SAVEADDPHRASE: {
      return {
        ...state,
        phraseInAddMode: action.payload.phraseInAddMode,
        phraseAddStatus: action.payload.phraseAddStatus,
      };
    }
    case SAVEHIGHLIGHTEDTABLECELLS: {
      return {
        ...state,
        savedHighlightedTableCells: action.payload.savedHighlightedTableCells,
      };
    }
    case SAVEHIGHLIGHTEDDATES: {
      return {
        ...state,
        savedHighlightedDates: action.payload.savedHighlightedDates,
      };
    }
    case SAVEDATEINADDEDITMODE: {
      return { ...state, dateInAddEditMode: action.payload.dateInAddEditMode };
    }
    case SAVEDATEEDITINGSTATUS: {
      return { ...state, dateEditingStatus: action.payload.dateEditingStatus };
    }
    case SAVEHIGHLIGHTEDEVENTS: {
      return {
        ...state,
        savedHighlightedEvents: action.payload.savedHighlightedEvents,
      };
    }
    case SAVEEVENTINADDEDIT: {
      return { ...state, eventInAddEdit: action.payload.eventInAddEdit };
    }
    case SAVEEVENTEDITINGSTATUS: {
      return {
        ...state,
        eventEditingStatus: action.payload.eventEditingStatus,
      };
    }
    case SAVECLAUSEMODESTATUS: {
      return { ...state, clauseModeStatus: action.payload.clauseModeStatus };
    }
    case SAVEINSIGHTTODELETE: {
      return {
        ...state,
        insightToDelete: action.payload.insightToDelete,
        childLabelToDelete: action.payload.childLabelToDelete,
      };
    }
    case GETDURATIONTYPES_SUCCESS: {
      return { ...state, durationList: action.payload.durationList };
    }
    case GETCURRENCYTYPES_SUCCESS: {
      return { ...state, currencyList: action.payload.currencyList };
    }
    default:
      return state;
  }
}
