import {
  getLocalStorage,
  getSessionStorage,
} from '../Authentication/Actions/authentication';

export const isRiverusAdmin = () => {
  const orgName = getLocalStorage('kcRealm');
  const adminRole = getSessionStorage('user_profile');
  const isAdmin = adminRole?.roles?.includes('admin');
  if (orgName === 'Riverus' && isAdmin) {
    return true;
  }
  return false;
};
