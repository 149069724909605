const buttonTheme = {
  styleOverrides: {
    root: {
      margin: '4px',
      padding: '10px 24px',
      fontSize: '14px',
      fontWeight: '500',
      borderRadius: '100px',
    },
  },
  variants: [
    {
      props: { variant: 'contained' } as const,
      style: {
        outline: 'none',
      },
    },
    {
      props: { variant: 'outlined' } as const,
      style: {
        border: '2px solid',
        ':hover': {
          borderWidth: '2px',
        },
      },
    },
    {
      props: {
        variant: 'text' as const,
        ':hover': {
          backgroundColor: 'transparent',
        },
      },
      style: {
        padding: '0',
      },
    },
  ],
};

export default buttonTheme;
