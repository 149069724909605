import {
  GotoAdmin,
  GOTOADMIN,
  GOTOREMINDER,
  GotoReminder,
  GotoStyllus,
  GOTOSTYLLUS,
  GOTOUMBRELA,
  GotoUmbrella,
} from './definitions';

export default class HeimdallActionGen {
  public static gotoStyllus(): GotoStyllus {
    return {
      type: GOTOSTYLLUS,
    };
  }

  public static gotoUmbrella(): GotoUmbrella {
    return {
      type: GOTOUMBRELA,
    };
  }

  public static gotoReminder(): GotoReminder {
    return {
      type: GOTOREMINDER,
    };
  }

  public static gotoAdmin(): GotoAdmin {
    return {
      type: GOTOADMIN,
    };
  }
}
