import React from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ShareChip from './ShareChip';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { editDraftData } from '../../../Services/Draft';
import {
  addExternalUser,
  fetchExternalUsers,
  fetchInternalUsers,
  share,
} from '../../../Services/Share';

interface IProps {
  openModal: boolean;
  handleCloseShareBtn: VoidFunction;
  selectedList: any[];
  type: string;
  draftId?: string;
}

const ShareModal: React.FC<IProps> = (props) => {
  const { openModal, handleCloseShareBtn, selectedList, type, draftId } = props;

  const methods = useForm();
  const queryClient = useQueryClient();
  const { handleSubmit, reset, control, watch, resetField } = methods;

  const internalUsersId = watch('internal_users') || '';
  const email = watch('email') || '';
  const first_name = watch('first_name') || '';
  const last_name = watch('last_name') || '';

  const { data: internalUsers, isLoading: isLoadingInternals } = useQuery({
    queryKey: ['internal-users'],
    queryFn: async () => await fetchInternalUsers(),
    select: (response: any) => {
      const groups = response?.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups;
    },
  });

  const { data: externalUsers, isLoading: isLoadingExternals } = useQuery({
    queryKey: ['external-users'],
    queryFn: fetchExternalUsers,
    select: (response: any) => {
      const groups = response.results.map((data: any) => {
        const name =
          data?.first_name === data?.last_name
            ? data?.first_name
            : `${data.first_name} ${data?.last_name}`;
        return {
          ...data,
          name: `${name} - ${data.email}`,
        };
      });
      return groups;
    },
    enabled: !!(type && selectedList?.length),
  });

  const { mutate: updateDraftData } = useMutation({
    mutationKey: ['edit_draft_data'],
    mutationFn: editDraftData,
    onSuccess: () => {
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleCloseModal = () => {
    handleCloseShareBtn();
    reset();
  };

  const { mutate: share_draft_contract, isPending: isLoadingShare } =
    useMutation({
      mutationKey: ['share_draft_contract'],
      mutationFn: share,
      onSuccess: () => {
        enqueueSnackbar(
          `${type === 'stylus' ? 'Draft' : 'Contract'} shared successfully !`,
          {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
        if (draftId && internalUsersId?.length > 0) {
          const draftPayload = {
            id: draftId,
            body: {
              collaborators: internalUsersId,
            },
          };
          updateDraftData(draftPayload);
        }
        handleCloseModal();
      },
      onError: () => {
        enqueueSnackbar('Failed to share', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: addExternal, isPending: isLoadingExt } = useMutation({
    mutationKey: ['add_external_users'],
    mutationFn: addExternalUser,
    onSuccess: () => {
      enqueueSnackbar('External User added successfully !', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      resetField('first_name');
      resetField('last_name');
      resetField('email');
      queryClient.invalidateQueries({
        queryKey: ['external-users'],
      });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to save external user'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddExtUser = () => {
    const profile = getSessionStorage('user_profile');
    const group = profile.groups[0];

    const payload = {
      first_name: first_name,
      last_name: last_name,
      username: first_name?.trim(),
      email: email,
      group: group,
      assignee_type: 'external',
    };

    if (first_name && email) {
      if (type === 'umbrella') {
        const payload_ = {
          ...payload,
          contract: selectedList?.[0],
        };
        addExternal(payload_);
      } else {
        const payload_ = {
          ...payload,
          draft: selectedList?.[0]?.id,
        };
        addExternal(payload_);
      }
    } else {
      enqueueSnackbar('Please enter user name and user email', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const onSubmit = (data: any) => {
    const payload = {
      uuid: selectedList?.[0]?.id || selectedList?.[0],
      type: type,
      external_users: data?.external_users || [],
      internal_users: data?.internal_users || [],
      send_document: data?.send_document,
    };
    share_draft_contract(payload);
  };

  return (
    <React.Fragment>
      <CustomModal
        title={type === 'stylus' ? 'Share the draft' : 'Share the contract'}
        open={openModal}
        handleClose={handleCloseModal}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} mt={4} width="550px">
              <RISelectComponent
                label="Select Internal User"
                name="internal_users"
                control={control}
                options={sortListAlphabetical(internalUsers)}
                loading={isLoadingInternals}
                isMultiselect
                renderCustomComponent={(value: any, props) => (
                  <ShareChip key={value?.id} {...props} label={value?.name} />
                )}
              />
              {selectedList?.[0]?.version !== 0 && (
                <Controller
                  name="send_document"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          style={{ padding: 0 }}
                        />
                      }
                      label={`Share this ${
                        type === 'umbrella' ? 'contract' : 'draft'
                      } as an attachment.`}
                    />
                  )}
                />
              )}
              {selectedList?.[0]?.version !== 0 &&
                selectedList?.[0]?.version_type !== 'minor' && (
                  <Stack width="100%" spacing={2}>
                    <RISelectComponent
                      label="Select External User"
                      name="external_users"
                      control={control}
                      options={sortListAlphabetical(externalUsers)}
                      loading={isLoadingExternals}
                      isMultiselect
                      renderCustomComponent={(value: any, props) => (
                        <ShareChip
                          key={value?.id}
                          {...props}
                          label={value?.name}
                        />
                      )}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography>Add new external users</Typography>
                      <LoadingButton
                        sx={{ padding: 0 }}
                        variant="text"
                        loading={isLoadingExt}
                        onClick={handleAddExtUser}
                      >
                        save user
                      </LoadingButton>
                    </Stack>
                    <Stack spacing={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <ControlledTextField
                          name="first_name"
                          control={control}
                          label="First Name"
                          fullWidth
                        />
                        <ControlledTextField
                          name="last_name"
                          control={control}
                          label="Last Name"
                          fullWidth
                        />
                      </Stack>
                      <ControlledTextField
                        name="email"
                        control={control}
                        label="Email"
                        fullWidth
                        type="email"
                      />
                    </Stack>
                  </Stack>
                )}
              <Stack direction="row">
                <LoadingButton
                  type="submit"
                  loading={isLoadingShare}
                  variant="contained"
                >
                  Share
                </LoadingButton>

                <Button variant="outlined" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>
    </React.Fragment>
  );
};

export default ShareModal;
