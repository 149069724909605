import React, { FC } from 'react';

import { Card, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Control } from 'react-hook-form';

import { filterHeaderStyle } from './StaticData';
import CustomChip from '../../Approvals/Component/CreateApprovalForm/CustomChip';
import RISelectComponent from '../../RiverusUI/Components/SelectComponent';
import { getTags } from '../../Services/DocumentLibrary';

interface IProps {
  label: string;
  clause: string;
  control: Control;
}

const EventFilter: FC<IProps> = ({ label, clause, control }) => {
  const { data: events, isLoading: eventLoading } = useQuery({
    queryKey: ['get_all_events', clause],
    queryFn: async () => await getTags(clause),
    select: (response: any) => response?.results,
  });

  return (
    <Card>
      <Typography sx={filterHeaderStyle}>{label}</Typography>
      <Stack gap={2} padding={2}>
        <RISelectComponent
          name={`${clause}_events`}
          control={control}
          label="Events"
          options={events}
          loading={eventLoading}
          valueKey="name"
          isMultiselect
          renderCustomComponent={(value: any) => (
            <CustomChip key={value?.id} label={value?.name} />
          )}
        />
      </Stack>
    </Card>
  );
};

export default EventFilter;
