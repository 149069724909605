import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Card,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import ClauseTypeFilter from './CLauseTypeFilter';
import { scrollContainerSx } from '../../Filter/Components/StaticData';
import FilterBg from '../../RiverusUI/Components/Icons/FilterBg';
import ListSkeleton from '../../RiverusUI/Components/Skeleton/ListSkeleton';
import { getInsightsES, getSearchResult } from '../../Services/search';

const Search = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const [searchData, setSearchData] = useState<any>();
  const [searchResultIds, setSearchResultIds] = useState<any>([]);

  const clause_type = watch('clause_type');
  const jurisdiction = watch('jurisdiction') || null;
  const contracting_parties = watch('contracting_parties') || null;
  const contract_type = watch('contract_type') || null;
  const groups = watch('groups') || null;
  const projects = watch('projects') || null;

  const searchTerm = useMemo(
    () => (query ? JSON.parse(atob(query)) : { search: [] }),
    [query]
  );

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length ||
      contract_type?.length ||
      groups?.length ||
      projects?.length ||
      jurisdiction?.length ||
      clause_type?.length ||
      contracting_parties?.length,
    [
      searchTerm,
      contract_type,
      jurisdiction,
      groups,
      projects,
      clause_type,
      contracting_parties,
    ]
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      jurisdiction,
      contracting_parties,
      contract_type,
      groups,
      projects,
    ],
    queryFn: () => {
      const clauseTypes = clause_type?.map((data: any) => data.index) || [];

      return getSearchResult({
        ...searchTerm,
        ...(clauseTypes.length > 0 && {
          indexes: clauseTypes,
        }),
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(jurisdiction?.length > 0 && { jurisdiction: jurisdiction }),
        ...(contracting_parties?.length > 0 && {
          contracting_parties: contracting_parties,
        }),
      });
    },
  });

  useEffect(() => {
    if (data) {
      setSearchData(data);
    }
  }, [data]);

  useEffect(() => {
    const searchIds: any = [];
    if (searchData?.length) {
      searchData.map((data: any) =>
        data?.ids?.map((idsData: any) => {
          const index = searchIds.findIndex(
            (searchItem: any) => searchItem.id === idsData.id
          );
          if (index === -1) {
            searchIds.push(idsData);
          }
        })
      );
      setSearchResultIds(searchIds);
    }
    if (!showSearchResult) {
      navigate('/documentlibrary');
    }
  }, [searchData]);

  const { data: insightsData, isLoading: insightsLoading } = useQuery({
    queryKey: ['insights-es', searchResultIds, searchTerm],
    queryFn: () => {
      const ids = searchResultIds?.map((item: any) => item.id);
      const payload = {
        ...(ids.length > 0 && { ids: ids || [] }),
        ...(searchTerm.search.length > 0 && searchTerm),
      };
      return getInsightsES(payload);
    },
  });

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter(
      (item: any) => item !== term
    );
    searchQuery = {
      search: filteredData,
    };
    const url = `/search/${btoa(JSON.stringify(searchQuery))}`;
    navigate(url);
  };

  const handleFilterDelete = (list: any, item: string, clause: string) => {
    const filteredData = list.filter((data: string) => data !== item);
    setValue(clause, filteredData);
  };

  const handleClauseTypeDelete = (item: string) => {
    const filteredData = clause_type.filter((data: any) => data.index !== item);
    setValue('clause_type', filteredData);
  };

  // const isExpiredContract = (end_date: string) => {
  //   const endDateObj = new Date(end_date);
  //   const currentDate = new Date();
  //   // Compare endDate with current date
  //   return end_date ? endDateObj < currentDate : false;
  // };

  if (isLoading && !searchData) {
    return <ListSkeleton numberOfLines={10} />;
  }
  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="28vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <ClauseTypeFilter
              insightsData={insightsData}
              isLoading={insightsLoading}
              control={control}
            />
          </form>
        </FormProvider>
      </Stack>

      {showSearchResult ? (
        <Stack gap={2} flex={1}>
          <Typography>
            <strong>{searchResultIds?.length}</strong> results for
          </Typography>
          {isFetching && <LinearProgress variant="indeterminate" />}
          <Stack direction="row" gap={1} flexWrap="wrap">
            {searchTerm.search.map((term: string) => (
              <Chip
                key={term}
                label={term}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                deleteIcon={<CloseIcon fontSize="small" />}
                onDelete={() => handleSearchTermDelete(term)}
                icon={<SearchOutlinedIcon fontSize="small" />}
              />
            ))}
            {contract_type?.map((item: any, index: number) => (
              <Chip
                key={`contract-type-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(contract_type, item, 'contract_type')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {groups?.map((item: any, index: number) => (
              <Chip
                key={`groups-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(groups, item, 'groups')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {projects?.map((item: any, index: number) => (
              <Chip
                key={`projects-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(projects, item, 'projects')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {clause_type?.map((clause: any, index: number) => (
              <Chip
                key={`clause-type-${index}`}
                label={clause.index}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleClauseTypeDelete(clause.index)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {jurisdiction?.map((item: any, index: number) => (
              <Chip
                key={`jurisdiction-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(jurisdiction, item, 'jurisdiction')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {contracting_parties?.map((contract: any, index: number) => (
              <Chip
                key={`contracting-parties-${index}`}
                label={contract}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(
                    contracting_parties,
                    contract,
                    'contracting_parties'
                  )
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
          </Stack>
          <Stack
            sx={{
              ...scrollContainerSx,
              maxHeight: '80vh',
              paddingRight: '10px',
            }}
            gap={2}
          >
            {searchResultIds?.map((contractData: any, index: number) => (
              <Card
                key={index}
                sx={{ padding: '10px 15px', overflow: 'visible' }}
              >
                <Stack gap={1}>
                  <Stack
                    sx={{ color: '#996C84', alignItems: 'center' }}
                    direction="row"
                  >
                    <Typography variant="h6">
                      {contractData.file_name}
                    </Typography>
                    <IconButton
                      sx={{ color: '#996C84' }}
                      onClick={() =>
                        window.open(
                          '/document/documentlibrary/' +
                            btoa(contractData?.id?.toString()),
                          '_blank'
                        )
                      }
                    >
                      <OpenInNewIcon />
                    </IconButton>
                    {/* {contractData?.terminate_status && (
                      <Chip label="Terminated" />
                    )}
                    {!contractData?.terminate_status && (
                      <>
                        {isExpiredContract(contractData?.end_date) ? (
                          <Chip
                            label="Expired"
                            sx={{ background: '#ff4d4f', color: 'white' }}
                          />
                        ) : (
                          <Chip
                            label="Active"
                            sx={{
                              background: '#87d068',
                              color: 'white',
                            }}
                          />
                        )}
                      </>
                    )} */}
                  </Stack>
                  {Array.isArray(contractData.contract_type) ? (
                    <Typography sx={{ color: '#6D6E71', fontSize: '14px' }}>
                      {contractData.contract_type[0]}
                    </Typography>
                  ) : null}
                  {contractData.groups.length > 0 && (
                    <Stack direction="row" gap={1}>
                      {contractData.groups.map(
                        (item: string, index: number) => (
                          <Chip
                            key={`contract-group-${index}`}
                            label={item}
                            color="primary"
                            sx={{ fontSize: '13px' }}
                          />
                        )
                      )}
                      {contractData.projects.map(
                        (item: string, index: number) => (
                          <Chip
                            key={`contract-group-${index}`}
                            label={item}
                            color="success"
                            sx={{ fontSize: '13px' }}
                          />
                        )
                      )}
                    </Stack>
                  )}
                  <Stack
                    gap={1}
                    sx={{
                      ...scrollContainerSx,
                      maxHeight: '250px',
                    }}
                  >
                    {contractData.para.map((para: any, index: number) => (
                      <Typography
                        key={index}
                        dangerouslySetInnerHTML={{ __html: para.sentence }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Stack>
        </Stack>
      ) : (
        <Stack
          height="70vh"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <FilterBg />
          <Typography>{`${searchResultIds?.length} results found`}</Typography>
          <Typography>Start by searching/Filtering</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Search;
