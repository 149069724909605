import React, { useEffect } from 'react';

import { Box, Checkbox, Chip, InputBase, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProps {
  label: string;
  onChange?: any;
  options: any;
  valueKey?: string;
  value?: any;
}

const TableAsyncSelectInput: React.FC<IProps> = ({
  label,
  options,
  onChange,
  valueKey = 'id',
  value = [],
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string[]>(
    value || []
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(typeof value === 'string' ? value.split(',') : value);

    onChange?.(
      typeof value === 'string'
        ? value.split(',')
        : value.length > 0
          ? value
          : null
    );
  };

  const generateSingleOptions = () => {
    return options?.map((option: any) => {
      return (
        <MenuItem key={option.id} value={option[valueKey]}>
          <Checkbox checked={selectedValue.indexOf(option[valueKey]) > -1} />
          <ListItemText primary={option.name} />
        </MenuItem>
      );
    });
  };

  const getName = React.useCallback(
    (id: string) => {
      const index =
        options.findIndex((data: any) => data[valueKey] === id) || 0;
      if (index > -1) {
        return options[index].name;
      }
    },
    [options, valueKey]
  );

  return (
    <FormControl sx={{ m: 0, minWidth: 180, maxWidth: 200 }}>
      <Select
        multiple
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        input={<InputBase />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{label}</em>;
          }

          return (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: 0.5,
                height: '55px',
                alignItems: 'center',
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {selected?.map((value: any) => (
                <Chip
                  key={value}
                  label={valueKey === 'id' ? getName(value) : value}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled value="">
          <em>{label}</em>
        </MenuItem>
        {generateSingleOptions()}
      </Select>
    </FormControl>
  );
};

export default TableAsyncSelectInput;
