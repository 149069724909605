/* eslint-disable @typescript-eslint/no-unused-vars */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import DocumentAPI from '../Actions/API';
import {
  GETCONTRACTDATA,
  GetContractData,
  GetCurrencyTypes,
  GETCURRENCYTYPES,
  GETDURATIONTYPES,
  GetDurationTypes,
  GetInsights,
  GETINSIGHTS,
  GETSENTENCES,
  GetSentences,
} from '../Actions/def';
import DocumentActionGenerator from '../Actions/Gen';
import {
  ContractDetails,
  CurrencyType,
  DurationType,
  EventData,
  SentencesData,
  TagData,
} from '../State/documentState';

function* getSentences(action: GetSentences): any {
  const fileId = action.payload.fileId;
  const contract_id = atob(fileId.toString());
  const url = 'contracts/insights/' + contract_id + '/extracted/';

  try {
    const response = yield call(DocumentAPI.getSentences, url);

    if (response) {
      const extracted: any[] = [];
      if (response.raw_content.hierarchy.length !== 0) {
        Object.keys(response.raw_content.hierarchy).map(function (key) {
          extracted.push(response.raw_content.hierarchy[key]);
        });
        extracted.sort((a: any, b: any) => (a.index > b.index ? 1 : -1));
      }
      const data = {
        fileId: contract_id,
        hierarchy: extracted,
      };

      const parsed = data as SentencesData;
      yield put(DocumentActionGenerator.getSentencesSuccess(parsed));
    } else {
      yield put(DocumentActionGenerator.getSentencesFailure());
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getSentencesFailure());
  }
}

function* getInsights(action: GetInsights): any {
  const fileId = action.payload.fileId;
  const url = 'contracts/clause_types/' + fileId + '/';
  try {
    const response = yield call(DocumentAPI.getInsights, url);
    if (response) {
      const clause_type_response: any = response;
      yield put(
        DocumentActionGenerator.getInsightsSuccess(clause_type_response)
      );
    } else {
      yield put(DocumentActionGenerator.getInsightsFailure());
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getInsightsFailure());
  }
}

function* getContractData(action: GetContractData): any {
  const url = 'contracts/' + atob(action.payload.fileId.toString()) + '/';
  try {
    const response = yield call(DocumentAPI.getInsights, url);
    if (response) {
      const parsed = response as ContractDetails;

      yield put(DocumentActionGenerator.getContractDataSuccess(parsed));
    } else {
      yield put(DocumentActionGenerator.getContractDataFailure());
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getContractDataFailure());
  }
}

function* getDurationTypes(action: GetDurationTypes): any {
  try {
    const response = [
      {
        durationTypeId: 1,
        durationName: 'Days',
        durationType: 'day',
      },
      {
        durationTypeId: 2,
        durationName: 'Months',
        durationType: 'month',
      },
      {
        durationTypeId: 3,
        durationName: 'Years',
        durationType: 'year',
      },
    ];
    const parsed = response as DurationType[];
    yield put(DocumentActionGenerator.getDurationTypesSuccess(parsed));
  } catch (e) {
    yield put(DocumentActionGenerator.getDurationTypesFailure());
  }
}

function* getCurrencyTypes(action: GetCurrencyTypes): any {
  const url: any = process.env.REACT_APP_RIVERUS_CLM_API + 'admin/currency/';
  try {
    const currency_response = yield call(DocumentAPI.getCurrencyTypes, url);
    if (currency_response) {
      const parsed = currency_response.results as CurrencyType[];
      yield put(DocumentActionGenerator.getCurrencyTypesSuccess(parsed));
    } else {
      yield put(DocumentActionGenerator.getCurrencyTypesFailure());
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getCurrencyTypesFailure());
  }
}

export default function* DocumentWatcher() {
  yield all([
    takeLatest(GETSENTENCES, getSentences),
    takeLatest(GETINSIGHTS, getInsights),
    takeLatest(GETDURATIONTYPES, getDurationTypes),
    takeLatest(GETCURRENCYTYPES, getCurrencyTypes),
    takeLatest(GETCONTRACTDATA, getContractData),
  ]);
}
