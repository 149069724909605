import React, { useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import CreateStampForm from './CreateStampForm';
import StampTable from './StampTable';
import UploadStamp from './UploadStamp';
import { Blank_Document_link } from '../Constants/const';
import { downloadFile } from '../Draft/Component/Helper';
import { fetchStamps } from '../Services/Stamp';

const buttonStyle = {
  borderRadius: '6px',
  fontSize: '11px',
  padding: '8px 16px',
  m: '0',
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: 1,
  alignItems: 'center',
  whiteSpace: 'nowrap',
};

const StampBank = () => {
  const [openCreateStamp, setOpenCreateStamp] = useState<boolean>(false);
  const [openUploadStamp, setOpenUploadStamp] = useState<boolean>(false);
  const [params, setParams] = useState<string>('');
  const [view, setView] = useState({ view: 'card', state: '' });

  const handleChange = (nextView: string, state: string) => {
    if (nextView === 'card') {
      setView({ view: 'card', state: '' });
    } else {
      setView({ view: nextView, state: state });
      setParams(`?state=${state}`);
    }
  };

  const { data: stampBankData } = useQuery({
    queryKey: ['stamp-bank'],
    queryFn: () => fetchStamps(),
  });

  const { data: stampByState, isLoading: stampStateLoading } = useQuery({
    queryKey: ['stamp-by-state', params],
    queryFn: () => fetchStamps(params),
    enabled: !!params,
  });

  const handleClose = () => {
    setOpenCreateStamp(false);
  };

  const handleCloseUploadStamp = () => {
    setOpenUploadStamp(false);
  };

  const handleDownloadSample = async () => {
    const stampFile = await fetch(Blank_Document_link.STAMP_EXCEL);
    const stampData = await stampFile.blob();
    downloadFile(stampData, 'Stamp_Bulk_Upload', 'xlsx');
  };

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="24px">
        {/* Buttons */}
        <Stack gap={1}>
          <Typography variant="body2" fontWeight="600">
            Add to Stamp Bank
          </Typography>
          <Stack gap={1}>
            <Stack direction="row" gap={1}>
              <Tooltip
                placement="bottom"
                title={
                  <Typography variant="subtitle2">
                    1. Click on “Sample Excel File Download” button.
                    <br />
                    2. Update the downloaded Excel File with details of Stamp
                    Paper at your disposal.
                    <br />
                    3. Click on “UPLOAD A CSV/EXCEL FILE (BULK UPLOAD)” button.
                    <br />
                    4. Click on Upload icon{' '}
                    <FileUploadOutlinedIcon sx={{ fontSize: '18px' }} />, <br />
                    select a file and click on “SUBMIT” button.
                  </Typography>
                }
              >
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => setOpenUploadStamp(true)}
                >
                  <UploadFileOutlinedIcon sx={{ fontSize: '24px' }} />
                  <Typography component="span" sx={{ mt: '6px' }}>
                    Upload a CSV/Excel file (Bulk upload)
                  </Typography>
                </Button>
              </Tooltip>
              <Button
                variant="outlined"
                sx={buttonStyle}
                onClick={() => setOpenCreateStamp(true)}
              >
                <EditOutlinedIcon sx={{ fontSize: '24px' }} />
                <Typography component="span" sx={{ mt: '6px' }}>
                  Add/Modify
                </Typography>
              </Button>
            </Stack>
            <Button
              sx={{
                flexDirection: 'row',
                gap: 1,
                padding: '6px',
                width: 'fit-content',
                textTransform: 'capitalize',
              }}
              onClick={handleDownloadSample}
            >
              <FileDownloadIcon sx={{ fontSize: '16px' }} />
              <Typography variant="subtitle2">
                Sample Excel File Download
              </Typography>
            </Button>
          </Stack>
        </Stack>

        {/* Stamp Bank */}
        <Stack gap={3}>
          {(stampBankData as any)?.map((stamp: any, index: number) => (
            <Stack key={index} gap={view.state === stamp.state ? 2 : 1}>
              <Stack direction="row" gap={2}>
                <Typography variant="body2" fontWeight="600">
                  {stamp.state} stamp-papers
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={view.state === stamp.state ? 'list' : 'card'}
                  exclusive
                  onChange={(_, nextView) =>
                    handleChange(nextView, stamp.state)
                  }
                >
                  <ToggleButton
                    value="card"
                    aria-label="card-view"
                    sx={{ padding: '5px' }}
                  >
                    <ViewModuleIcon sx={{ fontSize: '18px' }} />
                  </ToggleButton>
                  <ToggleButton
                    value="list"
                    aria-label="list-view"
                    sx={{ padding: '5px' }}
                  >
                    <ViewListIcon sx={{ fontSize: '18px' }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              {view.state === stamp.state ? (
                stampStateLoading ? (
                  <LinearProgress />
                ) : (
                  <StampTable
                    data={stampByState}
                    isLoading={stampStateLoading}
                  />
                )
              ) : (
                <Stack direction="row" gap={1}>
                  {stamp.total_denomination.length === 0 ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      color="GrayText"
                    >
                      <ErrorOutlineOutlinedIcon />
                      <Typography> No stamp available</Typography>
                    </Stack>
                  ) : (
                    stamp.total_denomination?.map(
                      (item: any, index: number) => (
                        <Box
                          key={index}
                          sx={{
                            background: '#FFF7FA',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            borderRadius: '6px',
                          }}
                        >
                          <Typography variant="h5" fontWeight="600">
                            &#8377;{item.denomination}
                          </Typography>
                          <Typography variant="caption" fontWeight={500}>
                            {item.count} available
                          </Typography>
                        </Box>
                      )
                    )
                  )}
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      {openCreateStamp && (
        <CreateStampForm open={openCreateStamp} onClose={handleClose} />
      )}
      {openUploadStamp && (
        <UploadStamp open={openUploadStamp} onClose={handleCloseUploadStamp} />
      )}
    </Stack>
  );
};

export default StampBank;
