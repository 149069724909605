import React, { useCallback, useMemo, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import {
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';

import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import AssigneePopoverTitle from '../AssigneePopoverTitle';
import AssigneeWithDate from '../AssigneeWithDate';

interface Props {
  assigneeList: any[];
  row: any;
  approversData: any[];
  collaboratorsData: any[];
}

const AssigneeList: React.FC<Props> = ({
  assigneeList,
  row,
  approversData,
  collaboratorsData,
}) => {
  const [clickedItemId, setClickedItemId] = useState<string | null>(null);
  const [clickedItemEmail, setClickedItemEmail] = useState<string | null>(null);
  const [clickedAssignedRole, setClickedAssignedRole] = useState<any | null>(
    null
  );
  const [clickedDraftId, setClickedDraftId] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [clickedAssigneeType, setClickedAssigneeType] = useState<any | null>(
    null
  );

  const assigneeCollaboratorsData = useMemo(() => {
    return Object.values(collaboratorsData ?? {}).filter(
      (collaborator: any) => collaborator?.id === clickedItemId
    );
  }, [collaboratorsData, clickedItemId]);

  const assigneeApproversData = useMemo(() => {
    return Object.values(approversData ?? {}).filter(
      (approver: any) => approver?.id === clickedItemId
    );
  }, [approversData, clickedItemId]);

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      itemRole: any,
      draftId: number,
      itemId: string,
      itemEmail: string,
      itemType: any
    ) => {
      setAnchorEl(event.currentTarget);
      setClickedAssignedRole(itemRole);
      setClickedDraftId(draftId);
      if (itemId) {
        setClickedItemId(itemId);
      } else {
        setClickedItemEmail(itemEmail);
      }
      setClickedAssigneeType(itemType);
    },
    []
  );

  const handleClose = () => {
    setAnchorEl(null);
    setClickedDraftId(null);
    setClickedItemId(null);
    setClickedItemEmail(null);
    setClickedAssigneeType(null);
  };

  // Memoized Fields data
  const draftTemplateCheck = useMemo(() => {
    return assigneeCollaboratorsData?.[0]?.data?.some(
      (collaboratorsData: any) =>
        collaboratorsData?.table === 'OptionalFields' &&
        assigneeCollaboratorsData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Collaborators')
    );
  }, [assigneeCollaboratorsData, clickedItemId, clickedAssignedRole]);

  const draftRequisitionCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.approval_type === 'requisition_approvers' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Approver')
    );
  }, [assigneeApproversData, clickedItemId, clickedAssignedRole]);

  const draftPreSignatoryCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.approval_type === 'pre_signatory_approvers' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Approver')
    );
  }, [assigneeApproversData, clickedItemId, clickedAssignedRole]);

  const draftPolicyCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.deviation_type === 'kdp' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Approver')
    );
  }, [assigneeApproversData, clickedItemId, clickedAssignedRole]);

  const draftCustomCheck = useMemo(() => {
    return assigneeApproversData?.[0]?.data?.some(
      (approverData: any) =>
        approverData?.deviation_type === 'custom' &&
        assigneeApproversData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Approver')
    );
  }, [assigneeApproversData, clickedItemId, clickedAssignedRole]);

  const draftChecklistCheck = useMemo(() => {
    return assigneeCollaboratorsData?.[0]?.data?.some(
      (collaboratorsData: any) =>
        collaboratorsData?.table === 'Checklist' &&
        assigneeCollaboratorsData?.[0]?.id === clickedItemId &&
        clickedAssignedRole?.includes('Collaborators')
    );
  }, [assigneeCollaboratorsData, clickedItemId, clickedAssignedRole]);

  const draftSignatureCheck = useMemo(() => {
    return (
      row?.signatories?.some((signatory: any) =>
        signatory?.id
          ? signatory?.id === clickedItemId
          : signatory?.email === clickedItemEmail
      ) && clickedAssignedRole?.includes('Signatories')
    );
  }, [row, clickedItemId, clickedAssignedRole, clickedItemEmail]);

  const memoizedOptionalFields = useMemo(() => {
    return (
      assigneeCollaboratorsData?.[0]?.data
        ?.filter(
          (collaboratorsData: any) =>
            collaboratorsData?.table === 'OptionalFields' &&
            assigneeCollaboratorsData?.[0]?.id === clickedItemId
        )
        .map((collaboratorsData: any) => {
          const fieldValue = collaboratorsData?.field_value;
          let status = 'Pending';
          if (
            collaboratorsData?.data_type === 'Bulleted List' ||
            collaboratorsData?.data_type === 'Numbered List'
          ) {
            const parsedFieldValue = fieldValue ? JSON.parse(fieldValue) : [];
            const filteredFieldValue = parsedFieldValue.filter(
              (data: any) => data['list'] !== ''
            );
            status = filteredFieldValue.length ? 'Completed' : 'Pending';
          } else if (collaboratorsData?.data_type === 'Boolean') {
            status = fieldValue === 'true' ? 'Completed' : 'Pending';
          } else if (fieldValue) {
            status = 'Completed';
          }

          return {
            fieldName: collaboratorsData?.field_name,
            createdOn:
              collaboratorsData?.assigned_on || collaboratorsData?.created_on,
            reminderDueDate: collaboratorsData?.due_date,
            version: collaboratorsData?.version,
            status,
            completedDate: collaboratorsData?.modified_on,
            key: collaboratorsData?.draft_id,
          };
        }) || []
    );
  }, [assigneeCollaboratorsData, clickedItemId]);

  const memoizedRequisitionApprovers = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.approval_type === 'requisition_approvers'
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          version: approverData?.version,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedPreSignatoryApprovers = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.approval_type === 'pre_signatory_approvers'
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          version: approverData?.version,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedPolicyApprovals = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.deviation_type === 'kdp' &&
            assigneeApproversData?.[0]?.id === clickedItemId
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          version: approverData?.version,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedCustomApprovals = useMemo(() => {
    return (
      assigneeApproversData?.[0]?.data
        ?.filter(
          (approverData: any) =>
            approverData?.deviation_type === 'custom' &&
            assigneeApproversData?.[0]?.id === clickedItemId
        )
        .map((approverData: any) => ({
          createdOn: approverData?.created_on,
          reminderDueDate: approverData?.due_date,
          version: approverData?.version,
          status:
            approverData?.status === 'approval_pending'
              ? 'Approval Pending'
              : 'Approved',
          approvedDate: approverData?.modified_on,
          key: approverData?.draft_id,
        })) || []
    );
  }, [assigneeApproversData]);

  const memoizedChecklist = useMemo(() => {
    return (
      assigneeCollaboratorsData?.[0]?.data
        ?.filter(
          (collaboratorsData: any) =>
            collaboratorsData?.table === 'Checklist' &&
            assigneeCollaboratorsData?.[0]?.id === clickedItemId
        )
        .map((collaboratorsData: any) => ({
          checklistName: collaboratorsData?.checklist_name,
          createdOn:
            collaboratorsData?.assigned_on || collaboratorsData?.created_on,
          reminderDueDate: collaboratorsData?.due_date,
          status: collaboratorsData?.status ? 'Completed' : 'Pending',
          completedDate: collaboratorsData?.modified_on,
          key: collaboratorsData?.draft_id,
        })) || []
    );
  }, [assigneeCollaboratorsData]);

  const memoizedSignatories = useMemo(() => {
    return row?.signatories
      ?.filter((signatory: any) =>
        signatory?.id
          ? signatory?.id === clickedItemId
          : signatory?.email === clickedItemEmail
      )
      ?.map((signatory: any, index: number) => {
        return {
          version: signatory?.version,
          assignedDate: signatory?.assigned_date,
          reminderDueDate: signatory?.reminder?.due_date,
          status: signatory?.status
            ? signatory?.status
            : signatory?.signed_date
              ? 'Signature Signed'
              : 'Signature Pending',
          signedDate: signatory?.signed_date,
          abortedDate: signatory?.declined_date,
          key: `${index}-${signatory?.id || signatory?.email}`,
        };
      });
  }, [row?.signatories, clickedItemId, clickedItemEmail]);

  const getAssignmentMessage = useCallback(
    (assignee: any) => {
      if (assignee?.assigned_role?.includes('Collaborators')) {
        return !draftTemplateCheck
          ? 'assigned as the Collaborator;'
          : 'assigned as the Collaborator for:';
      } else if (assignee?.assigned_role?.includes('Owners')) {
        return 'the Owner.';
      } else if (assignee?.assigned_role?.includes('Signatories')) {
        return 'assigned as the Signatory for:';
      } else if (assignee?.assigned_role?.includes('Approver')) {
        return 'assigned as the Approver for:';
      } else if (assignee?.assigned_role?.includes('Creators')) {
        return 'the Creator.';
      } else {
        return 'the Owner.';
      }
    },
    [draftTemplateCheck]
  );

  return (
    <List>
      {assigneeList?.map((assignee: any, index: number) => (
        <ListItem key={index}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack>
              <Box display="flex">
                <NameAvatar
                  firstName={assignee?.first_name || assignee?.name}
                  lastName={assignee?.last_name}
                />
                <Box>
                  <Typography
                    fontSize="16px"
                    fontWeight="400"
                    sx={{ marginLeft: 2 }}
                  >
                    {assignee?.first_name
                      ? `${assignee?.first_name} ${assignee?.last_name}`
                      : assignee?.name}
                    &nbsp;
                    {assignee?.user_type === 'external' && (
                      <Chip
                        label="EXT"
                        sx={{
                          background: riPrimary[20],
                          padding: '2px 4px 1px',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Typography>

                  <Typography fontSize="12px" fontWeight="400" marginLeft={2}>
                    {assignee.email}
                  </Typography>
                </Box>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography fontSize="16px" fontWeight="400" marginLeft={2}>
                {assignee.assigned_role || 'Owners'}
              </Typography>
              <IconButton
                onClick={(event) => {
                  handleClick(
                    event,
                    assignee?.assigned_role,
                    row?.id,
                    assignee?.id,
                    assignee?.email,
                    assignee?.assignee_type
                  );
                }}
              >
                <CallMadeIcon style={{ color: '#6D264C' }} />
              </IconButton>
              <Popover
                open={
                  Boolean(anchorEl) &&
                  (clickedItemId === assignee?.id ||
                    clickedItemEmail === assignee?.email) &&
                  clickedAssignedRole === assignee?.assigned_role &&
                  clickedAssigneeType === assignee?.assignee_type &&
                  clickedDraftId === row?.id
                }
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack padding="14px">
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle2" fontWeight={700}>
                      {`${assignee?.first_name} is ${getAssignmentMessage(assignee)}`}
                    </Typography>
                    <Scrollable maxHeight={400}>
                      <Stack spacing={1}>
                        {/* Template Field */}
                        {draftTemplateCheck && (
                          <Stack spacing={1}>
                            <AssigneePopoverTitle title="Template Field :" />
                            {memoizedOptionalFields?.map(
                              (optionalField: any, index: number) => (
                                <Stack key={optionalField?.key}>
                                  <AssigneeWithDate
                                    title={`${index + 1}.) Field Name:`}
                                    subTitle={optionalField?.fieldName}
                                  />
                                  <AssigneeWithDate
                                    title="Created On Version:"
                                    subTitle={optionalField?.version}
                                  />
                                  <AssigneeWithDate
                                    title="Assigned On:"
                                    date={optionalField?.createdOn}
                                  />
                                  {optionalField?.reminderDueDate && (
                                    <AssigneeWithDate
                                      title="Task Due Date:"
                                      date={optionalField?.reminderDueDate}
                                    />
                                  )}
                                  <AssigneeWithDate
                                    title="Status:"
                                    subTitle={optionalField?.status}
                                  />
                                  {optionalField?.status === 'Completed' && (
                                    <AssigneeWithDate
                                      title="Completed Date:"
                                      date={optionalField?.completedDate}
                                    />
                                  )}
                                </Stack>
                              )
                            )}
                          </Stack>
                        )}

                        {/* Requisition Approval */}
                        {draftRequisitionCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Requisition Approval :" />
                            {memoizedRequisitionApprovers?.map(
                              (approver: any) => (
                                <Stack key={approver?.key}>
                                  <AssigneeWithDate
                                    title="Created On Version:"
                                    subTitle={approver?.version}
                                  />
                                  <AssigneeWithDate
                                    title="Assigned On:"
                                    date={approver?.createdOn}
                                  />
                                  {approver?.reminderDueDate && (
                                    <AssigneeWithDate
                                      title="Task Due Date:"
                                      date={approver?.reminderDueDate}
                                    />
                                  )}
                                  <AssigneeWithDate
                                    title="Status:"
                                    subTitle={approver?.status}
                                  />
                                  {approver?.status === 'Approved' && (
                                    <AssigneeWithDate
                                      title="Approved Date:"
                                      date={approver?.approvedDate}
                                    />
                                  )}
                                </Stack>
                              )
                            )}
                          </Stack>
                        )}

                        {/* Policy Approvals */}
                        {draftPolicyCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Policy Approval :" />
                            <Stack spacing={1}>
                              {memoizedPolicyApprovals?.map(
                                (approval: any, index: number) => (
                                  <Stack key={approval?.key}>
                                    <AssigneeWithDate
                                      title={`${index + 1}.) Created On Version:`}
                                      subTitle={approval?.version}
                                    />
                                    <AssigneeWithDate
                                      title="Assigned On:"
                                      date={approval?.createdOn}
                                    />
                                    {approval?.reminderDueDate && (
                                      <AssigneeWithDate
                                        title="Task Due Date:"
                                        subTitle={approval?.reminderDueDate}
                                      />
                                    )}
                                    <AssigneeWithDate
                                      title="Status:"
                                      subTitle={approval?.status}
                                    />
                                    {approval?.status === 'Approved' && (
                                      <AssigneeWithDate
                                        title="Approved Date:"
                                        date={approval?.approvedDate}
                                      />
                                    )}
                                  </Stack>
                                )
                              )}
                            </Stack>
                          </Stack>
                        )}

                        {/* Custom Approval */}
                        {draftCustomCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Custom Approval :" />
                            <Stack spacing={1}>
                              {memoizedCustomApprovals?.map(
                                (approval: any, index: number) => (
                                  <Stack key={approval?.key}>
                                    <AssigneeWithDate
                                      title={`${index + 1}.) Created On Version:`}
                                      subTitle={approval?.version}
                                    />
                                    <AssigneeWithDate
                                      title="Assigned On:"
                                      date={approval?.createdOn}
                                    />
                                    {approval?.reminderDueDate && (
                                      <AssigneeWithDate
                                        title="Task Due Date:"
                                        date={approval?.reminderDueDate}
                                      />
                                    )}
                                    <AssigneeWithDate
                                      title="Status:"
                                      subTitle={approval?.status}
                                    />
                                    {approval?.status === 'Approved' && (
                                      <AssigneeWithDate
                                        title="Approved Date:"
                                        date={approval?.approvedDate}
                                      />
                                    )}
                                  </Stack>
                                )
                              )}
                            </Stack>
                          </Stack>
                        )}

                        {/* Pre Signatory Approval */}
                        {draftPreSignatoryCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Pre Signatory Approval :" />
                            {memoizedPreSignatoryApprovers?.map(
                              (approver: any) => (
                                <Stack key={approver?.key}>
                                  <AssigneeWithDate
                                    title="Created On Version:"
                                    subTitle={approver?.version}
                                  />
                                  <AssigneeWithDate
                                    title="Assigned On:"
                                    date={approver?.createdOn}
                                  />
                                  {approver?.reminderDueDate && (
                                    <AssigneeWithDate
                                      title="Task Due Date:"
                                      date={approver?.reminderDueDate}
                                    />
                                  )}
                                  <AssigneeWithDate
                                    title="Status:"
                                    subTitle={approver?.status}
                                  />
                                  {approver?.status === 'Approved' && (
                                    <AssigneeWithDate
                                      title="Approved Date:"
                                      date={approver?.approvedDate}
                                    />
                                  )}
                                </Stack>
                              )
                            )}
                          </Stack>
                        )}

                        {/* Checklist */}
                        {draftChecklistCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Checklist :" />
                            <Stack spacing={1}>
                              {memoizedChecklist?.map(
                                (checklist: any, index: number) => (
                                  <Stack key={checklist?.key}>
                                    <AssigneeWithDate
                                      title={`${index + 1}.) Checklist Name:`}
                                      subTitle={checklist?.checklistName}
                                    />
                                    <AssigneeWithDate
                                      title="Assigned On:"
                                      date={checklist?.createdOn}
                                    />
                                    {checklist?.reminderDueDate && (
                                      <AssigneeWithDate
                                        title="Task Due Date:"
                                        date={checklist?.reminderDueDate}
                                      />
                                    )}
                                    <AssigneeWithDate
                                      title="Status:"
                                      subTitle={checklist?.status}
                                    />
                                    {checklist?.status === 'Completed' && (
                                      <AssigneeWithDate
                                        title="Completed Date:"
                                        date={checklist?.completedDate}
                                      />
                                    )}
                                  </Stack>
                                )
                              )}
                            </Stack>
                          </Stack>
                        )}

                        {/* Signature */}
                        {draftSignatureCheck && (
                          <Stack>
                            <AssigneePopoverTitle title="Signature :" />
                            <Stack spacing={1}>
                              {memoizedSignatories?.map(
                                (signatory: any, index: number) => (
                                  <Stack key={signatory?.key}>
                                    {signatory?.version && (
                                      <AssigneeWithDate
                                        title={`${index + 1}.) Created On Version:`}
                                        subTitle={signatory?.version}
                                      />
                                    )}
                                    <AssigneeWithDate
                                      title="Assigned On:"
                                      subTitle={signatory?.assignedDate}
                                    />
                                    {signatory?.reminderDueDate && (
                                      <AssigneeWithDate
                                        title="Task Due Date:"
                                        date={signatory?.reminderDueDate}
                                      />
                                    )}
                                    {signatory?.signedDate && (
                                      <AssigneeWithDate
                                        title="Signed Date:"
                                        date={signatory?.signedDate}
                                      />
                                    )}
                                    <AssigneeWithDate
                                      title="Status:"
                                      subTitle={signatory?.status}
                                    />
                                    {signatory?.abortedDate && (
                                      <AssigneeWithDate
                                        title="Aborted Date:"
                                        date={signatory?.abortedDate}
                                      />
                                    )}
                                  </Stack>
                                )
                              )}
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Scrollable>
                  </Stack>
                </Stack>
              </Popover>
            </Stack>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
};
export default AssigneeList;
