import React, { Component } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Grid } from '@mui/material';

import MobSetting from './mobSetting';

interface Props {
  userDetail: any;
}

interface State {
  display: boolean;
  isSetting: boolean;
  homePageException: string[];
  searchBarException: string[];
}

export default class MobileMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      display: false,
      isSetting: false,
      homePageException: ['home'],
      searchBarException: ['home', 'judgetoc'],
    };
  }
  visibleSetting = () => {
    this.setState({ isSetting: !this.state.isSetting });
  };
  render() {
    const { isSetting } = this.state;
    return (
      <Box id="mobile-menubar">
        <Grid>
          <Grid item sm={2}>
            <MenuIcon onClick={this.visibleSetting} />
            {isSetting && <MobSetting visibleSetting={this.visibleSetting} />}
          </Grid>
          <Grid
            item
            sm={8}
            sx={{ textAlign: 'center' }}
            id="mobile-header-image"
          >
            <a href={'/'}>
              <img alt="" src="/static_images/mobile-menubar-logo.svg" />
            </a>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
