import React, { useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { deleteSingleColumn, getPara } from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function SublettingClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const data = React.useMemo(
    () => clauseData?.subletting && clauseData.subletting,
    [clauseData?.subletting]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'subletting');
  }, [fileId, hasData]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNotice, setShowAddNotice] = useState(false);
  const [showAddConsent, setShowAddConsent] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const updates = useMemo(
    () => updatedClauseData?.subletting,
    [updatedClauseData]
  );

  const subletting_clause = useMemo(() => {
    if (updates?.subletting) {
      const sortData = updates.subletting.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const noticeInfo = useMemo(() => {
    if (updates?.notice) {
      const sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consentInfo = useMemo(() => {
    if (updates?.consent) {
      const sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let subletting: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        subletting = deleteSingleColumn(subletting_clause, tableData);
      } else {
        subletting = subletting_clause?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id
        );
      }

      let savedRequired = noticeInfo?.required;

      let notice: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        notice = deleteSingleColumn(noticeInfo, tableData);
      } else {
        notice = noticeInfo?.filter(
          (data: any) => data.para_id !== item.para_id
        );
      }
      if (savedRequired) {
        notice.required = savedRequired;
      }
      if (notice?.length === 0) {
        notice.required = false;
      }
      savedRequired = consentInfo?.required;
      let consent: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        consent = deleteSingleColumn(consentInfo, tableData);
      } else {
        consent = consentInfo?.filter(
          (data: any) => data.para_id !== item.para_id
        );
      }
      if (savedRequired) {
        consent.required = savedRequired;
      }
      if (consent?.length === 0) {
        consent.required = false;
      }

      const updatedData = {
        ...updates,
        subletting,
        notice,
        consent,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'subletting', diff, updatedData);
      }
    },
    [
      subletting_clause,
      noticeInfo,
      consentInfo,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteNotice = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      const savedRequired = noticeInfo?.required;
      let notice: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        notice = deleteSingleColumn(noticeInfo, tableData);
      } else {
        notice = noticeInfo?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id
        );
      }
      notice.required = savedRequired;

      if (notice?.length === 0) {
        notice.required = false;
      }

      const updatedData = {
        ...updates,
        notice,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'subletting', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, noticeInfo, data, fileId]
  );

  const deleteConsent = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      const savedRequired = consentInfo?.required;
      let consent: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        consent = deleteSingleColumn(consentInfo, tableData);
      } else {
        consent = consentInfo?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id
        );
      }
      consent.required = savedRequired;

      if (consent?.length === 0) {
        consent.required = false;
      }

      const updatedData = {
        ...updates,
        consent,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'subletting', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consentInfo, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="Subletting/Assignment"
          onClick={() =>
            handleAddEdit(
              props,
              'Subletting/Assignment',
              subletting_clause,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Subletting/Assignment"
          savedInsight={subletting_clause ? subletting_clause : []}
          savedParentClauseDataPoint={subletting_clause}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="subletting"
        />
      )}
      <Scrollable maxHeight={200}>
        {subletting_clause?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'subletting', index });
              props.onClickHighlight(index, item, 'subletting');
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'subletting' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddNotice ? (
        <ClauseHeader
          title="Notice"
          buttonText="notice"
          onClick={() =>
            handleAddEdit(
              props,
              'Subletting/Assignment Notice Info',
              noticeInfo,
              setShowAddNotice
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Subletting/Assignment Notice Info"
          savedInsight={noticeInfo ? noticeInfo : []}
          savedParentClauseDataPoint={noticeInfo}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddNotice(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="subletting"
        />
      )}
      <Scrollable maxHeight={200}>
        {noticeInfo?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'notice', index });
              props.onClickHighlight(
                index,
                item,
                'Subletting/Assignment Notice Info'
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'notice' && activeClause?.index === index
            }
            deleteClause={() => deleteNotice(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
      {!showAddConsent ? (
        <ClauseHeader
          title="Consent Info"
          buttonText="consent"
          onClick={() =>
            handleAddEdit(
              props,
              'Subletting/Assignment Consent Info',
              consentInfo,
              setShowAddConsent
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Subletting/Assignment Consent Info"
          savedInsight={consentInfo ? consentInfo : []}
          savedParentClauseDataPoint={consentInfo}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddConsent(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="subletting"
        />
      )}
      <Scrollable maxHeight={200}>
        {consentInfo?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'consent', index });
              props.onClickHighlight(
                index,
                item,
                'Subletting/Assignment Consent Info'
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'consent' && activeClause?.index === index
            }
            deleteClause={() => deleteConsent(item)}
            para={
              item.sentence ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
          />
        ))}
      </Scrollable>
    </>
  );
}
