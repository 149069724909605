import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import ClauseComponentWatcher from './DocumentView/Component/ClauseComponent/Saga/clauseComponentSaga';
import DocumentWatcher from './DocumentView/Saga/documentSaga';
import { mainReducer } from './Utils/MainReducer/mainReducer';

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(
  mainReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleWare))
);

sagaMiddleWare.run(DocumentWatcher);
sagaMiddleWare.run(ClauseComponentWatcher);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
