import React, { Component } from 'react';

import Comment from './BasicInformation/comment';
import EditAddConsentAuthority from './CustomAddEdit/Consent/EditAddConsentAuthority';
import EditAddConsentRegulatory from './CustomAddEdit/Consent/EditAddConsentRegulatory';
import EditAddAssignmentInfo from './CustomAddEdit/EditAddAssignmentInfo';
import EditAddConsent from './CustomAddEdit/EditAddConsent';
import EditAddDefinition from './CustomAddEdit/EditAddDefinition';
import EditEvents from './Events/Container/editEventsCon';
import EditLinkAmountPhrase from './Link/EditLinkAmountPhrase';
import EditLinkDurationPhrase from './Link/EditLinkDurationPhrase';
import EditLinkPresentSentence from './Link/EditLinkPresentSentence';
import EditSinglePara from './LockInPeriod/editSinglePara';
import EditPhrases from './Phrases/Container/editPhrasesCon';
import EditLinkPara from './Present/editPresent';
import EditPresentSentence from './Present/EditPresentSentence';
import EditTerminationConvenience from './Present/EditTerminationConvenience';
import EditDuration from './TermDates/EditDuration';
import EffectiveDates from './TermDates/EffectiveDates';
import EndDate from './TermDates/endDate';
import RenewalReferenceDates from './TermDates/RenewalReferenceDates';
import StartDate from './TermDates/startDate';
import BlurryLoader from '../../../../UniversalComponents/Loader/blurryLoader';
import { ClauseType } from '../../../Constants/ClauseType';
import {
  CurrencyType,
  dateInfo,
  DurationType,
  ParentClauseDataPoint,
  phraseInfo,
  sentenceInfo,
  SentencesData,
  tableInfo,
} from '../../../State/documentState';

interface Props {
  toBeEdited: string;
  editOptionSelected: (editOptionSelected: boolean) => void;
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  fileId: string;
  savedInsight: any;
  editLoader: boolean;
  insightsLoader: boolean;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedDataPoint: (
    dataPointName: string,
    editOptionSelection: boolean
  ) => void;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedHighlightedDates: dateInfo[] | null;
  saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) => void;
  dateInAddEditMode: dateInfo | null;
  saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) => void;
  dateEditingStatus: boolean;
  saveDateEditingStatus: (dateEditingStatus: boolean) => void;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedParentClauseDataPoint: ParentClauseDataPoint;
  childInEditId: number;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  otherClauses: (type: string) => void;
  onClose: any;
  onSubmit: any;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: string;
  clauseItem: any;
  parentClauseData: any;
  updatedParentClauseDataByType: any;
  canAddTags?: boolean;
  parentData: any;
  mode?: string;
}

interface State {
  editOptionSelection: boolean;
}

export default class EditFeature extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editOptionSelection: false,
    };
  }

  render() {
    const {
      mode,
      highlightedId,
      toBeEdited,
      insightsLoader,
      savedInsight,
      editOptionSelected,
      editLoader,
      saveHighlightedId,
      saveHighlightedSentences,
      savedHighlightedSentences,
      savedHighlightedTableCells,
      saveHighlightedTableCells,
      savedHighlightedDates,
      saveHighlightedDates,
      dateInAddEditMode,
      saveDateInAddEditMode,
      dateEditingStatus,
      saveDateEditingStatus,
      savedHighlightedPhrases,
      saveHighlightedPhrases,
      phraseEditOptionSelected,
      savePhraseEditOption,
      phraseInDeleteMode,
      phraseDeleteStatus,
      saveDeletePhrase,
      phraseInAddMode,
      phraseAddStatus,
      saveAddPhrase,
      savedParentClauseDataPoint,
      durationList,
      currencyList,
      otherClauses,
      clauseData,
      updatedClauseData,
      updatedClauseDataByType,
      clauseDataByType,
      parentClauseType,
      canAddTags,
      parentData,
    } = this.props;
    if (editLoader || insightsLoader) {
      return (
        <div className="toc-content toc-content-default">
          <BlurryLoader />
        </div>
      );
    } else {
      switch (toBeEdited) {
        case 'Term Start Dates': {
          return (
            <StartDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              updatedClauseData={updatedClauseData}
            />
          );
        }
        case 'Effective Dates': {
          return (
            <EffectiveDates
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              clauseData={clauseData}
            />
          );
        }
        case 'Renewal Notice Reference Date': {
          return (
            <RenewalReferenceDates
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              currencyList={currencyList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              onClose={this.props.onClose}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              sentenceData={this.props.sentenceData}
              fileId={this.props.fileId}
              postClauseDataByType={this.props.postClauseDataByType}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }

        case 'Term End Dates': {
          return (
            <EndDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={this.props.clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }
        case 'Term Duration': {
          return (
            <EditDuration
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDuration={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              durationList={durationList}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }

        case 'Termination clause':
        case 'confidentiality':
        case 'payment':
        case 'Exception to Confidentiality':
        case 'non_compete':
        case 'Non-compete clause':
        case 'Non-solicitation clause':
        case 'Limitation Of Liability':
        case 'notice':
        case 'Notice Obligations':
        case 'non_solicitation':
        case 'exclusivity':
        case 'limited_liability':
        case 'change_of_control':
        case 'Change of Control Termination':
        case 'Change of Control Consent':
        case 'Change of Control Notice':
        case 'Change of Control Payment':
        case 'obligation_statements':
        case 'Other Obligations': {
          return (
            <EditPresentSentence
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              otherClauses={(type: string) => otherClauses(type)}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              clauseData={clauseData}
              parentClauseType={parentClauseType}
            />
          );
        }
        case 'Effective Dates Notify':
        case 'Notice for Auto-Renewal':
        case 'Non Compete Territory':
        case 'Confidentiality Nature':
        case 'Events Covered':
        case 'Jurisdiction':
        case 'Data Breach Regulation':
        case 'Data Breach Notify':
        case 'Dispute Resolution Panel':
        case 'Dispute Resolution Venue':
        case 'Dispute Resolution Act/Statute':
        case 'Indemnity/Reimbursements/Costs Payer':
        case 'Indemnity/Reimbursements/Costs Payee':
        case 'Indemnity/Reimbursements/Costs Triggering Event':
        case 'Indemnity/Reimbursements/Costs Extent of Cost': {
          return (
            <EditPhrases
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              clauseData={clauseData}
            />
          );
        }
        case 'Auto-Renewal':
        case 'Force Majeure':
        case 'renewal':
        case 'Renewal clause':
        case 'Insurance clause':
        case 'eventsOfDefault':
        case 'term':
        case 'Governing Law clause':
        case 'Dispute Resolution':
        case 'Dispute Resolution Conciliation':
        case 'Dispute Resolution Mediation':
        case 'Dispute Resolution Negotiation':
        case 'Dispute Resolution Others':
        case 'Dispute Resolution Arbitration':
        case 'notice_renewal':
        case 'insurance':
        case 'governing_law':
        case 'force_majeure':
        case 'data_breach':
        case 'indemnity':
        case 'events_of_default':
        case 'Change of Control Definition':
        case 'dispute_resolution':
        case 'Subletting/Assignment': {
          return (
            <EditLinkPara
              mode={mode}
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
            />
          );
        }
        case 'Termination at Convenience': {
          return (
            <EditTerminationConvenience
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              parentClauseData={this.props.parentClauseData}
              updatedParentClauseDataByType={
                this.props.updatedParentClauseDataByType
              }
            />
          );
        }
        case 'Lock-in Period': {
          return (
            <EditSinglePara
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={clauseData}
            />
          );
        }
        case 'Termination Event':
        case 'Events Of Default Event': {
          return (
            <EditEvents
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              canAddTags={canAddTags}
            />
          );
        }
        case 'general_definitions': {
          return (
            <EditAddDefinition
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              savedHighlightedPhrases={savedHighlightedPhrases}
              saveHighlightedPhrases={saveHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              phraseInDeleteMode={phraseInDeleteMode}
              phraseInAddMode={phraseInAddMode}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseAddStatus={phraseAddStatus}
              saveAddPhrase={saveAddPhrase}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              clauseData={clauseData}
            />
          );
        }
        case 'Consent Clause': {
          return (
            <EditAddConsent
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        //case "Exception to Confidentiality":
        case 'Subletting/Assignment Notice Info':
        case 'Subletting/Assignment Consent Info': {
          return (
            <EditAddAssignmentInfo
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              otherClauses={(type: string) => otherClauses(type)}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case 'Consent Regulatory': {
          return (
            <EditAddConsentRegulatory
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsightChild={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case 'Consent Authority': {
          return (
            <EditAddConsentAuthority
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsightChild={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedParentClauseDataPoint={savedParentClauseDataPoint}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case 'Subletting Duration':
        case 'Consent Duration':
        case 'Other Obligations - Post Contract Duration':
        case 'Renewal Notice Duration':
        case 'Non Compete Duration':
        case 'Non Solicitation Duration':
        case 'Confidentiality Duration':
        case 'Payment Duration':
        case 'Payment Timeline': {
          return (
            <EditLinkDurationPhrase
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              durationList={durationList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              parentData={parentData}
            />
          );
        }
        case 'Indemnity/Reimbursements/Costs Amount':
        case 'Amount':
        case 'Capped':
        case 'Uncapped':
        case 'Limitation Of Liability Amount':
        case 'Payment Amounts': {
          return (
            <EditLinkAmountPhrase
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              currencyList={currencyList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              clauseData={clauseData}
              canAddTags={canAddTags}
              parentData={parentData}
            />
          );
        }
        case 'Comment': {
          return (
            <Comment
              savedTitle={savedInsight[0] ? savedInsight[0].name : ''}
              editOptionSelected={editOptionSelected}
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
            />
          );
        }
        case 'Prohibitions': {
          return (
            <EditLinkPresentSentence
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
            />
          );
        }
        default: {
          return <div />;
        }
      }
    }
  }

  editOptionSelected(selection: boolean) {
    this.setState({ editOptionSelection: selection });
    this.props.editOptionSelected(selection);
  }

  saveHighlightedDataPoint(dataPointName: string) {
    this.props.saveHighlightedDataPoint(
      dataPointName,
      this.state.editOptionSelection
    );
  }
}
