import React, { useEffect } from 'react';

import { Chip, FormControl, InputBase } from '@mui/material';
import { useForm } from 'react-hook-form';

import RISelectComponent from '../Components/SelectComponent';

interface IProps {
  label: string;
  onChange?: any;
  options: any;
  labelKey?: string;
  valueKey?: string;
  value?: any;
}

const TableSearchSelectInput: React.FC<IProps> = ({
  label,
  options,
  onChange,
  labelKey = 'name',
  valueKey = 'displayName',
  value,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string[]>(
    value || []
  );

  const { control, watch } = useForm({
    defaultValues: {
      contract_type: selectedValue,
    },
  });

  const selectedContractType = watch('contract_type') || '';

  useEffect(() => {
    if (selectedContractType) {
      setSelectedValue(selectedContractType);
      onChange?.(selectedContractType);
    }
  }, [selectedContractType]);

  return (
    <FormControl sx={{ m: 0, minWidth: 150, maxWidth: 230 }}>
      <RISelectComponent
        name="contract_type"
        control={control}
        isMultiselect
        valueKey={valueKey}
        labelKey={labelKey}
        options={options || []}
        renderCustomComponent={(value: any, props) => (
          <Chip {...props} label={value?.[labelKey] || value} />
        )}
        disableCloseOnSelect={false}
        isFilterCustomComponent={true}
        CustomInput={(params) => (
          <InputBase
            placeholder={label}
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              gap: 0.5,
              height: '55px',
              alignItems: 'center',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            {...params.InputProps}
            inputProps={{ ...params.inputProps, 'aria-label': 'Without label' }}
          />
        )}
      />
    </FormControl>
  );
};

export default TableSearchSelectInput;
