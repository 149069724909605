import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';

import ChildFilter from './ChildFilter';
import ClauseTypeFilter from './CLauseTypeFilter';
import EventFilter from './EventFilter';
import FilterWithSearch from './FIlterWithSearch';
import SearchComponent from './SearchComponent';
import {
  DurationList,
  filterHeaderStyle,
  scrollContainerSx,
  yesNoOptions,
} from './StaticData';
import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import DateRangePicker from '../../RiverusUI/Components/DateRangePicker';
import FilterBg from '../../RiverusUI/Components/Icons/FilterBg';
import RadioButtonGroup from '../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../RiverusUI/Components/SelectComponent';
import { getFilterResults, getInsightsES } from '../../Services/search';

const FilterComponent = () => {
  const methods = useForm();
  const { control, watch, setValue, reset, resetField } = methods;
  const [searchTerm, setSearchTerm] = useState<{ search: string[] }>({
    search: [],
  });
  const [searchResultIds, setSearchResultIds] = useState<any>([]);

  const [startEndDate, setStartEndDate] = useState<any>({
    start: null,
    end: null,
  });

  const clause_type = watch('clause_type');
  const clause_type_absent = watch('clause_type_absent');
  const termination_events = watch('termination_events');
  const events_of_default_events = watch('events_of_default_events');
  const jurisdiction = watch('jurisdiction') || null;
  const contracting_parties = watch('contracting_parties') || null;
  const contract_type = watch('contract_type') || null;
  const groups = watch('groups') || null;
  const projects = watch('projects') || null;
  const auto_renewal = watch('auto_renewal') || null;
  const change_of_control_payment = watch('change_of_control_payment') || null;
  const change_of_control_termination =
    watch('change_of_control_termination') || null;
  const consent = watch('consent') || null;
  const notice = watch('notice') || null;
  const arbitration = watch('arbitration') || null;
  const conciliation = watch('conciliation') || null;
  const mediation = watch('mediation') || null;
  const negotiation = watch('negotiation') || null;
  const regulatory = watch('regulatory') || null;
  const data_breach_notify = watch('data_breach_notify') || null;
  const data_breach_regulation = watch('data_breach_regulation') || null;
  const payment = watch('payment') || null;
  const confidentiality = watch('confidentiality') || null;
  const limited_liability = watch('limited_liability') || null;
  const force_majeure = watch('force_majeure') || null;
  const term_duration = watch('term_duration') || null;
  const term_value = watch('term_value') || null;

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length > 0 ||
      clause_type?.length > 0 ||
      clause_type_absent?.length > 0 ||
      groups?.length > 0 ||
      contracting_parties?.length > 0 ||
      projects?.length > 0 ||
      jurisdiction?.length > 0 ||
      contract_type?.length > 0,
    [
      searchTerm,
      clause_type,
      clause_type_absent,
      groups,
      jurisdiction,
      contract_type,
      contracting_parties,
      projects,
    ]
  );

  const { data: filterData, isFetching } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      clause_type_absent,
      startEndDate,
      termination_events,
      events_of_default_events,
      auto_renewal,
      change_of_control_payment,
      change_of_control_termination,
      consent,
      notice,
      arbitration,
      conciliation,
      mediation,
      negotiation,
      regulatory,
      jurisdiction,
      contracting_parties,
      contract_type,
      groups,
      projects,
      force_majeure,
      limited_liability,
      confidentiality,
      payment,
      data_breach_notify,
      data_breach_regulation,
      term_duration,
      term_value,
    ],
    queryFn: () => {
      const operations: any[] = [];
      let newOperation;
      if (clause_type) {
        clause_type.map((clause: string) => {
          newOperation = {
            operation: 'exists',
            index: clause,
          };
          operations.push(newOperation);
        });
      }
      if (clause_type_absent) {
        clause_type_absent.map((clause: string) => {
          newOperation = {
            operation: 'not_exists',
            index: clause,
          };
          operations.push(newOperation);
        });
      }
      if (startEndDate.start) {
        newOperation = {
          operation: 'range',
          index: 'term',
          args: {
            property: 'start_date',
            gte: dayjs(startEndDate.start).format('DD-MM-YYYY'),
            lte: dayjs(startEndDate.start).format('DD-MM-YYYY'),
          },
        };
        if (startEndDate.end) {
          newOperation = {
            operation: 'range',
            index: 'term',
            args: {
              property: 'end_date',
              gte: dayjs(startEndDate.end).format('DD-MM-YYYY'),
              lte: dayjs(startEndDate.end).format('DD-MM-YYYY'),
            },
          };
          operations.push(newOperation);
        }
        operations.push(newOperation);
      }
      if (termination_events) {
        termination_events.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'termination',
            args: {
              property: 'event',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (events_of_default_events) {
        events_of_default_events.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'event_of_default',
            args: {
              property: 'event',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (auto_renewal) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'renewal',
          args: {
            property: 'renewal.auto_renewal.sentence',
            value: auto_renewal === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (change_of_control_payment) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'change_of_control',
          args: {
            property: 'change_of_control.payment.sentence',
            value: change_of_control_payment === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (change_of_control_termination) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'change_of_control',
          args: {
            property: 'change_of_control.termination.sentence',
            value: change_of_control_termination === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (consent) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'change_of_control',
          args: {
            property: 'change_of_control.consent.sentence',
            value: consent === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (notice) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'change_of_control',
          args: {
            property: 'change_of_control.notice.sentence',
            value: notice === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (arbitration) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'dispute_resolution',
          args: {
            property: 'dispute_resolution.arbitration.sentence',
            value: arbitration === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (conciliation) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'dispute_resolution',
          args: {
            property: 'dispute_resolution.conciliation.sentence',
            value: conciliation === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (mediation) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'dispute_resolution',
          args: {
            property: 'dispute_resolution.mediation.sentence',
            value: mediation === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (negotiation) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'dispute_resolution',
          args: {
            property: 'dispute_resolution.negotiation.sentence',
            value: negotiation === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }
      if (regulatory) {
        newOperation = {
          operation: 'exist_non_exist',
          index: 'consent',
          args: {
            property: 'consent.regulatory.sentence',
            value: regulatory === 'True' ? true : false,
          },
        };
        operations.push(newOperation);
      }

      if (force_majeure) {
        force_majeure.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'force_majeure',
            args: {
              property: 'fm_events_split.split_events.event',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (limited_liability) {
        limited_liability.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'limited_liability',
            args: {
              property: 'amounts.value',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (payment) {
        payment.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'payment',
            args: {
              property: 'amounts.value',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (confidentiality) {
        confidentiality.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'confidentiality',
            args: {
              property: 'confidentiality.duration',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (data_breach_regulation) {
        data_breach_regulation.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'data_breach',
            args: {
              property: 'regulation.extractions.ext',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (data_breach_notify) {
        data_breach_notify.map((event: string) => {
          newOperation = {
            operation: 'match_phrase',
            index: 'data_breach',
            args: {
              property: 'notify.notify.ext_',
              value: event,
            },
          };
          operations.push(newOperation);
        });
      }
      if (term_duration && term_value) {
        newOperation = {
          operation: 'match_phrase',
          index: 'term',
          args: {
            property: 'duration',
            value: `${term_value} ${term_duration}`,
          },
        };
        operations.push(newOperation);
      }

      return getFilterResults({
        ...searchTerm,
        operations: operations,
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(jurisdiction?.length > 0 && { jurisdiction: jurisdiction }),
        ...(contracting_parties?.length > 0 && {
          contracting_parties: contracting_parties,
        }),
      });
    },
    enabled: showSearchResult,
  });

  useEffect(() => {
    if (clause_type?.length === 0) {
      reset();
    }
    if (clause_type?.length) {
      if (!clause_type.includes('term')) {
        setStartEndDate({
          start: null,
          end: null,
        });
        resetField('term_value');
        resetField('term_duration');
      }
      if (!clause_type.includes('renewal')) {
        resetField('auto_renewal');
      }
      if (!clause_type.includes('change_of_control')) {
        resetField('change_of_control_payment');
        resetField('change_of_control_termination');
        resetField('consent');
        resetField('notice');
      }
      if (!clause_type.includes('dispute_resolution')) {
        resetField('arbitration');
        resetField('conciliation');
        resetField('mediation');
        resetField('negotiation');
      }
      if (!clause_type.includes('consent')) {
        resetField('regulatory');
      }
    }
  }, [clause_type]);

  useEffect(() => {
    const searchIds: any = [];
    if (filterData?.length) {
      filterData.map((data: any) =>
        data?.ids?.map((idsData: any) => {
          const index = searchIds.findIndex(
            (searchItem: any) => searchItem.id === idsData.id
          );
          if (index === -1) {
            searchIds.push(idsData);
          }
        })
      );
      setSearchResultIds(searchIds);
    }
    if (!showSearchResult) {
      setSearchResultIds([]);
    }
  }, [filterData]);

  const { data: insightsData, isLoading } = useQuery({
    queryKey: ['insights-es', searchResultIds, searchTerm],
    queryFn: () => {
      const ids = searchResultIds?.map((item: any) => item.id);
      const payload = {
        ...(ids.length > 0 && { ids: ids || [] }),
        ...(searchTerm.search.length > 0 && searchTerm),
      };
      return getInsightsES(payload);
    },
  });

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter(
      (item: any) => item !== term
    );
    searchQuery = {
      search: filteredData,
    };
    setSearchTerm(searchQuery);
  };

  const handleFilterDelete = (list: any, item: string, clause: string) => {
    const filteredData = list.filter((data: string) => data !== item);
    setValue(clause, filteredData);
  };

  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="25vw"
        maxWidth="30vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <Stack gap={2}>
              <ClauseTypeFilter
                clause_type={clause_type}
                clause_type_absent={clause_type_absent}
                control={control}
                insightsData={insightsData}
                isLoading={isLoading}
              />
              {clause_type?.includes('term') && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Term Clause</Typography>
                  <Stack gap={2} padding={2}>
                    <DateRangePicker
                      value={startEndDate}
                      onChange={(newValue: any) => setStartEndDate(newValue)}
                    />
                    <Stack direction="row" width="100%" spacing={1}>
                      <ControlledTextField
                        name="term_value"
                        type="number"
                        control={control}
                        label="Value"
                        fullWidth
                        onKeyPress={(event) => {
                          if (event?.key === '-' || event?.key === '+') {
                            event.preventDefault();
                          }
                        }}
                        sx={{ flex: 1 }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <RISelectComponent
                          name="term_duration"
                          control={control}
                          options={DurationList || []}
                          labelKey="durationName"
                          valueKey="durationType"
                          label="Select Duration"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Card>
              )}
              {clause_type?.includes('termination') && (
                <EventFilter
                  label="Termination Clause"
                  clause="termination"
                  control={control}
                />
              )}
              {clause_type?.includes('payment') && (
                <ChildFilter
                  label="Payment Tags"
                  clause="payment"
                  control={control}
                  searchResultIds={searchResultIds}
                />
              )}
              {clause_type?.includes('confidentiality') && (
                <ChildFilter
                  label="Confidentiality Duration"
                  clause="confidentiality"
                  control={control}
                  searchResultIds={searchResultIds}
                />
              )}
              {clause_type?.includes('change_of_control') && (
                <Card>
                  <Typography sx={filterHeaderStyle}>
                    Change Of Control
                  </Typography>
                  <Stack gap={2} padding={2}>
                    <RadioButtonGroup
                      row
                      label="Payment"
                      name="change_of_control_payment"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Termination"
                      name="change_of_control_termination"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Consent"
                      name="consent"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Notice"
                      name="notice"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                  </Stack>
                </Card>
              )}
              {clause_type?.includes('events_of_default') && (
                <EventFilter
                  label="Events Of Default"
                  clause="events_of_default"
                  control={control}
                />
              )}
              {clause_type?.includes('limited_liability') && (
                <ChildFilter
                  label="Limitation of liability"
                  clause="limited_liability"
                  control={control}
                  searchResultIds={searchResultIds}
                />
              )}
              {clause_type?.includes('force_majeure') && (
                <ChildFilter
                  label="Force Majeure Events"
                  clause="force_majeure"
                  control={control}
                  searchResultIds={searchResultIds}
                />
              )}
              {clause_type?.includes('renewal') && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Renewal Clause</Typography>
                  <Stack gap={2} padding={2}>
                    <RadioButtonGroup
                      row
                      label="Auto Renewal"
                      name="auto_renewal"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                  </Stack>
                </Card>
              )}
              {clause_type?.includes('dispute_resolution') && (
                <Card>
                  <Typography sx={filterHeaderStyle}>
                    Dispute Resolution
                  </Typography>
                  <Stack gap={2} padding={2}>
                    <RadioButtonGroup
                      row
                      label="Arbitration"
                      name="arbitration"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Conciliation"
                      name="conciliation"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Mediation"
                      name="mediation"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                    <RadioButtonGroup
                      row
                      label="Negotiation"
                      name="negotiation"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                  </Stack>
                </Card>
              )}
              {clause_type?.includes('consent') && (
                <Card>
                  <Typography sx={filterHeaderStyle}>Consent Clause</Typography>
                  <Stack gap={2} padding={2}>
                    <RadioButtonGroup
                      row
                      label="Regulatory"
                      name="regulatory"
                      options={yesNoOptions}
                      valueKey="value"
                      isDescription
                      control={control}
                    />
                  </Stack>
                </Card>
              )}
              {clause_type?.includes('data_breach') && (
                <>
                  <ChildFilter
                    label="Data Breach Notify"
                    clause="data_breach_notify"
                    control={control}
                    searchResultIds={searchResultIds}
                  />
                  <ChildFilter
                    label="Data Breach regulation"
                    clause="data_breach_regulation"
                    control={control}
                    searchResultIds={searchResultIds}
                  />
                </>
              )}
              <FilterWithSearch
                label="Contract Type"
                control={control}
                filterData={insightsData}
                clause="contract_type"
                propertyKey="contract_type_counts"
              />
              <FilterWithSearch
                label="Groups"
                control={control}
                filterData={insightsData}
                clause="groups"
                propertyKey="groups_counts"
              />
              <FilterWithSearch
                label="Projects"
                control={control}
                filterData={insightsData}
                clause="projects"
                propertyKey="projects_counts"
              />
              <FilterWithSearch
                label="Jurisdiction"
                control={control}
                filterData={insightsData}
                clause="jurisdiction"
                propertyKey="jurisdiction_counts"
              />
              <FilterWithSearch
                label="Contracting Parties"
                control={control}
                filterData={insightsData}
                clause="contracting_parties"
                propertyKey="contracting_parties_counts"
                canSearch
              />
            </Stack>
          </form>
        </FormProvider>
      </Stack>

      <Stack flex={1} gap={2}>
        <SearchComponent
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        {showSearchResult ? (
          <Stack gap={2} flex={1}>
            <Typography>
              <strong>{searchResultIds?.length}</strong> results for
            </Typography>
            {isFetching && <LinearProgress variant="indeterminate" />}
            <Stack direction="row" gap={1} flexWrap="wrap">
              {searchTerm.search.map((term: string) => (
                <Chip
                  key={term}
                  label={term}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  onDelete={() => handleSearchTermDelete(term)}
                  icon={<SearchOutlinedIcon fontSize="small" />}
                />
              ))}

              {clause_type?.map((clause: string, index: number) => (
                <Chip
                  key={`clause-type-${index}`}
                  label={clause}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(clause_type, clause, 'clause_type')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {force_majeure?.map((value: string, index: number) => (
                <Chip
                  key={`force-majeure-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(force_majeure, value, 'force_majeure')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {limited_liability?.map((value: string, index: number) => (
                <Chip
                  key={`limited_liability-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      limited_liability,
                      value,
                      'limited_liability'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {confidentiality?.map((value: string, index: number) => (
                <Chip
                  key={`confidentiality-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      confidentiality,
                      value,
                      'confidentiality'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {payment?.map((value: string, index: number) => (
                <Chip
                  key={`payment-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() => handleFilterDelete(payment, value, 'payment')}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {data_breach_regulation?.map((value: string, index: number) => (
                <Chip
                  key={`data_breach_regulation-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      data_breach_regulation,
                      value,
                      'data_breach_regulation'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {data_breach_notify?.map((value: string, index: number) => (
                <Chip
                  key={`data_breach_notify-${index}`}
                  label={value}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      data_breach_notify,
                      value,
                      'data_breach_notify'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {clause_type_absent?.map((clause: string, index: number) => (
                <Chip
                  key={`clause-type-${index}`}
                  label={clause}
                  variant="outlined"
                  color="error"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      clause_type_absent,
                      clause,
                      'clause_type_absent'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {projects?.map((item: string, index: number) => (
                <Chip
                  key={`projects-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(projects, item, 'projects')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {groups?.map((item: any, index: number) => (
                <Chip
                  key={`groups-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() => handleFilterDelete(groups, item, 'groups')}
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {contract_type?.map((item: any, index: number) => (
                <Chip
                  key={`contract-type-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(contract_type, item, 'contract_type')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {jurisdiction?.map((item: any, index: number) => (
                <Chip
                  key={`jurisdiction-${index}`}
                  label={item}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(jurisdiction, item, 'jurisdiction')
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
              {contracting_parties?.map((contract: any, index: number) => (
                <Chip
                  key={`contracting-parties-${index}`}
                  label={contract}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: '13px' }}
                  onDelete={() =>
                    handleFilterDelete(
                      contracting_parties,
                      contract,
                      'contracting_parties'
                    )
                  }
                  deleteIcon={<CloseIcon fontSize="small" />}
                  icon={<FilterAltOutlinedIcon fontSize="small" />}
                />
              ))}
            </Stack>
            <Stack
              sx={{
                ...scrollContainerSx,
                maxHeight: '80vh',
                paddingRight: '10px',
              }}
              gap={2}
            >
              {searchResultIds?.map((contractData: any, index: number) => (
                <Card
                  key={index}
                  sx={{ padding: '10px 15px', overflow: 'visible' }}
                >
                  <Stack gap={1}>
                    <Stack
                      sx={{ color: '#996C84', alignItems: 'center' }}
                      direction="row"
                    >
                      <Typography variant="h6">
                        {contractData.file_name}
                      </Typography>
                      <IconButton
                        sx={{ color: '#996C84' }}
                        onClick={() =>
                          window.open(
                            '/document/documentlibrary/' +
                              btoa(contractData?.id?.toString()),
                            '_blank'
                          )
                        }
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Stack>
                    {Array.isArray(contractData.contract_type) ? (
                      <Typography sx={{ color: '#6D6E71', fontSize: '14px' }}>
                        {contractData.contract_type[0]}
                      </Typography>
                    ) : null}
                    {contractData.groups.length > 0 && (
                      <Stack direction="row" gap={1}>
                        {contractData.groups.map(
                          (item: string, index: number) => (
                            <Chip
                              key={`contract-group-${index}`}
                              label={item}
                              color="primary"
                              sx={{ fontSize: '13px' }}
                            />
                          )
                        )}
                        {contractData.projects.map(
                          (item: string, index: number) => (
                            <Chip
                              key={`contract-group-${index}`}
                              label={item}
                              color="success"
                              sx={{ fontSize: '13px' }}
                            />
                          )
                        )}
                      </Stack>
                    )}
                    {contractData.para && (
                      <Stack
                        gap={1}
                        sx={{
                          ...scrollContainerSx,
                          maxHeight: '250px',
                        }}
                      >
                        {contractData.para.map((para: any, index: number) => (
                          <Typography
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: para.sentence,
                            }}
                          />
                        ))}
                      </Stack>
                    )}
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Stack
            height="70vh"
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            <FilterBg />
            <Typography>{`${searchResultIds?.length || insightsData?.total} Contract found`}</Typography>
            <Typography>Start by searching/Filtering</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default FilterComponent;
