import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';

import { sentenceInfo, tableInfo } from '../../../State/documentState';
import { sentenceLevelComponents } from '../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  editDataPoint: VoidFunction;
  saveHighlightedDataPoint?: (highlight?: boolean) => void;
  dataPointName: string;
  enableHighlightOption: boolean;
  enableSaveBtn?: boolean;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  previouslyLinkedSentences?: sentenceInfo[];
  previouslyLinkedTableCells?: tableInfo[];
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  otherClauses: (type: string) => void;
}

const SaveAndCancelSentence: React.FC<Props> = (props) => {
  const selectedCount = (propsData: any): number => {
    const highlightData = propsData.savedHighlightedSentences
      ? propsData.savedHighlightedSentences
      : [];
    const previouslyLinkedSentences = propsData.previouslyLinkedSentences
      ? propsData.previouslyLinkedSentences
      : [];
    let highlightCount = highlightData.length;
    const highlightTableCount = propsData.savedHighlightedTableCells
      ? propsData.savedHighlightedTableCells
      : [];
    for (let i = 0; i < highlightTableCount.length; i++) {
      const dataPresent = highlightData.filter(
        (e: any) => e.paraId === highlightTableCount[i]['paraId']
      );
      if (dataPresent.length === 0) {
        if (
          previouslyLinkedSentences.filter(
            (e: any) => e.paraId === highlightTableCount[i]['paraId']
          ).length === 0
        ) {
          highlightCount = highlightCount + 1;
        }
      }
    }
    return highlightCount;
  };

  const [highlight, setHighlight] = useState(!props.enableHighlightOption);
  const [highlightCount, setHighlightCount] = useState<number>(
    selectedCount(props)
  );

  useEffect(() => {
    setHighlightCount(selectedCount(props));
    const timeout = setTimeout(() => {
      setHighlightCount(selectedCount(props));
    }, 500);
    return () => clearTimeout(timeout);
  }, [props]);

  useEffect(() => {
    if (props.enableHighlightOption !== highlight) {
      setHighlight(!props.enableHighlightOption);
    }
  }, [props.enableHighlightOption]);

  const onSave = () => {
    props.editDataPoint();
    props.editOptionSelected(false);
    setHighlight(false);
    if (props.dataPointName === 'obligation_statements') {
      props.otherClauses('remove');
    }
  };

  const editLinkedSentence = () => {
    const {
      previouslyLinkedSentences,
      saveHighlightedDataPoint,
      previouslyLinkedTableCells,
      dataPointName,
      otherClauses,
    } = props;
    setHighlight(true);
    if (previouslyLinkedSentences) {
      props.saveHighlightedSentences(previouslyLinkedSentences);
    }
    if (previouslyLinkedTableCells) {
      props.saveHighlightedTableCells(previouslyLinkedTableCells);
    }
    props.editOptionSelected(true);
    if (dataPointName === 'obligation_statements') {
      otherClauses('add');
    }
    saveHighlightedDataPoint?.(true);
  };

  const saveHighlightedDataPoint = () => {
    const { saveHighlightedDataPoint } = props;
    setHighlight(true);
    props.editOptionSelected(true);
    saveHighlightedDataPoint?.(true);
  };

  const cancel = () => {
    if (props.dataPointName === 'obligation_statements') {
      props.otherClauses('remove');
    }
    props.onClose();
    setHighlight(false);
    props.editOptionSelected(false);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
  };

  const showHighlightedCount = () => {
    const {
      savedHighlightedSentences,
      enableHighlightOption,
      previouslyLinkedSentences,
      savedHighlightedTableCells,
      previouslyLinkedTableCells,
    } = props;

    if (previouslyLinkedSentences || previouslyLinkedTableCells) {
      if (
        (previouslyLinkedSentences && previouslyLinkedSentences.length > 0) ||
        (previouslyLinkedTableCells && previouslyLinkedTableCells.length > 0)
      ) {
        if (
          ((savedHighlightedSentences &&
            savedHighlightedSentences.length > 0) ||
            (savedHighlightedTableCells &&
              savedHighlightedTableCells.length > 0)) &&
          highlight
        ) {
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontSize="13px" style={{ color: '#C1C1C1' }}>
                Edit linked sentences(s)
              </Typography>
              <Typography fontSize="13px">{highlightCount}</Typography>
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" justifyContent="start" width="100%">
              <Button
                variant="text"
                style={{
                  color: '#88305F',
                  padding: 0,
                }}
                onClick={editLinkedSentence}
              >
                Edit linked sentences
                <span style={{ textTransform: 'lowercase' }}>(s)</span>
              </Button>
            </Stack>
          );
        }
      } else {
        if (
          ((savedHighlightedSentences &&
            savedHighlightedSentences.length > 0) ||
            (savedHighlightedTableCells &&
              savedHighlightedTableCells.length > 0)) &&
          highlight === true
        ) {
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontSize="13px" style={{ color: '#C1C1C1' }}>
                Edit linked sentences(s)
              </Typography>
              <Typography fontSize="13px">{highlightCount}</Typography>
            </Stack>
          );
        }
        if (enableHighlightOption) {
          return (
            <Stack alignItems="start" width="100%">
              <Button
                variant="text"
                sx={{
                  padding: 0,
                }}
                style={{
                  color: highlight ? '#C1C1C1' : '#88305F',
                }}
                onClick={saveHighlightedDataPoint}
              >
                Link to sentences
                <span style={{ textTransform: 'lowercase' }}>(s)</span>
              </Button>
            </Stack>
          );
        }
      }
    } else {
      return (
        <Stack direction="row" justifyContent="start" width="100%">
          <Button
            style={{ color: '#88305F', padding: 0 }}
            onClick={editLinkedSentence}
          >
            Edit linked sentences
            <span style={{ textTransform: 'lowercase' }}>(s)</span>
          </Button>
        </Stack>
      );
    }
  };

  return (
    <>
      {sentenceLevelComponents.indexOf(props.dataPointName) !== -1 &&
        showHighlightedCount()}
      <Stack direction="row">
        <Button
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={onSave}
          disabled={!props.enableSaveBtn}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={cancel} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default SaveAndCancelSentence;
