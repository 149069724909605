import colors from './colors';

const radioTheme = {
  styleOverrides: {
    root: {
      padding: '8px',
      '& .Mui-checked': {
        color: colors?.riPrimary?.main,
      },
    },
  },
};
export default radioTheme;
