import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

import { userTypes } from './StaticData';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  deleteExternalUser,
  editExternalUsers,
  externalUsers,
} from '../../../Services/Draft';
import { removeAnnotation, sortListAlphabetical } from '../Helper';

interface Props {
  signatoriesData: any[];
  onSubmit: (data: any) => void;
  handleUserType: VoidFunction;
  instance: any;
  externalUsersData: any;
  isLoading: boolean;
  isReplacing?: boolean;
  userType?: string;
  handleCancel?: VoidFunction;
}

const AddSignatories: React.FC<Props> = ({
  signatoriesData,
  onSubmit,
  handleUserType,
  instance,
  externalUsersData,
  isLoading,
  isReplacing = false,
  userType,
  handleCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { watch, reset, resetField, handleSubmit, control } = useFormContext();
  const [showExternalFields, setShowExternalFields] = useState(false);
  const [editExternalUser, setEditExternalUser] = useState<any>(null);

  const first_name = watch('first_name') || '';
  const last_name = watch('last_name') || '';
  const email = watch('email') || '';
  const selectExternalUser = watch('select_external_user') || '';
  const user_type = watch('user_type') || '';

  const handleClear = async () => {
    handleUserType();
    reset();
    await removeAnnotation(
      instance,
      user_type === 'external' ? selectExternalUser : ''
    );
  };

  useEffect(() => {
    if (selectExternalUser) {
      setEditExternalUser(null);
      resetField('first_name');
      resetField('last_name');
      resetField('email');
    }
  }, [selectExternalUser]);

  const { mutate: externalUsersMutation } = useMutation({
    mutationKey: ['external_users', editExternalUser],
    mutationFn: editExternalUser ? editExternalUsers : externalUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External fields saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setEditExternalUser(null);
      setShowExternalFields(false);
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to save external user'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteExternalUserMutation } = useMutation({
    mutationKey: ['delete-external-user'],
    mutationFn: deleteExternalUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External user deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddNewClick = useCallback(() => {
    setEditExternalUser(null);
    resetField('email');
    resetField('first_name');
    resetField('last_name');
    setShowExternalFields((prev) => !prev);
  }, []);

  const handleNewFieldsSave = () => {
    let payload: any = {
      first_name: first_name,
      last_name: last_name,
      username: first_name?.trim(),
      email: email,
      assignee_type: 'external',
    };
    if (editExternalUser) {
      payload = {
        id: editExternalUser.id,
        body: { user_email: email },
      };
    }
    externalUsersMutation(payload);
  };

  const sortedExternalUsers = useMemo(() => {
    return externalUsersData?.sort((a: any, b: any) =>
      a?.first_name.localeCompare(b?.first_name)
    );
  }, [externalUsersData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1}
        sx={{ backgroundColor: '#88305f1f' }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          {`${isReplacing ? 'Replace' : 'Add'} signatories`}
        </Typography>

        {!isReplacing && (
          <RadioButtonGroup
            name="user_type"
            control={control}
            row
            required
            options={userTypes}
            valueKey="value"
            onChange={handleUserType}
          />
        )}

        {(user_type === 'external' || userType === 'external') && (
          <Stack spacing={2}>
            <RISelectComponent
              label="Select External Signatory"
              name="select_external_user"
              control={control}
              options={sortedExternalUsers}
              required
              renderAction={(value: any) => (
                <Stack direction="row">
                  {/* <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowExternalFields(false);
                      setTimeout(() => {
                        setEditExternalUser(value);
                        setValue('name', value.username);
                        setValue('email', value.email);
                        resetField('select_external_user');
                      }, 0);
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton> */}
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setTimeout(() => {
                        resetField('select_external_user');
                      }, 0);
                      const payload = {
                        id: value.id,
                        body: {
                          is_active: false,
                        },
                      };
                      deleteExternalUserMutation(payload);
                      setTimeout(() => {
                        resetField('select_external_user');
                      }, 0);
                    }}
                    color="error"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
              )}
            />
            {!editExternalUser && (
              <Button
                sx={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  gap: '6px',
                }}
                onClick={handleAddNewClick}
              >
                <AddCircleOutlineRoundedIcon fontSize="small" /> Add New
              </Button>
            )}
            {(showExternalFields || editExternalUser) && (
              <Stack spacing={2}>
                {showExternalFields && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <ControlledTextField
                      name="first_name"
                      control={control}
                      label="First Name"
                      fullWidth
                      required
                    />
                    <ControlledTextField
                      name="last_name"
                      control={control}
                      label="Last Name"
                      fullWidth
                    />
                  </Stack>
                )}
                <ControlledTextField
                  name="email"
                  control={control}
                  label="Email Address"
                  fullWidth
                  type="email"
                  required
                />
                <Stack direction="row" justifyContent="end">
                  {editExternalUser && (
                    <Button
                      variant="text"
                      sx={{ width: 'fit-content' }}
                      onClick={() => setEditExternalUser(null)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                    onClick={handleNewFieldsSave}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        )}

        {(user_type === 'internal' || userType === 'internal') && (
          <RISelectComponent
            name="name"
            control={control}
            label="Select Signatory"
            options={sortListAlphabetical(signatoriesData)}
            required
          />
        )}
        <Stack justifyContent="end" direction="row" paddingTop="15px">
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            {`${isReplacing ? 'Update' : 'Add'} signatory`}
          </LoadingButton>
          {isReplacing ? (
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
          ) : (
            <Button onClick={handleClear} variant="outlined">
              Clear
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default AddSignatories;
