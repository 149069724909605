import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

interface IProps extends DialogProps {
  title: string;
  handleClose: VoidFunction;
}
const CustomModal: React.FC<IProps> = ({
  open,
  handleClose,
  title,
  children,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      {...props}
    >
      <DialogTitle
        id="scroll-dialog-title"
        className="modal-header"
        sx={{
          borderRadius: props.fullScreen ? 0 : '4px 4px 0 0',
          paddingY: '4px',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography justifyItems="center">{title}</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
