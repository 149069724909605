import {
  BiPointDataMode,
  DataPoints,
  dateInfo,
  EventData,
  eventInfo,
  InsightsInterface,
  LinkParagraphRequest,
  phraseInfo,
  sentenceInfo,
  tableInfo,
} from '../../State/documentState';

export const highlight_other_clause = '#ffc7ad';
export const highlight_yellow = '#FFEDAD';
export const highlight_lilac = '#D5A9CE';
export const nature_tag_color = '#BA0748';
export const type_tag_color = '#253586';
export const others_tag_color = '#076532';
export const groups_tag_color = '#C6E6F5';
export const tag_tooltip =
  'Mark your documents with relevant tags like ‘Amendment’, ‘Master Agreement’, ‘Security Agreement’, ‘Collaboration Agreement’ etc. You can also add custom tags used within your organization.';
export const tag_nature_tooltip =
  'Add the nature of the file (eg. Amendment, Master Agreement, Invoice etc.)';
export const tag_type_tooltip =
  'Add the type of file (eg. Lease, Loan, Employment Agreement etc.)';
export const tag_others_tooltip =
  'See customs tags used in your organization and add more if you want!';
export const tag_groups_tooltip =
  'Add groups to this file for access permissions.';
export const separateComponent = [
  'Tags',
  'Contracting Parties',
  'Consent Authority',
];
export const editableComponent = [
  'Title',
  'Tags',
  'Contracting Parties',
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Duration',
  'Force Majeure',
  'Subletting/Assignment',
  'Renewal',
  'Renewal clause',
  'Confidentiality',
  'Payment Obligations',
  'eventsOfDefault',
  'notice',
  'non_compete',
  'Exception to Confidentiality',
  'Non-solicitation clause',
  'Insurance clause',
  'Change of Control',
  'Indemnity/Reimbursements/Costs',
  'Confidentiality Duration',
  'Confidentiality Nature',
  'Non Compete Duration',
  'Non Compete Territory',
  'Non Solicitation Duration',
  'Payment Duration',
  'Payment Timeline',
  'Term clause',
  'Termination clause',
  'Governing Law clause',
  'Termination at Convenience',
  'Lock-in Period',
  'Jurisdiction',
  'Events Covered',
  'Amount',
  'Limitation Of Liability',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Termination Event',
  'Events Of Default Event',
  'Dispute Resolution',
  'Dispute Resolution Arbitration',
  'Dispute Resolution Conciliation',
  'Dispute Resolution Mediation',
  'Dispute Resolution Negotiation',
  'Dispute Resolution Others',
  'Dispute Resolution Panel',
  'Dispute Resolution Venue',
  'Dispute Resolution Act/Statute',
  'Other Obligations',
  'General Definitions',
  'Consent Clause',
  'Subletting/Assignment Notice Info',
  // "Exception to Confidentiality",
  'Subletting/Assignment Consent Info',
  'Change of Control Definition',
  'Change of Control Termination',
  'Change of Control Consent',
  'Change of Control Notice',
  'Change of Control Payment',
  'Indemnity/Reimbursements/Costs Payer',
  'Indemnity/Reimbursements/Costs Payee',
  'Indemnity/Reimbursements/Costs Triggering Event',
  'Indemnity/Reimbursements/Costs Extent of Cost',
  'Indemnity/Reimbursements/Costs Amount',
  'Consent Regulatory',
  'Consent Authority',
  'Comment',
  'Prohibitions',
  'Exclusivity',
  'Data Breach',
  'Data Breach Regulation',
  'Data Breach Notify',
  // check
  'Effective Dates',
  'Auto-Renewal',
  'Renewal clause',
  'Notice for Auto-Renewal',
  'Renewal Notice Reference Date',
  'Renewal Notice Duration',
  'indemnity',
];

export const editableIndividualBIs = [
  'Consent Regulatory',
  'Consent Authority',
];

export const paraLevelComponents = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Force Majeure',
  'Subletting/Assignment',
  'renewal',
  'Renewal clause',
  'eventsOfDefault',
  'Insurance clause',
  'Indemnity/Reimbursements/Costs',
  'Term clause',
  'Governing Law clause',
  'Termination at Convenience',
  'Lock-in Period',
  'Dispute Resolution',
  'Dispute Resolution Arbitration',
  'Dispute Resolution Conciliation',
  'Dispute Resolution Mediation',
  'Dispute Resolution Negotiation',
  'Dispute Resolution Others',
  'Change of Control Definition',
  'Data Breach',
  'Effective Dates',
  'Auto-Renewal',
  'Renewal Notice Reference Date',
  // updated parent clause name
  'insurance',
  'term',
  'force_majeure',
  'events_of_default',
  'dispute_resolution',
  'governing_law',
  'data_breach',
  'renewal',
  'subletting',
  'effective_date',
  'indemnity',
];

export const sentenceLevelComponents = [
  'Termination clause',
  'Confidentiality',
  'Exception to Confidentiality',
  'Non-solicitation clause',
  'Payment Obligations',
  'Change of Control',
  'Limitation Of Liability',
  'Other Obligations',
  'Change of Control Termination',
  'Change of Control Consent',
  'Change of Control Notice',
  'Change of Control Payment',
  // "Auto-Renewal",
  // "Notice for Auto-Renewal",
  'Prohibitions',
  'Exclusivity',
  // "Data Breach",

  // updated parent name
  'non_compete',
  'notice',
  'non_solicitation',
  'confidentiality',
  'change_of_control',
  'exclusivity',
  'termination',
  'payment',
  'limited_liability',
  'obligation_statements',
  'Subletting/Assignment Notice Info',
  'Subletting/Assignment Consent Info',
  'Consent Clause',
  'Consent Authority',
];

export const phraseLevelComponents = [
  'Confidentiality Duration',
  'Confidentiality Nature',
  'Amount',
  'Payment Amounts',
  'Events Covered',
  'Non Compete Duration',
  'Non Compete Territory',
  'Non Solicitation Duration',
  'Jurisdiction',
  'Payment Duration',
  'Payment Timeline',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Dispute Resolution Panel',
  'Dispute Resolution Venue',
  'Dispute Resolution Act/Statute',
  'Indemnity/Reimbursements/Costs Payer',
  'Indemnity/Reimbursements/Costs Payee',
  'Indemnity/Reimbursements/Costs Triggering Event',
  'Indemnity/Reimbursements/Costs Extent of Cost',
  'Indemnity/Reimbursements/Costs Amount',
  'General Definitions',
  'Renewal Notice Reference Date',
  'Renewal Notice Duration',
  'Data Breach Regulation',
  'Data Breach Notify',
  'Effective Dates',
  'Notice for Auto-Renewal',
  'Renewal Notice Reference Date',
  'general_definitions',
  // "Auto-Renewal",
];

export const phraseLevelMapping: any = {
  'Confidentiality Duration': 'Post Contract Duration',
  'Confidentiality Nature': 'Nature',
  Jurisdiction: 'Jurisdiction',
  'Data Breach Regulation': 'Data Breach Regulation',
  'Data Breach Notify': 'Data Breach Notify',
  'Non Compete Duration': 'Post Contract Duration',
  'Non Compete Territory': 'Territory',
  'Non Solicitation Duration': 'Post Contract Duration',
  Amount: 'Amount',
  'Events Covered': 'Events Covered',
  'Payment Duration': 'Duration',
  'Limitation Of Liability Amount': 'Amount',
  Capped: 'Capped',
  Uncapped: 'Uncapped',
  'Indemnity/Reimbursements/Costs Extent of Cost': 'Extent of Costs',
  'Indemnity/Reimbursements/Costs Payer': 'Payer',
  'Indemnity/Reimbursements/Costs Payee': 'Payee',
  'Indemnity/Reimbursements/Costs Triggering Event': 'Triggering Event',
  'Indemnity/Reimbursements/Costs Amount': 'Cap',
  'Dispute Resolution Panel': 'Panel',
  'Dispute Resolution Venue': 'Venue',
  'Dispute Resolution Act/Statute': 'Act/Statute',
  'General Definitions': 'General Definitions',
  'Notice for Auto-Renewal': 'Notice for Auto-Renewal',
  'Renewal Notice Reference Date': ' Renewal Notice Reference Date',
  'Renewal Notice Duration': 'Duration',
};

export const phraseBiMap: any = {
  'Confidentiality Duration': 'duration',
  'Confidentiality Nature': 'nature',
  Jurisdiction: 'jurisdiction',
  'Data Breach Regulation': 'Data Breach Regulation',
  'Data Breach Notify': 'Data Breach Notify',
  'Non Compete Duration': 'duration',
  'Non Compete Territory': 'jurisdiction',
  'Non Solicitation Duration': 'duration',
  'Non Solicitation Territory': 'jurisdiction',
  'Payment Duration': 'duration',
  Amount: 'amount',
  'Events Covered': 'force_majeure_event',
  'Limitation Of Liability Amount': 'amount',
  Capped: 'amount',
  Uncapped: 'amount',
  'Indemnity/Reimbursements/Costs Payer': 'Payer',
  'Indemnity/Reimbursements/Costs Payee': 'Payee',
  'Indemnity/Reimbursements/Costs Amount': 'amount',
  'Renewal Notice Reference Date': 'date',
  'Renewal Notice Duration': 'duration',
  general_definitions: 'general_definitions',
};

export const sentenceBiMap: any = {
  'Change of Control Termination': 'Termination',
  'Change of Control Consent': 'Consent',
  'Change of Control Notice': 'Notice',
  'Change of Control Payment': 'Payment',
};

export const paraBiMap: any = {
  'Dispute Resolution Arbitration': 'Arbitration',
  'Dispute Resolution Conciliation': 'Conciliation',
  'Dispute Resolution Mediation': 'Mediation',
  'Dispute Resolution Negotiation': 'Negotiation',
  'Dispute Resolution Others': 'Others',
  'Change of Control Definition': 'definition',
};

export const dataForMap: any = {
  'Consent Clause': 'clause',
  'Consent Regulatory': 'regulatory',
  'Consent Authority': 'authority',
};

export const unClickableBI = [
  'Title',
  'Tags',
  'Contracting Parties',
  'Force Majeure',
  'Subletting/Assignment',
  'Renewal',
  'Renewal clause',
  'Confidentiality',
  'Payment Obligations',
  'notice',
  'non_compete',
  'Exception to Confidentiality',
  'Non-solicitation clause',
  'Insurance clause',
  'Change of Control',
  'Indemnity/Reimbursements/Costs',
  'eventsOfDefault',
  'Term clause',
  'Termination clause',
  'Governing Law clause',
  'Limitation Of Liability',
  'Dispute Resolution',
  'Change of Control Definition',
  'Change of Control Termination',
  'Change of Control Consent',
  'Change of Control Notice',
  'Change of Control Payment',
  'Indemnity/Reimbursements/Costs Payer',
  'Indemnity/Reimbursements/Costs Payee',
  'Indemnity/Reimbursements/Costs Triggering Event',
  'Indemnity/Reimbursements/Costs Extent of Cost',
  'Other Obligations',
  'Subletting/Assignment',
  'Subletting/Assignment Notice Info',
  // "Exception to Confidentiality",
  'Subletting/Assignment Consent Info',
  'General Definitions',
  'Indemnity/Reimbursements/Costs Amount',
  'Consent Clause',
  'Consent Regulatory',
  'Consent Authority',
  'Auto-Renewal',
  // "Notice for Auto-Renewal",
  'Renewal Notice Reference Date',

  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Duration',
  'Term Duration',
  'Payment Obligations Payee',
  'Amount',
  'Payment Duration',
  'Payment Timeline',
  'Payment Obligations Payment For',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Comment',
  'Prohibitions',
  'Exclusivity',
  'Data Breach',
  'Effective Dates',
  'Renewal Notice Reference Date',
  'insurance',
  'term',
  'force_majeure',
  'events_of_default',
  'dispute_resolution',
  'governing_law',
  'data_breach',
  'renewal',
  'subletting',
  'effective_date',
];

export const hideBIValue = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Duration',
  'Payment Duration',
  'Payment Timeline',
  'Renewal Notice Duration',
  'Non Compete Duration',
  'Non Solicitation Duration',
  'Confidentiality Duration',
  'Amount',
  'Indemnity/Reimbursements/Costs Amount',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Comment',
  'Effective Dates',
  'Renewal Notice Reference Date',

  // "Auto-Renewal",
];

export const omitBIBackground = [
  'Title',
  'Tags',
  'Contracting Parties',
  'Regulatory',
  'Authority',
  'Comment',
];

export const alternateNewBIs = [
  'Change of Control',
  'Change of Control Definition',
  'Change of Control Termination',
  'Change of Control Consent',
  'Change of Control Notice',
  'Change of Control Payment',
  'Indemnity/Reimbursements/Costs',
  'Indemnity/Reimbursements/Costs Payer',
  'Indemnity/Reimbursements/Costs Payee',
  'Indemnity/Reimbursements/Costs Triggering Event',
  'Indemnity/Reimbursements/Costs Extent of Cost',
  'Other Obligations',
  'Subletting/Assignment',
  'Subletting/Assignment Notice Info',
  // "Exception to Confidentiality",
  'Subletting/Assignment Consent Info',
  'Indemnity/Reimbursements/Costs Amount',
  'General Definitions',
  'Consent Clause',
  'Auto-Renewal',
  // "Notice for Auto-Renewal",
  'Renewal Notice Reference Date',
  'Renewal Notice Duration',
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Duration',
  'Amount',
  'Payment Duration',
  'Non Compete Duration',
  'Non Solicitation Duration',
  'Indemnity/Reimbursements/Costs Amount',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Renewal Notice Reference Date',
  'Payment Obligations',
  'Confidentiality Duration',
  'Data Breach Notify',
  'Data Breach Regulation',
  'Effective Dates',
  'Exclusivity',
  'Premise',
  'Prohibitions',
  'In Policy',
  'In Contract',
  'Data Breach',
  'Termination clause',
  'Termination at Convenience',
  'Lock-in Period',
  'Termination Event',
  'Renewal clause',
  'Notice for Auto-Renewal',
  'Renewal Notice Reference Date',
  'Confidentiality',
  'notice',
  'Insurance clause',
  'eventsOfDefault',
  'Limitation Of Liability',
  'Force Majeure',
  'Governing Law clause',
  'Jurisdiction',
  'Dispute Resolution',
  'Dispute Resolution Arbitration',
  'Dispute Resolution Conciliation',
  'Dispute Resolution Mediation',
  'Dispute Resolution Negotiation',
  'Dispute Resolution Others',
  'Dispute Resolution Panel',
  'Dispute Resolution Venue',
  'Dispute Resolution Act/Statute',
  'non_competee',
  'Exception to Confidentiality',
  'Non Compete Territory',
  'Non-solicitation clause',
  'Term clause',
  'Events Covered',
  'Confidentiality Nature',
  'Events Of Default Event',
  // "Auto-Renewal",
];

export const normalizedBIs = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Duration',
  'Amount',
  'Payment Duration',
  'Non Compete Duration',
  'Non Solicitation Duration',
  'Indemnity/Reimbursements/Costs Amount',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  'Renewal Notice Duration',
  'Renewal Notice Reference Date',
  'Confidentiality Duration',
  'Effective Dates',
];

export const normalizedDateBIs = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Renewal Notice Reference Date',
  'Effective Dates',
];
export const normalizedDurationBIs = [
  'Duration',
  'Payment Duration',
  'Payment Timeline',
  'Non Compete Duration',
  'Non Solicitation Duration',
  'Confidentiality Duration',
  'Renewal Notice Duration',
];
export const normalizedAmountBIs = [
  'Amount',
  'Indemnity/Reimbursements/Costs Amount',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  // "Renewal Notice Reference Date",
];

export const editableNormalizedComponents = [
  'Duration',
  'Payment Duration',
  'Payment Timeline',
  'Non Compete Duration',
  'Non Solicitation Duration',
  'Confidentiality Duration',
  'Amount',
  'Indemnity/Reimbursements/Costs Amount',
  'Limitation Of Liability Amount',
  'Capped',
  'Uncapped',
  // "Renewal Notice Duration"
  // "Renewal Notice Reference Date"
];

export const separateUnclickableBI = ['Tags', 'Contracting Parties'];

export const singleParaLinking = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Duration',
  'Lock-in Period',
  'Effective Dates',
  'Renewal Notice Reference Date',
  'Term Duration',
];
export const termDateComponents = [
  'Start Dates',
  'End Dates',
  'Term Start Dates',
  'Term End Dates',
  'Term Duration',
  'Effective Dates',
  'Renewal Notice Reference Date',
];

export const multipleParaLinking = [
  'Force Majeure',
  'Subletting/Assignment',
  'Data Breach',
  'Renewal',
  'Renewal clause',
  'Indemnity/Reimbursements/Costs',
  'Insurance clause',
  'eventsOfDefault',
  'Term clause',
  'Governing Law clause',
  'Termination at Convenience',
  'Dispute Resolution',
  'Dispute Resolution Arbitration',
  'Dispute Resolution Conciliation',
  'Dispute Resolution Mediation',
  'Dispute Resolution Negotiation',
  'Dispute Resolution Others',
  'Change of Control Definition',
  'Auto-Renewal',
  'dispute_resolution',
  'term',
  'renewal',
];

export const singleSentenceLinking = [
  'Subletting/Assignment Notice Info',
  // "Exception to Confidentiality",
  'Subletting/Assignment Consent Info',
  'Consent Clause',
  // "Auto-Renewal",
];

export const dateBiMap: any = {
  'Start Dates': 'date',
  'End Dates': 'date',
  Duration: 'duration',
  'Effective Dates': 'date',
  'Renewal Notice Reference Date': 'date',
};

export const disputeResolutionModeBi = [
  'Arbitration',
  'Conciliation',
  'Mediation',
  'Negotiation',
  'Others',
];
export const eventListComponents = [
  'Termination Event',
  'Events Of Default Event',
];

export const eventBiMap: any = {
  'Termination Event': 'termination',
  'Events Of Default Event': 'eventsofdefault',
};

export function normalizeDates(dateString: string) {
  const splitString = dateString.split('-');
  return (
    splitString[2] + '-' + getMonthName(splitString[1]) + '-' + splitString[0]
  );
}

export function normalizedTermClauseData(datString: any) {
  const returnStr: LinkParagraphRequest[] = [];
  datString.forEach((el: any) => {
    returnStr.push({
      data: el.name,
      mode: el.mode,
    });
  });
  return returnStr;
}

export function normalizeTermClauseDataNew(datString: any) {
  const returnStr: LinkParagraphRequest[] = [];
  datString.forEach((el: any) => {
    let tempParaId: number[] | null = [];
    if (el.dataPoints.paraId === null) {
      tempParaId = null;
    } else {
      tempParaId.push(el.dataPoints.paraId);
    }
    returnStr.push({
      data: el.name,
      mode: el.mode,
      paraId: tempParaId,
    });
  });
  return returnStr;
}

function getMonthName(str: string) {
  switch (str) {
    case '01': {
      return 'JAN';
    }
    case '02': {
      return 'FEB';
    }
    case '03': {
      return 'MAR';
    }
    case '04': {
      return 'APR';
    }
    case '05': {
      return 'MAY';
    }
    case '06': {
      return 'JUN';
    }
    case '07': {
      return 'JUL';
    }
    case '08': {
      return 'AUG';
    }
    case '09': {
      return 'SEP';
    }
    case '10': {
      return 'OCT';
    }
    case '11': {
      return 'NOV';
    }
    case '12': {
      return 'DEC';
    }
    default: {
      return str;
    }
  }
}

export function getBreadcrumbText(linkedPage: string) {
  switch (linkedPage) {
    case 'addfiles': {
      return 'Add Files';
    }
    case 'documentlibrary': {
      return 'Document Library';
    }
    case 'clauselibrary': {
      return 'Clause Library';
    }
    default: {
      const str1 = linkedPage.slice(0, 1);
      const str2 = linkedPage.slice(1, linkedPage?.length);
      return str1.toUpperCase() + str2;
    }
  }
}

export function simulateScroll(tempArray: string[], typeClicked: string) {
  document.documentElement.style.scrollBehavior = 'smooth';
  if (typeClicked === 'clause') {
    for (let i = 0; i < tempArray?.length; i++) {
      const paraHeader = document.getElementById(tempArray[i]);
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
    }
  } else {
    for (let i = 0; i < tempArray?.length; i++) {
      for (let j = 0; j < tempArray[i].length; j++) {
        const wordHeader = document.getElementById(tempArray[i][j]);
        if (wordHeader) {
          wordHeader.style.background = highlight_lilac;
        }
      }
    }
  }
}

export function createChildDataPointsAggregate(
  childValue: AuxArrayInterface[]
) {
  const childAggregate: any[] = [];
  for (let i = 0; i < childValue?.length; i++) {
    childAggregate.push(childValue[i].dataPoints);
  }
  return childAggregate;
}

export function getParentHighlights(clauseData: any[]) {
  const clauseDataArray: string[] = [];
  let auxString = '';
  for (let i = 0; i < clauseData?.length; i++) {
    const newDataPoint = {
      paraId: clauseData[i].para_id,
      startSentenceId: clauseData[i].sentence_id
        ? clauseData[i].sentence_id
        : clauseData[i].start_sentence_id
          ? clauseData[i].start_sentence_id
          : -1,
      endSentenceId: clauseData[i].sentence_id
        ? clauseData[i].sentence_id
        : clauseData[i].end_sentence_id
          ? clauseData[i].end_sentence_id
          : -1,
      startWordId: clauseData[i].start_word_id
        ? clauseData[i].start_word_id
        : -1,
      endWordId: clauseData[i].end_word_id ? clauseData[i].end_word_id : -1,
      rowId: clauseData[i]?.table?.length ? clauseData[i].table[0].row : -1,
      columnId: clauseData[i]?.table?.length
        ? clauseData[i].table[0].coulmn
        : -1,
    };
    if (newDataPoint.paraId && newDataPoint.paraId !== -1) {
      auxString = 'p' + newDataPoint.paraId;
      if (
        newDataPoint.rowId &&
        newDataPoint.rowId !== -1 &&
        newDataPoint.columnId !== -1
      ) {
        //only for paraid,rowid,columnid
        auxString += ';r' + newDataPoint.rowId + ';c' + newDataPoint.columnId;
        clauseDataArray.push(auxString);
        continue;
      }
      clauseDataArray.push(auxString);
    }
    if (newDataPoint.startWordId !== -1 && newDataPoint.startWordId !== null) {
      clauseDataArray.pop(); //Pop last element when next level is present.
      if (newDataPoint.paraId !== -1) {
        //set here again to paraIndex, then add wordIndex
        auxString = 'p' + newDataPoint.paraId;
      }
      if (
        newDataPoint.endWordId !== -1 &&
        newDataPoint.endWordId !== newDataPoint.startWordId
      ) {
        for (
          let k = newDataPoint.startWordId;
          k <= newDataPoint.endWordId;
          k++
        ) {
          const interMediateStr = auxString + ';w' + k;
          clauseDataArray.push(interMediateStr);
        }
      } else {
        auxString += ';w' + newDataPoint.startWordId;
        clauseDataArray.push(auxString);
      }
    }
    if (
      newDataPoint.startSentenceId !== -1 &&
      newDataPoint.startSentenceId !== null &&
      (newDataPoint.startWordId === null || newDataPoint.startWordId < 0)
    ) {
      clauseDataArray.pop(); //Pop last element when next level is present.
      if (
        newDataPoint.endSentenceId !== -1 &&
        newDataPoint.endSentenceId !== newDataPoint.startSentenceId
      ) {
        for (
          let k = newDataPoint.startSentenceId;
          k <= newDataPoint.endSentenceId;
          k++
        ) {
          const interMediateStr = auxString + ';s' + k;
          clauseDataArray.push(interMediateStr);
        }
      } else {
        auxString += ';s' + newDataPoint.startSentenceId;
        clauseDataArray.push(auxString);
      }
    }
  }
  return clauseDataArray;
}

export interface AuxArrayInterface {
  name: string;
  dataPoints: string[];
}

// export interface AuxArrayInterface {
//     name: string;
//     // dataPoints: string[];
//     dataPoints: ChildDataPoints; //For handling cases where a child having multiple instances point to same data points.
// }

export function getChildHighlights(child: any[], label: string) {
  const auxArray: AuxArrayInterface[] = [];
  let auxString = '';
  for (let i = 0; i < child?.length; i++) {
    const newDataPoint = {
      paraId: child[i].para_id,
      startSentenceId: child[i].sentence_id
        ? child[i].sentence_id
        : child[i].start_sentence_id
          ? child[i].start_sentence_id
          : -1,
      startWordId: child[i].start_word_id ? child[i].start_word_id : -1,
      endWordId: child[i].end_word_id ? child[i].end_word_id : -1,
      rowId: child[i].table?.length ? child[i].table[0].row : -1,
      columnId: child[i].table?.length ? child[i].table[0].column : -1,
      endSentenceId: child[i].sentence_id
        ? child[i].sentence_id
        : child[i].end_sentence_id
          ? child[i].end_sentence_id
          : -1,
    };

    const dataPointsArray: string[] = [];
    if (newDataPoint.paraId !== -1 && newDataPoint.paraId !== null) {
      auxString = 'p' + newDataPoint.paraId;
      if (
        newDataPoint.rowId !== undefined &&
        newDataPoint.rowId !== null &&
        newDataPoint.rowId !== -1 &&
        newDataPoint.columnId !== undefined &&
        newDataPoint.columnId !== null &&
        newDataPoint.columnId !== -1
      ) {
        //only for paraid,rowid,columnid
        auxString += ';r' + newDataPoint.rowId + ';c' + newDataPoint.columnId;
        dataPointsArray.push(auxString);
        auxArray.push({
          name: child[i].para,
          dataPoints: dataPointsArray,
        });
        continue;
      }
      dataPointsArray.push(auxString);
    }
    if (newDataPoint.startWordId !== -1 && newDataPoint.startWordId !== null) {
      dataPointsArray.pop(); //Pop last element when next level is present.
      if (newDataPoint.paraId !== -1) {
        //set here again to paraIndex, then add wordIndex
        auxString = 'p' + newDataPoint.paraId;
      }
      if (
        newDataPoint.endWordId !== -1 &&
        newDataPoint.endWordId !== newDataPoint.startWordId
      ) {
        for (
          let k = newDataPoint.startWordId;
          k <= newDataPoint.endWordId;
          k++
        ) {
          const interMediateStr = auxString + ';w' + k;
          dataPointsArray.push(interMediateStr);
        }
      } else {
        auxString += ';w' + newDataPoint.startWordId;
        dataPointsArray.push(auxString);
      }
    }
    if (
      newDataPoint.startSentenceId !== -1 &&
      newDataPoint.startSentenceId !== null &&
      (newDataPoint.startWordId === null || newDataPoint.startWordId < 0)
    ) {
      dataPointsArray.pop(); //Pop last element when next level is present.
      if (
        newDataPoint.endSentenceId !== -1 &&
        newDataPoint.endSentenceId !== null &&
        newDataPoint.endSentenceId !== newDataPoint.startSentenceId
      ) {
        for (
          let k = newDataPoint.startSentenceId;
          k <= newDataPoint.endSentenceId;
          k++
        ) {
          const interMediateStr = auxString + ';s' + k;
          dataPointsArray.push(interMediateStr);
        }
      } else {
        auxString += ';s' + newDataPoint.startSentenceId;
        dataPointsArray.push(auxString);
      }
    }
    auxArray.push({
      name: label,
      dataPoints: dataPointsArray,
    });
  }
  return auxArray;
}

export function detectMultipleInstances(children: AuxArrayInterface[]) {
  const dataPointsArray: string[] = [];
  const tempArray: AuxArrayInterface[] = [];
  for (let i = 0; i < children?.length; i++) {
    if (tempArray?.length === 0) {
      //Initial
      tempArray.push({
        name: children[i].name,
        dataPoints: children[i].dataPoints,
      });
    } else {
      const index = tempArray.findIndex((el) => {
        return el.name === children[i].name;
      });
      if (index > -1) {
        dataPointsArray.push(children[i].dataPoints[0]);
        tempArray[index].dataPoints = dataPointsArray;
      } else {
        tempArray.push({
          name: children[i].name,
          dataPoints: children[i].dataPoints,
        });
      }
    }
  }
  return tempArray;
}

export function getDatapointScrollString(dataPoints: DataPoints) {
  let auxString = '';
  if (dataPoints.paraId && dataPoints.paraId !== -1) {
    auxString = 'p' + dataPoints.paraId;
    if (
      dataPoints.rowId !== undefined &&
      dataPoints.rowId !== null &&
      dataPoints.rowId !== -1 &&
      dataPoints.columnId !== undefined &&
      dataPoints.columnId !== null &&
      dataPoints.columnId !== -1
    ) {
      //only for paraid,rowid,columnid
      auxString += ';r' + dataPoints.rowId + ';c' + dataPoints.columnId;
      return auxString;
    }
  }
  if (
    dataPoints.startSentenceId !== -1 &&
    dataPoints.startSentenceId !== null &&
    (dataPoints.startWordId === null || dataPoints.startWordId < 0)
  ) {
    auxString += ';s' + dataPoints.startSentenceId;
    return auxString;
  }
  if (dataPoints.startWordId !== -1 && dataPoints.startWordId !== null) {
    auxString += ';w' + dataPoints.startWordId;
    return auxString;
  }

  return auxString;
}

export function resetAllBiPointDataModes(insights: InsightsInterface[]) {
  const biPointDataType: BiPointDataMode[] = [];

  for (let i = 0; i < insights?.length; i++) {
    for (let j = 0; j < insights[i].children?.length; j++) {
      biPointDataType.push({
        biDataPointName: insights[i].children[j].childLabel,
        isDataOriginal: false,
      });
    }
  }
  return biPointDataType;
}

export function getParasFromChild(savedChildInsight: any) {
  const tempArr: number[] = [];
  if (savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      const newDataPoint = {
        paraId: savedChildInsight[i].para_id,
        rowId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].row
            : -1
          : -1,
        columnId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].column
            : -1
          : -1,
      };
      if (newDataPoint.paraId > -1) {
        if (
          (newDataPoint.rowId || newDataPoint.rowId === -1) &&
          (newDataPoint.columnId || newDataPoint.columnId === -1)
        ) {
          tempArr.push(newDataPoint.paraId);
        }
      } else {
        return [];
      }
    }
  }
  return tempArr;
}

export function getSingleText(savedChildInsight: any) {
  let tempText = '';
  if (savedChildInsight?.length > 0) {
    tempText = savedChildInsight[0].name;
  }
  return tempText;
}

export function getSingleParaIdFromChild(savedChildInsight: any) {
  let tempParaId = -1;
  if (savedChildInsight?.length > 0) {
    if (
      savedChildInsight[0].dataPoints.paraId !== undefined &&
      savedChildInsight[0].dataPoints.paraId !== null &&
      savedChildInsight[0].dataPoints.paraId !== -1
    ) {
      if (
        (savedChildInsight[0].dataPoints.rowId === null ||
          savedChildInsight[0].dataPoints.rowId === -1) &&
        (savedChildInsight[0].dataPoints.columnId === null ||
          savedChildInsight[0].dataPoints.columnId === -1)
      ) {
        tempParaId = savedChildInsight[0].dataPoints.paraId;
      }
    }
  }
  return tempParaId;
}

export function getSingleTableCellFromChild(savedChildInsight: any) {
  let tempSingleTableCell: tableInfo | null = null;
  if (savedChildInsight?.length > 0) {
    if (
      savedChildInsight[0].dataPoints.paraId !== undefined &&
      savedChildInsight[0].dataPoints.paraId !== null &&
      savedChildInsight[0].dataPoints.paraId !== -1
    ) {
      if (
        savedChildInsight[0].dataPoints.rowId !== undefined &&
        savedChildInsight[0].dataPoints.rowId !== null &&
        savedChildInsight[0].dataPoints.rowId !== -1 &&
        savedChildInsight[0].dataPoints.columnId !== undefined &&
        savedChildInsight[0].dataPoints.columnId !== null &&
        savedChildInsight[0].dataPoints.columnId !== -1
      ) {
        tempSingleTableCell = {
          paraId: savedChildInsight[0].dataPoints.paraId,
          rowId: savedChildInsight[0].dataPoints.rowId,
          columnId: savedChildInsight[0].dataPoints.columnId,
        };
      }
    }
  }
  return tempSingleTableCell;
}

export function getSentencesFromChild(savedChildInsight: any) {
  const tempArr: sentenceInfo[] = [];
  if (savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      const newDataPoint = {
        paraId: savedChildInsight[i].para_id,
        startSentenceId:
          savedChildInsight[i].sentence_id > -1
            ? savedChildInsight[i].sentence_id
            : savedChildInsight[i].start_sentence_id > -1
              ? savedChildInsight[i].start_sentence_id
              : -1,
        rowId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].row
              ? savedChildInsight[i].table[0].row
              : -1
            : -1
          : -1,
        columnId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].column
              ? savedChildInsight[i].table[0].column
              : -1
            : -1
          : -1,
      };
      if (newDataPoint.paraId !== undefined) {
        if (
          newDataPoint.startSentenceId !== undefined &&
          newDataPoint.startSentenceId !== null &&
          newDataPoint.startSentenceId !== -1
        ) {
          const paraId =
            newDataPoint.paraId === null ? null : Number(newDataPoint.paraId);
          const sentenceId = newDataPoint.startSentenceId;
          const rowId = newDataPoint.rowId;
          const columnId = newDataPoint.columnId;

          tempArr.push({
            paraId: paraId,
            sentenceId: sentenceId,
            rowId: rowId,
            columnId: columnId,
          });
        }
      }
    }
  }
  return tempArr;
}

export function hasSentence(
  sentencesInfo: sentenceInfo[] | null,
  paraIndex: number,
  sentenceIndex: number
): boolean {
  if (sentencesInfo === null || sentencesInfo?.length === 0) {
    return false;
  } else {
    for (let i = 0; i < sentencesInfo?.length; i++) {
      if (
        sentencesInfo[i].paraId === paraIndex &&
        sentencesInfo[i].sentenceId === sentenceIndex
      ) {
        return true;
      }
    }
  }
  return false;
}

export function deleteSentence(
  sentencesInfo: sentenceInfo[] | null,
  paraIndex: number,
  sentenceIndex: number
): sentenceInfo[] | null {
  let tempSentences: sentenceInfo[] | null = [];
  if (sentencesInfo === null || sentencesInfo?.length === 0) {
    tempSentences = sentencesInfo;
  } else {
    for (let i = 0; i < sentencesInfo?.length; i++) {
      if (
        sentencesInfo[i].paraId === paraIndex &&
        sentencesInfo[i].sentenceId === sentenceIndex
      ) {
        continue;
      }
      tempSentences.push(sentencesInfo[i]);
    }
  }
  return tempSentences;
}

export function hasWord(
  phrasesInfo: phraseInfo[] | null,
  paraIndex: number,
  wordIndex: number
): boolean {
  let hasWord = false;
  if (phrasesInfo === null || phrasesInfo?.length === 0) {
    hasWord = false;
  } else if (phrasesInfo !== null) {
    for (let i = 0; i < phrasesInfo?.length; i++) {
      if (phrasesInfo[i].paraId === paraIndex && phrasesInfo[i].paraId !== -1) {
        if (
          phrasesInfo[i].startWordId !== -1 &&
          phrasesInfo[i].endWordId !== -1
        ) {
          if (
            phrasesInfo[i].startWordId === wordIndex ||
            phrasesInfo[i].endWordId === wordIndex
          ) {
            hasWord = true;
            break;
          } else if (
            phrasesInfo[i].startWordId < wordIndex &&
            phrasesInfo[i].endWordId > wordIndex
          ) {
            hasWord = true;
            break;
          }
        }
      }
    }
  }
  return hasWord;
}

export function isWordInPhrase(
  paraIndex: number,
  wordIndex: number,
  phraseInfo: phraseInfo | null
): boolean {
  let wordInPhrase = false;
  if (phraseInfo === null) {
    wordInPhrase = false;
  } else {
    if (phraseInfo !== null) {
      if (phraseInfo.paraId === paraIndex) {
        if (
          phraseInfo.startWordId === wordIndex ||
          phraseInfo.endWordId === wordIndex
        ) {
          wordInPhrase = true;
        } else if (
          phraseInfo.startWordId < wordIndex &&
          phraseInfo.endWordId > wordIndex
        ) {
          wordInPhrase = true;
        }
      }
    }
  }
  return wordInPhrase;
}

export function isStartWordInPhrase(
  paraIndex: number,
  wordIndex: number,
  phraseInfo: phraseInfo | null
): boolean {
  let isEndWordInPhrase = false;
  if (phraseInfo === null) {
    isEndWordInPhrase = false;
  } else {
    if (phraseInfo !== null) {
      if (phraseInfo.paraId === paraIndex) {
        if (phraseInfo.startWordId === wordIndex) {
          isEndWordInPhrase = true;
        }
      }
    }
  }
  return isEndWordInPhrase;
}

export function isEndWordInPhrase(
  paraIndex: number,
  wordIndex: number,
  phraseInfo: phraseInfo | null
): boolean {
  let isEndWordInPhrase = false;
  if (phraseInfo === null) {
    isEndWordInPhrase = false;
  } else {
    if (phraseInfo !== null) {
      if (phraseInfo.paraId === paraIndex) {
        if (phraseInfo.endWordId === wordIndex) {
          isEndWordInPhrase = true;
        }
      }
    }
  }
  return isEndWordInPhrase;
}

export function deletePhrase(
  phrasesInfo: phraseInfo[] | null,
  paraIndex: number,
  wordIndex: number
) {
  let tempPhrases: phraseInfo[] | null = [];
  if (phrasesInfo === null || phrasesInfo?.length === 0) {
    tempPhrases = phrasesInfo;
  } else if (phrasesInfo !== null) {
    for (let i = 0; i < phrasesInfo?.length; i++) {
      if (phrasesInfo[i].paraId === paraIndex && phrasesInfo[i].paraId !== -1) {
        if (
          phrasesInfo[i].startWordId !== -1 &&
          phrasesInfo[i].endWordId !== -1
        ) {
          if (
            phrasesInfo[i].startWordId === wordIndex ||
            phrasesInfo[i].endWordId === wordIndex
          ) {
            continue;
          } else if (
            phrasesInfo[i].startWordId < wordIndex &&
            phrasesInfo[i].endWordId > wordIndex
          ) {
            continue;
          } else {
            tempPhrases.push(phrasesInfo[i]);
            continue;
          }
        }
      } else if (
        phrasesInfo[i].paraId !== paraIndex &&
        phrasesInfo[i].paraId !== -1
      ) {
        tempPhrases.push(phrasesInfo[i]);
      }
    }
  }
  return tempPhrases;
}

export function deletePhraseFromPhraseArray(
  phraseInfo: phraseInfo,
  phrases: phraseInfo[] | null
) {
  let tempPhrases: phraseInfo[] = [];
  if (phrases === null || phrases?.length === 0) {
    tempPhrases = [];
  } else {
    for (let i = 0; i < phrases.length; i++) {
      if (phrases[i].paraId === phraseInfo.paraId) {
        if (
          phrases[i].rowId !== null &&
          phrases[i].rowId !== -1 &&
          phrases[i].rowId === phraseInfo.rowId &&
          phrases[i].columnId !== null &&
          phrases[i].columnId !== -1 &&
          phrases[i].columnId === phraseInfo.columnId
        ) {
          continue;
        } else if (
          (phrases[i].rowId === null || phrases[i].rowId === -1) &&
          (phrases[i].columnId === null || phrases[i].columnId === -1) &&
          phrases[i].startWordId === phraseInfo.startWordId &&
          phrases[i].endWordId === phraseInfo.endWordId
        ) {
          continue;
        }
      }
      tempPhrases.push(phrases[i]);
    }
  }
  return tempPhrases;
}

export function getPhrasesFromChild(savedChildInsight: any, type: string) {
  const tempArr: phraseInfo[] = [];
  if (savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      let para = '';
      switch (type) {
        case 'Events Covered': {
          para = savedChildInsight[i].events;
          break;
        }
        case 'Jurisdiction': {
          para = savedChildInsight[i].jurisdiction;
          break;
        }
        case 'Non Compete Territory':
        case 'Confidentiality Nature':
        case 'Notice for Auto-Renewal': {
          para = savedChildInsight[i].phrase;
          break;
        }
        case 'Dispute Resolution Panel':
        case 'Dispute Resolution Venue':
        case 'Dispute Resolution Act/Statute': {
          para = savedChildInsight[i].capture;
          break;
        }
        default: {
          para = savedChildInsight[i].string;
          break;
        }
      }
      if (savedChildInsight[i].para_id > -1) {
        if (savedChildInsight[i]?.capture || savedChildInsight[i]?.entity) {
          para = savedChildInsight[i]?.capture || savedChildInsight[i]?.entity;
        }
        if (
          savedChildInsight[i]?.table &&
          savedChildInsight[i]?.table[0] &&
          savedChildInsight[i]?.table[0]?.rowId !== -1 &&
          savedChildInsight[i]?.table[0]?.columnId !== -1
        ) {
          tempArr.push({
            paraId: savedChildInsight[i].para_id,
            startWordId: savedChildInsight[i].start_word_id,
            endWordId: savedChildInsight[i].end_word_id,
            startSentenceId: savedChildInsight[i].start_sentence_id,
            endSentenceId: savedChildInsight[i].end_sentence_id,
            rowId: savedChildInsight[i].table[0]?.rowId || -1,
            columnId: savedChildInsight[i].table[0]?.columnId || -1,
            phrase: para,
          });
          continue;
        } else if (
          (savedChildInsight[i]?.startWordId !== undefined &&
            savedChildInsight[i]?.startWordId !== null &&
            savedChildInsight[i]?.startWordId !== -1 &&
            savedChildInsight[i]?.endWordId !== null &&
            savedChildInsight[i]?.endWordId !== -1) ||
          (savedChildInsight[i]?.start_word_id !== undefined &&
            savedChildInsight[i]?.start_word_id !== null &&
            savedChildInsight[i]?.start_word_id !== -1 &&
            savedChildInsight[i]?.end_word_id !== null &&
            savedChildInsight[i]?.end_word_id !== -1)
        ) {
          const paraId = Number(savedChildInsight[i].para_id);
          const startWordId = Number(savedChildInsight[i].start_word_id);
          const endWordId = Number(savedChildInsight[i].end_word_id);
          const startSentenceId =
            savedChildInsight[i].sentence_id > -1
              ? savedChildInsight[i].sentence_id
              : savedChildInsight[i].start_sentence_id > -1
                ? savedChildInsight[i].start_sentence_id
                : -1;
          const endSentenceId =
            savedChildInsight[i].sentence_id > -1
              ? savedChildInsight[i].sentence_id
              : savedChildInsight[i].start_sentence_id > -1
                ? savedChildInsight[i].start_sentence_id
                : -1;
          const rowId =
            savedChildInsight[i]?.row_id > -1
              ? savedChildInsight[i].row_id
              : -1;
          const columnId =
            savedChildInsight[i]?.column_id > -1
              ? savedChildInsight[i].column_id
              : -1;
          const phrase: string = savedChildInsight[i].events || para;
          const id: string = savedChildInsight[i]?.id;

          tempArr.push({
            paraId: paraId,
            startWordId: startWordId,
            endWordId: endWordId,
            startSentenceId: startSentenceId,
            endSentenceId: endSentenceId,
            rowId: rowId > 0 ? rowId : -1,
            columnId: columnId > 0 ? columnId : -1,
            phrase: phrase,
            id: id,
          });
        }
      }
    }
  }

  return tempArr;
}

export function hasTableCell(
  paraIndex: number,
  rowIndex: number,
  columnIndex: number,
  savedHighlightedTableCells: tableInfo[] | null
) {
  let hasTableCell = false;
  if (
    savedHighlightedTableCells === null ||
    savedHighlightedTableCells?.length === 0
  ) {
    hasTableCell = false;
  } else {
    for (let i = 0; i < savedHighlightedTableCells?.length; i++) {
      if (
        savedHighlightedTableCells[i].paraId === paraIndex &&
        savedHighlightedTableCells[i].rowId === rowIndex &&
        savedHighlightedTableCells[i].columnId === columnIndex
      ) {
        hasTableCell = true;
        break;
      }
    }
  }
  return hasTableCell;
}

export function getTableCellsFromChild(savedChildInsight: any) {
  const tempTableCellsArr: tableInfo[] = [];
  if (savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      const newDataPoint = {
        paraId: savedChildInsight[i].para_id,
        rowId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].row
            : -1
          : -1,
        columnId: savedChildInsight[i].table
          ? savedChildInsight[i].table.length
            ? savedChildInsight[i].table[0].column
            : -1
          : -1,
      };
      if (newDataPoint.paraId > -1) {
        if (newDataPoint.rowId && newDataPoint.columnId) {
          tempTableCellsArr.push({
            paraId: newDataPoint.paraId,
            rowId: newDataPoint.rowId,
            columnId: newDataPoint.columnId,
          });
        }
      }
    }
  }
  return tempTableCellsArr;
}

export function deleteTableCell(
  savedHighlightedTableCells: tableInfo[],
  paraIndex: number,
  rowIndex: number,
  columnIndex: number
) {
  const filteredTableCells: tableInfo[] = [];
  if (savedHighlightedTableCells?.length > 0) {
    for (let i = 0; i < savedHighlightedTableCells.length; i++) {
      if (
        savedHighlightedTableCells[i].paraId === paraIndex &&
        savedHighlightedTableCells[i].rowId === rowIndex &&
        savedHighlightedTableCells[i].columnId === columnIndex
      ) {
        continue;
      }
      filteredTableCells.push(savedHighlightedTableCells[i]);
    }
  }
  return filteredTableCells;
}

export function deleteTableCellFromPhraseArray(
  paraIndex: number,
  rowIndex: number,
  columnIndex: number,
  savedHighlightedPhrases: phraseInfo[]
) {
  const tempHighlightedPhrases: phraseInfo[] = [];
  if (savedHighlightedPhrases?.length > 0) {
    for (let i = 0; i < savedHighlightedPhrases.length; i++) {
      if (
        savedHighlightedPhrases[i].paraId === paraIndex &&
        savedHighlightedPhrases[i].rowId === rowIndex &&
        savedHighlightedPhrases[i].columnId === columnIndex
      ) {
        continue;
      }
      tempHighlightedPhrases.push(savedHighlightedPhrases[i]);
    }
  }
  return tempHighlightedPhrases;
}

export function isTableCell(phraseInfo: phraseInfo) {
  let isTableCell = false;
  if (
    phraseInfo.paraId !== undefined &&
    phraseInfo.paraId !== null &&
    phraseInfo.paraId !== -1 &&
    phraseInfo.rowId !== undefined &&
    phraseInfo.rowId !== null &&
    phraseInfo.rowId !== -1 &&
    phraseInfo.columnId !== undefined &&
    phraseInfo.columnId !== null &&
    phraseInfo.columnId !== -1
  ) {
    isTableCell = true;
  }
  return isTableCell;
}

export function filterPhrasesFromPhrasesArray(phraseInfo: phraseInfo[]) {
  const tempFilteredPhrases: phraseInfo[] = [];
  if (phraseInfo !== null && phraseInfo?.length > 0) {
    for (let i = 0; i < phraseInfo.length; i++) {
      if (
        phraseInfo[i].paraId !== undefined &&
        phraseInfo[i].paraId !== null &&
        phraseInfo[i].paraId !== -1 &&
        (phraseInfo[i].rowId === -1 || phraseInfo[i].rowId === null) &&
        (phraseInfo[i].columnId === -1 || phraseInfo[i].columnId === null)
      ) {
        if (
          phraseInfo[i].startWordId !== undefined &&
          phraseInfo[i].startWordId !== null &&
          phraseInfo[i].startWordId !== -1 &&
          phraseInfo[i].endWordId !== undefined &&
          phraseInfo[i].endWordId !== null &&
          phraseInfo[i].endWordId !== -1
        ) {
          tempFilteredPhrases.push(phraseInfo[i]);
        }
      }
    }
  }
  return tempFilteredPhrases;
}

export function filterTableCellsFromPhraseArray(phraseInfo: phraseInfo[]) {
  const tempTableCellPhrases: phraseInfo[] = [];
  if (phraseInfo !== null && phraseInfo?.length > 0) {
    for (let i = 0; i < phraseInfo.length; i++) {
      if (
        phraseInfo[i].paraId !== undefined &&
        phraseInfo[i].paraId !== null &&
        phraseInfo[i].paraId !== -1 &&
        phraseInfo[i].rowId !== undefined &&
        phraseInfo[i].rowId !== null &&
        phraseInfo[i].rowId !== -1
      ) {
        tempTableCellPhrases.push(phraseInfo[i]);
      }
    }
  }
  return tempTableCellPhrases;
}

export function getDatesFromChild(savedChildInsight: any) {
  const tempDateArray: dateInfo[] = [];
  if (savedChildInsight !== null && savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      tempDateArray.push({
        dateId: i,
        // phrase: savedChildInsight[i].name,
        // paraId: savedChildInsight[i].dataPoints.paraId,
        // rowId: savedChildInsight[i].dataPoints.rowId,
        // columnId: savedChildInsight[i].dataPoints.columnId,
        // id: savedChildInsight[i].dataPoints.id,
        phrase: savedChildInsight[i].pattern,
        paraId: savedChildInsight[i].para_id,
        rowId: savedChildInsight[i]?.table?.row,
        columnId: savedChildInsight[i]?.table?.column,
      });
    }
  }
  return tempDateArray;
}

export function getTermDurationFromChildArray(savedChildInsight: any) {
  let tempTermDuration: dateInfo = {
    dateId: -1,
    phrase: '',
    paraId: -1,
    rowId: -1,
    columnId: -1,
    duration_value: -1,
    duration_typeid: -1,
  };
  if (savedChildInsight !== null && savedChildInsight?.length > 0) {
    tempTermDuration = {
      dateId: -1,
      // phrase: savedChildInsight[0].name,
      // paraId: savedChildInsight[0].dataPoints.paraId,
      // rowId: savedChildInsight[0].dataPoints.rowId,
      // columnId: savedChildInsight[0].dataPoints.columnId,
      phrase: savedChildInsight[0].name,
      paraId: savedChildInsight[0].para_id,
      rowId: savedChildInsight[0]?.table?.row,
      columnId: savedChildInsight[0]?.table?.column,
      // duration_value: savedChildInsight[0].dataPoints.headerData.durationValue,
      // duration_typeid:
      //   savedChildInsight[0].dataPoints.headerData.durationTypeId,
    };
  }
  return tempTermDuration;
}

export function deleteDateFromDateArray(
  dateInfo: dateInfo,
  dateArray: dateInfo[]
) {
  const tempFilteredDates: dateInfo[] = [];
  if (dateArray?.length > 0) {
    for (let i = 0; i < dateArray.length; i++) {
      if (
        dateArray[i].dateId === dateInfo.dateId &&
        dateArray[i].phrase === dateInfo.phrase &&
        dateArray[i].paraId === dateInfo.paraId
      ) {
        continue;
      }
      tempFilteredDates.push(dateArray[i]);
    }
  }
  return tempFilteredDates;
}

export function getFilteredDatePhrases(dateArray: dateInfo[]) {
  const tempFilteredDatePhrases: dateInfo[] = [];
  for (let i = 0; i < dateArray?.length; i++) {
    if (
      dateArray[i].phrase !== '' &&
      (dateArray[i].paraId === null || dateArray[i].paraId === -1) &&
      (dateArray[i].rowId === null || dateArray[i].rowId === -1) &&
      (dateArray[i].columnId === null || dateArray[i].columnId === -1)
    ) {
      tempFilteredDatePhrases.push(dateArray[i]);
    }
  }
  return tempFilteredDatePhrases;
}

export function getFilteredDateParas(dateArray: dateInfo[]) {
  const tempFilteredDateParas: dateInfo[] = [];
  for (let i = 0; i < dateArray?.length; i++) {
    if (
      dateArray[i].phrase !== '' &&
      dateArray[i].paraId !== null &&
      dateArray[i].paraId !== -1 &&
      (dateArray[i].rowId === null || dateArray[i].rowId === -1) &&
      (dateArray[i].columnId === null || dateArray[i].columnId === -1)
    ) {
      tempFilteredDateParas.push(dateArray[i]);
    }
  }
  return tempFilteredDateParas;
}

export function getFilteredDateTableCells(dateArray: dateInfo[]) {
  const tempFilteredDateTableCells: dateInfo[] = [];
  for (let i = 0; i < dateArray?.length; i++) {
    if (
      dateArray[i].phrase !== '' &&
      dateArray[i].paraId !== null &&
      dateArray[i].paraId !== -1 &&
      dateArray[i].rowId !== null &&
      dateArray[i].rowId !== -1 &&
      dateArray[i].columnId !== null &&
      dateArray[i].columnId !== -1
    ) {
      tempFilteredDateTableCells.push(dateArray[i]);
    }
  }
  return tempFilteredDateTableCells;
}

export function getEventDataFromEventResult(queryResult: any) {
  const testEvents: EventData[] = [];

  if (queryResult && queryResult?.length > 0) {
    for (let i = 0; i < queryResult.length; i++) {
      testEvents.push({
        eventId: queryResult[i].id,
        eventName: queryResult[i].name,
      });
    }
  }

  return testEvents;
}

export function getHighlightedEventsFromChild(savedChildInsights: any) {
  const tempHighlightedEvents: any[] = [];
  if (savedChildInsights !== null && savedChildInsights?.length > 0) {
    for (let i = 0; i < savedChildInsights.length; i++) {
      tempHighlightedEvents.push({
        // eventHighlightId: i,
        // eventId: savedChildInsights[i]?.eventId,
        // eventName: savedChildInsights[i]?.name,
        // paraId: savedChildInsights[i].para_id,
        // sentenceId: savedChildInsights[i]?.dataPoints?.sentenceId,
        // startWordId: savedChildInsights[i]?.dataPoints?.startWordId,
        // endWordId: savedChildInsights[i]?.dataPoints?.endWordId,
        // rowId: savedChildInsights[i]?.dataPoints?.rowId,
        // columnId: savedChildInsights[i]?.dataPoints?.columnId,
        // phrase: "",
        // id: savedChildInsights[i]?.dataPoints?.id,
        eventName: savedChildInsights[i]?.eventName,
        paraId: savedChildInsights[i]?.para_id,
        rowId: savedChildInsights[i]?.table?.row,
        columnId: savedChildInsights[i]?.table?.column,
        phrase: savedChildInsights[i]?.phrase,
      });
    }
  }
  return tempHighlightedEvents;
}

export function deletePhraseFromEventArray(
  eventInfo: eventInfo,
  eventArray: eventInfo[]
) {
  const tempChangedHighlightedEvents: eventInfo[] = [];
  if (eventArray?.length > 0) {
    for (let i = 0; i < eventArray.length; i++) {
      if (
        eventArray[i].eventId === eventInfo.eventId &&
        eventArray[i].eventName === eventInfo.eventName &&
        eventArray[i].paraId === eventInfo.paraId &&
        eventArray[i].startWordId === eventInfo.startWordId &&
        eventArray[i].endWordId === eventInfo.endWordId
      ) {
        const tempHighlightedEvent: eventInfo = eventArray[i];
        tempHighlightedEvent.paraId = -1;
        tempHighlightedEvent.startWordId = -1;
        tempHighlightedEvent.endWordId = -1;
        tempChangedHighlightedEvents.push(tempHighlightedEvent);
      } else {
        tempChangedHighlightedEvents.push(eventArray[i]);
      }
    }
  }
  return tempChangedHighlightedEvents;
}

export function replaceEventInEventArray(
  eventInfo: eventInfo,
  eventArray: eventInfo[]
) {
  const tempChangedHighlightedEvents: eventInfo[] = [];
  if (eventArray?.length > 0) {
    for (let i = 0; i < eventArray.length; i++) {
      if (eventInfo.eventHighlightId === eventArray[i].eventHighlightId) {
        tempChangedHighlightedEvents.push(eventInfo);
      } else {
        tempChangedHighlightedEvents.push(eventArray[i]);
      }
    }
  }
  return tempChangedHighlightedEvents;
}

export function deleteEventFromEventArray(
  eventInfo: eventInfo,
  eventArray: eventInfo[]
) {
  const tempChangedHighlightedEvents: eventInfo[] = [];
  if (eventArray?.length > 0) {
    for (let i = 0; i < eventArray.length; i++) {
      // if (eventInfo.eventHighlightId === eventArray[i].eventHighlightId) {
      //   continue;
      // }
      if (
        eventInfo.eventName === eventArray[i].eventName &&
        eventInfo.phrase === eventArray[i].phrase
      ) {
        continue;
      }
      tempChangedHighlightedEvents.push(eventArray[i]);
    }
  }
  return tempChangedHighlightedEvents;
}

export function getPhraseEventsFromEventArray(eventArray: eventInfo[]) {
  const tempChangedHighlightedEventPhrases: eventInfo[] = [];
  for (let i = 0; i < eventArray?.length; i++) {
    if (
      eventArray[i].paraId !== null &&
      eventArray[i].paraId !== -1 &&
      eventArray[i].startWordId !== null &&
      eventArray[i].startWordId !== -1 &&
      eventArray[i].endWordId !== null &&
      eventArray[i].endWordId !== -1
    ) {
      tempChangedHighlightedEventPhrases.push(eventArray[i]);
    }
  }
  return tempChangedHighlightedEventPhrases;
}

export function getTableCellEventsFromEventArray(eventArray: eventInfo[]) {
  const tempChangedHighlightedEventTableCells: eventInfo[] = [];
  for (let i = 0; i < eventArray?.length; i++) {
    if (
      eventArray[i].paraId !== null &&
      eventArray[i].paraId !== -1 &&
      eventArray[i].rowId !== null &&
      eventArray[i].rowId !== -1 &&
      eventArray[i].columnId !== null &&
      eventArray[i].columnId !== -1
    ) {
      tempChangedHighlightedEventTableCells.push(eventArray[i]);
    }
  }
  return tempChangedHighlightedEventTableCells;
}

export function getAuthoritiesFromChild(savedChildInsight: any) {
  let tempArr: sentenceInfo[] = [];
  if (savedChildInsight?.length > 0) {
    for (let i = 0; i < savedChildInsight.length; i++) {
      if (i === 0 && savedChildInsight[i].name === 'No') {
        tempArr = [];
        break;
      } else {
        if (savedChildInsight[i].para_id !== undefined) {
          if (
            savedChildInsight[i]?.dataPoints?.startSentenceId !== undefined &&
            savedChildInsight[i]?.dataPoints?.startSentenceId !== null &&
            savedChildInsight[i]?.dataPoints?.startSentenceId !== -1 &&
            (savedChildInsight[i]?.dataPoints?.startWordId === null ||
              savedChildInsight[i]?.dataPoints?.startWordId < 0)
          ) {
            const paraId = savedChildInsight[i].para_id;
            const sentenceId = savedChildInsight[i].sentence_id;
            const rowId = savedChildInsight[i]?.dataPoints?.rowId;
            const columnId = savedChildInsight[i]?.dataPoints?.columnId;
            tempArr.push({
              paraId: paraId,
              sentenceId: sentenceId,
              rowId: rowId,
              columnId: columnId,
              startWordId: savedChildInsight[i]?.dataPoints?.startWordId,
              endWordId: savedChildInsight[i]?.dataPoints?.endWordId,
              phrase: savedChildInsight[i].sentence,
              typestring: '',
            });
          }
        }
      }
    }
  }
  return tempArr;
}

export function getDurationPhraseFromChild(savedChildInsight: any) {
  let tempDateArray: phraseInfo = {
    paraId: -1,
    startWordId: -1,
    endWordId: -1,
    startSentenceId: -1,
    endSentenceId: -1,
    rowId: -1,
    columnId: -1,
    phrase: '',
    durationValue: -1,
    durationTypeId: -1,
  };
  if (savedChildInsight !== undefined && savedChildInsight !== null) {
    tempDateArray = {
      paraId: savedChildInsight.dataPoints.paraId,
      startWordId: savedChildInsight.dataPoints.startWordId,
      endWordId: savedChildInsight.dataPoints.endWordId,
      startSentenceId: savedChildInsight.dataPoints.startSentenceId,
      endSentenceId: savedChildInsight.dataPoints.endSentenceId,
      rowId: savedChildInsight.dataPoints.rowId,
      columnId: savedChildInsight.dataPoints.columnId,
      phrase: savedChildInsight.name,
      durationValue: savedChildInsight.dataPoints.headerData.durationValue,
      durationTypeId: savedChildInsight.dataPoints.headerData.durationTypeId,
    };
  }
  return tempDateArray;
}

export function getCurrencyPhraseFromChild(savedChildInsight: any) {
  let tempAmountArray: phraseInfo = {
    paraId: -1,
    startWordId: -1,
    endWordId: -1,
    startSentenceId: -1,
    endSentenceId: -1,
    rowId: -1,
    columnId: -1,
    phrase: '',
    total: -1,
    currency_typeid: -1,
  };
  if (savedChildInsight !== undefined && savedChildInsight !== null) {
    tempAmountArray = {
      paraId: savedChildInsight.dataPoints.paraId,
      startWordId: savedChildInsight.dataPoints.startWordId,
      endWordId: savedChildInsight.dataPoints.endWordId,
      startSentenceId: savedChildInsight.dataPoints.startSentenceId,
      endSentenceId: savedChildInsight.dataPoints.endSentenceId,
      rowId: savedChildInsight.dataPoints.rowId,
      columnId: savedChildInsight.dataPoints.columnId,
      phrase: savedChildInsight.name,
      total: savedChildInsight.dataPoints.headerData.amountValue,
      currency_typeid: savedChildInsight.dataPoints.headerData.amountTypeId,
    };
  }
  return tempAmountArray;
}

export function getTermDurationInfoFromChild(savedChildInsight: any) {
  const tempTermDuration: dateInfo = {
    dateId: -1,
    phrase: savedChildInsight.name,
    paraId: savedChildInsight.dataPoints.paraId,
    rowId: savedChildInsight.dataPoints.rowId,
    columnId: savedChildInsight.dataPoints.columnId,
    duration_value: savedChildInsight.dataPoints.headerData.durationValue,
    duration_typeid: savedChildInsight.dataPoints.headerData.durationTypeId,
  };
  return tempTermDuration;
}

export function getClauseType(str: string) {
  switch (str) {
    case 'Basic Information':
    case 'Title':
    case 'Contracting Parties': {
      return 'basic_information';
    }
    case 'Term clause':
    case 'Start Dates':
    case 'End Dates':
    case 'Duration': {
      return 'term';
    }
    case 'Renewal':
    case 'Auto-Renewal':
    case 'Renewal clause':
    case 'Notice for Auto-Renewal':
    case 'Renewal Notice Reference Date':
    case 'Renewal Notice Duration': {
      return 'renewal';
    }
    case 'Payment Obligations':
    case 'Amount':
    case 'Payment Duration': {
      return 'payment';
    }
    case 'Indemnity/Reimbursements/Costs':
    case 'Indemnity/Reimbursements/Costs Amount':
    case 'Indemnity/Reimbursements/Costs Extent of Cost':
    case 'Indemnity/Reimbursements/Costs Triggering Event':
    case 'Indemnity/Reimbursements/Costs Payer':
    case 'Indemnity/Reimbursements/Costs Payee': {
      return 'indemnity';
    }
    case 'Confidentiality':
    case 'Confidentiality Duration':
    case 'Confidentiality Nature':
    case 'Exception to Confidentiality': {
      return 'confidentiality';
    }
    case 'Change of Control':
    case 'Change of Control Definition':
    case 'Change of Control Termination':
    case 'Change of Control Payment':
    case 'Change of Control Consent':
    case 'Change of Control Notice': {
      return 'change_of_control';
    }
    case 'Termination clause':
    case 'Termination at Convenience':
    case 'Lock-in Period':
    case 'Termination Event': {
      return 'termination';
    }
    case 'Insurance clause': {
      return 'insurance';
    }
    case 'notice': {
      return 'notice';
    }
    case 'Events of Default':
    case 'eventsOfDefault':
    case 'Events Of Default Event': {
      return 'events_of_default';
    }
    case 'Limitation Of Liability':
    case 'Limitation Of Liability Amount': {
      return 'limited_liability';
    }
    case 'Force Majeure':
    case 'Events Covered': {
      return 'force_majeure';
    }
    case 'Governing Law clause':
    case 'Jurisdiction': {
      return 'governing_law';
    }
    case 'Dispute Resolution':
    case 'Dispute Resolution Arbitration':
    case 'Dispute Resolution Conciliation':
    case 'Dispute Resolution Mediation':
    case 'Dispute Resolution Negotiation':
    case 'Dispute Resolution Others':
    case 'Dispute Resolution Panel':
    case 'Dispute Resolution Venue':
    case 'Dispute Resolution Act/Statute': {
      return 'dispute_resolution';
    }
    case 'non_compete':
    case 'Non Compete Duration':
    case 'Non Compete Territory': {
      return 'non_compete';
    }
    case 'Non-solicitation clause':
    case 'Non Solicitation Duration': {
      return 'non_solicitation';
    }
    case 'Subletting/Assignment':
    case 'Subletting/Assignment Notice Info':
    case 'Subletting/Assignment Consent Info': {
      return 'assignment';
    }
    case 'Consent Clause':
    case 'Consent Regulatory':
    case 'Consent Authority': {
      return 'consent';
    }
    case 'General Definitions': {
      return 'general_definitions';
    }
    case 'Data Breach':
    case 'Data Breach Regulation':
    case 'Data Breach Notify': {
      return 'data_breach';
    }
    case 'Effective Dates': {
      return 'effective_date';
    }
    case 'Exclusivity': {
      return 'exclusivity';
    }
    case 'Other Obligations': {
      return 'obligation_statements';
    }
    default: {
      return str;
    }
  }
}

export function getAllInsightsData(
  clauseData: InsightsInterface,
  insightsData: InsightsInterface[]
) {
  let para_dataPoints: any[] = [];
  let para_with_sentence_datapoints: any[] = [];

  const getIndex: number = insightsData.findIndex(
    (obj) => obj.label === clauseData.label
  );
  if (getIndex !== -1) {
    insightsData[getIndex] = clauseData;
  }
  for (let i = 0; i < insightsData?.length; i++) {
    const label = insightsData[i].label;
    if (label === 'Other Obligations') {
      //Do Nothing
    } else {
      if (
        label === 'Term Clause' ||
        label === 'Renewal Clause' ||
        label === 'Indemnity/Reimbursements/Costs' ||
        label === 'Insurance Clause' ||
        label === 'Events Of Default' ||
        label === 'Force Majeure' ||
        label === 'Governing Clause' ||
        label === 'Dispute Resolution' ||
        label === 'Subletting/Assignment' ||
        label === 'Data Breach' ||
        label === 'Effective Dates'
      ) {
        para_dataPoints = [...para_dataPoints, ...insightsData[i].dataPoints];
      } else {
        para_with_sentence_datapoints = [
          ...para_with_sentence_datapoints,
          ...insightsData[i].dataPoints,
        ];
      }
    }
  }

  const other_obligation_index: number = insightsData.findIndex(
    (obj) => obj.label === 'Other Obligations'
  );
  if (other_obligation_index !== -1) {
    const other_obligation_dataPoints: any[] = [];
    const other_obligation_child_dataPoints: any[] = [];
    const other_obligation_data = insightsData[other_obligation_index];

    for (let j = 0; j < other_obligation_data.dataPoints?.length; j++) {
      const paraIndex: any = other_obligation_data.dataPoints[j]['paraId'];
      const sentenceIndex: any =
        other_obligation_data.dataPoints[j]['startSentenceId'];
      const rowId: any = other_obligation_data.dataPoints[j]['rowId'];
      const columnId: any = other_obligation_data.dataPoints[j]['columnId'];

      const check_para_with_sentences = para_with_sentence_datapoints.filter(
        (el: any) => {
          return (
            el.paraId === paraIndex &&
            el.startSentenceId === sentenceIndex &&
            el.rowId === rowId &&
            el.columnId === columnId
          );
        }
      );
      if (check_para_with_sentences?.length === 0) {
        const check_para = para_dataPoints.filter((el: any) => {
          return (
            el.paraId === paraIndex &&
            el.rowId === rowId &&
            el.columnId === columnId
          );
        });
        if (check_para?.length === 0) {
          other_obligation_dataPoints.push(other_obligation_data.dataPoints[j]);
          other_obligation_child_dataPoints.push(
            other_obligation_data.children[0]['childValue'][j]
          );
        }
      }
    }
    const obligation_data: any = other_obligation_data;
    if (other_obligation_data.dataPoints?.length > 0) {
      obligation_data['children'][0]['childValue'] =
        other_obligation_child_dataPoints;
      obligation_data['dataPoints'] = other_obligation_dataPoints;
    }
    insightsData[other_obligation_index] = obligation_data;
  }

  return insightsData;
}
