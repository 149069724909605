export const userTypes = [
  {
    value: 'internal',
    title: 'Internal signatory',
  },
  {
    value: 'external',
    title: 'External signatory (counterparty)',
  },
];

export const captureMethods = [
  {
    id: 1,
    value: 'stylus',
    label: 'Stylus (Riverus)',
  },
  {
    id: 2,
    value: 'NDSL Adhar',
    label: 'NDSL Adhar',
  },
  {
    id: 3,
    value: 'docusign',
    label: 'Docusign',
  },
  {
    id: 4,
    value: 'adobe',
    label: 'Adobe',
  },
];

export const signatureSequences = [
  {
    value: 'all',
    title: 'Any',
  },
  {
    value: 'all_in_sequence',
    title: 'In Sequence',
  },
];
