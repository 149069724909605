import {
  EDITDATAPOINT,
  EditDataPoint,
  EDITDATAPOINT_FAILURE,
  EDITDATAPOINT_SUCCESS,
  EditDataPointFailure,
  EditDataPointSuccess,
  GetContractData,
  GETCONTRACTDATA,
  GETCONTRACTDATA_FAILURE,
  GETCONTRACTDATA_SUCCESS,
  GetContractDataFailure,
  GetContractSuccess,
  GetCurrencyTypes,
  GETCURRENCYTYPES,
  GETCURRENCYTYPES_FAILURE,
  GETCURRENCYTYPES_SUCCESS,
  GetCurrencyTypesFailure,
  GetCurrencyTypesSuccess,
  GETDURATIONTYPES,
  GetDurationTypes,
  GETDURATIONTYPES_FAILURE,
  GETDURATIONTYPES_SUCCESS,
  GetDurationTypesFailure,
  GetDurationTypesSuccess,
  GetInsights,
  GETINSIGHTS,
  GETINSIGHTS_FAILURE,
  GETINSIGHTS_SUCCESS,
  GetInsightsFailure,
  GetInsightsSuccess,
  GETSENTENCES,
  GetSentences,
  GETSENTENCES_FAILURE,
  GETSENTENCES_SUCCESS,
  GetSentencesFailure,
  GetSentencesSuccess,
  SAVEADDPHRASE,
  SaveAddPhrase,
  SaveBiPointDataMode,
  SAVEBIPOINTDATAMODE,
  SAVECLAUSEMODESTATUS,
  SaveClauseModeStatus,
  SaveDateEditingStatus,
  SAVEDATEEDITINGSTATUS,
  SaveDateInAddEditMode,
  SAVEDATEINADDEDITMODE,
  SaveDeletePhrase,
  SAVEDELETEPHRASE,
  SaveEditPhraseOption,
  SAVEEDITPHRASEOPTION,
  SaveEventEditingStatus,
  SAVEEVENTEDITINGSTATUS,
  SaveEventInAddEdit,
  SAVEEVENTINADDEDIT,
  SAVEHIGHLIGHTEDDATAPOINT,
  SaveHighlightedDataPoint,
  SAVEHIGHLIGHTEDDATES,
  SaveHighlightedDates,
  SaveHighlightedEvents,
  SAVEHIGHLIGHTEDEVENTS,
  SaveHighlightedId,
  SAVEHIGHLIGHTEDID,
  SaveHighlightedPhrases,
  SAVEHIGHLIGHTEDPHRASES,
  SaveHighlightedSentences,
  SAVEHIGHLIGHTEDSENTENCES,
  SaveHighlightedTableCells,
  SAVEHIGHLIGHTEDTABLECELLS,
  SaveInsightToDelete,
  SAVEINSIGHTTODELETE,
} from './def';
import {
  BiPointDataMode,
  ContractDetails,
  CurrencyType,
  dateInfo,
  DurationType,
  eventInfo,
  InsightsInterface,
  phraseInfo,
  sentenceInfo,
  SentencesData,
  tableInfo,
} from '../State/documentState';

export default class DocumentActionGenerator {
  public static saveHighlightedId(
    highlightedId: number[] | null
  ): SaveHighlightedId {
    return {
      type: SAVEHIGHLIGHTEDID,
      payload: {
        highlightedId: highlightedId,
      },
    };
  }
  public static saveHighlightedDataPoint(
    dataPointName: string,
    editOptionSelection: boolean
  ): SaveHighlightedDataPoint {
    return {
      type: SAVEHIGHLIGHTEDDATAPOINT,
      payload: {
        dataPointName,
        editOptionSelection,
      },
    };
  }
  public static getSentences(fileId: string): GetSentences {
    return {
      type: GETSENTENCES,
      payload: {
        fileId: fileId,
      },
    };
  }
  public static getSentencesSuccess(
    sentenceData: SentencesData
  ): GetSentencesSuccess {
    return {
      type: GETSENTENCES_SUCCESS,
      payload: {
        sentenceData: sentenceData,
      },
    };
  }
  public static getSentencesFailure(): GetSentencesFailure {
    return {
      type: GETSENTENCES_FAILURE,
    };
  }
  public static getInsights(fileId: string): GetInsights {
    return {
      type: GETINSIGHTS,
      payload: {
        fileId: fileId,
      },
    };
  }
  public static getContractData(fileId: string): GetContractData {
    return {
      type: GETCONTRACTDATA,
      payload: {
        fileId: fileId,
      },
    };
  }

  public static getInsightsSuccess(
    insightsData: InsightsInterface[]
  ): GetInsightsSuccess {
    return {
      type: GETINSIGHTS_SUCCESS,
      payload: {
        insightsData: insightsData,
      },
    };
  }
  public static getContractDataSuccess(
    contractData: ContractDetails
  ): GetContractSuccess {
    return {
      type: GETCONTRACTDATA_SUCCESS,
      payload: {
        contractData: contractData,
      },
    };
  }
  public static getInsightsFailure(): GetInsightsFailure {
    return {
      type: GETINSIGHTS_FAILURE,
    };
  }
  public static getContractDataFailure(): GetContractDataFailure {
    return {
      type: GETCONTRACTDATA_FAILURE,
    };
  }
  public static editDataPoint(
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ): EditDataPoint {
    return {
      type: EDITDATAPOINT,
      payload: {
        fileId: fileId,
        dataType: dataType,
        dataPointName: dataPointName,
        highlightedId: highlightedId,
      },
    };
  }
  public static editDataPointSuccess(): EditDataPointSuccess {
    return {
      type: EDITDATAPOINT_SUCCESS,
    };
  }
  public static saveBiPointDataMode(
    storedBiPointDataModes: BiPointDataMode[]
  ): SaveBiPointDataMode {
    return {
      type: SAVEBIPOINTDATAMODE,
      payload: {
        storedBiPointDataModes: storedBiPointDataModes,
      },
    };
  }
  public static saveHighlightedSentences(
    savedHighlightedSentences: sentenceInfo[] | null
  ): SaveHighlightedSentences {
    return {
      type: SAVEHIGHLIGHTEDSENTENCES,
      payload: {
        savedHighlightedSentences: savedHighlightedSentences,
      },
    };
  }
  public static saveHighlightedPhrases(
    savedHighlightedPhrases: phraseInfo[] | null
  ): SaveHighlightedPhrases {
    return {
      type: SAVEHIGHLIGHTEDPHRASES,
      payload: {
        savedHighlightedPhrases: savedHighlightedPhrases,
      },
    };
  }
  public static saveEditPhraseOption(
    phraseEditOptionSelected: boolean
  ): SaveEditPhraseOption {
    return {
      type: SAVEEDITPHRASEOPTION,
      payload: {
        phraseEditOptionSelected: phraseEditOptionSelected,
      },
    };
  }
  public static saveDeletePhrase(
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ): SaveDeletePhrase {
    return {
      type: SAVEDELETEPHRASE,
      payload: {
        phraseInDeleteMode: phraseInDeleteMode,
        phraseDeleteStatus: phraseDeleteStatus,
      },
    };
  }
  public static saveAddPhrase(
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ): SaveAddPhrase {
    return {
      type: SAVEADDPHRASE,
      payload: {
        phraseInAddMode: phraseInAddMode,
        phraseAddStatus: phraseAddStatus,
      },
    };
  }
  public static saveHighlightedTableCells(
    savedHighlightedTableCells: tableInfo[] | null
  ): SaveHighlightedTableCells {
    return {
      type: SAVEHIGHLIGHTEDTABLECELLS,
      payload: {
        savedHighlightedTableCells: savedHighlightedTableCells,
      },
    };
  }
  public static saveHighlightedDates(
    savedHighlightedDates: dateInfo[] | null
  ): SaveHighlightedDates {
    return {
      type: SAVEHIGHLIGHTEDDATES,
      payload: {
        savedHighlightedDates: savedHighlightedDates,
      },
    };
  }
  public static saveDateInAddEditMode(
    dateInAddEditMode: dateInfo | null
  ): SaveDateInAddEditMode {
    return {
      type: SAVEDATEINADDEDITMODE,
      payload: {
        dateInAddEditMode: dateInAddEditMode,
      },
    };
  }
  public static saveDateEditingStatus(
    dateEditingStatus: boolean
  ): SaveDateEditingStatus {
    return {
      type: SAVEDATEEDITINGSTATUS,
      payload: {
        dateEditingStatus: dateEditingStatus,
      },
    };
  }
  public static saveHighlightedEvents(
    savedHighlightedEvents: eventInfo[] | null
  ): SaveHighlightedEvents {
    return {
      type: SAVEHIGHLIGHTEDEVENTS,
      payload: {
        savedHighlightedEvents: savedHighlightedEvents,
      },
    };
  }
  public static saveEventInAddEdit(
    eventInAddEdit: eventInfo | null
  ): SaveEventInAddEdit {
    return {
      type: SAVEEVENTINADDEDIT,
      payload: {
        eventInAddEdit: eventInAddEdit,
      },
    };
  }
  public static saveEventEditingStatus(
    eventEditingStatus: boolean
  ): SaveEventEditingStatus {
    return {
      type: SAVEEVENTEDITINGSTATUS,
      payload: {
        eventEditingStatus: eventEditingStatus,
      },
    };
  }
  public static saveClauseModeStatus(
    clauseModeStatus: boolean
  ): SaveClauseModeStatus {
    return {
      type: SAVECLAUSEMODESTATUS,
      payload: {
        clauseModeStatus: clauseModeStatus,
      },
    };
  }
  public static saveInsightToDelete(
    insightToDelete: any,
    childLabelToDelete: string
  ): SaveInsightToDelete {
    return {
      type: SAVEINSIGHTTODELETE,
      payload: {
        insightToDelete: insightToDelete,
        childLabelToDelete: childLabelToDelete,
      },
    };
  }
  public static getDurationTypes(): GetDurationTypes {
    return {
      type: GETDURATIONTYPES,
    };
  }
  public static getDurationTypesSuccess(
    durationList: DurationType[]
  ): GetDurationTypesSuccess {
    return {
      type: GETDURATIONTYPES_SUCCESS,
      payload: {
        durationList: durationList,
      },
    };
  }
  public static getDurationTypesFailure(): GetDurationTypesFailure {
    return {
      type: GETDURATIONTYPES_FAILURE,
    };
  }
  public static getCurrencyTypes(): GetCurrencyTypes {
    return {
      type: GETCURRENCYTYPES,
    };
  }
  public static getCurrencyTypesSuccess(
    currencyList: CurrencyType[]
  ): GetCurrencyTypesSuccess {
    return {
      type: GETCURRENCYTYPES_SUCCESS,
      payload: {
        currencyList: currencyList,
      },
    };
  }
  public static getCurrencyTypesFailure(): GetCurrencyTypesFailure {
    return {
      type: GETCURRENCYTYPES_FAILURE,
    };
  }
  public static editDataPointFailure(): EditDataPointFailure {
    return {
      type: EDITDATAPOINT_FAILURE,
    };
  }
}
