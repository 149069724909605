import React from 'react';

import { Chip, Stack, Typography } from '@mui/material';

import OverflowTip from '../Components/OverflowTip';
import PopoverPopupState from '../Components/Popups/PopoverPopupState';

interface IProps {
  list: any[];
  chipColor?: string;
  labelKey?: string;
  valueKey?: string;
  onClick: (value: any) => void;
  id: string;
  title?: string;
}

const TableChipList = ({
  list,
  chipColor,
  labelKey = 'name',
  valueKey = 'name',
  onClick,
  id,
  title,
}: IProps) => {
  const chipLabel = labelKey ? list?.[0]?.[labelKey] : list[0];
  const chipValue = valueKey ? list?.[0]?.[valueKey] : list[0];

  return (
    <Stack direction="row" spacing={0.25} sx={{ alignItems: 'center' }}>
      {chipLabel ? (
        <Chip
          key={chipLabel}
          sx={{
            background: chipColor || '#C4DBFF',
            color: 'riSecondary.900',
          }}
          label={<OverflowTip value={chipLabel} />}
          onClick={() => onClick([chipValue])}
        />
      ) : (
        '_'
      )}
      {list.length > 1 && (
        <PopoverPopupState
          id={id}
          renderIcon={() => (
            <Typography
              key="2"
              sx={{ color: 'riNeutralVariant.700', fontSize: '11px' }}
            >
              {list.length - 1}+
            </Typography>
          )}
        >
          <Stack spacing={2}>
            {title && <Typography fontSize="15px">{title}</Typography>}
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              maxWidth="200px"
              gap="10px"
            >
              {list?.map((item: any, index: number) => {
                const chipLabel = labelKey ? item[labelKey] : item;
                const chipValue = valueKey ? item[valueKey] : item;

                return (
                  index !== 0 && (
                    <Chip
                      key={index}
                      sx={{
                        background: chipColor || '#C4DBFF',
                        color: 'riSecondary.900',
                      }}
                      label={<OverflowTip value={chipLabel} />}
                      onClick={() => onClick([chipValue])}
                    />
                  )
                );
              })}
            </Stack>
          </Stack>
        </PopoverPopupState>
      )}
    </Stack>
  );
};

export default TableChipList;
