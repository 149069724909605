import React from 'react';

import { FormGroup, List, ListItem } from '@mui/material';

interface Props {
  list: string[];
  renderAction?: (id: string) => React.ReactNode;
}

const ApprovalChecklist: React.FC<Props> = ({ list, renderAction }) => {
  return (
    <FormGroup>
      {list?.map((item: any, index: number) => (
        <List
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ListItem secondaryAction={renderAction?.(item)}>{item}</ListItem>
        </List>
      ))}
    </FormGroup>
  );
};

export default ApprovalChecklist;
