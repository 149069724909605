import React, { useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { deleteSingleColumn } from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function DisputeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    hasData,
    updatedClauseData,
    postClauseDataByType,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddArbitration, setShowAddArbitration] = useState(false);
  const [showAddConciliation, setShowAddConciliation] = useState(false);
  const [showAddMediation, setShowAddMediation] = useState(false);
  const [showAddNegotiation, setShowAddNegotiation] = useState(false);
  const [showAddOthers, setShowAddOthers] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [showAddAct, setShowAddAct] = useState(false);
  const [showAddVenue, setShowAddVenue] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'dispute_resolution');
  }, [fileId, hasData]);

  const data = useMemo(
    () => clauseData?.disputeResolution || null,
    [clauseData?.disputeResolution]
  );

  const updates = useMemo(
    () => updatedClauseData?.disputeResolution || {},
    [updatedClauseData]
  );

  const disputeResolution = useMemo(() => {
    if (updates?.dispute_resolution) {
      const sortData = updates.dispute_resolution.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const arbitration = useMemo(() => {
    if (disputeResolution) {
      const data = disputeResolution.filter((filterData: any) => {
        const found = filterData.modes?.some(
          (item: any) => item.key === 'Arbitration'
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const conciliation = useMemo(() => {
    if (disputeResolution) {
      const data = disputeResolution.filter((filterData: any) => {
        const found = filterData.modes?.some(
          (item: any) => item.key === 'Conciliation'
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const mediation = useMemo(() => {
    if (disputeResolution) {
      const data = disputeResolution.filter((filterData: any) => {
        const found = filterData.modes?.some(
          (item: any) => item.key === 'Mediation'
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const negotiation = useMemo(() => {
    if (disputeResolution) {
      const data = disputeResolution.filter((filterData: any) => {
        const found = filterData.modes?.some(
          (item: any) => item.key === 'Negotiation'
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const others = useMemo(() => {
    if (disputeResolution) {
      const data = disputeResolution.filter((filterData: any) => {
        const found = filterData.modes?.some(
          (item: any) => item.key === 'Others'
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  // it's an Act/Statue in UI
  const legalEntities = useMemo(() => {
    if (updates?.legal_entities) {
      const sortData = updates.legal_entities.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const venue = useMemo(() => {
    if (updates?.venue) {
      const sortData = updates.venue.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const panel = useMemo(() => {
    if (updates?.panel) {
      const sortData = updates.panel.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let dispute_resolution: any;
      let venue_data: any;
      let panel_data: any;
      let legal_entities: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        dispute_resolution = deleteSingleColumn(disputeResolution, tableData);
        venue_data = deleteSingleColumn(venue, tableData);
        panel_data = deleteSingleColumn(panel, tableData);
        legal_entities = deleteSingleColumn(legalEntities, tableData);
      } else {
        dispute_resolution = disputeResolution?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        venue_data = venue?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        panel_data = panel?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        legal_entities = legalEntities?.filter(
          (data: any) => data.para_id !== item.para_id
        );
      }

      const updatedData = {
        ...updates,
        dispute_resolution,
        venue: venue_data,
        panel: panel_data,
        legal_entities,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'dispute_resolution', diff, updatedData);
      }
    },
    [
      disputeResolution,
      venue,
      panel,
      legalEntities,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteVenue = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let venue_data: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        venue_data = deleteSingleColumn(venue, tableData);
      } else {
        venue_data = venue?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }
      const updatedData = {
        ...updates,
        venue: venue_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'dispute_resolution', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, venue, data, fileId]
  );

  const deletePanel = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let panel_data: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        panel_data = deleteSingleColumn(panel, tableData);
      } else {
        panel_data = panel?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }
      const updatedData = {
        ...updates,
        panel: panel_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'dispute_resolution', diff, updatedData);
      }
    },
    [panel, updates, data?.raw_content, postClauseDataByType, fileId]
  );

  const deleteLegalEntity = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let legal_entities: any;
      if (tableData?.row > -1 && tableData?.column > -1) {
        legal_entities = deleteSingleColumn(legalEntities, tableData);
      } else {
        legal_entities = legalEntities?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }
      const updatedData = {
        ...updates,
        legal_entities: legal_entities,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'dispute_resolution', diff, updatedData);
      }
    },
    [legalEntities, updates, data?.raw_content, postClauseDataByType, fileId]
  );

  const deleteClauseModes = React.useCallback(
    (item: any, mode: string) => {
      const dispute_resolution = disputeResolution.filter((data: any) => {
        if (data.para_id === item.para_id) {
          const modes = data.modes.filter((item: any) => item.key !== mode);
          data.modes = modes;
        }
        return data;
      });
      const updatedData = {
        ...updates,
        dispute_resolution,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'dispute_resolution', diff, updatedData);
      }
    },
    [
      disputeResolution,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="resolution"
          onClick={() =>
            handleAddEdit(
              props,
              'dispute_resolution',
              disputeResolution,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="dispute_resolution"
          savedInsight={disputeResolution ? disputeResolution : []}
          savedParentClauseDataPoint={disputeResolution}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      <Scrollable maxHeight={200}>
        {disputeResolution?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'dispute_resolution', index });
              props.onClickHighlight(index, item, 'dispute_resolution');
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'dispute_resolution' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {/* Arbitration block start */}
      {!showAddArbitration ? (
        <ClauseHeader
          title="Arbitration"
          buttonText="arbitration"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Arbitration',
              arbitration,
              setShowAddArbitration
            )
          }
        />
      ) : (
        <EditFeature
          mode="Arbitration"
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Arbitration"
          savedInsight={arbitration ? arbitration : []}
          savedParentClauseDataPoint={arbitration}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddArbitration(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {arbitration?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'arbitration', index });
            props.onClickHighlight(index, item, 'arbitration');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'arbitration' &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClauseModes(item, 'Arbitration')}
        />
      ))}
      {/* Arbitration block end */}
      {/* Conciliation block start */}
      {!showAddConciliation ? (
        <ClauseHeader
          title="Conciliation"
          buttonText="conciliation"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Conciliation',
              conciliation,
              setShowAddConciliation
            )
          }
        />
      ) : (
        <EditFeature
          mode="Conciliation"
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Conciliation"
          savedInsight={conciliation ? conciliation : []}
          savedParentClauseDataPoint={conciliation}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddConciliation(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {conciliation?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'conciliation', index });
            props.onClickHighlight(index, item, 'conciliation');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'conciliation' &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClauseModes(item, 'Conciliation')}
        />
      ))}
      {/* Conciliation block end */}

      {/* Mediation block start */}
      {!showAddMediation ? (
        <ClauseHeader
          title="Mediation"
          buttonText="mediation"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Mediation',
              mediation,
              setShowAddMediation
            )
          }
        />
      ) : (
        <EditFeature
          mode="Mediation"
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Mediation"
          savedInsight={mediation ? mediation : []}
          savedParentClauseDataPoint={mediation}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddMediation(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {mediation?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'mediation', index });
            props.onClickHighlight(index, item, 'mediation');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'mediation' && activeClause?.index === index
          }
          deleteClause={() => deleteClauseModes(item, 'Mediation')}
        />
      ))}
      {/* Mediation block end */}
      {/* Negotiation block start */}
      {!showAddNegotiation ? (
        <ClauseHeader
          title="Negotiation"
          buttonText="negotiation"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Negotiation',
              negotiation,
              setShowAddNegotiation
            )
          }
        />
      ) : (
        <EditFeature
          mode="Negotiation"
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Negotiation"
          savedInsight={negotiation ? negotiation : []}
          savedParentClauseDataPoint={negotiation}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddNegotiation(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {negotiation?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'negotiation', index });
            props.onClickHighlight(index, item, 'negotiation');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'negotiation' &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClauseModes(item, 'Negotiation')}
        />
      ))}
      {/* Negotiation block end */}

      {/* Others block start */}
      {!showAddOthers ? (
        <ClauseHeader
          title="Other resolution"
          buttonText="other resolution"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Others',
              others,
              setShowAddOthers
            )
          }
        />
      ) : (
        <EditFeature
          mode="Others"
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Others"
          savedInsight={others ? others : []}
          savedParentClauseDataPoint={others}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddOthers(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {others?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'others', index });
            props.onClickHighlight(index, item, 'others');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'others' && activeClause?.index === index
          }
          deleteClause={() => deleteClauseModes(item, 'Others')}
        />
      ))}
      {/* Others block end */}
      {/* Panel block Start */}
      {!showAddPanel ? (
        <ClauseHeader
          title="Panel"
          buttonText="panel"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Panel',
              panel,
              setShowAddPanel
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Panel"
          savedInsight={panel ? panel : []}
          savedParentClauseDataPoint={panel}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddPanel(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {panel?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'panel', index });
            props.onClickHighlight(index, item, 'panel');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'panel' && activeClause?.index === index
          }
          deleteClause={() => deletePanel(item)}
          para={item.capture}
        />
      ))}
      {/* Panel block end */}

      {/* Venue block Start */}
      {!showAddVenue ? (
        <ClauseHeader
          title="Venue"
          buttonText="venue"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Venue',
              venue,
              setShowAddVenue
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Venue"
          savedInsight={venue ? venue : []}
          savedParentClauseDataPoint={venue}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddVenue(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {venue?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'venue', index });
            props.onClickHighlight(index, item, 'venue');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'venue' && activeClause?.index === index
          }
          deleteClause={() => deleteVenue(item)}
          para={item.capture}
        />
      ))}
      {/* Venue block end */}

      {/* Legal Entity block Start */}
      {!showAddAct ? (
        <ClauseHeader
          title="Act/Statute"
          buttonText="Act/Statute"
          onClick={() =>
            handleAddEdit(
              props,
              'Dispute Resolution Act/Statute',
              legalEntities,
              setShowAddAct
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Dispute Resolution Act/Statute"
          savedInsight={legalEntities ? legalEntities : []}
          savedParentClauseDataPoint={legalEntities}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddAct(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="dispute_resolution"
        />
      )}
      {legalEntities?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'legalEntities', index });
            props.onClickHighlight(index, item, 'legalEntities');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'legalEntities' &&
            activeClause?.index === index
          }
          deleteClause={() => deleteLegalEntity(item)}
          para={item.capture}
        />
      ))}
      {/* Legal Entity block end */}
    </>
  );
}
