import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';
import { useForm } from 'react-hook-form';

import RadioButtonGroup from '../../../../../RiverusUI/Components/RadioButtonGroup';
import { ClauseType } from '../../../../Constants/ClauseType';
import {
  editedParas,
  LinkParaRequest,
  paraInfo,
  SentencesData,
  tableInfo,
} from '../../../../State/documentState';
import {
  getClauseDataFormat,
  getClauseObjects,
} from '../../../ClauseComponent/utils/ClauseTypeUtils';
import {
  getParasFromChild,
  getTableCellsFromChild,
} from '../../../Utils/docUtils';
import SaveOrCancel from '../saveOrCancel';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  savedPresent: string;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  parentClauseData: any;
  updatedParentClauseDataByType: any;
}

const EditTerminationConveniences: React.FC<Props> = (props) => {
  const {
    onClose,
    saveHighlightedDataPoint,
    dataPointName,
    clauseType,
    sentenceData,
    clauseDataByType,
    fileId,
    updatedClauseDataByType,
    postClauseDataByType,
    parentClauseType,
    parentClauseData,
    updatedParentClauseDataByType,
    highlightedId,
    savedPresentData,
    savedHighlightedTableCells,
    saveHighlightedId,
    editOptionSelected,
    saveHighlightedTableCells,
  } = props;

  const { control, watch } = useForm({
    defaultValues: {
      isPresent: 'Yes',
    },
  });

  const clauseOptions = [
    {
      value: 'Yes',
      title: `${dataPointName} clause is present`,
    },
    {
      value: 'No',
      title: `${dataPointName}  clause is not present`,
    },
  ];

  const isPresent = watch('isPresent') || '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const saveHighlightedDataPoints = (highlight?: boolean) => {
    saveHighlightedDataPoint(dataPointName);
  };

  const getAddedAndDeletedParas = (
    previousParas: number[],
    changedParas: number[]
  ) => {
    const addedParas: paraInfo[] = [];
    const deletedParas: paraInfo[] = [];
    if (previousParas.length !== 0) {
      if (changedParas.length !== 0) {
        for (let i = 0; i < changedParas.length; i++) {
          const exists = previousParas.includes(changedParas[i]);
          if (!exists) {
            addedParas.push({
              paraId: changedParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
        for (let i = 0; i < previousParas.length; i++) {
          const exists = changedParas.includes(previousParas[i]);
          if (!exists) {
            deletedParas.push({
              paraId: previousParas[i],
              rowId: -1,
              columnId: -1,
            });
          }
        }
      } else {
        for (let i = 0; i < previousParas.length; i++) {
          deletedParas.push({
            paraId: previousParas[i],
            rowId: -1,
            columnId: -1,
          });
        }
      }
    } else {
      if (changedParas.length !== 0) {
        for (let i = 0; i < changedParas.length; i++) {
          addedParas.push({
            paraId: changedParas[i],
            rowId: -1,
            columnId: -1,
          });
        }
      }
    }

    const addedDeletedParas: editedParas = {
      upsert: addedParas,
      deleted: deletedParas,
      bi: '',
    };

    return addedDeletedParas;
  };

  const getAddedAndDeletedTableCells = (
    previousLinkedTableCells: tableInfo[],
    changedLinkedTableCells: tableInfo[] | null
  ) => {
    const addedTableCells: tableInfo[] = [];
    const deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          const addedCellExists = previousLinkedTableCells.some(
            (cell) =>
              cell.paraId === changedLinkedTableCells[i].paraId &&
              cell.rowId === changedLinkedTableCells[i].rowId &&
              cell.columnId === changedLinkedTableCells[i].columnId
          );
          if (!addedCellExists) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          const deletedCellExists = changedLinkedTableCells.some(
            (cell) =>
              previousLinkedTableCells[i].paraId === cell.paraId &&
              previousLinkedTableCells[i].rowId === cell.rowId &&
              previousLinkedTableCells[i].columnId === cell.columnId
          );
          if (!deletedCellExists) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push(changedLinkedTableCells[i]);
      }
    }

    const upsertParas: paraInfo[] = addedTableCells.map((cell) => ({
      paraId: cell.paraId,
      rowId: cell.rowId,
      columnId: cell.columnId,
    }));

    const deletedParas: paraInfo[] = deletedTableCells.map((cell) => ({
      paraId: cell.paraId,
      rowId: cell.rowId,
      columnId: cell.columnId,
    }));

    const editedTableCellsAsPara: editedParas = {
      upsert: upsertParas,
      deleted: deletedParas,
      bi: '',
    };
    return editedTableCellsAsPara;
  };

  const mergeEditedParas = (
    firstEditedParas: editedParas,
    secondEditedParas: editedParas
  ) => {
    const mergedAddedParas: paraInfo[] = firstEditedParas.upsert.concat(
      secondEditedParas.upsert
    );
    const mergedDeletedParas: paraInfo[] = firstEditedParas.deleted.concat(
      secondEditedParas.deleted
    );

    const mergeEditedParas: editedParas = {
      upsert: mergedAddedParas,
      deleted: mergedDeletedParas,
      bi: dataPointName,
    };
    return mergeEditedParas;
  };

  const addOrRemovePresent = (action: string) => {
    let tempPresentList: LinkParaRequest = {
      data: '',
      mode: '',
      editedParas: { upsert: [], deleted: [], bi: '' },
    };

    const previousParas = getParasFromChild(savedPresentData);
    const changedParas: number[] = highlightedId !== null ? highlightedId : [];

    const previousTableCells = getTableCellsFromChild(savedPresentData);
    const changedLinkedTableCells = savedHighlightedTableCells;

    const editedPara: editedParas = getAddedAndDeletedParas(
      previousParas,
      changedParas
    );

    const editedTablesCells: editedParas = getAddedAndDeletedTableCells(
      previousTableCells,
      changedLinkedTableCells
    );

    const mergeEditedPara: editedParas = mergeEditedParas(
      editedPara,
      editedTablesCells
    );

    if (action === 'add') {
      if (highlightedId !== null || savedHighlightedTableCells !== null) {
        tempPresentList = {
          data: isPresent,
          mode: 'manual',
          editedParas: mergeEditedPara,
        };
      } else {
        tempPresentList = {
          data: isPresent,
          mode: 'retain',
        };
      }
    }
    return tempPresentList;
  };

  const editPresentSentence = () => {
    const newParasRequest = addOrRemovePresent('add');
    let newData = updatedClauseDataByType;
    let newTerminationData = updatedParentClauseDataByType;

    if (isPresent === 'Yes') {
      if (newParasRequest.editedParas?.upsert) {
        const addedData = newParasRequest.editedParas.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            'add',
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData,
            '',
            0,
            clauseDataByType?.raw_content
          );
          newTerminationData = getClauseDataFormat(
            'add',
            'termination',
            addedData[i],
            newTerminationData,
            sentenceData
          );
        }
      }
      if (newParasRequest?.editedParas?.deleted) {
        const deletedData = newParasRequest.editedParas.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            'remove',
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType, newData);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(fileId, parentClauseType, diff, newData);
    }
    if (isPresent === 'Yes' && newTerminationData) {
      const parentDiff = changesets.diff(
        parentClauseData?.raw_content,
        newTerminationData,
        {
          children: '$index',
        }
      );

      if (parentDiff.length > -1) {
        postClauseDataByType(
          fileId,
          'termination',
          parentDiff,
          newTerminationData
        );
      }
    }
    onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1} textTransform="capitalize">
        Add / Edit {dataPointName} Clause
      </Typography>

      <Stack width="100%" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a paragraph to {dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select `&quot;`{dataPointName} Clause Is Present`&quot;`
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on `&quot;`Edit Linked Paragraph(s)`&quot;` button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a paragraph from {dataPointName} clause?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted paragraph you want
            to remove from {dataPointName} clause.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveOrCancel
          enableHighlightOption={isPresent === 'Yes'}
          dataPointName={dataPointName}
          editOptionSelected={editOptionSelected}
          editDataPoint={() => {
            editPresentSentence();
            saveHighlightedId(null);
            editOptionSelected(false);
            saveHighlightedTableCells(null);
            onClose();
          }}
          highlightedId={highlightedId}
          saveHighlightedDataPoint={(highlight?: boolean) =>
            saveHighlightedDataPoints(highlight)
          }
          enableSaveBtn={true}
          saveHighlightedId={(highlightedId: number[] | null) => {
            saveHighlightedId(highlightedId);
          }}
          previouslyLinkedPara={
            getParasFromChild(savedPresentData)?.length > 0
              ? getParasFromChild(savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(savedPresentData)?.length > 0
              ? getTableCellsFromChild(savedPresentData)
              : undefined
          }
          savedHighlightedTableCells={savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => saveHighlightedTableCells(savedHighlightedTableCells)}
          onClose={onClose}
        />
      </Stack>
    </Box>
  );
};

export default EditTerminationConveniences;
