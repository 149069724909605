import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export default function BlurryLoader() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}
