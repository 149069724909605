import React, { useEffect } from 'react';

import {
  Box,
  Pagination as MuiPagination,
  TablePaginationProps,
  TextField,
} from '@mui/material';
import {
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  GridPagination,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid';
import { Controller, useForm } from 'react-hook-form';

type Props = Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>;

const getPageCount = (rowCount: number, pageSize: number): number => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }

  return 0;
};

function Pagination({ page, onPageChange, className }: Props) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  );
  const pageCount = getPageCount(
    rootProps.rowCount ?? visibleTopLevelRowCount,
    pageSize
  );

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { pageNumber: page + 1 },
  });

  const onSubmit = (data: { pageNumber: number }) => {
    const newPage = data.pageNumber - 1;
    if (newPage >= 0 && newPage < pageCount) {
      onPageChange(null, newPage);
    }
  };

  useEffect(() => {
    setValue('pageNumber', page + 1);
  }, [page]);

  return (
    <Box display="flex" alignItems="center" className={className}>
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '10px' }}>
        <Controller
          name="pageNumber"
          control={control}
          rules={{ min: 1, max: pageCount }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Page No."
              type="number"
              variant="outlined"
              size="small"
              InputProps={{ inputProps: { min: 1, max: pageCount } }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSubmit(onSubmit)();
                }
              }}
              sx={{
                width: '80px',
              }}
            />
          )}
        />
      </form>
    </Box>
  );
}

function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomPagination;
