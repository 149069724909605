import React from 'react';

import { Grid, Snackbar } from '@mui/material';

import SentenceLoader from './sentenceLoader';
import { DarkTooltip } from '../../../DocumentView/Component/DocumentInsight';
import {
  SentencesData,
  Table,
} from '../../../DocumentView/State/documentState';

interface Props {
  sentenceData: SentencesData;
  sentenceLoader: boolean;
  setClauseText: (selectedClauseText: string) => void;
  showClauseModal: () => void;
}

interface State {
  popAddButton: boolean;
  selectedTextId: string;
  selectedText: string;
  openModal: boolean;
}

export default class SentenceFrameRenderer extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popAddButton: false,
      selectedTextId: '',
      selectedText: '',
      openModal: true,
    };
  }

  render() {
    const { sentenceData, sentenceLoader } = this.props;
    return (
      <>
        <Grid container sx={{ marginRight: '0px' }}>
          <Grid md={12} className="sentences-container" id="scroll-to">
            {sentenceLoader ? (
              <SentenceLoader />
            ) : (
              sentenceData.hierarchy.map((para, i) => (
                <React.Fragment key={i}>
                  <div
                    id={'p' + para.index}
                    key={para.index}
                    style={{ marginBottom: '20px' }}
                  >
                    {para.table ? (
                      this.renderTable(para.table, para.index)
                    ) : (
                      <>
                        {para.sentences.map((sentence, j) => (
                          <React.Fragment key={j}>
                            <span
                              id={'p' + para.index + ';s' + sentence.index}
                              style={{
                                fontWeight: this.getSentencesFontWeight(
                                  sentence.level
                                ),
                              }}
                              key={sentence.index}
                            >
                              {sentence.words.map((word, k) => (
                                <React.Fragment key={k}>
                                  <span
                                    id={'p' + para.index + ';w' + word.index}
                                    className="clause-selector"
                                    style={{
                                      fontWeight: this.getFontWeight(
                                        para.isHeading
                                      ),
                                    }}
                                    onMouseUp={() => {
                                      this.showAddClauseOption(
                                        'p' + para.index + ';w' + word.index
                                      );
                                    }}
                                    key={word.index}
                                  >
                                    {word.word + ' '}
                                  </span>
                                  {window.getSelection()?.toString() != null &&
                                  window?.getSelection()?.toString() !== '' ? (
                                    <>
                                      {this.addClause(
                                        'p' + para.index + ';w' + word.index
                                      )}
                                    </>
                                  ) : (
                                    true
                                  )}
                                </React.Fragment>
                              ))}
                            </span>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </div>
                </React.Fragment>
              ))
            )}
          </Grid>
        </Grid>
        {/* ==== */}
        <Snackbar
          open={
            window.getSelection()?.toString() != undefined &&
            window.getSelection()?.toString() != null &&
            window.getSelection()?.toString() != ''
              ? false
              : this.state.openModal == true
                ? true
                : false
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="Please highlight selection on the document to add to the clause library"
        />
      </>
    );
  }

  renderTable(table: Table[], paraIndex: number) {
    return (
      <table
        className="table table-bordered"
        style={{ wordBreak: 'break-word' }}
      >
        <tbody>
          {table.map((row, i) => (
            <React.Fragment key={i}>
              <tr
                key={i}
                id={'p' + paraIndex + ';r' + i}
                onMouseUp={() =>
                  this.showAddClauseOption('p' + paraIndex + ';r' + i)
                }
              >
                {row.Row.map((col, j) => (
                  <React.Fragment key={j}>
                    <td key={j} id={'p' + paraIndex + ';r' + i + ';c' + j}>
                      <span className="clause-selector">{col.Content}</span>
                    </td>
                  </React.Fragment>
                ))}
              </tr>
              {window.getSelection()?.toString() != null &&
              window?.getSelection()?.toString() !== '' ? (
                <> {this.addClause('p' + paraIndex + ';r' + i)}</>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }

  getSentencesFontWeight(level: string) {
    switch (level) {
      case 'para': {
        return 'normal';
      }
      case 'heading':
      case 'bullet': {
        return 'bold';
      }
      default: {
        return 'normal';
      }
    }
  }

  getFontWeight(isHeading: string) {
    if (isHeading === 'Yes') {
      return 'bold';
    }
  }

  showAddClauseOption(textId: string) {
    const selectedText = this.getSelectionText();
    this.setState({
      popAddButton: true,
      selectedTextId: textId,
      selectedText: selectedText,
    });
  }

  addClause(textId: string) {
    const { popAddButton, selectedTextId } = this.state;
    if (
      selectedTextId === textId &&
      popAddButton === true &&
      window?.getSelection()?.toString() !== null &&
      window?.getSelection()?.toString() !== ''
    ) {
      return (
        <DarkTooltip title="Add to Clause Library" placement="bottom">
          <span
            style={{
              position: 'absolute',
              // left: "-23px",
              cursor: 'pointer',
              display: 'inline-block',
              zIndex: 20,
            }}
            data-toggle="modal"
            data-target="#createClauseModal"
            onClick={() => {
              this.setState(
                {
                  openModal: false,
                },
                () => {
                  this.openCreateClause();
                }
              );
            }}
          >
            <img src="/static_images/add-clause-icn.svg" alt="add-clause" />
          </span>
        </DarkTooltip>
      );
    }
  }

  getSelectionText() {
    let text = '';
    const windowSelection = window.getSelection();
    if (windowSelection !== null && windowSelection !== undefined) {
      text = windowSelection.toString();
    }
    return text;
  }

  openCreateClause() {
    const { selectedText } = this.state;
    this.props.setClauseText(selectedText);
    this.setState({ popAddButton: false, selectedTextId: '' });
    this.props.showClauseModal();
  }
}
