import { createTheme } from '@mui/material/styles';

import colors from './colors';
import components from './components';

const theme = createTheme({
  palette: colors,
  components: components,
});

export default theme;
