import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';
import { useForm } from 'react-hook-form';

import RadioButtonGroup from '../../../../../RiverusUI/Components/RadioButtonGroup';
import { ClauseType } from '../../../../Constants/ClauseType';
import {
  editedSentences,
  LinkSentenceRequest,
  sentenceInfo,
  SentencesData,
  tableInfo,
} from '../../../../State/documentState';
import {
  getClauseDataFormat,
  getClauseObjects,
} from '../../../ClauseComponent/utils/ClauseTypeUtils';
import {
  dataForMap,
  getSentencesFromChild,
  getTableCellsFromChild,
} from '../../../Utils/docUtils';
import SaveAndCancelSentence from '../SaveAndCancelSentence';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  dataPointName: string;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: any;
  clauseData: any;
}

export const regulatoryMap: any = {
  Yes: 'Non-Regulatory',
  No: 'Regulatory',
};

const EditAddConsent: React.FC<Props> = (props) => {
  const { control, watch } = useForm({
    defaultValues: {
      isPresent: 'Yes',
    },
  });

  const clauseOptions = [
    {
      value: 'Yes',
      title: 'Consent clause is present',
    },
    {
      value: 'No',
      title: 'Consent clause is not present',
    },
  ];

  const isPresent = watch('isPresent') || '';

  const saveHighlightedDataPointCallback = () => {
    props.saveHighlightedDataPoint(props.dataPointName);
  };

  const onSave = (addSentenceRequest: any) => {
    const {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
    } = props;

    const updatedData = updatedClauseDataByType;
    let newData = updatedData;

    if (isPresent === 'Yes') {
      if (addSentenceRequest?.editedSentences?.upsert) {
        const addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            'add',
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        const deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            'remove',
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(props.clauseType as ClauseType);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
  };

  const getAddedAndDeletedSentences = (
    changedLinkedSentences: sentenceInfo[] | null,
    previousLinkedSentences: sentenceInfo[]
  ) => {
    const addedSentences: sentenceInfo[] = [];
    const deletedSentences: sentenceInfo[] = [];

    if (previousLinkedSentences.length) {
      if (changedLinkedSentences) {
        if (changedLinkedSentences.length) {
          //get newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousLinkedSentences.length; j++) {
              if (
                changedLinkedSentences[i].paraId ===
                previousLinkedSentences[j].paraId
              ) {
                if (
                  changedLinkedSentences[i].sentenceId ===
                  previousLinkedSentences[j].sentenceId
                ) {
                  addedExists = true;
                  break;
                }
              }
            }
            if (!addedExists) {
              addedSentences.push({
                paraId: changedLinkedSentences[i].paraId,
                sentenceId: changedLinkedSentences[i].sentenceId,
                rowId: -1,
                columnId: -1,
                phrase: '',
                typestring: '',
                startWordId: -1,
                endWordId: -1,
              });
            }
          }

          //get deleted elements
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedLinkedSentences.length; j++) {
              if (
                previousLinkedSentences[i].paraId ===
                changedLinkedSentences[j].paraId
              ) {
                if (
                  previousLinkedSentences[i].sentenceId ===
                  changedLinkedSentences[j].sentenceId
                ) {
                  deletedExists = true;
                  break;
                }
              }
            }
            if (!deletedExists) {
              deletedSentences.push({
                paraId: previousLinkedSentences[i].paraId,
                sentenceId: previousLinkedSentences[i].sentenceId,
                rowId: previousLinkedSentences[i].rowId,
                columnId: previousLinkedSentences[i].columnId,
              });
            }
          }
        } else if (changedLinkedSentences.length === 0) {
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
            });
          }
        }
      }
    } else {
      if (changedLinkedSentences) {
        if (changedLinkedSentences.length) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            addedSentences.push({
              paraId: changedLinkedSentences[i].paraId,
              sentenceId: changedLinkedSentences[i].sentenceId,
              rowId: changedLinkedSentences[i].rowId,
              columnId: changedLinkedSentences[i].columnId,
              phrase: '',
              typestring: '',
              startWordId: -1,
              endWordId: -1,
            });
          }
        }
      }
    }

    const addedDeletedSentences: editedSentences = {
      upsert: addedSentences,
      deleted: deletedSentences,
    };

    return addedDeletedSentences;
  };

  const getAddedAndDeletedTableCells = (
    changedLinkedTableCells: tableInfo[] | null,
    previousLinkedTableCells: tableInfo[]
  ) => {
    const addedTableCells: tableInfo[] = [];
    const deletedTableCells: tableInfo[] = [];
    if (previousLinkedTableCells.length && previousLinkedTableCells) {
      if (changedLinkedTableCells && changedLinkedTableCells.length) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
              phrase: '',
              typestring: '',
              startWordId: -1,
              endWordId: -1,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (!deletedCellExists) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (changedLinkedTableCells && changedLinkedTableCells.length) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push({
          paraId: changedLinkedTableCells[i].paraId,
          rowId: changedLinkedTableCells[i].rowId,
          columnId: changedLinkedTableCells[i].columnId,
          phrase: '',
          typestring: '',
          startWordId: -1,
          endWordId: -1,
        });
      }
    }

    //Convert table cells json to sentence level json
    const upsertSentences: sentenceInfo[] = [];
    const deletedSentences: sentenceInfo[] = [];

    for (let i = 0; i < addedTableCells.length; i++) {
      upsertSentences.push({
        paraId: addedTableCells[i].paraId,
        sentenceId: -1,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
        phrase: addedTableCells[i].phrase,
        typestring: addedTableCells[i].typestring,
        startWordId: -1,
        endWordId: -1,
      });
    }

    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedSentences.push({
        paraId: deletedTableCells[i].paraId,
        sentenceId: -1,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }

    const editedTableCellsAsSentence: editedSentences = {
      upsert: upsertSentences,
      deleted: deletedSentences,
    };
    return editedTableCellsAsSentence;
  };

  const mergeEditedSentences = (
    firstEditedSentences: editedSentences,
    secondEditedSentences: editedSentences
  ) => {
    const mergedAddedSentences: sentenceInfo[] =
      firstEditedSentences.upsert.concat(secondEditedSentences.upsert);
    const mergedDeletedSentences: sentenceInfo[] =
      firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

    const mergedEditedSentences: editedSentences = {
      upsert: mergedAddedSentences,
      deleted: mergedDeletedSentences,
      dataFor: dataForMap[props.dataPointName],
    };
    return mergedEditedSentences;
  };

  const addOrRemovePresent = (action: string): LinkSentenceRequest => {
    const {
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
    } = props;
    let tempPresentList: LinkSentenceRequest = { data: '', mode: '' };

    //edited sentences
    const previousLinkedSentences = getSentencesFromChild(savedPresentData);
    const editedSentencesObject = getAddedAndDeletedSentences(
      savedHighlightedSentences,
      previousLinkedSentences
    );

    //edited table cells
    const previousLinkedTableCells = getTableCellsFromChild(savedPresentData);
    const editedTableCellsObject = getAddedAndDeletedTableCells(
      savedHighlightedTableCells,
      previousLinkedTableCells
    );

    //merge both
    const mergeEditedSentence = mergeEditedSentences(
      editedTableCellsObject,
      editedSentencesObject
    );

    if (action === 'add') {
      if (savedHighlightedSentences || savedHighlightedTableCells) {
        tempPresentList = {
          data: isPresent,
          mode: 'manual',
          editedSentences: mergeEditedSentence,
        };
      } else {
        tempPresentList = {
          data: isPresent,
          mode: 'retain',
        };
      }
    }
    return tempPresentList;
  };

  const onSaveClause = () => {
    const addSentenceRequest = addOrRemovePresent('add');
    props.editOptionSelected(false);
    props.saveHighlightedTableCells(null);
    props.saveHighlightedSentences(null);
    onSave(addSentenceRequest);
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600}>Add / Edit {props.dataPointName}</Typography>
      <Stack direction="column" className="edit-clause-select" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            How to add a sentence to {props?.dataPointName}?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Select `&quot;`{props?.dataPointName} Is Present`&quot;`
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on `&quot;`Edit Linked Sentences(s)`&quot;` button.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            4). Click on the desired text or the link icon on the box around the
            text that you want to add as {props?.dataPointName}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            5). Click on Save.
          </Typography>
        </Stack>

        <Stack>
          <Typography variant="body2" fontWeight={700}>
            How to remove a sentence from {props?.dataPointName}?
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            1). Hover over the highlighted text in the contract on left.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on the bin icon against the highlighted sentence you want
            to remove from {props?.dataPointName}.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            3). Click on Save.
          </Typography>
        </Stack>
        <SaveAndCancelSentence
          enableHighlightOption={isPresent === 'Yes'}
          dataPointName={props.dataPointName}
          editOptionSelected={props.editOptionSelected}
          editDataPoint={() => {
            onSaveClause();
          }}
          savedHighlightedSentences={props.savedHighlightedSentences}
          saveHighlightedDataPoint={saveHighlightedDataPointCallback}
          enableSaveBtn={true}
          saveHighlightedSentences={(
            savedHighlightedSentences: sentenceInfo[] | null
          ) => props.saveHighlightedSentences(savedHighlightedSentences)}
          savedHighlightedTableCells={props.savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => props.saveHighlightedTableCells(savedHighlightedTableCells)}
          previouslyLinkedSentences={
            getSentencesFromChild(props.savedPresentData).length > 0
              ? getSentencesFromChild(props.savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(props.savedPresentData).length > 0
              ? getTableCellsFromChild(props.savedPresentData)
              : undefined
          }
          onClose={props.onClose}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          otherClauses={() => {}}
        />
      </Stack>
    </Box>
  );
};

export default EditAddConsent;
