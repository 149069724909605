export const DrafTableTabs = [
  { label: 'All', value: 'all' },
  { label: 'My Drafts', value: 'my_drafts' },
  { label: 'Internal Draft Created', value: 'Internal Draft Created' },
  { label: 'For Approval', value: 'Requisition Approval Pending' },
  { label: 'For Signature', value: 'Signature Pending' },
];

export const myDraftTabs = [
  'my_drafts',
  'my_new_requisition_form',
  'my_drafts_under_collaboration',
  'my_approval_pending',
  'my_signature_pending',
];

export const allDraftsTabs = [
  'total',
  'new_requisition_form',
  'drafts_under_collaboration',
  'approval_pending',
];

export const statisticTabs = [
  { label: 'Total', value: 'total', count: 0, apiKey: 'total_documents' },
  {
    label: 'New Reqs.',
    value: 'new_requisition_form',
    count: 0,
    apiKey: 'total_new_requisition_form',
  },
  {
    label: 'Collaborating',
    value: 'drafts_under_collaboration',
    count: 0,
    apiKey: 'drafts_under_collaboration',
  },
  {
    label: 'Approval Pending',
    value: 'approval_pending',
    count: 0,
    apiKey: 'approval_pending',
  },
  {
    label: 'Signatures Pending',
    value: 'signature_pending',
    count: 0,
    apiKey: 'signature_pending',
  },
];

export const myStatisticTabs = [
  {
    label: 'Total',
    value: 'my_drafts',
    count: 0,
    apiKey: 'total_my_documents',
  },
  {
    label: 'New Reqs.',
    value: 'my_new_requisition_form',
    count: 0,
    apiKey: 'my_new_requisition_form',
  },
  {
    label: 'Collaborating',
    value: 'my_drafts_under_collaboration',
    count: 0,
    apiKey: 'my_drafts_under_collaboration',
  },
  {
    label: 'Approval Pending',
    value: 'my_approval_pending',
    count: 0,
    apiKey: 'my_approval_pending',
  },
  {
    label: 'Signatures Pending',
    value: 'my_signature_pending',
    count: 0,
    apiKey: 'my_signature_pending',
  },
];

export const version0Tabs = [
  {
    label: 'Checklist',
    value: 'checklist',
  },
  // {
  //   label: "Applied Policies",
  //   value: "applied_policies",
  //   notIn: 1,
  // },
  {
    label: 'Collaborate',
    value: 'collaborate',
  },
  // {
  //   label: "Access",
  //   value: "access",
  // },
];

export const version1Tabs = [
  {
    label: 'Collaborate',
    value: 'collaborate',
  },
  {
    label: 'Checklist',
    value: 'checklist',
  },
  // {
  //   label: "Access",
  //   value: "access",
  // },
];

export const dropNotShowingStatus = [
  'Contract Executed Successfully',
  'Committed for Signature',
  'Signature Pending',
  'Signature Aborted',
];
