import React, { Component } from 'react';

import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';

import ClauseComponent from './ClauseComponent/Container/ClauseComponentCon';
import FilterInsights from './InsightFilter/FilterInsights';
import {
  AuxArrayInterface,
  createChildDataPointsAggregate,
  detectMultipleInstances,
  disputeResolutionModeBi,
  getParentHighlights,
  highlight_lilac,
  highlight_other_clause,
  highlight_yellow,
  simulateScroll,
} from './Utils/docUtils';
import Scrollable from '../../UniversalComponents/Scrollable/scrollable';
import {
  BiPointDataMode,
  Child,
  ContractDetails,
  CurrencyType,
  DurationType,
  InsightsInterface,
  QuickIntel,
} from '../State/documentState';

export const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#393939',
    // color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows,
    fontSize: 13,
    marginTop: 0,
  },
}))(Tooltip);

interface Props {
  sentenceData: any;
  insightsData: any[];
  saveDataPoints: (
    dataPoints: string[],
    state: boolean,
    tempParaIndex: number,
    type: string
  ) => void;
  scrolledChildIndex: number;
  superImposeChildIndex: (childIndex: number) => void;
  fileId: string;
  sentenceLoader: boolean;
  editLoader: boolean;
  editSuccessLoader: boolean;
  editFailureLoader: boolean;
  location: any;
  navigate: any;
  editOptionSelected: (editOptionSelected: boolean) => void;
  storedBiPointDataModes: BiPointDataMode[];
  saveSelectedInsightPoint: (selectedInsightPoint: string) => void;
  setCurrentScrollIndex: (currentScrollIndex: number) => void;
  currentScrollIndex: number;
  fileEncoded: string;
  setTaskPage: (hideTaskPage: boolean) => void;
  clauseModeStatus: boolean;
  setTaskOrigin: (origin: string) => void;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  contractData: ContractDetails;
  clauseInsightsLoader: boolean;
  clauseData: any;
  updatedClauseData: any;
}

interface State {
  clickedMenu: string;
  clickedSubMenu: string;
  clickedInstanceIndex: number;
  highlightedYellow: string[];
  highlightedLilac: string[];
  savedParentDataPoints: string[];
  toBeEdited: string;
  editOptionSelected: boolean;
  quickIntel: QuickIntel;
  hideTaskPage: boolean;
  clickedTaskMenu: string;
  childInEditId: number;
  currentEditIsGroupedData: boolean;
  currentEditParentClause: Child[];
  currentEditParentClauseId: number;
  currentEditParentLabel: string;
  showClauseModal: boolean;
  currentInstance: number;
  localScrollIndex: number;
  extremeEnd: string;
  editfailureStatus: string;
  editClause: number;
  selectedDataPoints: any[];
  showDeleteModal: boolean;
  deleteData: any;
  clauseType: string;
}

export default class DocumentInsights extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clickedMenu: 'Basic Information',
      clickedSubMenu: '',
      highlightedYellow: [],
      highlightedLilac: [],
      clickedInstanceIndex: 0,
      savedParentDataPoints: [],
      toBeEdited: '',
      editOptionSelected: false,
      quickIntel: {
        fileName: '',
        metaData: [],
      },
      hideTaskPage: true,
      clickedTaskMenu: '',
      childInEditId: -1,
      currentEditIsGroupedData: false,
      currentEditParentClause: [],
      currentEditParentClauseId: -1,
      currentEditParentLabel: '',
      showClauseModal: false,
      currentInstance: 1,
      localScrollIndex: 0,
      extremeEnd: 'up',
      editfailureStatus: '',
      editClause: 0,
      selectedDataPoints: [],
      showDeleteModal: false,
      deleteData: null,
      clauseType: '',
    };
  }

  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({
        editOptionSelected: true,
        toBeEdited: this.props.location.state.bi,
        clickedMenu: this.props.location.state.clause,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.editFailureLoader && this.props.editLoader) {
      if (this.state.editClause === 0) {
        this.setState({ editClause: 1 }, () => {
          notifyError();
        });
      }
    }
    if (nextProps.editSuccessLoader && this.props.editLoader) {
      notifySuccess();
      this.setState({ editClause: 0 });
    }
    if (this.props.editLoader !== nextProps.editLoader) {
      this.props.editLoader && this.setState({ editOptionSelected: false });
    }
    if (
      this.props.location.state != null &&
      this.props.location.state === nextProps.location.state
    ) {
      this.props.navigate(this.props.location.pathname);
    }
    if (this.props.currentScrollIndex !== nextProps.currentScrollIndex) {
      if (this.state.clickedInstanceIndex !== nextProps.currentScrollIndex) {
        this.setState({
          clickedInstanceIndex: nextProps.currentScrollIndex,
        });
      }
    }
  }

  onClickDataPoint = (dataPoints: any, dataLabel: string) => {
    let selectedDataPoints: any = [];

    this.setState({ selectedDataPoints: dataPoints });
    selectedDataPoints = dataPoints;
    const { saveSelectedInsightPoint } = this.props;
    const { clickedMenu } = this.state;
    if (clickedMenu !== dataLabel) {
      this.setState({ localScrollIndex: 0 });
    }
    this.props.editOptionSelected(false);
    saveSelectedInsightPoint(dataLabel);
    this.scrollToDataPoint(
      'clause',
      dataLabel,
      clickedMenu === dataLabel ? [] : getParentHighlights(selectedDataPoints)
    );
  };

  scrollUpDown(current: string) {
    const paraHeader = document.getElementById(current);
    if (paraHeader) {
      paraHeader.style.background = highlight_lilac;
      paraHeader.scrollIntoView({ block: 'center' });
    }
  }

  scrollTo(direction: string, data: any) {
    const { localScrollIndex } = this.state;
    const dataPoints: any = getParentHighlights(data);
    let currentScrollIndex = 0;
    if (direction === 'up') {
      const paraHeader = document.getElementById(dataPoints[localScrollIndex]);
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex - 1;
      if (currentScrollIndex >= 0) {
        if (currentScrollIndex === 0) {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: 'up',
          });
        } else {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: '',
          });
        }
        this.scrollUpDown(dataPoints[currentScrollIndex]);
      }
    } else if (direction === 'down') {
      const paraHeader = document.getElementById(
        dataPoints[localScrollIndex - 1]
      );
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex + 1;
      if (currentScrollIndex <= dataPoints.length) {
        if (currentScrollIndex === dataPoints.length) {
          this.setState({
            extremeEnd: 'down',
          });
        } else {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: '',
          });
        }

        this.scrollUpDown(dataPoints[currentScrollIndex - 1]);
      }
    }
  }

  handleInstanceChange = (type: string, data: InsightsInterface) => {
    const { currentInstance } = this.state;
    if (type === 'up' && currentInstance > 1) {
      this.scrollToDataPoint(
        'clause',
        data.label,
        getParentHighlights(data.dataPoints)
      );
      this.setState({ currentInstance: currentInstance - 1 });
    }
  };
  render() {
    const { clauseModeStatus } = this.props;
    return (
      <Box>
        <FilterInsights contractData={this.props.contractData} />
        <Box>
          <Scrollable
            minHeight="70vh"
            maxHeight={
              window.screen.width < 1300
                ? 550
                : window.screen.width > 1300 && window.screen.width < 1500
                  ? 650
                  : 480
            }
          >
            <Box>
              <ClauseComponent
                fileId={this.props.fileId}
                onScroll={(direction: string, data: any) =>
                  this.scrollTo(direction, data)
                }
                onClickDataPoint={(dataPoints: any, dataLabel: string) =>
                  this.onClickDataPoint(dataPoints, dataLabel)
                }
                onEdit={(
                  clauseName: string,
                  key: number,
                  isGroupedData: boolean,
                  parentClause: any,
                  parentClauseId: number,
                  parentLabel: string,
                  dataPoints: any
                ) =>
                  this.goToEditFeatures(
                    clauseName,
                    key,
                    isGroupedData,
                    parentClause,
                    parentClauseId,
                    parentLabel,
                    dataPoints
                  )
                }
                toBeEdited={this.state.toBeEdited}
                childInEditId={this.state.childInEditId}
                editOptionSelected={(selected: boolean) => {
                  this.props.editOptionSelected(selected);
                  this.setState({ editOptionSelected: selected });
                }}
                scrollToChildDataPoint={this.scrollToChildDataPoint}
                scrollToDatapoint={this.scrollToDataPoint}
                otherClauses={(type: string) => {
                  this.OtherClauses(type);
                }}
              />
            </Box>
          </Scrollable>
        </Box>
        {clauseModeStatus && (
          <Box
            sx={{
              zIndex: 10,
              background: 'rgba(0, 0, 0, 0.15)',
              backdropFilter: 'blur(2px)',
              position: 'absolute',
              top: '-3vh',
              left: '0vw',
              height: '100vh',
              width: '50vw',
            }}
          />
        )}
      </Box>
    );
  }

  goToEditFeature(
    toBeEdited: string,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string
  ) {
    this.props.setCurrentScrollIndex(0);
    this.scrollToChildDataPoint('bi', toBeEdited, [], 0, true);
    this.setState({
      toBeEdited: toBeEdited,
      editOptionSelected: true,
      childInEditId: key,
      currentEditIsGroupedData: isGroupedData,
      currentEditParentClause: parentClause,
      currentEditParentClauseId: parentClauseId,
      currentEditParentLabel: parentLabel,
    });
  }

  goToEditFeatures(
    toBeEdited: string,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string,
    dataPoints: any
  ) {
    this.setState({ selectedDataPoints: dataPoints });
    this.props.setCurrentScrollIndex(0);
    this.scrollToChildDataPoint('bi', toBeEdited, [], 0, true);
    this.setState({
      toBeEdited: toBeEdited,
      editOptionSelected: true,
      childInEditId: key,
      currentEditIsGroupedData: isGroupedData,
      currentEditParentClause: parentClause,
      currentEditParentClauseId: parentClauseId,
      currentEditParentLabel: parentLabel,
    });
  }

  getClauseData = (clauseType: string) => {
    const { updatedClauseData } = this.props;
    switch (clauseType) {
      case 'contractingParties': {
        return updatedClauseData?.contractingParties?.contracting_parties || [];
      }
      case 'nonCompete': {
        return updatedClauseData?.nonCompete?.non_compete || [];
      }
      case 'changeOfControl': {
        return updatedClauseData?.changeOfControl?.change_of_control || [];
      }
      case 'confidentiality': {
        return updatedClauseData?.confidentiality?.confidentiality || [];
      }
      case 'consent': {
        return updatedClauseData?.consent?.consent || [];
      }
      case 'dataBreach': {
        return updatedClauseData?.dataBreach?.data_breach || [];
      }
      case 'disputeResolution': {
        return updatedClauseData?.disputeResolution?.dispute_resolution || [];
      }
      case 'effectiveDate': {
        return updatedClauseData?.effectiveDate?.effective_date || [];
      }
      case 'eventsOfDefault': {
        return updatedClauseData?.eventsOfDefault?.events_of_default || [];
      }
      case 'exclusivity': {
        return updatedClauseData?.exclusivity?.exclusivity || [];
      }
      case 'forceMajeure': {
        return updatedClauseData?.forceMajeure?.force_majeure || [];
      }
      case 'generalDefinitions': {
        return updatedClauseData?.generalDefinitions?.general_definitions || [];
      }
      case 'governingLaw': {
        return updatedClauseData?.governingLaw?.governing_law || [];
      }
      case 'indemnity': {
        return updatedClauseData?.indemnity?.indemnity || [];
      }
      case 'insurance': {
        return updatedClauseData?.insurance?.insurance || [];
      }
      case 'limitedLiability': {
        return updatedClauseData?.limitedLiability?.limited_liability || [];
      }
      case 'term': {
        return updatedClauseData?.term?.term || [];
      }
      case 'renewal': {
        return updatedClauseData?.renewal?.renewal || [];
      }
      case 'payment': {
        return updatedClauseData?.payment?.payment || [];
      }
      case 'termination': {
        return updatedClauseData?.termination?.termination || [];
      }
      case 'notice': {
        return updatedClauseData?.notice?.notice || [];
      }
      case 'nonSolicitation': {
        return updatedClauseData?.nonSolicitation?.non_solicitation || [];
      }
      case 'subletting': {
        return updatedClauseData?.subletting?.subletting || [];
      }
      case 'terminationAtConvenience': {
        return (
          updatedClauseData?.termination_at_convenience
            ?.termination_at_convenience || []
        );
      }
    }
  };

  isClauseProperty = (clauseType: string) => {
    // eslint-disable-next-line no-prototype-builtins
    return this.props.updatedClauseData?.hasOwnProperty(clauseType);
  };

  OtherClauses(type: string) {
    let clauseData: any[] = [];

    if (this.isClauseProperty('contractingParties')) {
      const data = this.getClauseData('contractingParties');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('nonCompete')) {
      const data = this.getClauseData('nonCompete');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('changeOfControl')) {
      const data = this.getClauseData('changeOfControl');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('confidentiality')) {
      const data = this.getClauseData('confidentiality');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('consent')) {
      const data = this.getClauseData('consent');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('dataBreach')) {
      const data = this.getClauseData('dataBreach');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('disputeResolution')) {
      const data = this.getClauseData('disputeResolution');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('effectiveDate')) {
      const data = this.getClauseData('effectiveDate');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('eventsOfDefault')) {
      const data = this.getClauseData('eventsOfDefault');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('exclusivity')) {
      const data = this.getClauseData('exclusivity');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('forceMejeure')) {
      const data = this.getClauseData('forceMejeure');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('generalDefinitions')) {
      const data = this.getClauseData('generalDefinitions');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('governingLaw')) {
      const data = this.getClauseData('governingLaw');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('indemnity')) {
      const data = this.getClauseData('indemnity');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }

    if (this.isClauseProperty('insurance')) {
      const data = this.getClauseData('insurance');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('limitedLiability')) {
      const data = this.getClauseData('limitedLiability');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('term')) {
      const data = this.getClauseData('term');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('renewal')) {
      const data = this.getClauseData('renewal');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('payment')) {
      const data = this.getClauseData('payment');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('termination')) {
      const data = this.getClauseData('termination');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('notice')) {
      const data = this.getClauseData('notice');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('nonSolicitation')) {
      const data = this.getClauseData('nonSolicitation');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty('subletting')) {
      const data = this.getClauseData('subletting');
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }

    const highlightedPale: any = getParentHighlights(clauseData);

    for (let i = 0; i < highlightedPale.length; i++) {
      const paraHeader = document.getElementById(highlightedPale[i]);
      if (paraHeader) {
        if (type === 'add') {
          paraHeader.style.background = highlight_other_clause;
        } else {
          paraHeader.style.background = 'none';
        }
      }
    }
  }

  setClickedItem(clickedItem: string, type: string, unselectChild: boolean) {
    const {
      highlightedYellow,
      highlightedLilac,
      clickedMenu,
      clickedSubMenu,
      clickedInstanceIndex,
    } = this.state;
    this.OtherClauses('remove');
    if (clickedItem === 'clause') {
      this.setState({ clickedSubMenu: '', clickedInstanceIndex: 0 });
      clickedMenu === type
        ? this.setState({ clickedMenu: '' })
        : this.setState({ clickedMenu: type });
      for (let i = 0; i < highlightedYellow.length; i++) {
        //For clearing highlighted background color
        const clauseHeader = document.getElementById(highlightedYellow[i]);
        if (clauseHeader != null) {
          clauseHeader.style.background = 'none';
        }
      }
      for (let i = 0; i < highlightedLilac.length; i++) {
        for (let j = 0; j < highlightedLilac[i].length; j++) {
          const biHeader = document.getElementById(highlightedLilac[i][j]);
          if (biHeader != null) {
            biHeader.style.background = 'none';
          }
        }
      }
    } else if (clickedItem === 'bi') {
      if (unselectChild) {
        clickedSubMenu === type
          ? this.setState({ clickedSubMenu: '' })
          : this.setState({ clickedSubMenu: type });
      } else {
        clickedInstanceIndex >= 0 && this.setState({ clickedSubMenu: type });
      }
      const index = highlightedYellow.findIndex((el) => {
        return el === highlightedLilac[0];
      });
      if (index !== -1) {
        //When parent and child both highlight same set of datapoints, highlight parent again when child is unclicked.
        for (let i = 0; i < highlightedYellow.length; i++) {
          const paraHeader = document.getElementById(highlightedYellow[i]);
          if (paraHeader != null) {
            paraHeader.style.background = highlight_yellow;
          }
        }
      } else {
        for (let i = 0; i < highlightedLilac.length; i++) {
          for (let j = 0; j < highlightedLilac[i].length; j++) {
            const biHeader = document.getElementById(highlightedLilac[i][j]);
            if (biHeader != null) {
              biHeader.style.background = 'none';
            }
          }
        }
      }
    }
    return clickedItem;
  }

  scrollToDataPoint(insight: string, type: string, dataPoints: string[]) {
    this.props.superImposeChildIndex(0);
    const typeClicked = this.setClickedItem(insight, type, false); //Set clicked menu or submenu tempArray.push('p' + paraId[i]);
    const paraHeader = document.getElementById(dataPoints[0]);
    paraHeader != null && paraHeader.scrollIntoView({ block: 'center' });
    if (dataPoints?.length > 0) {
      this.setState({ highlightedYellow: dataPoints });
      simulateScroll(dataPoints, typeClicked);
      this.props.saveDataPoints(dataPoints, true, 0, 'clause');
      this.props.setCurrentScrollIndex(0);
    } else {
      this.setState({ highlightedYellow: [] });
      this.props.saveDataPoints(dataPoints, false, 0, 'clause');
      this.props.setCurrentScrollIndex(0);
    }
    this.setState({ savedParentDataPoints: dataPoints });
  }

  scrollToChildDataPoint = (
    insight: string,
    type: string,
    children: AuxArrayInterface[],
    index: number,
    unselectChild: boolean
  ) => {
    const { savedParentDataPoints } = this.state;
    const aggregateDataPoints = createChildDataPointsAggregate(children);
    const typeClicked = this.setClickedItem(insight, type, unselectChild);
    let tempParaIndex = -1;
    let tempParaId = '';
    const paraHeader = document.getElementById(savedParentDataPoints[0]);
    paraHeader != null && paraHeader.scrollIntoView({ block: 'center' });
    const aggregateDataArray: string[] = [];
    if (children?.length > 0) {
      let multipleInstances: AuxArrayInterface[] = [];
      multipleInstances =
        type === 'Termination at Convenience' ||
        disputeResolutionModeBi.indexOf(type) > -1
          ? detectMultipleInstances(children)
          : children;
      const paraHeader = document.getElementById(
        multipleInstances[index].dataPoints[index]
      );
      paraHeader != null && paraHeader.scrollIntoView({ block: 'center' });
      this.setState({ highlightedLilac: aggregateDataPoints });
      simulateScroll(aggregateDataPoints, typeClicked);

      //For making scroll button act according to the child data point index in parent array. //Experimental
      tempParaId = children[index].dataPoints[0];
      for (let i = 0; i < aggregateDataPoints?.length; i++) {
        aggregateDataArray.push(aggregateDataPoints[i][0]);
      }
      tempParaIndex = aggregateDataArray.findIndex((el) => {
        return el === tempParaId;
      });
    }
    this.props.saveDataPoints(
      aggregateDataArray.length === 0
        ? savedParentDataPoints
        : aggregateDataArray,
      true,
      tempParaIndex !== -1 ? tempParaIndex : 0,
      aggregateDataArray.length === 0 ? 'clause' : 'bi'
    );
    if (tempParaId != null && !(tempParaId === '')) {
      const paraHeader = document.getElementById(tempParaId);
      paraHeader != null && paraHeader.scrollIntoView({ block: 'center' });
    }
  };
}

const notifySuccess = () =>
  enqueueSnackbar('Updated Successfully', {
    variant: 'success',
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });

const notifyError = () =>
  enqueueSnackbar('Oops! Something went wrong...', {
    variant: 'error',
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });
