export const deleteClauseParaLevel = (clause: any, item: any) => {
  const filterColumns = clause.filter((data: any) => {
    const table = item?.table?.[0];
    if (table && table?.row > -1 && table?.column > -1) {
      return (
        data.para_id !== item.para_id ||
        data?.table?.[0]?.row !== table?.row ||
        data?.table?.[0]?.column !== table?.column
      );
    } else {
      return data.para_id !== item.para_id;
    }
  });
  return filterColumns;
};

export const deleteClauseSentenceLevel = (clause: any, item: any) => {
  const filterColumns = clause.filter((data: any) => {
    const table = item?.table?.[0];
    const data_sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id
          ? data.start_sentence_id
          : -1;
    const item_sentence_id =
      item.sentence_id > -1
        ? item.sentence_id
        : item.start_sentence_id
          ? item.start_sentence_id
          : -1;
    if (table && table?.row > -1 && table?.column > -1) {
      return (
        data.para_id !== item.para_id ||
        data_sentence_id !== item_sentence_id ||
        data?.table?.[0]?.row !== table?.row ||
        data?.table?.[0]?.column !== table?.column
      );
    } else {
      return (
        data.para_id !== item.para_id || data_sentence_id !== item_sentence_id
      );
    }
  });
  return filterColumns;
};

export const deleteClausePhraseLevel = (clause: any, item: any) => {
  const filterColumns = clause.filter((data: any) => {
    const table = item?.table?.[0];
    const data_sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id
          ? data.start_sentence_id
          : -1;
    const item_sentence_id =
      item.sentence_id > -1
        ? item.sentence_id
        : item.start_sentence_id
          ? item.start_sentence_id
          : -1;
    if (table && table?.row > -1 && table?.column > -1) {
      return (
        data.para_id !== item.para_id ||
        data_sentence_id !== item_sentence_id ||
        data.start_word_id !== item.start_word_id ||
        data?.table?.[0]?.row !== table?.row ||
        data?.table?.[0]?.column !== table?.column
      );
    } else {
      return (
        data.para_id !== item.para_id ||
        data_sentence_id !== item_sentence_id ||
        data.start_word_id !== item.start_word_id
      );
    }
  });
  return filterColumns;
};
