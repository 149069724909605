import { authApi } from '../../Services/authApi';

export default class DocumentAPI {
  public static getSentences(url: string) {
    return authApi.get(url);
  }
  public static getInsights(url: string) {
    return authApi.get(url);
  }
  public static getDurationTypes(url: string) {
    return authApi.get(url);
  }
  public static getCurrencyTypes(url: string) {
    return authApi.get(url);
  }
}
