import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import ViewDocumentDrawer from '../../../DocumentLibrary/Component/TableComponents/ViewDocumentDrawer';

interface Props {
  fileName: string;
  fileId: string;
  amendment: any[];
  contractId: string;
  downloadFile: any;
}

const OriginalDocuments: React.FC<Props> = ({
  fileName,
  fileId,
  amendment,
  downloadFile,
  contractId,
}) => {
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);

  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
    setViewDocumentId('');
  };

  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Original Documents
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2} padding="0 10px">
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={600}>{fileName}</Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <Chip label="Master" style={{ background: '#FFECF1' }} />
                <IconButton onClick={() => downloadFile(fileId)}>
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpenViewDrawer(true);
                    setViewDocumentId(contractId);
                  }}
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Stack>
            </Stack>
            {amendment?.map((item: any) => (
              <Stack
                key={item?.id}
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography fontWeight={600}>{item?.file_name}</Typography>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Chip label="Amendment" style={{ background: '#FFECF1' }} />
                  <IconButton onClick={() => downloadFile(item?.id)}>
                    <FileDownloadOutlinedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setOpenViewDrawer(true);
                      setViewDocumentId(item?.id);
                    }}
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
    </React.Fragment>
  );
};

export default OriginalDocuments;
