export const UPLOADING = 'Uploading';
export const UPLOADED = 'Uploaded';
export const FAILED = 'Failed';
export const PROCESSING = 'Processing';
export const DONE = 'Done';
export const defaultPageSize = 15;

export interface contractType {
  contractType: string;
  displayName: string;
}

export const Blank_Document_link = {
  TEMPLATE_BLANK_DOC:
    'https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/requisition_forms/requisition_approval_form_template.docx',
  SUPPORT_BLANK_DOC:
    'https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/blank_docs/support+document%2Ccontract+party+draft+-+templates.docx',
  NOTES_BLANK_DOC: '/blank_docs/notes_document.docx',
  STAMP_EXCEL: '/stamp/Stamp_Bulk_Upload.xlsx',
};
