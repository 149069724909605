import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import ControlledTextField from '../../../../../RiverusUI/Components/ControlledTextField';
import SaveOrCancel from '../saveOrCancel';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedTitle: string;
  saveHighlightedId: (highlightedId: number[] | null) => void;
}

const Comment: React.FC<Props> = (props) => {
  const [title, setTitle] = useState(props.savedTitle);

  const { control } = useForm();

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Comment
      </Typography>

      <Stack direction="column" spacing={2}>
        <ControlledTextField
          name="comment"
          type="text"
          value={title}
          control={control}
          label="Comment"
          onChange={(e) => setTitle(e.currentTarget.value)}
          fullWidth
        />

        <SaveOrCancel
          enableHighlightOption={true}
          dataPointName={'Title'}
          editOptionSelected={props.editOptionSelected}
          highlightedId={null}
          enableSaveBtn={true}
          saveHighlightedId={(highlightedId: number[] | null) =>
            props.saveHighlightedId(highlightedId)
          }
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleSubmitSelectedUserGroups={() => {}}
        />
      </Stack>
    </Box>
  );
};

export default Comment;
