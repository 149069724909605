export const DurationList = [
  {
    durationTypeId: 1,
    durationName: 'Days',
    durationType: 'day',
  },
  {
    durationTypeId: 2,
    durationName: 'Months',
    durationType: 'month',
  },
  {
    durationTypeId: 3,
    durationName: 'Years',
    durationType: 'year',
  },
];

export const yesNoOptions = [
  {
    value: 'True',
    title: 'Yes',
  },
  {
    value: 'False',
    title: 'No',
  },
];

export const scrollContainerSx = {
  maxHeight: '420px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e3e3e3',
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#996c84',
    borderRadius: '6px',
  },
};

export const filterHeaderStyle = {
  fontWeight: 'bold',
  backgroundColor: '#88305F14',
  padding: '10px',
  borderBottom: '1px solid gray',
};
