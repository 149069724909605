import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import ClauseLibrary from '../../../Approvals/Component/ClauseLibrary/ClauseLibrary';

const ClauseLibraryTab: React.FC = () => {
  const [selectedClause, setSelectedClause] = useState<string>('');
  const handleSelectClause = (value: any) => {
    setSelectedClause(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'riTertiary.50',
        height: '85vh',
        overflowY: 'scroll',
        marginY: '15px',
        paddingY: '15px',
        borderRadius: '5px',
      }}
    >
      <Stack spacing={2} paddingX="15px">
        <Stack spacing={1}>
          <Typography fontWeight={700}>How to replace clause?</Typography>
          <Typography fontSize="15px">
            Search for your desired clause below and copy to your clipboard
            using copy icon on the right. Now you can paste the clause in the
          </Typography>
        </Stack>
        <ClauseLibrary
          selectedClause={selectedClause}
          handleSelectedValue={(value) => handleSelectClause(value)}
          isClauseLibraryTab={true}
        />
      </Stack>
    </Box>
  );
};

export default ClauseLibraryTab;
