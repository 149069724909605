import React, { useState } from 'react';

import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import UploadStampDialog from './UploadStampDialog';
import ViewDocumentDrawer from '../DocumentLibrary/Component/TableComponents/ViewDocumentDrawer';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import { upload_file_in_s3_bucket } from '../Services/Draft';
import {
  deleteStamp,
  fetchStampById,
  fetchStampFileName,
  stampFileUpload,
  updateStampPaper,
} from '../Services/Stamp';

const SparkMD5 = require('spark-md5');

interface Props {
  data: any;
  isLoading: boolean;
  showAction?: boolean;
}

const StampTable = (props: Props) => {
  const { data, isLoading, showAction = true } = props;

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [stampRowId, setStampRowId] = useState<any>({});
  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [stampData, setStampData] = useState<any>({});
  const [stampFileName, setStampFileName] = useState<any>(null);

  const handleCloseViewDrawer = () => {
    setViewDocumentId('');
    setOpenViewDrawer(false);
  };

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: deleteStampMutation } = useMutation({
    mutationKey: ['delete-stamp'],
    mutationFn: deleteStamp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-by-state'] });
      enqueueSnackbar({
        message: 'Stamp deleted successfully!',
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteModal();
    },
  });

  const { mutate: fetchStampByIdMutation } = useMutation({
    mutationKey: ['fetch-stamp-by-id'],
    mutationFn: fetchStampById,
    onSuccess: (response: any) => {
      setViewDocumentId(response?.uri);
    },
  });

  const { mutate: updateStampPaperMutation } = useMutation({
    mutationKey: ['update_stamp_paper'],
    mutationFn: updateStampPaper,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-by-state'] });
      if (!showAction) {
        queryClient.invalidateQueries({ queryKey: ['draft-stamps'] });
      }
    },
  });

  const { mutate: stampFileUploadMutation } = useMutation({
    mutationKey: ['upload-stamp-file'],
    mutationFn: stampFileUpload,
    onSuccess: (response: any) => {
      const presignedUrl = response?.presigned_url;
      if (presignedUrl) {
        if (selectedFile) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: presignedUrl,
            file: selectedFile,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
        enqueueSnackbar('Stamp paper uploaded successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload Stamp paper!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadFileInS3Bucket, isPending: s3BucketLoading } =
    useMutation({
      mutationKey: ['upload_draft_to_s3'],
      mutationFn: upload_file_in_s3_bucket,
      onSuccess: (response: any) => {
        const { url, fields } = response;
        const payload = {
          id: stampRowId,
          body: {
            link: `${url}${fields?.key}`,
          },
        };
        updateStampPaperMutation(payload);
      },
      onError: () => {
        enqueueSnackbar('Failed to upload document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: stampFileNameMutation } = useMutation({
    mutationKey: ['stamp-file-name'],
    mutationFn: fetchStampFileName,
    onSuccess: (response: any) => {
      setStampFileName(response);
      handleCloseDeleteModal();
    },
  });

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = selectedFile;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [selectedFile]
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setStampRowId(id);
    const file = event.target.files?.[0];
    setSelectedFile(file || null);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const hexHash = SparkMD5.ArrayBuffer.hash(arrayBuffer);
        const payload = {
          file_name: file.name,
          file_hash: hexHash,
        };
        stampFileUploadMutation(payload);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleViewDocument = (id: string) => {
    fetchStampByIdMutation(id);
  };

  const handleDecline = async () => {
    const payload = {
      id: deleteDocId,
      body: {
        status: 'UNUSED',
        draft: null,
        used_on: null,
      },
    };
    await updateStampPaperMutation(payload);
    enqueueSnackbar('Stamp deleted successfully.', {
      variant: 'success',
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    });
    handleCloseDeleteModal();
  };

  const handleCloseUploadDialog = () => {
    setStampData({});
    setOpenUploadDialog(false);
  };

  const handleOpenUploadDialog = (stamp: any) => {
    stampFileNameMutation(stamp?.id);
    setStampData(stamp);
    setOpenUploadDialog(true);
  };

  const columns = React.useMemo<GridColDef<any>[]>(() => {
    let col = [
      {
        field: 'serial_number',
        headerName: 'Serial Number',
        minWidth: 80,
        flex: 1,
        sortable: false,
      },
      {
        field: 'denomination',
        headerName: 'Value',
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 80,
        flex: 1,
        sortable: false,
      },
      {
        field: 'contractName',
        headerName: 'Assigned draft',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => (
          <Typography>{params?.row?.draft?.contractName || '-'}</Typography>
        ),
      },
      {
        field: 'used_on',
        headerName: 'Used On',
        minWidth: 80,
        flex: 1,
        sortable: false,
        renderCell: (params: any) => (
          <Typography>{params?.row?.used_on || '-'}</Typography>
        ),
      },
      {
        field: 'id',
        headerName: 'Action',
        minWidth: 125,
        sortable: false,
        renderCell: (params: any) => {
          return (
            <Stack direction="row" width="100%" justifyContent="end">
              {showAction ? (
                <>
                  {!params?.row?.link ? (
                    <Tooltip placement="bottom" title="Upload Stamp Paper">
                      <IconButton component="label">
                        <FileUploadOutlinedIcon fontSize="small" />
                        <input
                          type="file"
                          hidden
                          accept="application/pdf"
                          onChange={(event) =>
                            handleFileChange(event, params?.row?.id)
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <>
                      <Tooltip
                        placement="bottom"
                        title="View attached stamp paper"
                      >
                        <IconButton
                          onClick={() => {
                            setOpenViewDrawer(true);
                            handleViewDocument(params?.row?.id);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="bottom"
                        title="Replace or delete attached stamp paper"
                      >
                        <IconButton
                          onClick={() => handleOpenUploadDialog(params?.row)}
                          disabled={
                            params?.row?.status === 'USED' ||
                            params?.row?.status === 'ASSIGNED'
                          }
                        >
                          <AttachmentIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}

                  <Tooltip placement="bottom" title="Delete stamp paper entry">
                    <IconButton
                      onClick={() => handleOpenDeleteModal(params.id)}
                      disabled={params?.row?.used_on}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip placement="bottom" title="Delete attached stamp paper">
                  <IconButton
                    onClick={() => handleOpenDeleteModal(params?.row?.id)}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    ];
    if (!showAction) {
      col = col.filter((data: any) => data.headerName !== 'Assigned draft');
    }
    return col;
  }, [deleteStampMutation, showAction]);

  const getHeight = () => {
    const length = data?.results.length;
    switch (length) {
      case 1:
        return 130;
      case 2:
        return 180;
      case 3:
        return 250;
      case 4:
        return 300;
      default:
        return 350;
    }
  };

  if (data?.results.length) {
    return (
      <Box sx={{ height: getHeight(), width: '100%' }}>
        <DataGrid
          rows={data?.results || []}
          columns={columns}
          disableRowSelectionOnClick
          rowCount={data?.count || 0}
          loading={isLoading}
          disableColumnMenu
          hideFooter
          slots={{
            loadingOverlay: LinearProgress,
          }}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'riPrimary.10',
              fontSize: 16,
              borderRadius: '50px',
              borderBottom: 'none',
            },
          }}
        />
        {openUploadDialog && (
          <UploadStampDialog
            open={openUploadDialog}
            handleCloseModal={handleCloseUploadDialog}
            stampData={stampData}
            stampFileName={stampFileName}
            s3BucketLoading={s3BucketLoading}
            handleFileChange={handleFileChange}
            selectedFile={selectedFile}
          />
        )}
        {openViewDrawer && (
          <ViewDocumentDrawer
            open={openViewDrawer}
            onClose={handleCloseViewDrawer}
            documentLink={viewDocumentId}
          />
        )}
        {openDeleteModal && (
          <ReusableConfirmationModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            title="Delete Stamp Paper Entry"
            cancelBtnText="No, Go Back"
            confirmBtnText="Yes, Delete"
            onConfirm={() =>
              showAction ? deleteStampMutation(deleteDocId) : handleDecline()
            }
          >
            <Stack spacing={2}>
              <Typography>Are you sure?</Typography>
              <Typography>
                The selected stamp paper entry will be permanently deleted.
              </Typography>
              <Typography>Note: This action is not reversible.</Typography>
            </Stack>
          </ReusableConfirmationModal>
        )}
      </Box>
    );
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ color: '#938F99' }}
    >
      <InfoOutlinedIcon />
      <Typography margin={0}>No stamp-papers available</Typography>
    </Stack>
  );
};

export default StampTable;
