import React, { MouseEvent, useState } from 'react';

import { Tooltip } from '@mui/material';

interface IProps {
  value: string;
}

const OverflowTip: React.FC<IProps> = ({ value }) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const hideTooltip = () => setTooltipEnabled(false);

  return (
    <Tooltip
      title={value}
      onMouseEnter={handleShouldShow}
      onMouseLeave={hideTooltip}
      disableHoverListener={!tooltipEnabled}
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
