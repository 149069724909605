import React, { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';

import { IObject } from '../../../interfaces/IObject';
import PopoverPopupState from '../../../RiverusUI/Components/Popups/PopoverPopupState';

interface Props {
  list: any[];
  id: string;
  title?: string;
  filters?: IObject;
}

const ContractingPartiesList: React.FC<Props> = ({
  list,
  id,
  title,
  filters,
}) => {
  const contracting_parties = useMemo(() => {
    let raw_content = null;
    let edited_content = null;

    if (list?.[0] != null) {
      raw_content = list?.[0]?.raw_content;
      edited_content = list?.[0]?.edited_content;
    }
    if (raw_content == null) {
      raw_content = {
        contracting_parties: [],
      };
    }
    if (edited_content == null) {
      edited_content = {
        update: [],
      };
    }
    const diff = changesets.unflattenChanges(edited_content.update);
    const changeList = changesets.applyChangeset(raw_content, diff);
    const selectedFilters: any = filters?.contracting_parties;
    let reOrderContractingParties = changeList?.contracting_parties || [];
    if (selectedFilters) {
      const filterSelectedContractingParties =
        reOrderContractingParties?.filter((item: any) =>
          item?.party?.toLowerCase()?.includes(selectedFilters?.toLowerCase())
        );
      const filterNotSelectedContractingParties =
        reOrderContractingParties?.filter(
          (item: any) =>
            !item?.party
              ?.toLowerCase()
              ?.includes(selectedFilters?.toLowerCase())
        );
      reOrderContractingParties = [
        ...filterSelectedContractingParties,
        ...filterNotSelectedContractingParties,
      ];
    }
    return reOrderContractingParties;
  }, [list]);

  return (
    <Stack direction="row" spacing={0.25} sx={{ alignItems: 'center' }}>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '150px',
        }}
      >
        {contracting_parties?.[0]?.partyName
          ? contracting_parties?.[0]?.partyName
          : contracting_parties?.[0]?.party}
      </Box>
      {contracting_parties?.length > 1 && (
        <PopoverPopupState
          id={id}
          renderIcon={() => (
            <Typography
              sx={{ fontSize: '12px', color: 'riNeutralVariant.700' }}
            >
              {contracting_parties?.length - 1}+
            </Typography>
          )}
        >
          <Stack spacing={2} direction="column" width="100%">
            {title && (
              <Typography fontSize="15px" fontWeight={600}>
                {title}
              </Typography>
            )}
            {contracting_parties?.map((item: any, index: number) => (
              <Typography key={index} fontSize="14px">
                {index + 1}). {item?.partyName ? item?.partyName : item?.party}
              </Typography>
            ))}
          </Stack>
        </PopoverPopupState>
      )}
    </Stack>
  );
};

export default ContractingPartiesList;
