import {
  ElementType,
  forwardRef,
  ReactNode,
  Ref,
  RefAttributes,
  SyntheticEvent,
} from 'react';
import React from 'react';

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteValue,
  Box,
  Button,
  Checkbox,
  ChipTypeMap,
  createFilterOptions,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useForkRef,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { useFormError } from './FormErrorProvider';
import { useTransform } from './useTransform';
import { propertyExists } from './utils';

type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};

const filter = createFilterOptions();

export type AutocompleteElementProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = AutoDefault | string | any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = {
  name: TName;
  control?: Control<TFieldValues>;
  options: TValue[];
  loading?: boolean;
  multiple?: Multiple;
  loadingIndicator?: ReactNode;
  rules?: UseControllerProps<TFieldValues, TName>['rules'];
  parseError?: (error: FieldError) => ReactNode;
  required?: boolean;
  label?: TextFieldProps['label'];
  showCheckbox?: boolean;
  matchId?: boolean;
  autocompleteProps?: Omit<
    AutocompleteProps<
      TValue,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'name' | 'options' | 'loading' | 'renderInput'
  >;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
  transform?: {
    input?: (
      value: PathValue<TFieldValues, TName>
    ) => AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>;
    output?: (
      event: SyntheticEvent,
      value: AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<TValue>
    ) => PathValue<TFieldValues, TName>;
  };
  value?: any;
  labelKey?: string;
  valueKey?: string;
  countKey?: string;
  fixedValues?: any;
  readOnly?: boolean;
  canCreateNew?: boolean;
  canSelectAll?: boolean;
  addNewValue?: (value: string) => void;
  CustomInput?: React.ElementType;
  disableCloseOnSelect?: boolean;
  renderProgress?: (value: number) => React.ReactElement;
  renderAction?: (value: string | any) => React.ReactElement;
  isGroupByOptions?: boolean;
  groupValue?: string;
  onChange?: (
    event: any,
    newValue: AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TValue>
  ) => void;
};

type AutocompleteElementComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = AutoDefault | string | any, // Maybe we can change it to 'unknown' type for more strict type checking
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteElementProps<
    TFieldValues,
    TName,
    TValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  > &
    RefAttributes<HTMLDivElement>
) => JSX.Element;

const AutocompleteElement = forwardRef(function AutocompleteElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = AutoDefault | string | any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteElementProps<
    TFieldValues,
    TName,
    TValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  ref: Ref<HTMLDivElement>
) {
  const {
    textFieldProps,
    autocompleteProps,
    name,
    control,
    options,
    loading,
    showCheckbox,
    rules,
    loadingIndicator,
    required,
    multiple,
    label,
    parseError,
    transform,
    matchId,
    readOnly = false,
    labelKey = 'name',
    valueKey = 'id',
    countKey = 'count',
    canCreateNew = false,
    addNewValue,
    CustomInput,
    renderProgress,
    renderAction,
    canSelectAll,
    onChange,
    isGroupByOptions = false,
    groupValue = 'assignee_type',
  } = props;

  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;

  const validationRules = {
    ...rules,
    ...(required && {
      required: rules?.required || 'This field is required',
    }),
  };

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    disabled: autocompleteProps?.disabled,
    rules: validationRules,
  });

  const getOptionLabel = (
    option: TValue | AutocompleteFreeSoloValueMapping<FreeSolo>
  ): string => {
    if (typeof autocompleteProps?.getOptionLabel === 'function') {
      return autocompleteProps.getOptionLabel(option);
    }
    if (propertyExists(option, labelKey)) {
      return `${option[labelKey]}`;
    }
    return `${option}`;
  };

  const isOptionEqualToValue = (option: TValue, value: TValue): boolean => {
    if (typeof autocompleteProps?.isOptionEqualToValue == 'function') {
      return autocompleteProps.isOptionEqualToValue(option, value);
    }
    const optionKey = propertyExists(option, valueKey)
      ? option[valueKey]
      : option;
    const newValueKey = propertyExists(value, valueKey)
      ? value[valueKey]
      : value;
    return optionKey === newValueKey;
  };

  const matchOptionByValue = (currentValue: TValue) => {
    return options?.find((option) => {
      if (matchId && propertyExists(option, valueKey)) {
        return option?.[valueKey] === currentValue;
      }
      return isOptionEqualToValue(option, currentValue);
    });
  };

  const { value, onChange: transformedOnChange } = useTransform<
    TFieldValues,
    TName,
    AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>
  >({
    value: field.value,
    onChange: field.onChange,
    transform: {
      input:
        typeof transform?.input === 'function'
          ? transform.input
          : (newValue) => {
              return (
                multiple
                  ? (Array.isArray(newValue) ? newValue : []).map(
                      matchOptionByValue
                    )
                  : (matchOptionByValue(newValue) ?? null)
              ) as AutocompleteValue<
                TValue,
                Multiple,
                DisableClearable,
                FreeSolo
              >;
            },
      output:
        typeof transform?.output === 'function'
          ? transform.output
          : (
              _event: SyntheticEvent,
              newValue: AutocompleteValue<
                TValue,
                Multiple,
                DisableClearable,
                FreeSolo
              >
            ) => {
              if (multiple) {
                const newValues = Array.isArray(newValue) ? newValue : [];
                return (
                  matchId
                    ? newValues.map((currentValue) =>
                        propertyExists(currentValue, valueKey)
                          ? currentValue[valueKey]
                          : currentValue
                      )
                    : newValues
                ) as PathValue<TFieldValues, TName>;
              }
              return (
                matchId && propertyExists(newValue, valueKey)
                  ? newValue[valueKey]
                  : newValue
              ) as PathValue<TFieldValues, TName>;
            },
    },
  });

  const handleInputRef = useForkRef(field.ref, textFieldProps?.inputRef);

  const loadingElement = loadingIndicator || (
    <CircularProgress color="inherit" size={20} />
  );

  const handleSelectAll = (event: SyntheticEvent, newValue: boolean) => {
    const allOptions = options?.map((option) =>
      propertyExists(option, valueKey) ? option[valueKey] : option
    );

    const hasSelection = Array.isArray(value) && value.length > 0;
    const updatedValue: AutocompleteValue<
      TValue,
      Multiple,
      DisableClearable,
      FreeSolo
    > = hasSelection
      ? ([] as AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>)
      : (allOptions as AutocompleteValue<
          TValue,
          Multiple,
          DisableClearable,
          FreeSolo
        >);

    const reason: AutocompleteChangeReason = newValue
      ? 'selectOption'
      : 'removeOption';

    transformedOnChange(event, updatedValue, reason);
    if (typeof onChange === 'function') {
      onChange(event, updatedValue, reason);
    }
  };

  const isSelectAllChecked =
    options?.length > 0 &&
    options?.every((option) => {
      if (Array.isArray(value)) {
        return (value as TValue[]).some((val) =>
          isOptionEqualToValue(option, val)
        );
      } else {
        return isOptionEqualToValue(option, value as TValue);
      }
    });

  const isSelectAllIndeterminate =
    options?.length > 0 &&
    options?.some((option) => {
      if (Array.isArray(value)) {
        return (value as TValue[]).some((val) =>
          isOptionEqualToValue(option, val)
        );
      } else {
        return isOptionEqualToValue(option, value as TValue);
      }
    }) &&
    !isSelectAllChecked;

  const selectAllOption = {
    isSelectAll: true,
  };

  const combinedOptions = canSelectAll
    ? [selectAllOption, ...options]
    : options;

  return (
    <Autocomplete
      readOnly={readOnly}
      {...autocompleteProps}
      value={value}
      loading={loading}
      multiple={multiple}
      options={combinedOptions as TValue[]}
      groupBy={
        isGroupByOptions ? (option: any) => option?.[groupValue] : undefined
      }
      renderGroup={
        isGroupByOptions
          ? (params) => (
              <li key={params.key}>
                <Typography
                  color="#88305F"
                  sx={{
                    background: '#FFECF1',
                    p: '8px 12px',
                    textTransform: 'capitalize',
                  }}
                >
                  {params.group}
                </Typography>
                <Typography>{params.children}</Typography>
              </li>
            )
          : undefined
      }
      disableCloseOnSelect={
        typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
          ? autocompleteProps.disableCloseOnSelect
          : !!multiple
      }
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      onChange={(event, newValue, reason, details) => {
        if (Array.isArray(newValue)) {
          if (newValue.some((option: any) => option?.isSelectAll)) {
            handleSelectAll(
              event,
              newValue.some((option: any) => option.isSelectAll)
            );
            return;
          }
        }

        transformedOnChange(event, newValue, reason, details);
        if (typeof onChange === 'function') {
          onChange(event, newValue, reason, details);
        }
        if (typeof autocompleteProps?.onChange === 'function') {
          autocompleteProps.onChange(event, newValue, reason, details);
        }
      }}
      ref={ref}
      filterOptions={(options, params) => {
        const newParams: any = {
          ...params,
          inputValue: params.inputValue.trim(),
        };
        const filtered: any = filter(options, newParams);
        if (params.inputValue !== '') {
          canCreateNew &&
            filtered.unshift({
              newValue: newParams.inputValue,
            });
        }
        return filtered;
      }}
      renderOption={
        autocompleteProps?.renderOption ??
        (showCheckbox
          ? (props, option: any, { selected }) => (
              <Box
                key={
                  propertyExists(option, valueKey) ? option[valueKey] : option
                }
              >
                {option.isSelectAll ? (
                  <Box padding="4px 16px">
                    <Checkbox
                      sx={{ marginRight: 1 }}
                      checked={isSelectAllChecked}
                      indeterminate={isSelectAllIndeterminate}
                      onChange={(e) => handleSelectAll(e, e.target.checked)}
                    />
                    Select All
                  </Box>
                ) : option.newValue ? (
                  <Button
                    key="create-button"
                    variant="text"
                    onClick={() => addNewValue?.(option.newValue)}
                  >
                    Create new +
                  </Button>
                ) : (
                  <Stack
                    component="li"
                    {...props}
                    key={`label-${getOptionLabel(option)}`}
                  >
                    <Stack
                      direction="row"
                      width="100%"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                        {getOptionLabel(option)}
                        {multiple &&
                          propertyExists(option, countKey) &&
                          ` (${option?.[countKey]})`}
                      </Box>
                      {renderAction?.(option)}
                    </Stack>
                    {multiple &&
                    propertyExists(option, countKey) &&
                    renderProgress ? (
                      <Box width="100%">
                        {renderProgress(option?.[countKey])}
                      </Box>
                    ) : null}
                  </Stack>
                )}
              </Box>
            )
          : undefined)
      }
      onBlur={(event) => {
        field.onBlur();
        if (typeof autocompleteProps?.onBlur === 'function') {
          autocompleteProps.onBlur(event);
        }
      }}
      renderInput={(params) =>
        CustomInput ? (
          <CustomInput {...params} />
        ) : (
          <TextField
            name={name}
            required={rules?.required ? true : required}
            label={label}
            {...textFieldProps}
            {...params}
            error={!!error}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...textFieldProps?.InputLabelProps,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? loadingElement : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              ...textFieldProps?.InputProps,
            }}
            inputProps={{
              ...params.inputProps,
              ...textFieldProps?.inputProps,
            }}
            helperText={
              error
                ? typeof customErrorFn === 'function'
                  ? customErrorFn(error)
                  : error.message
                : textFieldProps?.helperText
            }
            inputRef={handleInputRef}
          />
        )
      }
    />
  );
});

AutocompleteElement.displayName = 'AutocompleteElement';
export default AutocompleteElement as AutocompleteElementComponent;
