import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Stack, Tooltip } from '@mui/material';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import { droppedMsg } from '../Helper';

interface Props {
  contractTypeId: string;
  handleAddCheckList: (payload: any) => void;
  handleSubmit: any;
  control: any;
  loadingAddCheckList: boolean;
  loadingCreateCheckList: boolean;
  isDraftDropped: boolean;
}

const AddNewChecklist: React.FC<Props> = ({
  handleSubmit,
  control,
  handleAddCheckList,
  loadingAddCheckList,
  loadingCreateCheckList,
  isDraftDropped,
}) => {
  const onSubmit = async (data: any) => {
    const payload = {
      checklist_name: data?.name,
      checklist_type: 'draft',
      active_status: false,
    };
    handleAddCheckList(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <ControlledTextField
          name="name"
          control={control}
          required
          label="Item label"
          fullWidth
        />
        <Tooltip
          title={droppedMsg}
          arrow
          disableHoverListener={!isDraftDropped}
        >
          <span>
            <LoadingButton
              loading={loadingAddCheckList || loadingCreateCheckList}
              loadingPosition="start"
              variant="contained"
              startIcon={<AddIcon />}
              type="submit"
              disabled={isDraftDropped}
              fullWidth
            >
              Add item to the checklist
            </LoadingButton>
          </span>
        </Tooltip>
      </Stack>
    </form>
  );
};

export default AddNewChecklist;
