import React, { useEffect, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { editDraftData } from '../../../Services/Draft';
import { sortListAlphabetical } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  draftData: any;
  templateList: any;
  loadingTemplateList: any;
  versionHistory: any;
}

const LinkTemplateDialog: React.FC<Props> = ({
  open,
  onClose,
  draftData,
  templateList,
  loadingTemplateList,
  versionHistory,
}) => {
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const template = watch('template');

  const { mutate: updateDraftData } = useMutation({
    mutationKey: ['edit_draft_data'],
    mutationFn: editDraftData,
    onSuccess: () => {
      enqueueSnackbar('Successfully updated the draft!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['version_history', draftData?.draftID || draftData?.id],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update the draft!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const templateUrl = useMemo(() => {
    return templateList
      ?.filter((templateItem: any) => templateItem?.id === template)
      ?.map((templateItem: any) => templateItem?.file_path);
  }, [templateList, template]);

  const templateId = useMemo(() => {
    const templateData = templateList
      ?.filter(
        (templateItem: any) =>
          templateItem?.file_path === versionHistory?.[0]?.template_link
      )
      ?.map((templateItem: any) => templateItem?.id);

    return templateData?.[0];
  }, [templateList, versionHistory]);

  useEffect(() => {
    setValue('template', templateId ? templateId : '');
  }, [templateId, setValue]);

  const handleLinkTemplateToDraft = () => {
    if (template) {
      const draftPayload = {
        id: draftData?.id,
        body: {
          template_link: templateUrl[0],
          link: templateUrl[0],
          status: 'Draft Creation Pending',
        },
      };
      updateDraftData(draftPayload);
    }
  };

  const handleUnlinkTemplate = () => {
    const draftPayload = {
      id: draftData?.id,
      body: {
        template_link: '',
        link: draftData?.access_url,
        status: 'Draft Upload Pending',
      },
    };
    updateDraftData(draftPayload);
  };

  const processedOptions = useMemo(() => {
    return templateList?.map((option: any) => ({
      ...option,
      name: option?.name || option?.file_name,
    }));
  }, [templateList]);

  return (
    <CustomModal
      title={
        versionHistory?.[0]?.template_link
          ? 'Replace / Unlink a template'
          : 'Link a template'
      }
      open={open}
      handleClose={onClose}
    >
      <Stack margin="20px 15px" spacing={2}>
        <RISelectComponent
          label="Select Template"
          name="template"
          control={control}
          options={sortListAlphabetical(processedOptions) || []}
          labelKey="name"
          valueKey="id"
          loading={loadingTemplateList}
        />
        <Stack direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loadingPosition="start"
            variant="contained"
            onClick={handleLinkTemplateToDraft}
          >
            {versionHistory?.[0]?.template_link ? 'Replace' : 'Confirm'}
          </LoadingButton>
          {template && versionHistory?.[0]?.template_link && (
            <Button variant="outlined" onClick={handleUnlinkTemplate}>
              Unlink
            </Button>
          )}
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default LinkTemplateDialog;
