import React, { useEffect, useState } from 'react';

import DrawIcon from '@mui/icons-material/Draw';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { getSignatureStatus } from '../../../Services/Draft';

interface Props {
  row: any;
}

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <MailOutlineIcon />,
    2: <VisibilityIcon />,
    3: <DrawIcon />,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: '50%',
        color: active || completed ? 'white' : 'black',
        backgroundColor: active || completed ? '#6D264C' : '#e0e0e0',
      }}
    >
      {icons[String(icon)]}
    </Box>
  );
};

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#6D264C',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#6D264C',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#e0e0e0',
    borderTopWidth: 3,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const SignatoryDrawer: React.FC<Props> = ({ row }) => {
  const [signatories, setSignatories] = useState<any[]>([]);

  const steps = ['Mailed', 'Viewed', 'Signed'];

  const { data: signatoriesStatus, isLoading } = useQuery({
    queryKey: ['signatories_status', row.id],
    queryFn: () => getSignatureStatus(row.id),
    select: (response: any) => response.results,
  });

  useEffect(() => {
    const statusCode = (status: any) => {
      if (status === 'Delivered') {
        return 0;
      } else if (status === 'Viewed') {
        return 1;
      } else if (status === 'Signature Signed') {
        return 2;
      } else {
        return -1;
      }
    };
    const updatedSignatories = row?.signatories?.map((signatory: any) => {
      const signatoryData = signatoriesStatus?.find(
        (item: any) => item?.to_address === signatory?.email
      );
      return {
        ...signatory,
        status: statusCode(signatoryData?.status),
      };
    });

    setSignatories(updatedSignatories);
  }, [row?.signatories, signatoriesStatus]);

  return (
    <Stack mx={2} spacing={2}>
      <Typography variant="body1">People with Signature access</Typography>
      {isLoading ? (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      ) : (
        signatories?.map((signatory: any, index: number) => (
          <Stack
            key={`signatory_details_${index}`}
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              border: '1px solid #6D264C',
              padding: '20px',
            }}
          >
            <Stack>
              <Typography variant="body2" textTransform="capitalize">
                {signatory?.first_name === signatory?.last_name
                  ? signatory?.first_name
                  : `${signatory?.first_name} ${signatory?.last_name}`}
              </Typography>
              <Typography variant="body2">{signatory?.email}</Typography>
            </Stack>
            <Box sx={{ width: '350px' }}>
              <Stepper
                activeStep={signatory?.status}
                alternativeLabel
                connector={<CustomConnector />}
              >
                {steps.map((step: string) => (
                  <Step key={step}>
                    <StepLabel StepIconComponent={CustomStepIcon}>
                      {step}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default SignatoryDrawer;
