import React, { useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import ViewSupportDoc from './ViewSupportDoc';
import { fetchExternalSupportDocsDownloadUrl } from '../../../ExternalUserFlow/Services/Draft';
import DrawerComponent from '../../../RiverusUI/Components/DrawerComponent';
import { fetchSupportDocsDownloadUrl } from '../../../Services/Draft';
import { download_file } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  supportDocs: any[];
  isExternal?: boolean;
}

const SupportDocsDrawer: React.FC<Props> = ({
  open,
  onClose,
  supportDocs,
  isExternal,
}) => {
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [viewDocumentId, setViewDocumentId] = useState<string>('');

  const handleCloseViewDrawer = () => {
    setViewDocumentId('');
    setOpenViewDrawer(false);
  };

  const { mutate: downloadFile } = useMutation({
    mutationFn: isExternal
      ? fetchExternalSupportDocsDownloadUrl
      : fetchSupportDocsDownloadUrl,
    onSuccess: (response) => {
      download_file(response);
    },
  });

  return (
    <React.Fragment>
      <DrawerComponent
        open={open}
        onClose={onClose}
        drawerHeader="Connected Support Documents"
      >
        <Stack direction="column" spacing={1} margin="20px 15px" width="400px">
          {supportDocs?.length === 0 && (
            <Typography>No support documents</Typography>
          )}
          {supportDocs?.map((item: any, index: number) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" spacing={1}>
                <Typography>{index + 1}).</Typography>
                <Typography>{item?.file_name}</Typography>
              </Stack>
              <Stack direction="row">
                <IconButton onClick={() => downloadFile(item?.id)}>
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpenViewDrawer(true);
                    setViewDocumentId(item?.id);
                  }}
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </DrawerComponent>
      {openViewDrawer && (
        <ViewSupportDoc
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
          isExternal={isExternal}
        />
      )}
    </React.Fragment>
  );
};

export default SupportDocsDrawer;
