import React, { useEffect, useRef, useState } from 'react';

import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import FilterInput from './FilterInput';
import ReminderColumn from './ReminderColumn';
import { appOption } from './ReminderStaticData';
import CustomChip from '../Approvals/Component/CreateApprovalForm/CustomChip';
import { getKeyCloakRealmFromLS } from '../Authentication/Actions/authentication';
import { reminderStatus } from '../DocumentView/Component/Reminders/ReminderStatic';
import { sortListAlphabetical } from '../Draft/Component/Helper';
import { fetchCollaborators } from '../Services/Draft';
import { fetchGroups, fetchReminders } from '../Services/Reminder';

const ReminderComponent = () => {
  const { control, watch } = useForm();
  const realm_name = getKeyCloakRealmFromLS();

  const filterApp = watch('filter_by_app') || '';
  const filterGroup = watch('filter_by_group') || '';
  const filterUsers = watch('filter_by_user') || '';
  const filterStatus = watch('filter_by_status') || '';

  const [isShowExpired, setIsShowExpired] = useState<boolean>(true);
  const [filterParams, setFilterParams] = useState<string>('');
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['group_data'],
    queryFn: fetchGroups,
  });

  useEffect(() => {
    let filterParams = '';

    if (filterApp) {
      filterParams = `&app=${filterApp}`;
    }

    if (filterGroup?.length > 0) {
      filterParams = `${filterParams}&groups=${filterGroup.join(',')}`;
    }

    if (filterUsers?.length > 0) {
      const users = filterUsers?.map(
        (item: string) => `${realm_name}__${item}`
      );
      filterParams = `${filterParams}&users=${users.join(',')}`;
    }

    if (filterStatus?.length > 0) {
      const statusParams = filterStatus
        ?.map((status: string) => `status=${status}`)
        .join('&');
      filterParams = `${filterParams}&${statusParams}`;
    }

    setFilterParams(filterParams);
  }, [filterApp, filterGroup, filterUsers, filterStatus]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: fetchCollaborators,
    select: (response) => {
      return response.results.map((data: any) => ({
        ...data,
        name: `${data.first_name} ${data.last_name}`,
      }));
    },
  });

  const getReminderQuery = (type: string, extraParams = '') => ({
    queryKey: [`get_${type}_reminder_list`, filterParams, isShowExpired],
    queryFn: async ({ pageParam = 1 }) => {
      const params = `page=${pageParam}${filterParams}${extraParams}`;
      const response = await fetchReminders(params);
      const pageCount = Math.ceil(response.count / 20);
      return {
        reminderList: response?.results?.filter(
          (result: any) => !result?.contract?.terminate_status
        ),
        nextPage: pageParam + 1,
        totalPages: pageCount,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => {
      if (
        lastPage?.reminderList?.length &&
        lastPage?.nextPage <= lastPage?.totalPages
      ) {
        return lastPage.nextPage;
      }
      return undefined;
    },
  });

  const urgentReminderQuery = getReminderQuery(
    'urgent',
    isShowExpired ? '&priority=High' : '&urgent_expired=true'
  );
  const weekReminderQuery = getReminderQuery(
    'this_week',
    isShowExpired ? '&this_week=true' : '&week_expired=true'
  );
  const monthReminderQuery = getReminderQuery(
    'this_month',
    isShowExpired ? '&this_month=true' : '&month_expired=true'
  );
  const upcomingReminderQuery = getReminderQuery('upcoming', '&upcoming=true');
  const expiredReminderQuery = getReminderQuery('expired', '&expired=true');

  const {
    data: urgentReminderList,
    fetchNextPage: fetchUrgentNextPage,
    hasNextPage: hasUrgentNextPage,
    isFetching: isFetchingUrgent,
  } = useInfiniteQuery(urgentReminderQuery);

  const {
    data: weekReminderList,
    fetchNextPage: fetchWeekNextPage,
    hasNextPage: hasWeekNextPage,
    isFetching: isFetchingWeek,
  } = useInfiniteQuery(weekReminderQuery);

  const {
    data: monthReminderList,
    fetchNextPage: fetchMonthNextPage,
    hasNextPage: hasMonthNextPage,
    isFetching: isFetchingMonth,
  } = useInfiniteQuery(monthReminderQuery);

  const {
    data: upcomingReminderList,
    fetchNextPage: fetchUpcomingNextPage,
    hasNextPage: hasUpcomingNextPage,
    isFetching: isFetchingUpcoming,
  } = useInfiniteQuery(upcomingReminderQuery);

  const {
    data: expiredReminderList,
    fetchNextPage: fetchExpiredNextPage,
    hasNextPage: hasExpiredNextPage,
    isFetching: isFetchingExpired,
  } = useInfiniteQuery(expiredReminderQuery);

  const handleScrollToBottom = async () => {
    const fetchPromises: Promise<any>[] = [];
    if (hasUrgentNextPage) {
      fetchPromises.push(fetchUrgentNextPage());
    }
    if (hasWeekNextPage) {
      fetchPromises.push(fetchWeekNextPage());
    }
    if (hasMonthNextPage) {
      fetchPromises.push(fetchMonthNextPage());
    }
    if (hasUpcomingNextPage) {
      fetchPromises.push(fetchUpcomingNextPage());
    }
    if (hasExpiredNextPage) {
      fetchPromises.push(fetchExpiredNextPage());
    }

    if (fetchPromises.length) {
      await Promise.all(fetchPromises);
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        handleScrollToBottom();
      }
    });

    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current);
    }

    return () => {
      if (observerRef.current && sentinelRef.current) {
        observerRef.current.unobserve(sentinelRef.current);
      }
    };
  }, [
    hasUrgentNextPage,
    hasWeekNextPage,
    hasMonthNextPage,
    hasUpcomingNextPage,
    hasExpiredNextPage,
  ]);

  return (
    <Box p={5}>
      <Stack gap="16px">
        <Typography fontWeight={700}>Filters</Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_app"
              control={control}
              label="Filter by app"
              options={appOption}
              renderCustomComponent={(value: any, props) => (
                <CustomChip {...props} label={value?.name} />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_group"
              control={control}
              label="Filter by group"
              options={sortListAlphabetical(groupData)}
              loading={groupLoading}
              isMultiselect={true}
              valueKey="name"
              renderCustomComponent={(value: any, props) => (
                <CustomChip
                  {...props}
                  icon={
                    <GroupWorkOutlinedIcon
                      style={{
                        color: '#6D264C',
                      }}
                    />
                  }
                  label={value?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_user"
              control={control}
              label="Filter by user"
              options={sortListAlphabetical(approversData)}
              loading={approversLoading}
              isMultiselect={true}
              valueKey="email"
              renderCustomComponent={(value: any, props) => (
                <CustomChip
                  {...props}
                  icon={
                    <PersonOutlineOutlinedIcon
                      style={{
                        color: '#6D264C',
                      }}
                    />
                  }
                  label={value?.name}
                />
              )}
            />
          </Grid>
          <Grid item xs={2.3}>
            <FilterInput
              name="filter_by_status"
              control={control}
              label="Filter by status"
              options={sortListAlphabetical(reminderStatus)}
              isMultiselect={true}
              renderCustomComponent={(value: any, props) => (
                <CustomChip {...props} label={value?.name} />
              )}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Button
              variant="text"
              style={{
                padding: '10px',
              }}
              startIcon={
                isShowExpired ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              onClick={() => setIsShowExpired(!isShowExpired)}
            >
              {isShowExpired ? 'Hide' : 'Show'} expired reminders
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1} flexWrap="unset">
          {filterApp !== 'Umbrella' && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Urgent</Typography>
              <ReminderColumn
                reminderList={urgentReminderList?.pages.flatMap(
                  (page: any) => page?.reminderList
                )}
                isLoading={isFetchingUrgent}
              />
            </Grid>
          )}
          <Grid item xs={2.9}>
            <Typography fontWeight={700}>This week</Typography>
            <ReminderColumn
              reminderList={weekReminderList?.pages.flatMap(
                (page: any) => page?.reminderList
              )}
              isLoading={isFetchingWeek}
            />
          </Grid>
          <Grid item xs={2.9}>
            <Typography fontWeight={700}>This month</Typography>
            <ReminderColumn
              reminderList={monthReminderList?.pages.flatMap(
                (page: any) => page?.reminderList
              )}
              isLoading={isFetchingMonth}
            />
          </Grid>
          {filterApp !== 'Stylus' && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Reminders coming later</Typography>
              <ReminderColumn
                reminderList={upcomingReminderList?.pages.flatMap(
                  (page: any) => page?.reminderList
                )}
                isLoading={isFetchingUpcoming}
              />
            </Grid>
          )}
          {isShowExpired && (
            <Grid item xs={2.9}>
              <Typography fontWeight={700}>Expired reminders</Typography>
              <ReminderColumn
                reminderList={expiredReminderList?.pages.flatMap(
                  (page: any) => page?.reminderList
                )}
                isLoading={isFetchingExpired}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
      <div ref={sentinelRef} style={{ height: '20px' }} />
    </Box>
  );
};

export default ReminderComponent;
