export const GOTOSTYLLUS = 'GOTOSTYLLUS';
export type GOTOSTYLLUS = typeof GOTOSTYLLUS;

export const GOTOUMBRELA = 'GOTOUMBRELLA';
export type GOTOUMBRELA = typeof GOTOUMBRELA;

export const GOTOREMINDER = 'GOTOREMINDER';
export type GOTOREMINDER = typeof GOTOREMINDER;

export const UMBRELLA = 'UMBRELLA';
export const STYLLUS = 'STYLLUS';
export const ADMIN = 'ADMIN';
export const REMINDER = 'REMINDER';

export const GOTOADMIN = 'GOTOADMIN';
export type GOTOADMIN = typeof GOTOADMIN;

export interface GotoStyllus {
  type: GOTOSTYLLUS;
}

export interface GotoUmbrella {
  type: GOTOUMBRELA;
}

export interface GotoReminder {
  type: GOTOREMINDER;
}

export interface GotoAdmin {
  type: GOTOADMIN;
}
export type heimdallActions =
  | GotoReminder
  | GotoStyllus
  | GotoUmbrella
  | GotoAdmin;
