import React, { useState } from 'react';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import ViewDocumentDrawer from './ViewDocumentDrawer';
import { download_file } from '../../../Draft/Component/Helper';
import DrawerComponent from '../../../RiverusUI/Components/DrawerComponent';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import {
  deleteContractDoc,
  fetchDownloadUrl,
} from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  contract_data: any;
  contractLoading: boolean;
  refContractData: any;
  userCanDoAction: boolean;
}

const ConnectedDocsDrawer: React.FC<Props> = ({
  open,
  onClose,
  contract_data,
  contractLoading,
  refContractData,
  userCanDoAction,
}) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const handleCloseViewDrawer = () => {
    setViewDocumentId('');
    setOpenViewDrawer(false);
  };

  const handleOpenDeleteModal = (id: string) => {
    if (userCanDoAction) {
      setOpenDeleteModal(true);
      setDeleteDocId(id);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: deleteUploadedDoc } = useMutation({
    mutationKey: ['delete_connected_doc'],
    mutationFn: deleteContractDoc,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get_contract_data_id'] });
      enqueueSnackbar('Document deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDeleteModal();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: downloadFile } = useMutation({
    mutationFn: fetchDownloadUrl,
    onSuccess: (response) => {
      download_file(response);
    },
  });

  const handleDownloadFile = (id: string) => {
    downloadFile(id);
  };

  const handleViewContractFile = (id: string) => {
    setOpenViewDrawer(true);
    setViewDocumentId(id);
  };

  return (
    <>
      <DrawerComponent
        open={open}
        onClose={onClose}
        drawerHeader="Connected Documents"
      >
        {contractLoading ? (
          <Box px={2}>
            <ListSkeleton />
          </Box>
        ) : (
          <Stack
            direction="column"
            spacing={2}
            margin="20px 15px"
            width="400px"
          >
            <Typography fontSize="15px" fontWeight={600}>
              Original documents
            </Typography>

            {contract_data?.uploaded_type === 'Amendment' &&
              refContractData && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography>{refContractData?.file_name}</Typography>
                  <Stack direction="row" spacing={1}>
                    {refContractData?.uploaded_type === 'Contract' && (
                      <Chip
                        label="Master"
                        style={{ background: '#88305F24' }}
                      />
                    )}
                    {refContractData?.uploaded_type === 'Amendment' && (
                      <Chip
                        label="Amendment"
                        style={{ background: '#88305F24' }}
                      />
                    )}
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => handleDownloadFile(refContractData?.id)}
                    >
                      <SaveAltIcon />
                    </IconButton>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => handleOpenDeleteModal(refContractData?.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        handleViewContractFile(refContractData?.id);
                      }}
                    >
                      <ArrowRightIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              )}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography>{contract_data?.file_name}</Typography>
              <Stack direction="row" spacing={1}>
                {contract_data?.uploaded_type === 'Contract' && (
                  <Chip label="Master" style={{ background: '#88305F24' }} />
                )}
                {contract_data?.uploaded_type === 'Amendment' && (
                  <Chip label="Amendment" style={{ background: '#88305F24' }} />
                )}
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => handleDownloadFile(contract_data?.id)}
                >
                  <SaveAltIcon />
                </IconButton>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => handleOpenDeleteModal(contract_data?.id)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    handleViewContractFile(contract_data?.id);
                  }}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Stack>
            </Stack>

            {contract_data?.amendment?.length > 0 && (
              <Stack direction="column" spacing={1}>
                <Typography fontSize="15px" fontWeight={600}>
                  Amendment documents
                </Typography>
                {contract_data?.amendment?.map((item: any) => (
                  <Stack
                    key={item?.id}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography>{item?.file_name}</Typography>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => handleDownloadFile(item?.id)}
                      >
                        <SaveAltIcon />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => handleOpenDeleteModal(item?.id)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          handleViewContractFile(item?.id);
                        }}
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}

            {contract_data?.support_document?.length > 0 && (
              <Stack direction="column" spacing={1} pt="15px">
                <Typography fontSize="15px" fontWeight={600}>
                  Support documents
                </Typography>
                {contract_data?.support_document?.map((item: any) => (
                  <Stack
                    key={item?.id}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography>{item?.file_name}</Typography>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => handleDownloadFile(item?.id)}
                      >
                        <SaveAltIcon />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => handleOpenDeleteModal(item?.id)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          handleViewContractFile(item?.id);
                        }}
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        )}
      </DrawerComponent>
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteUploadedDoc(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </>
  );
};

export default ConnectedDocsDrawer;
