import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { getSessionStorage } from '../Authentication/Actions/authentication';

const Admin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (getSessionStorage('user_profile').roles.includes('admin')) {
      navigate('/admin/data-dictionary');
    } else {
      navigate('/documentlibrary');
    }
  }, []);

  return <div></div>;
};

export default Admin;
