import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TagIcon from '@mui/icons-material/Tag';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import CustomChip from '../../../../../Approvals/Component/CreateApprovalForm/CustomChip';
import ControlledTextField from '../../../../../RiverusUI/Components/ControlledTextField';
import RISelectComponent from '../../../../../RiverusUI/Components/SelectComponent';
import { AddTag, getTags } from '../../../../../Services/DocumentLibrary';
import { ClauseType } from '../../../../Constants/ClauseType';
import {
  CurrencyType,
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  SentencesData,
  tableInfo,
} from '../../../../State/documentState';
import { getClauseDataFormat } from '../../../ClauseComponent/utils/ClauseTypeUtils';
import { isTableCell, phraseBiMap } from '../../../Utils/docUtils';

interface Props {
  dataPointName: string;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editOptionSelected: (editOptionSelected: boolean) => void;
  savedInsight: any;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  currencyList: CurrencyType[];
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  onClose: VoidFunction;
  updatedClauseDataByType: any;
  parentClauseType: string;
  clauseData: any;
  canAddTags?: boolean;
  parentData?: any;
}

interface State {
  isAddingPhrase: boolean;
  currentEditingPhrase: phraseInfo | null;
  selectedOption: CurrencyType;
  currencyValue: number;
}

const EditLinkAmountPhrase: React.FC<Props> = (props) => {
  const {
    clauseType,
    updatedClauseDataByType,
    sentenceData,
    postClauseDataByType,
    clauseDataByType,
    fileId,
    dataPointName,
    parentClauseType,
    saveAddPhrase,
    saveDeletePhrase,
    savePhraseEditOption,
    saveHighlightedPhrases,
    saveHighlightedTableCells,
    saveHighlightedDataPoint,
    editOptionSelected,
    canAddTags,
    phraseInAddMode,
    onClose,
    phraseEditOptionSelected,
    parentData,
  } = props;

  const [state, setState] = useState<State>({
    isAddingPhrase: false,
    currentEditingPhrase: null,
    selectedOption: {
      currencyName: '',
      currencyType: '',
      currencyTypeId: -1,
    },
    currencyValue: -1,
  });

  const { control, watch, setValue } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const tags = watch('tags') || '';
  const queryClient = useQueryClient();

  useEffect(() => {
    const amount = parentData?.child?.amount;
    if (amount) {
      setValue('tags', amount.tags);
      setValue('value', amount.total);
      setValue('currency', amount.currency);
      const currency = props.currencyList.filter(
        (data: any) => data.currencyName === amount.currency
      );
      setState((prevState) => ({
        ...prevState,
        currencyValue: amount.total,
        selectedOption: currency[0],
      }));
    }
  }, [parentData?.child?.amount, props.currencyList, setValue]);

  const { data: tagData, isLoading: tagsLoading } = useQuery({
    queryKey: ['tags', parentClauseType],
    queryFn: () => getTags(parentClauseType),
    select: (response) => response.results,
    enabled: canAddTags,
  });

  const { mutate: createTag } = useMutation({
    mutationKey: ['add_tags'],
    mutationFn: AddTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  useEffect(() => {
    saveHighlightedDataPoint(dataPointName);
  }, [dataPointName, saveHighlightedDataPoint]);

  useEffect(() => {
    if (phraseInAddMode !== null) {
      setState((prevState) => ({ ...prevState, isAddingPhrase: false }));
    }
  }, [phraseInAddMode]);

  const setCurrencyValue = (event: any) => {
    const { currencyValue } = state;
    const value = event.target.value;
    let currency: number = currencyValue;
    if (value.length === 0) {
      currency = -1;
    } else {
      if (!isNaN(value) && value.length > 0) {
        currency = Number(value);
      }
    }
    setState((prevState) => ({ ...prevState, currencyValue: currency }));
  };

  const setCurrency = (currency: CurrencyType) => {
    setState((prevState) => ({
      ...prevState,
      selectedOption: currency,
    }));
  };

  const linkToPhrase = () => {
    savePhraseEditOption(true);
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: true,
      currentEditingPhrase: null,
    }));
    saveDeletePhrase(null, false);
    saveHighlightedTableCells(null);
    editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = '';
    if (isTableCell(phraseInfo)) {
      const tempTablCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        'p' +
        phraseInfo.paraId +
        ';r' +
        phraseInfo.rowId +
        ';c' +
        phraseInfo.columnId;
      saveHighlightedTableCells([tempTablCell]);
    } else {
      deletePhraseElement =
        'p' + phraseInfo.paraId + ';w' + phraseInfo.startWordId;
      saveHighlightedTableCells(null);
    }
    const phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = 'smooth';

    saveDeletePhrase(phraseInfo, true);
    saveHighlightedDataPoint(props.dataPointName);
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: false,
      currentEditingPhrase: phraseInfo,
    }));
    editOptionSelected(true);
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo === null) {
      return (
        <Stack alignItems="start" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                state.isAddingPhrase !== true
                  ? '#88305F'
                  : phraseEditOptionSelected === true
                    ? '#C1C1C1'
                    : '#88305F',
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                state.currentEditingPhrase === phraseInfo
                  ? '#C1C1C1'
                  : '#88305F',
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  const onCancel = () => {
    onClose();
    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    saveHighlightedPhrases(null);
    savePhraseEditOption(false);
    editOptionSelected(false);
    saveHighlightedTableCells(null);
    setState({
      isAddingPhrase: false,
      currentEditingPhrase: null,
      selectedOption: {
        currencyName: '',
        currencyType: '',
        currencyTypeId: -1,
      },
      currencyValue: -1,
    });
  };

  const getSaveStatus = () => {
    const { phraseInAddMode } = props;
    const { currencyValue, selectedOption } = state;
    let saveStatus = false;
    if (
      phraseInAddMode !== null &&
      phraseInAddMode.paraId !== null &&
      phraseInAddMode.paraId !== -1 &&
      currencyValue !== -1 &&
      selectedOption.currencyTypeId !== -1
    ) {
      saveStatus = true;
    }
    return saveStatus;
  };

  const getTempPhrase = () => {
    const { phraseInAddMode } = props;
    const { selectedOption, currencyValue } = state;

    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };
    const newPhrase: phraseInfo =
      phraseInAddMode === null
        ? {
            paraId: -1,
            startWordId: -1,
            endWordId: -1,
            startSentenceId: -1,
            endSentenceId: -1,
            rowId: -1,
            columnId: -1,
            phrase: '',
            total: -1,
            currency_typeid: -1,
          }
        : phraseInAddMode;
    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          total: currencyValue,
          currency_typeid: selectedOption.currencyTypeId,
          currency: selectedOption.currencyName,
          tags: tags,
        },
      ],
      deleted: [],
      bi: phraseBiMap[props.dataPointName],
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };

    return tempPhraseRequest;
  };

  const onSave = () => {
    const updatedData = updatedClauseDataByType;
    let newData = updatedData;
    const tempPhraseRequest = getTempPhrase();

    const addedData = tempPhraseRequest.editedPhrases.upsert;

    for (let i = 0; i < addedData.length; i++) {
      if (
        (addedData[i]?.paraId !== parentData?.para_id ||
          addedData[i]?.startSentenceId !== parentData?.start_sentence_id) &&
        clauseType === 'Payment Amounts'
      ) {
        enqueueSnackbar('Please add a Phrase from the same sentence!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } else if (
        addedData[i]?.startSentenceId === addedData[i]?.endSentenceId
      ) {
        newData = getClauseDataFormat(
          'add',
          clauseType,
          addedData[i],
          newData,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType as ClauseType,
        diff,
        newData
      );
    }

    saveDeletePhrase(null, false);
    saveAddPhrase(null, false);
    savePhraseEditOption(false);
    saveHighlightedPhrases(null);
    editOptionSelected(false);
    saveHighlightedDataPoint(dataPointName);
    saveHighlightedTableCells(null);
    onClose();
    setState({
      isAddingPhrase: false,
      currentEditingPhrase: null,
      selectedOption: {
        currencyName: '',
        currencyType: '',
        currencyTypeId: -1,
      },
      currencyValue: -1,
    });
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>
      <Stack width="100%" className="edit-clause-select" spacing={2}>
        <Stack direction="row" width="100%" spacing={1}>
          <ControlledTextField
            name="value"
            type="number"
            control={control}
            label="Value"
            value={state.currencyValue === -1 ? '' : state.currencyValue}
            onChange={setCurrencyValue}
            fullWidth
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
          />
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="Select-Currency">Currency</InputLabel>
                <Select
                  {...field}
                  required
                  label="Currency"
                  variant="outlined"
                  inputProps={{
                    id: 'Select-currency',
                  }}
                  value={state.selectedOption}
                >
                  {props.currencyList.map((currencyItem: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={currencyItem}
                      onClick={() => setCurrency(currencyItem)}
                    >
                      {currencyItem.currencyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        {canAddTags && (
          <RISelectComponent
            name="tags"
            control={control}
            label={`${parentClauseType} Tags`}
            options={tagData}
            loading={tagsLoading}
            isMultiselect={true}
            valueKey="name"
            canCreateNew
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                {...props}
                icon={
                  <TagIcon
                    style={{
                      color: '#6D264C',
                    }}
                  />
                }
                label={value?.name}
              />
            )}
            addNewValue={(value) =>
              createTag({ name: value, type: parentClauseType })
            }
          />
        )}
        {linkPhraseOnDoc(props.phraseInAddMode)}

        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to link a phrase ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Click on `&quot;`Link Phrase`&quot;`.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Select and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={!getSaveStatus()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkAmountPhrase;
