export const CLAUSE_RENDER_DATA = {
  term: {
    term: 'Term Clause',
    start: 'Start Dates',
    end: 'End Dates',
    duration: 'Duration',
  },
  renewal: {
    renewal: 'Renewal Clause',
    auto_renewal: 'Auto Renewal',
    notice_auto_renewal: 'Notice for Auto-Renewal',
    reference_date: 'Notice Reference Date',
    renewal_duration: 'Duration',
  },
  payment: {
    payment: 'Payment Obligations',
    amounts: 'Amount',
    timeline: 'Timeline',
  },
  indemnity: {
    indemnity: 'Indemnity/Reimbursements/Costs',
  },
  confidentiality: {
    confidentiality: 'Confidentiality Clause',
    duration: 'Duration',
    nature: 'Nature',
    c_except: 'Exception to Confidentiality',
  },
  changeOfControl: {
    change_of_control: 'Change Of Control',
    definitions: 'Definition',
    termination: 'Termination',
    payment: 'Payment',
    consent: 'Consent',
    notice: 'Notice',
  },
  termination: {
    termination: 'Termination Clause',
    terminationAtConvenience: 'Termination At Convenience',
  },
  insurance: {
    insurance: 'Insurance Clause',
  },
  notice: {
    notice: 'Notice Obligation',
  },
  eventsOfDefault: {
    events_of_default: 'Events Of Default',
    events: 'Event',
  },
  limitedLiability: {
    limited_liability: 'Limitation Of Liability',
    amounts: 'Amount',
  },
  forceMajeure: {
    force_majeure: 'Force Majeure',
    fm_events: 'Events Covered',
  },
  governingLaw: {
    governing_law: 'Governing Law Clause',
    jurisdiction: 'Jurisdiction',
  },
  disputeResolution: {
    dispute_resolution: 'Dispute Resolution',
    panel: 'Panel',
    venue: 'Venue',
  },
  nonCompete: {
    non_compete: 'Non-Compete Clause',
    duration: 'Duration',
    territory: 'Territory',
  },
  nonSolicitation: {
    non_solicitation: 'Non-Solicitation Clause',
    duration: 'Duration',
  },
  subletting: {
    subletting: 'Subletting/Assignment',
  },
  consent: {
    consent: 'Consent Clause',
  },
  generalDefinitions: {
    general_definitions: 'General Definitions',
  },
  dataBreach: {
    data_breach: 'Data Breach',
    notify: 'Notify',
    regulation: 'Regulation',
  },
  exclusivity: {
    exclusivity: 'Exclusivity',
  },
  obligationStatements: {
    obligation_statements: 'Other Obligation',
  },
};
