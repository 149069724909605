import React, { useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Stack } from '@mui/material';

import InsightFilterDrawer from './InsightFilterDrawer';

interface Props {
  contractData: any;
}

const FilterInsights: React.FC<Props> = ({ contractData }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <Stack alignItems="end" width="100%">
        <Button
          variant="text"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenDrawer(true)}
        >
          Filter Insights
        </Button>
      </Stack>
      {openDrawer && (
        <InsightFilterDrawer
          contractData={contractData}
          open={openDrawer}
          onClose={handleCloseDrawer}
        />
      )}
    </React.Fragment>
  );
};

export default FilterInsights;
