interface formControlLabelProps {
  styleOverrides?: any;
  defaultProps?: any;
}

const formControlLabelTheme: formControlLabelProps = {
  styleOverrides: {
    root: {
      textTransform: 'capitalize',
      padding: 2,
    },
    label: {
      marginLeft: '10px',
    },
  },
};

export default formControlLabelTheme;
