import React, { useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IObject } from '../../../interfaces/IObject';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import {
  deleteClauseLibrary,
  getClauseLibraryData,
  getSectionNames,
} from '../../../Services/Approval';
import CreateNewClauseModal from '../../../UniversalComponents/Modals/ClauseSectionModal/CreateNewClauseModal';
import ClauseTable from '../../Component/ClauseTable';

const initialFilter = {
  search: '',
  applicability: '',
  tags: '',
  creator: '',
};

const ClauseLibraryAdmin = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [openClauseModal, setOpenClauseModal] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['admin-clause-library', sorting, pageNumber, filters],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }

      const sortingParam = '&ordering=-created_on';
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${sortingParam}${filterParam}`;
      return getClauseLibraryData(params);
    },
  });

  const { data: sectionNamesData } = useQuery({
    queryKey: ['section-names'],
    queryFn: getSectionNames,
  });

  const { mutate: deleteClauseMutation } = useMutation({
    mutationKey: ['delete-clause-library'],
    mutationFn: deleteClauseLibrary,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['admin-clause-library'],
      });
      setSelectedRow([]);
      setShowWarning(false);
    },
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ['admin-clause-library'],
    });
  };

  const handleAddClause = () => {
    setOpenClauseModal(true);
  };

  const handleCloseClauseModal = () => {
    setOpenClauseModal(false);
  };

  const handleDeleteClause = () => {
    let payload: any = {};

    if (selectedRow?.length) {
      const clauseIds: string[] = [];
      const sectionNames: string[] = [];

      const isUUID = (str: string) =>
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(
          str
        );

      selectedRow?.forEach((row) => {
        const rowString = row.toString();

        if (isUUID(rowString)) {
          clauseIds.push(rowString);
        } else {
          sectionNames.push(rowString);
        }
      });

      if (clauseIds?.length > 0 && sectionNames?.length > 0) {
        payload = {
          clause_id: clauseIds,
          section_name: sectionNames,
        };
      } else if (clauseIds?.length > 0) {
        payload = {
          clause_id: clauseIds,
        };
      } else if (sectionNames?.length > 0) {
        payload = {
          section_name: sectionNames,
        };
      }
    }
    deleteClauseMutation(payload);
  };

  return (
    <Stack>
      <Stack className="sidebar-right-column" spacing={1}>
        <Stack>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
            Add to clause library
          </Typography>
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'riPrimary.500',
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
            }}
            onClick={handleAddClause}
          >
            <AddCircleOutlineRoundedIcon fontSize="small" />
            <br />
            Add a clause
          </Button>

          <Stack direction="row" justifyContent="end" alignItems="center">
            {selectedRow?.length > 0 ? (
              <Button
                startIcon={<DeleteOutlineIcon />}
                onClick={() => setShowWarning(true)}
              >
                Delete
              </Button>
            ) : (
              <IconButton onClick={handleRefresh}>
                <RefreshIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <ClauseTable
          data={data}
          isLoading={isLoading || isFetching}
          pageNumber={pageNumber}
          setPageNumberChange={setPageNumber}
          setFilters={setFilters}
          setSorting={setSorting}
          filters={filters}
          setSelectedRow={setSelectedRow}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          sectionNamesData={sectionNamesData}
        />
      </Stack>

      <CreateNewClauseModal
        open={openClauseModal}
        onClose={handleCloseClauseModal}
        sectionNamesData={sectionNamesData}
      />

      {showWarning && (
        <ReusableConfirmationModal
          open={showWarning}
          onClose={() => setShowWarning(false)}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={handleDeleteClause}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected clause will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default ClauseLibraryAdmin;
