import {
  ADMIN,
  GOTOADMIN,
  GOTOREMINDER,
  GOTOSTYLLUS,
  GOTOUMBRELA,
  heimdallActions,
  REMINDER,
  STYLLUS,
  UMBRELLA,
} from '../Actions/definitions';
import HeimdallState, { getDefaultHeimdallState } from '../State/heimdallState';

export default function HeimdallReducer(
  state: HeimdallState = getDefaultHeimdallState(),
  action: heimdallActions
): HeimdallState {
  switch (action.type) {
    case GOTOSTYLLUS: {
      return {
        ...state,
        platform: STYLLUS,
      };
    }
    case GOTOREMINDER: {
      return {
        ...state,
        platform: REMINDER,
      };
    }
    case GOTOUMBRELA: {
      return {
        ...state,
        platform: UMBRELLA,
      };
    }
    case GOTOADMIN: {
      return {
        ...state,
        platform: ADMIN,
      };
    }
    default: {
      return state;
    }
  }
}
