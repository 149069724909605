import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';
import { useForm } from 'react-hook-form';

import ControlledTextField from '../../../../../../RiverusUI/Components/ControlledTextField';
import { ClauseType } from '../../../../../Constants/ClauseType';
import {
  ParentClauseDataPoint,
  sentenceInfo,
  SentencesData,
} from '../../../../../State/documentState';
import { getClauseDataFormat } from '../../../../ClauseComponent/utils/ClauseTypeUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  dataPointName: string;
  savedInsightChild: any;
  savedParentClauseDataPoint: ParentClauseDataPoint;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

const EditAddConsentAuthority: React.FC<Props> = (props) => {
  const {
    editOptionSelected,
    savedParentClauseDataPoint,
    fileId,
    clauseType,
    postClauseDataByType,
    updatedClauseData,
    sentenceData,
    clauseDataByType,
    updatedClauseDataByType,
    parentClauseType,
    clauseItem,
    onClose,
  } = props;

  const [savedAuthorities, setSavedAuthorities] = useState<any[]>([]);
  const [authorityInAddMode, setAuthorityInAddMode] = useState<sentenceInfo>({
    paraId: savedParentClauseDataPoint.paraId,
    sentenceId: savedParentClauseDataPoint.sentenceId,
    rowId: savedParentClauseDataPoint.rowId,
    columnId: savedParentClauseDataPoint.columnId,
    startWordId: -1,
    endWordId: -1,
    phrase: '',
    typestring: '',
  });

  const { control, watch, resetField } = useForm();
  const authorityValue = watch('authority') || '';

  useEffect(() => {
    let currentIndex = -1;

    for (
      let i = 0;
      i < updatedClauseData.consent.consent_regulatory_bi?.length;
      i++
    ) {
      if (
        updatedClauseData.consent.consent_regulatory_bi[i]?.para_id ===
          clauseItem?.para_id &&
        updatedClauseData.consent.consent_regulatory_bi[i]?.sentence_id ===
          clauseItem?.sentence_id
      ) {
        currentIndex = i;
      }
    }

    const existingAuthoritiesArray =
      updatedClauseData.consent.consent_regulatory_bi?.[currentIndex]
        ?.extraction;
    setSavedAuthorities(existingAuthoritiesArray);
  }, []);

  const onCancel = () => {
    editOptionSelected(false);
    onClose();
    resetField('authority');
  };

  const editPresent = (action: string, updateAuthority: any[]) => {
    let newData = updatedClauseDataByType;
    if (updateAuthority?.length > 0) {
      const addedData = updateAuthority;
      for (let i = 0; i < addedData?.length; i++) {
        newData = getClauseDataFormat(
          'add',
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData,
          clauseItem,
          i
        );
      }
    } else {
      newData = getClauseDataFormat(
        'add',
        clauseType as ClauseType,
        null,
        newData,
        sentenceData,
        clauseItem,
        0
      );
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    onCancel();
  };

  const addOrRemoveAuthority = (action: string, authority: any) => {
    if (action === 'add') {
      let tempSavedAuthorities: sentenceInfo[];
      if (!savedAuthorities) {
        tempSavedAuthorities = [];
      } else {
        tempSavedAuthorities = savedAuthorities;
      }
      tempSavedAuthorities.push(authority);
      setSavedAuthorities(tempSavedAuthorities);
      setAuthorityInAddMode({
        paraId: savedParentClauseDataPoint.paraId,
        sentenceId: savedParentClauseDataPoint.sentenceId,
        rowId: savedParentClauseDataPoint.rowId,
        columnId: savedParentClauseDataPoint.columnId,
        startWordId: -1,
        endWordId: -1,
        phrase: '',
        typestring: '',
      });
      editPresent('add', tempSavedAuthorities);
    } else if (action === 'remove') {
      const tempFilteredAuthorities: sentenceInfo[] = [];
      for (let i = 0; i < savedAuthorities.length; i++) {
        if (authority.entity) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].entity === authority.entity
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        } else if (authority.phrase) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].phrase === authority.phrase
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        }
      }
      setSavedAuthorities(tempFilteredAuthorities);
    }
  };

  useEffect(() => {
    if (!authorityValue && savedAuthorities?.length > 0) {
      addOrRemoveAuthority('remove', savedAuthorities?.[0]);
    }
  }, [authorityValue, savedAuthorities]);

  const onSave = () => {
    if (authorityValue) {
      const newAuthority = {
        ...authorityInAddMode,
        phrase: authorityValue,
      };
      setAuthorityInAddMode(newAuthority);
      addOrRemoveAuthority('add', newAuthority);
    }
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add/edit Consent Authority
      </Typography>
      <Stack className="edit-clause-select" spacing={2}>
        <ControlledTextField
          name="authority"
          control={control}
          label="Authority"
          fullWidth
        />

        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditAddConsentAuthority;
