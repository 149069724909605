import { ClauseType } from '../../../DocumentView/Constants/ClauseType';

export const CLAUSE_RENDERING_ORDER: ClauseType[] = [
  'term',
  'termination_at_convenience',
  'renewal',
  'payment',
  'indemnity',
  'confidentiality',
  'change_of_control',
  'termination',
  'insurance',
  'notice',
  'events_of_default',
  'limited_liability',
  'force_majeure',
  'governing_law',
  'dispute_resolution',
  'non_compete',
  'non_solicitation',
  'subletting',
  'consent',
  'general_definitions',
  'assignment',
  'contracting_parties',
  'data_breach',
  'locked_in_period',
  'effective_date',
  // 'exclusivity',
  // 'premise',
  'obligation_statements',
];
