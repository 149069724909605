interface chipProps {
  styleOverrides: any;
}

const chipTheme: chipProps = {
  styleOverrides: {
    root: {
      padding: '4px 12px',
      height: '24px',
    },
    label: {
      textTransform: 'capitalize',
      fontWeight: '500',
      fontSize: '11px',
    },
  },
};
export default chipTheme;
