import * as React from 'react';

import { Box } from '@mui/material';

interface Props {
  maxHeight: number | string;
  minHeight?: string;
  mode?: string;
  padding?: boolean;
  children: React.ReactNode;
  id?: string;
}

const Scrollable = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const getClassName = () => {
    if (props.mode) {
      return 'custom-scrollbar-checkbox-dark';
    }
    if (props.padding != null && props.padding === false) {
      return 'custom-scrollbar-checkbox';
    }
    return 'custom-scrollbar-checkbox';
  };

  const getStyle = () => {
    return {
      maxHeight:
        typeof props.maxHeight === 'number'
          ? `${props.maxHeight}px`
          : `${props.maxHeight}vh`,
      minHeight: props.minHeight ? props.minHeight : '',
    };
  };

  return (
    <Box className={getClassName()} style={getStyle()} id={props.id} ref={ref}>
      {props.children}
    </Box>
  );
});

Scrollable.displayName = 'Scrollable';

export default Scrollable;
