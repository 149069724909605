import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import AddNewChecklist from './AddNewChecklist';
import AssignDialog from './AssignDialog';
import Checklist from './Checklist';
import DeleteDialog from './DeleteDialog';
import UploadSupportDocs from './UploadSupportDocs';
import {
  getLocalStorage,
  getSessionStorage,
} from '../../../Authentication/Actions/authentication';
import {
  editDraftDataExternal,
  fetchExternalChecklistData,
  updateExternalChecklist,
} from '../../../ExternalUserFlow/Services/Draft';
import {
  createReminder,
  editReminder,
} from '../../../Services/DocumentLibrary';
import {
  addDraftChecklistBulkComments,
  addNewChecklist,
  editDraftData,
  fetchChecklistData,
  fetchCollaborators,
  updateActiveStatus,
  updateChecklist,
} from '../../../Services/Draft';
import { fetchReminderById } from '../../../Services/Reminder';
import { draftStatus } from '../../State/DraftState';

interface Props {
  selectedDraftData: any;
  isExternal?: boolean;
  openChecklist?: boolean;
  templateUserIds?: any;
}

const CheckListComponent: React.FC<Props> = ({
  selectedDraftData,
  isExternal,
  openChecklist,
  templateUserIds,
}) => {
  const draftId = selectedDraftData?.draftID;
  const contractTypeId = selectedDraftData?.contractType?.id;

  const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [deleteChecklistId, setDeleteChecklistId] = useState<string>('');
  const [passPropData, setPassPropData] = useState<any>({});
  const [supportDocList, setSupportDocList] = useState<any[]>([]);
  const [collaborators, setCollaborators] = useState<any>([]);
  const [deleteData, setDeleteData] = useState<any>({});
  const [checklistItemData, setChecklistItemData] = useState<any>({});
  const [dialogReminderData, setDialogReminderData] = useState<any>(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [reminderPayload, setReminderPayload] = useState<any>(null);
  const [accordionExpanded, setAccordionExpanded] = useState<any>('');
  const [showCommentsInput, setShowCommentsInput] = useState<any>('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [activeChecklistId, setActiveChecklistId] = useState<{
    [key: string]: boolean;
  }>({});

  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('reminder_type');
  const reminderId = queryParams.get('reminderId') || '';
  const external_token = queryParams.get('external_token') || '';

  const user_data = useMemo(() => getSessionStorage('user_profile'), []);
  const external_email = useMemo(() => getLocalStorage('external_email'), []);

  const userIsOwner = useMemo(
    () =>
      selectedDraftData?.owners?.find(
        (owner: any) => owner?.id === user_data?.id
      ),
    [selectedDraftData, user_data]
  );

  const isDraftDropped = useMemo(
    () => selectedDraftData?.status === draftStatus?.Draft_Dropped,
    [selectedDraftData?.status]
  );

  const { data: reminderData } = useQuery({
    queryKey: ['get_reminder_by_id'],
    queryFn: async () => await fetchReminderById(reminderId),
    enabled: !!reminderId,
  });

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setChecklistItemData({});
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteChecklistId('');
    setDeleteData({});
  };

  const handleCloseAssignDialog = () => {
    if (queryParamsTypeValue === 'checklist') {
      navigate(pathname);
    }
    setOpenAssignDialog(false);
    setDialogOpened(false);
  };

  const { data: getDraftCheckList, isLoading: isLoadingCheckList } = useQuery({
    queryKey: ['draft_checkLists'],
    queryFn: async () =>
      isExternal
        ? await fetchExternalChecklistData(draftId)
        : await fetchChecklistData(draftId),
    select: (response: any) => {
      const filterNotDeletedItem = response?.results?.filter(
        (item: any) => !item?.deleted_status
      );
      return filterNotDeletedItem;
    },
    enabled: !!draftId,
  });

  const { data: approversData } = useQuery({
    queryKey: ['Approvers_list'],
    queryFn: async () => {
      const response = await fetchCollaborators();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups;
    },
    enabled: !isExternal,
  });

  const { mutate: updateDraftData } = useMutation({
    mutationFn: isExternal ? editDraftDataExternal : editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get_draft_by_id'],
      });

      if (!openChecklist) {
        navigate(
          `/draft/${selectedDraftData?.version}/${selectedDraftData?.id}?isActiveTab=checklist`
        );
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (getDraftCheckList?.length) {
      const listOfActiveStatus = getDraftCheckList?.filter(
        (item: any) => item?.active_status
      );
      setChecked(listOfActiveStatus);
    } else {
      setChecked([]);
    }
  }, [getDraftCheckList]);

  useEffect(() => {
    const collaborator = selectedDraftData?.collaborators?.map(
      (data: any) => data.id
    );
    setCollaborators(collaborator || []);
  }, [selectedDraftData]);

  const checklistCollaborators = useMemo(() => {
    if (getDraftCheckList?.length) {
      return getDraftCheckList
        ?.filter((item: any) => item?.user)
        ?.map((item: any) => item?.user);
    }
    return [];
  }, [getDraftCheckList]);

  const uniqueCombinedCollaborators = useMemo(() => {
    return Array.from(
      new Set([
        ...checklistCollaborators,
        ...((selectedDraftData?.version === 0 && templateUserIds) || []),
      ])
    );
  }, [checklistCollaborators, templateUserIds, selectedDraftData?.version]);

  const { mutate: updateChecklistItem, isPending: loadingUpdateItem } =
    useMutation({
      mutationFn: isExternal ? updateExternalChecklist : updateChecklist,
      onSuccess: async (response: any) => {
        const updatedDraftChecklist = await queryClient.fetchQuery({
          queryKey: ['draft_checkList', draftId, isExternal],
          queryFn: async () => {
            return isExternal
              ? await fetchExternalChecklistData(draftId)
              : await fetchChecklistData(draftId);
          },
        });
        const checklistCollaboratorsLatest = updatedDraftChecklist?.results
          ?.length
          ? updatedDraftChecklist?.results
              ?.filter((item: any) => item?.user)
              ?.map((item: any) => item?.user)
          : [];

        const newCollaborator = response?.data?.user;
        const updatedCollaborators = uniqueCombinedCollaborators.filter(
          (collaborator) =>
            collaborator !== passPropData?.user ||
            (passPropData?.user &&
              (selectedDraftData?.version === 0
                ? templateUserIds.includes(passPropData?.user)
                : checklistCollaboratorsLatest.includes(passPropData?.user)))
        );
        if (newCollaborator) {
          updatedCollaborators.push(newCollaborator);
        }

        const uniqueCollaborators = Array.from(new Set(updatedCollaborators));
        const updateDraftDataPayload = {
          id: selectedDraftData?.id || '',
          body: { collaborators: uniqueCollaborators },
        };
        updateDraftData(updateDraftDataPayload);

        queryClient.invalidateQueries({
          queryKey: ['draft_checkLists'],
        });
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
        enqueueSnackbar('Checklist item updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleCloseAssignDialog();
      },
      onError: () => {
        enqueueSnackbar('Failed to update checklist item!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      handleCloseAssignDialog();
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseAssignDialog();
    },
  });

  const { mutate: addChecklistItem, isPending: loadingAddCheckList } =
    useMutation({
      mutationFn: addNewChecklist,
      onSuccess: (response: any) => {
        const userId = response?.data?.user;
        const filteredCollaborators = uniqueCombinedCollaborators.filter(
          (collaborator) => collaborator !== null
        );
        const collaborators = userId
          ? [...filteredCollaborators, userId]
          : filteredCollaborators;

        const updateDraftDataPayload = {
          id: selectedDraftData?.id,
          body: {
            collaborators: Array.from(new Set(collaborators)),
          },
        };
        updateDraftData(updateDraftDataPayload);

        if (
          response?.data?.checklist_type === 'policy' &&
          reminderPayload?.due_date
        ) {
          const updatedReminderPayload = {
            ...reminderPayload,
            checklist: response?.data?.id,
          };
          createReminderMutation(updatedReminderPayload);
        }
        queryClient.invalidateQueries({
          queryKey: ['draft_checkLists'],
        });
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
        reset();
        if (openAssignDialog) handleCloseAssignDialog();
        if (openDeleteDialog) handleCloseDeleteDialog();
        if (response?.data?.deleted_status) {
          const userToDelete = deleteData?.user;
          const checklistIdToDeleteFrom = deleteData?.id;
          const draftChecklistUsers = (getDraftCheckList ?? [])
            ?.filter(
              (item: any) =>
                item?.user !== null &&
                item?.user !== undefined &&
                item?.id !== checklistIdToDeleteFrom
            )
            ?.map((item: any) => item?.user);

          const isUserInOtherCollaborators = (() => {
            const isInDraftChecklist =
              draftChecklistUsers?.includes(userToDelete);
            const isInTemplateCollaborators =
              selectedDraftData?.version === 0 &&
              templateUserIds?.includes(userToDelete);
            return isInDraftChecklist || isInTemplateCollaborators;
          })();

          let finalCollaborators;
          if (!isUserInOtherCollaborators) {
            const updatedChecklistCollaborators =
              checklistCollaborators?.filter(
                (collaborator: any) => collaborator !== userToDelete
              );
            finalCollaborators = [
              ...updatedChecklistCollaborators,
              ...(selectedDraftData?.version === 0 ? templateUserIds : ''),
            ];
          } else {
            finalCollaborators = [
              ...checklistCollaborators,
              ...(selectedDraftData?.version === 0 ? templateUserIds : ''),
            ];
          }

          const updateDraftDataPayload = {
            id: selectedDraftData?.id,
            body: { collaborators: finalCollaborators },
          };
          updateDraftData(updateDraftDataPayload);
          enqueueSnackbar(
            isUserInOtherCollaborators
              ? 'Checklist item added successfully!'
              : 'Checklist item deleted successfully!',
            {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }
          );
        }
      },
      onError: (error: any) => {
        const message = error?.response?.data?.__all__;
        enqueueSnackbar(`${message || 'Failed to add new checklist item!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: partialUpdateDraft, isPending } = useMutation({
    mutationKey: ['update_draft_partialy', openDeleteDialog],
    mutationFn: updateActiveStatus,
    onSuccess(response: any) {
      if (response?.data?.deleted_status) {
        const userToDelete = deleteData?.user;
        const checklistIdToDeleteFrom = deleteData?.id;
        const draftChecklistUsers = (getDraftCheckList ?? [])
          ?.filter(
            (item: any) => item?.user && item?.id !== checklistIdToDeleteFrom
          )
          ?.map((item: any) => item?.user);

        const isUserInOtherCollaborators = (() => {
          const isInDraftChecklist =
            draftChecklistUsers?.includes(userToDelete);
          const isInTemplateCollaborators =
            selectedDraftData?.version === 0 &&
            templateUserIds?.includes(userToDelete);
          return isInDraftChecklist || isInTemplateCollaborators;
        })();

        let finalCollaborators;
        if (!isUserInOtherCollaborators) {
          const updatedChecklistCollaborators = checklistCollaborators?.filter(
            (collaborator: any) => collaborator !== userToDelete
          );
          finalCollaborators = [
            ...updatedChecklistCollaborators,
            ...(selectedDraftData?.version === 0 ? templateUserIds : ''),
          ];
        } else {
          finalCollaborators = [
            ...checklistCollaborators,
            ...(selectedDraftData?.version === 0 ? templateUserIds : ''),
          ];
        }
        const collaborators = Array.from(
          new Set(
            finalCollaborators?.filter((collaborator) => collaborator != null)
          )
        );

        const updateDraftDataPayload = {
          id: selectedDraftData?.id,
          body: { collaborators: collaborators },
        };
        updateDraftData(updateDraftDataPayload);

        enqueueSnackbar(
          isUserInOtherCollaborators
            ? 'Checklist updated successfully!'
            : 'Checklist item deleted successfully!',
          {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
        if (openDeleteDialog) {
          handleCloseDeleteDialog();
        }
        queryClient.invalidateQueries({
          queryKey: ['draft_checkLists'],
        });
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['draft_checkLists'],
        });
      }
    },
  });

  const { mutate: updateReminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleAddCheckList = (payload: any) => {
    const allChecklistNames = getDraftCheckList?.map(
      (item: any) => item?.checklist_name
    );
    if (userIsOwner) {
      const existingChecklists = allChecklistNames?.includes(
        payload.checklist_name
      );
      if (existingChecklists) {
        enqueueSnackbar('A checklist with the same name already exists', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return;
      } else {
        addChecklistItem({ ...payload, draft: draftId });
      }
    } else {
      enqueueSnackbar('You are not owner of this draft', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleActiveStatus = (value: boolean, body: any) => {
    if (isExternal) return;
    if (body.id) {
      const payload = {
        id: body?.id,
        body: {
          active_status: value,
        },
      };
      partialUpdateDraft(payload);
    } else {
      const payload = {
        ...body,
        active_status: true,
        draft: draftId,
      };
      addChecklistItem(payload);
    }
  };

  const handleAssignee = (body: any) => {
    if (body?.assignee_type === 'internal') {
      const index = collaborators?.findIndex(
        (collaborator: any) => collaborator === body.id
      );
      if (index === -1) {
        setCollaborators((prev: any) => [...prev, body.id]);
      }
    }
    if (passPropData?.id) {
      const payload = {
        id: passPropData?.checklist || passPropData?.id,
        body: {
          active_status: passPropData?.active_status,
          draft: passPropData?.draft?.id || passPropData?.draft,
          checklist: passPropData?.checklist?.id,
          created_by: passPropData?.created_by?.id || passPropData?.created_by,
          ...body,
        },
      };
      updateChecklistItem(payload);
    } else {
      const payload = {
        ...body,
        draft: draftId,
        ...passPropData,
      };
      addChecklistItem(payload);
    }
  };

  const handleDeleteChecklist = (id: string) => {
    if (id) {
      const payload = {
        id: id,
        body: {
          deleted_status: true,
        },
      };
      partialUpdateDraft(payload);
    } else {
      const payload = {
        ...deleteData,
        draft: draftId,
        deleted_status: true,
      };
      addChecklistItem(payload);
    }
  };

  const handleOpenDeleteDialog = (id: string, data: any) => {
    setOpenDeleteDialog(true);
    setDeleteChecklistId(id);
    setDeleteData(data);
  };

  const handleOpenAssigneeDialog = useCallback((data: any) => {
    setOpenAssignDialog(true);
    setPassPropData(data);
  }, []);

  const handleOpenUploadDialog = (listItem: any) => {
    if (
      userIsOwner ||
      (user_data?.email && user_data?.email === listItem?.email) ||
      (external_email && external_email === listItem?.email)
    ) {
      const supportDocArray = listItem?.support_doc?.map((item: any) => {
        const { file_name, ...otherDetails } = item;
        return {
          name: file_name,
          ...otherDetails,
        };
      });
      setSupportDocList(supportDocArray);
      setChecklistItemData(listItem);
      setOpenUploadDialog(true);
    } else {
      enqueueSnackbar('You are not assigned any role for this checklist!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (queryParamsTypeValue === 'checklist' && reminderId) {
      setDialogReminderData(reminderData);
      handleOpenAssigneeDialog(reminderData);
      setDialogOpened(true);
    }
  }, [
    queryParamsTypeValue,
    reminderId,
    reminderData,
    handleOpenAssigneeDialog,
  ]);

  const { mutate: addBulkCommentMutation } = useMutation({
    mutationKey: ['draft_checklist_bulk_comments'],
    mutationFn: addDraftChecklistBulkComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_checkLists'],
      });
    },
  });

  const isOwner = selectedDraftData?.owners?.some(
    (owner: { id: string }) => owner?.id === user_data?.id
  );

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [getDraftCheckList]);

  const handleCommentsClick = useCallback(
    (clickedApprover: any) => {
      if (isButtonClicked) {
        setIsButtonClicked(false);
      }
      const bulkCommentIds = clickedApprover?.comments
        ?.filter((comment: any) => !comment?.is_read)
        ?.map((comment: any) => comment?.id);
      const hasUnreadCommentsByOthers = clickedApprover?.comments?.some(
        (comment: any) =>
          comment?.created_by?.id !== user_data?.id &&
          !comment?.is_read &&
          clickedApprover?.user === comment?.commented_for?.id
      );
      const canCallApiAsOwner = !isOwner && hasUnreadCommentsByOthers;
      const canCallApiAsNonOwner =
        isOwner &&
        bulkCommentIds?.length > 0 &&
        clickedApprover?.comments?.some(
          (comment: any) =>
            !comment?.is_read &&
            comment?.created_by?.id !== clickedApprover?.created_by
        );

      if (
        bulkCommentIds.length > 0 &&
        (canCallApiAsOwner || canCallApiAsNonOwner)
      ) {
        const payload = {
          ids: bulkCommentIds,
          is_read: true,
        };
        addBulkCommentMutation(payload);
      }
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }
    },
    [addBulkCommentMutation, isOwner, user_data?.id, isButtonClicked]
  );

  const handleButtonClick = (userData: any) => {
    const filteredChecklist =
      getDraftCheckList?.filter(
        (checklist: any) => checklist?.id === userData?.checklist
      ) || [];

    const filteredChecklistId =
      getDraftCheckList
        ?.filter((checklist: any) => checklist?.id === userData?.checklist)
        .map((checklist: any) => checklist?.id) || [];

    const isComments = filteredChecklist?.some(
      (checklist: any) => checklist?.comments?.length > 0
    );
    handleCloseAssignDialog();
    if (isComments) {
      setIsButtonClicked(true);
      handleCommentsClick(filteredChecklist[0]);
      setAccordionExpanded(userData?.checklist);
    } else {
      setShowCommentsInput(filteredChecklistId[0]);
    }
  };

  return (
    <React.Fragment>
      <Stack spacing={2} margin="15px">
        {!isExternal && (
          <Accordion
            sx={{
              background: '#88305F24',
              boxShadow: 'none',
              borderRadius: '5px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction="row" spacing={1}>
                <AddIcon />
                <Typography>Add another checklist item</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <AddNewChecklist
                handleSubmit={handleSubmit}
                control={control}
                contractTypeId={contractTypeId}
                handleAddCheckList={handleAddCheckList}
                loadingAddCheckList={loadingAddCheckList}
                loadingCreateCheckList={loadingAddCheckList}
                isDraftDropped={isDraftDropped}
              />
            </AccordionDetails>
          </Accordion>
        )}
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography fontSize="14px">
            Upload files against each to complete items
          </Typography>

          <Typography fontSize="14px">
            {checked?.length}/{getDraftCheckList?.length || 0} completed
          </Typography>
        </Grid>
        {isLoadingCheckList ? (
          <LinearProgress />
        ) : (
          <Checklist
            isDraftDropped={isDraftDropped}
            setOpenUploadDialog={setOpenUploadDialog}
            handleOpenUploadDialog={handleOpenUploadDialog}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleOpenAssigneeDialog={handleOpenAssigneeDialog}
            handleActiveStatus={handleActiveStatus}
            checklistData={getDraftCheckList}
            isExternal={isExternal}
            userIsOwner={!userIsOwner}
            updateReminder={updateReminder}
            user_data={user_data}
            isOwner={isOwner}
            handleCommentsClick={handleCommentsClick}
            commentsContainerRef={commentsContainerRef}
            accordionExpanded={accordionExpanded}
            setAccordionExpanded={setAccordionExpanded}
            showCommentsInput={showCommentsInput}
            setShowCommentsInput={setShowCommentsInput}
            openAssignDialog={openAssignDialog}
            isButtonClicked={isButtonClicked}
            setIsButtonClicked={setIsButtonClicked}
            activeChecklistId={activeChecklistId}
            setActiveChecklistId={setActiveChecklistId}
          />
        )}
      </Stack>

      {openAssignDialog && (
        <AssignDialog
          open={openAssignDialog}
          onClose={handleCloseAssignDialog}
          propsData={passPropData}
          handleAssignee={handleAssignee}
          loadingUpdateItem={loadingUpdateItem}
          approversData={approversData}
          isExternal={isExternal}
          dialogReminderData={dialogReminderData}
          dialogOpened={dialogOpened}
          setDialogReminderData={setDialogReminderData}
          setReminderPayload={setReminderPayload}
          draftData={selectedDraftData}
          allChecklists={getDraftCheckList}
          updateDraftData={updateDraftData}
          templateCollaborators={templateUserIds}
          queryParamsTypeValue={queryParamsTypeValue}
          handleButtonClick={handleButtonClick}
          isButtonClicked={isButtonClicked}
          setIsButtonClicked={setIsButtonClicked}
          setActiveChecklistId={setActiveChecklistId}
          setShowCommentsInput={setShowCommentsInput}
        />
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          handleDeleteChecklist={handleDeleteChecklist}
          deleteChecklistId={deleteChecklistId}
          loadingDeleteItem={isPending}
          name={deleteData?.checklist_name}
        />
      )}
      {openUploadDialog && (
        <UploadSupportDocs
          open={openUploadDialog}
          handleCloseModal={handleCloseUploadDialog}
          isExternal={isExternal}
          checklistItemData={checklistItemData}
          draftId={draftId}
          externalToken={external_token}
          documentList={supportDocList}
          updateReminder={updateReminder}
        />
      )}
    </React.Fragment>
  );
};

export default CheckListComponent;
