export const statisticTabs = [
  {
    label: 'Total templates',
    value: 'template',
    count: 0,
    apiKey: 'total_templates',
  },
  {
    label: 'Riverus templates',
    value: 'system_templates',
    count: 0,
    apiKey: 'system_templates',
  },
  {
    label: 'Your templates',
    value: 'my_templates',
    count: 0,
    apiKey: 'my_templates',
  },
];

export const TemplateTableTabs = [
  { label: 'Contract Templates', value: 'template' },
  // { label: 'Support Document Templates', value: 'support_doc_template' },
];

export const selectTypeDocument = [
  { id: 1, name: 'Invoice' },
  { id: 2, name: 'Purchase Order' },
  { id: 3, name: 'Receipt' },
  { id: 4, name: 'Correspondence' },
  { id: 5, name: 'Approval/Licenses' },
  { id: 6, name: 'Litigation Papers' },
  { id: 7, name: 'GST / Trade Docs' },
  { id: 8, name: 'Reports' },
  { id: 9, name: 'Checklist' },
];
