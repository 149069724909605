import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { GridSortModel } from '@mui/x-data-grid';
// import { useInfiniteQuery } from '@tanstack/react-query';

// import {
//   getKeyCloakRealmFromLS,
//   getSessionStorage,
// } from '../../Authentication/Actions/authentication';
import { IObject } from '../../interfaces/IObject';
// import { fetchDocLibContracts } from '../../Services/DocumentLibrary';

const initialFilter = {
  end_date: null,
  projects: null,
  title: null,
  groups: null,
  contract_type: null,
  contracting_parties: null,
  status: null,
};

interface IContextProps {
  activeTab: string;
  handleActiveTab: (value: string) => void;
  activeHeader: string;
  setActiveHeader: Dispatch<SetStateAction<string>>;
  filters: IObject;
  setFilters: Dispatch<SetStateAction<IObject>>;
  sorting: GridSortModel;
  setSorting: Dispatch<SetStateAction<GridSortModel>>;
  documentLibraryData: any;
  excelDataLoading: boolean;
  fetchExcel: boolean;
  setFetchExcel: Dispatch<SetStateAction<boolean>>;
}

const DocumentLibraryContext = React.createContext<IContextProps | null>(null);

export const useDocumentLibrary = (): IContextProps => {
  const context = React.useContext(DocumentLibraryContext);
  if (!context) {
    throw new Error(
      `useDocumentLibrary must be used within a DocumentLibraryProvider`
    );
  }

  const {
    activeTab,
    handleActiveTab,
    activeHeader,
    setActiveHeader,
    filters,
    setFilters,
    sorting,
    setSorting,
    documentLibraryData,
    excelDataLoading,
    fetchExcel,
    setFetchExcel,
  } = context;

  return {
    activeTab,
    handleActiveTab,
    activeHeader,
    setActiveHeader,
    filters,
    sorting,
    setFilters,
    setSorting,
    documentLibraryData,
    excelDataLoading,
    fetchExcel,
    setFetchExcel,
  };
};

interface IProps {
  children: React.ReactNode;
}

const DocumentLibraryProvider: FC<IProps> = (props) => {
  const [activeTab, setActiveTab] = useState('active');
  const [activeHeader, setActiveHeader] = useState<string>('contracts_count');
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  //   const realm_name = getKeyCloakRealmFromLS();

  //   const user_data = useMemo(() => getSessionStorage('user_profile'), []);
  //   const [pageNumber, setPageNumber] = useState<number>(1);
  //   const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [fetchExcel, setFetchExcel] = useState<boolean>(false);

  const handleActiveTab = (value: string) => {
    setActiveTab?.(value);
  };

  //   const {
  //     data: excelData,
  //     isFetching,
  //     // fetchNextPage,
  //     hasNextPage,
  //   } = useInfiniteQuery({
  //     queryKey: [
  //       'contract-excel',
  //       filters,
  //       activeTab,
  //       activeHeader,
  //       pageNumber,
  //       totalPageCount,
  //     ],
  //     queryFn: async ({ pageParam }) => {
  //       let params = '';

  //       let filterParam = '';
  //       for (const key in filters) {
  //         if (filters[key]) {
  //           filterParam = `${filterParam}&${key}=${filters[key]}`;
  //         }
  //       }

  //       let filterTypeParam = '';
  //       if (activeHeader && activeHeader !== 'contracts_count') {
  //         filterTypeParam = `&${activeHeader}=true`;
  //       }

  //       let sortingParam = '';

  //       if (sorting.length > 0) {
  //         for (let i = 0; i < sorting.length; i++) {
  //           if (sorting[i].sort === 'asc') {
  //             sortingParam = `${sortingParam}?ordering=${sorting[i].field}`;
  //           } else {
  //             sortingParam = `${sortingParam}?ordering=-${sorting[i].field}`;
  //           }
  //         }
  //       } else {
  //         sortingParam = '?ordering=-created_on';
  //       }

  //       if (activeTab === 'all') {
  //         params = `${sortingParam}&page=${
  //           pageParam
  //         }${filterParam}${filterTypeParam}`;
  //       } else if (activeTab === 'active') {
  //         params = `${sortingParam}&page=${
  //           pageParam
  //         }&active=true${filterParam}${filterTypeParam}`;
  //       } else if (activeTab === 'expired') {
  //         params = `${sortingParam}&page=${
  //           pageParam
  //         }&expired=true${filterParam}${filterTypeParam}`;
  //       } else if (activeTab === 'my_contracts') {
  //         params = `${sortingParam}&page=${pageParam}&created_by=${realm_name}__${
  //           user_data?.email
  //         }${filterParam}${filterTypeParam}`;
  //       }
  //       const response: any = await fetchDocLibContracts(params);
  //       const pageCount = Math.ceil(response?.count / 10);
  //       setTotalPageCount(pageCount);
  //       if (pageNumber < totalPageCount) {
  //         setPageNumber(pageParam + 1);
  //       }
  //       return { contracts: response?.results, nextPage: pageParam + 1 };
  //     },
  //     initialPageParam: pageNumber,
  //     getNextPageParam: (lastPage) => {
  //       if (lastPage?.nextPage - 1 === totalPageCount) {
  //         return;
  //       }
  //       return lastPage?.nextPage;
  //     },
  //     enabled: !!realm_name && fetchExcel,
  //   });

  //   React.useEffect(() => {
  //     if (pageNumber < totalPageCount + 1 && hasNextPage) {
  //       fetchNextPage();
  //     }
  //   }, [pageNumber, hasNextPage, totalPageCount]);

  const excelDataLoading = false;
  // useMemo(
  //     () => isFetching || hasNextPage,
  //     [isFetching, hasNextPage]
  //   );

  const documentLibraryData: any = [];
  // useMemo(
  //     () =>
  //       excelDataLoading
  //         ? []
  //         : excelData?.pages.flatMap((page: any) => page?.contracts),
  //     [excelData?.pages, excelDataLoading]
  //   );

  const value = React.useMemo(
    () => ({
      activeTab,
      handleActiveTab,
      activeHeader,
      setActiveHeader,
      filters,
      setFilters,
      sorting,
      setSorting,
      documentLibraryData,
      excelDataLoading,
      fetchExcel,
      setFetchExcel,
    }),
    [
      activeTab,
      activeHeader,
      filters,
      sorting,
      documentLibraryData,
      excelDataLoading,
      fetchExcel,
    ]
  );

  return <DocumentLibraryContext.Provider value={value} {...props} />;
};

export default DocumentLibraryProvider;
