import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  bulkDeleteDocs,
  deleteContractDoc,
  fetchContractById,
  fetchGroups,
  upload_file_in_s3_bucket,
  uploadDoc,
} from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleRefresh: VoidFunction;
  selectedRow: GridRowId[];
}

const SparkMD5 = require('spark-md5');

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6D264C' : '#308fe8',
  },
}));

const UploadContractDoc: React.FC<Props> = ({
  open,
  onClose,
  handleRefresh,
  selectedRow,
}) => {
  const [uploadedContractFile, setUploadedContractFile] = useState<any>([]);
  const [uploadedContractData, setUploadedContractData] = useState<any[]>([]);
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(true);
  const [deletedContractId, setDeletedContractId] = useState<string>('');
  const [uploadedAllFile, setUploadedAllFile] = useState<any>({});
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const { control, watch, setValue } = useForm();

  const group = watch('groups') || '';

  const disabledUploadFileField = useMemo(() => {
    if (group?.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [group]);

  const onUploadProgress = useCallback(
    (progressEvent: any, fileData: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = fileData;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedContractFile]
  );

  const { data: groupData, isLoading: groupLoading } = useQuery({
    queryKey: ['doc_library_groups'],
    queryFn: async () => await fetchGroups(),
  });

  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_id', selectedRow?.[0]],
    queryFn: async () => {
      const response = await fetchContractById(selectedRow?.[0]?.toString());
      return response;
    },
    enabled: !!selectedRow?.[0],
  });

  useEffect(() => {
    if (contract_data) {
      setValue('groups', contract_data.groups);
    }
  }, [contract_data]);

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: uploadFileInS3Bucket, isPending: s3BucketLoading } =
    useMutation({
      mutationKey: ['upload_contract_to_s3_bucket'],
      mutationFn: upload_file_in_s3_bucket,
      onSuccess: () => {
        enqueueSnackbar('Document uploaded successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to upload document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadDocument, isPending: isLoadingUploadDoc } = useMutation(
    {
      mutationKey: ['upload_contract_file', uploadedContractFile],
      mutationFn: uploadDoc,
      onSuccess: (response: any) => {
        if (response.data) {
          setUploadedContractData((prevData) => [
            response?.data?.contract,
            ...prevData,
          ]);
          setIsShowUploadField(false);
          Object.entries(uploadedAllFile)?.map(([key]) => {
            if (
              response?.data?.contract?.file_name === uploadedAllFile[key]?.name
            ) {
              const file = uploadedAllFile[key];
              if (file) {
                const onHandleFileProgress = {
                  onUploadProgress: (progressEvent: any) =>
                    onUploadProgress(progressEvent, file),
                };
                uploadFileInS3Bucket({
                  presignedPostData: response?.data?.presigned_url,
                  file: file,
                  onHandleFileProgress: onHandleFileProgress,
                });
              }
            }
          });
        }
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errors?.[0];
        enqueueSnackbar(`${responseData || 'Failed to upload document!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  const { mutate: deleteUploadedDoc, isPending: isLoadingDeleteDoc } =
    useMutation({
      mutationKey: ['delete_uploaded_doc', deletedContractId],
      mutationFn: deleteContractDoc,
      onSuccess: (data, deletedContractId) => {
        enqueueSnackbar('Document deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        setUploadedContractData((prevData) =>
          prevData.filter((item) => item?.id !== deletedContractId)
        );
        handleCloseDeleteModal();
      },
      onError: () => {
        enqueueSnackbar('Failed to delete Document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  useEffect(() => {
    if (uploadedContractData?.length === 0) {
      setIsShowUploadField(true);
    }
  }, [uploadedContractData]);

  const { mutate: deleteBulkUploadedDocs, isPending: isLoadingBulkDelete } =
    useMutation({
      mutationKey: ['delete_bulk_uploaded_docs'],
      mutationFn: bulkDeleteDocs,
      onSuccess: () => {
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Failed to delete all documents!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const handleFileUploadChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFile: any = event.target.files;
    setUploadedAllFile(uploadedFile);
    Object.entries(uploadedFile)?.map(([key, value]) => {
      setUploadedContractFile({ [key]: value });
      const reader = new FileReader();
      const file = uploadedFile[key];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_: any = {
            file_hash: hexHash,
            file_name: file.name,
            file_size: file.size,
            uploaded_type: selectedRow?.length === 1 ? 'Amendment' : 'Contract',
            groups: group,
          };
          if (selectedRow?.length === 1) {
            file_ = {
              ...file_,
              refcontract_id: selectedRow?.[0],
            };
          }
          uploadDocument(file_);
        };
      }
    });
  };

  const handleBulkDelete = () => {
    if (uploadedContractData?.length > 0) {
      const ids = uploadedContractData?.map((data: any) => data.id);
      deleteBulkUploadedDocs(ids);
    } else {
      onClose();
    }
  };

  const deleteUploadedDocById = (id: string) => {
    deleteUploadedDoc(id);
    setDeletedContractId(id);
  };

  const handleSaveAndClose = () => {
    onClose();
    handleRefresh();
  };

  const handleCloseModal = () => {
    if (
      isLoadingBulkDelete ||
      isLoadingDeleteDoc ||
      isLoadingUploadDoc ||
      s3BucketLoading
    ) {
      enqueueSnackbar("Don't close modal because process is going on!", {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      handleBulkDelete();
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        title={
          selectedRow?.length === 1
            ? 'Upload Amendment Contract'
            : 'Upload Contract'
        }
        open={open}
        handleClose={handleCloseModal}
      >
        <Stack spacing={2} mt="25px" width="550px">
          <RISelectComponent
            name="groups"
            control={control}
            label="Select group"
            valueKey="name"
            options={sortListAlphabetical(groupData)}
            loading={groupLoading}
            isMultiselect={true}
            renderCustomComponent={(value: any, props) => (
              <CustomChip
                icon={<GroupWorkOutlinedIcon style={{ color: '#6D264C' }} />}
                label={value?.name}
                {...props}
              />
            )}
          />

          {isShowUploadField && (
            <Controller
              name="upload_contract"
              control={control}
              render={() => (
                <TextField
                  variant="outlined"
                  label={
                    selectedRow?.length === 1
                      ? 'Browse Amendment Contracts to upload'
                      : 'Browse Contracts to upload'
                  }
                  fullWidth
                  disabled
                  helperText="Supported file formats: PDF, DOCX"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor={`upload-doc`}>
                          <input
                            disabled={disabledUploadFileField}
                            type="file"
                            multiple
                            id={`upload-doc`}
                            style={{ display: 'none' }}
                            accept="pdf, docx"
                            onChange={(e) => handleFileUploadChanges(e)}
                          />
                          <IconButton
                            component="span"
                            disabled={disabledUploadFileField}
                          >
                            <FileUploadOutlinedIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}

          {(isLoadingUploadDoc || s3BucketLoading) && (
            <Box sx={{ width: '100%', padding: '15px 0' }}>
              <LinearProgress />
            </Box>
          )}

          {uploadedContractData?.length > 0 &&
            uploadedContractData?.map((item: any, index: number) => (
              <Stack
                key={index}
                direction="column"
                spacing={1}
                paddingBottom="15px"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize="17px" fontWeight={600}>
                    {item?.file_name}
                  </Typography>
                  <LoadingButton
                    disabled={isLoadingBulkDelete}
                    size="small"
                    onClick={() => handleOpenDeleteModal(item?.id)}
                    loading={isLoadingDeleteDoc}
                  >
                    <DeleteOutlineIcon />
                  </LoadingButton>
                </Stack>
                <BorderLinearProgress variant="determinate" value={100} />
              </Stack>
            ))}

          <Stack direction="row" spacing={2} pt="15px">
            {uploadedContractData?.length === 0 && (
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>
            )}
            {uploadedContractData?.length > 0 && (
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  disabled={isLoadingBulkDelete || isLoadingDeleteDoc}
                  loading={s3BucketLoading || isLoadingUploadDoc}
                  variant="contained"
                  onClick={handleSaveAndClose}
                  style={{ fontSize: '15px', padding: '3px 15px' }}
                >
                  Save and close
                </LoadingButton>
                {!isShowUploadField && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setIsShowUploadField(true)}
                  >
                    Upload more
                  </Button>
                )}
                <LoadingButton
                  onClick={handleBulkDelete}
                  startIcon={<CloseIcon />}
                  disabled={
                    s3BucketLoading || isLoadingUploadDoc || isLoadingDeleteDoc
                  }
                  loading={isLoadingBulkDelete}
                  loadingPosition="end"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CustomModal>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteUploadedDocById(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default UploadContractDoc;
