import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as changesets from 'json-diff-ts';
import { Controller, useForm } from 'react-hook-form';

import ControlledTextField from '../../../../../RiverusUI/Components/ControlledTextField';
import { ClauseType } from '../../../../Constants/ClauseType';
import {
  dateInfo,
  DurationType,
  editedDates,
  tableInfo,
} from '../../../../State/documentState';
import { getClauseDataFormat } from '../../../ClauseComponent/utils/ClauseTypeUtils';
import {
  dateBiMap,
  getTermDurationFromChildArray,
} from '../../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  savedDuration: any;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedTableCells: tableInfo[] | null;

  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedHighlightedDates: dateInfo[] | null;
  saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) => void;
  dateInAddEditMode: dateInfo | null;
  saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) => void;
  dateEditingStatus: boolean;
  saveDateEditingStatus: (dateEditingStatus: boolean) => void;
  durationList: DurationType[];
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  onClose: () => void;
  updatedClauseDataByType: any;
  sentenceData: any;
  clauseDataByType: any;
  parentClauseType: any;
  clauseData: any;
  updatedClauseData: any;
}

const EditDuration: React.FC<Props> = (props) => {
  const [dateInAddMode, setDateInAddMode] = useState<dateInfo>({
    dateId: -1,
    phrase: '',
    paraId: -1,
    rowId: -1,
    columnId: -1,
    duration_value: -1,
    duration_typeid: -1,
  });
  const [selectedOption, setSelectedOption] = useState<DurationType>({
    durationTypeId: -1,
    durationName: '',
    durationType: '',
  });

  const { control } = useForm();

  useEffect(() => {
    getTermDurationFromChildArray(props.savedDuration);
  }, [props.savedDuration, props.durationList]);

  useEffect(() => {
    if (
      props.dateInAddEditMode !== null &&
      props.dateInAddEditMode.dateId === -1
    ) {
      if (
        dateInAddMode !== null &&
        (props.dateInAddEditMode.paraId !== dateInAddMode.paraId ||
          props.dateInAddEditMode.rowId !== dateInAddMode.rowId ||
          props.dateInAddEditMode.columnId !== dateInAddMode.columnId)
      ) {
        setDateInAddMode(props.dateInAddEditMode);
      }
    }
  }, [props.dateInAddEditMode, dateInAddMode]);

  const setDurationValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const duration: number =
      isNaN(Number(value)) || value.length === 0 ? -1 : Number(value);
    const tempDateInAddMode: dateInfo = {
      ...dateInAddMode,
      duration_value: duration,
    };
    const durationString: string = duration > -1 ? String(duration) : '';
    let durationTypeName = '';
    if (
      tempDateInAddMode.duration_typeid !== undefined &&
      tempDateInAddMode.duration_typeid > -1
    ) {
      const durationType: DurationType = props.durationList.filter(
        (item: any) => item.durationTypeId === tempDateInAddMode.duration_typeid
      )[0];
      durationTypeName += durationType.durationName;
    }
    tempDateInAddMode.phrase = `${durationString} ${durationTypeName}`;
    setDateInAddMode(tempDateInAddMode);
    props.saveDateEditingStatus(false);
    props.saveHighlightedId(null);
    props.saveHighlightedTableCells(null);
    props.saveDateInAddEditMode(null);
  };

  const editLinkedPara = (dateInfo: dateInfo) => {
    if (
      dateInfo.paraId &&
      dateInfo.paraId !== -1 &&
      dateInfo.rowId &&
      dateInfo.rowId !== -1 &&
      dateInfo.columnId &&
      dateInfo.columnId !== -1
    ) {
      const tempTableCell = {
        paraId: dateInfo.paraId,
        rowId: dateInfo.rowId,
        columnId: dateInfo.columnId,
      };
      const scrollToTableCellId = document.getElementById(
        `p${dateInfo.paraId};r${dateInfo.rowId};c${dateInfo.columnId}`
      );
      scrollToTableCellId !== undefined &&
        scrollToTableCellId !== null &&
        scrollToTableCellId.scrollIntoView({ block: 'center' });
      props.saveHighlightedTableCells([tempTableCell]);
      props.saveHighlightedId(null);
    } else if (
      dateInfo.paraId !== null &&
      dateInfo.paraId !== -1 &&
      (dateInfo.rowId === null || dateInfo.rowId === -1) &&
      (dateInfo.columnId === null || dateInfo.columnId === -1)
    ) {
      const scrollToParaId = document.getElementById(`p${dateInfo.paraId}`);
      scrollToParaId !== undefined &&
        scrollToParaId !== null &&
        scrollToParaId.scrollIntoView({ block: 'center' });
      props.saveHighlightedId([dateInfo.paraId]);
      props.saveHighlightedTableCells(null);
    }
    props.saveDateInAddEditMode(dateInfo);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveDateEditingStatus(true);
    props.editOptionSelected(true);
  };

  const linkToPara = (dateInfo: dateInfo) => {
    props.saveDateEditingStatus(true);
    props.saveHighlightedId(null);
    props.saveHighlightedTableCells(null);
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveDateInAddEditMode(dateInfo);
  };

  const setDuration = (duration: DurationType) => {
    const tempDateInAddMode: dateInfo = dateInAddMode;
    tempDateInAddMode.duration_typeid = duration.durationTypeId;
    setSelectedOption(duration);
    setDateInAddMode(tempDateInAddMode);
  };

  const getDuration = () => {
    return (
      <Stack direction="row" width="100%" spacing={1}>
        <ControlledTextField
          name="duration"
          type="number"
          control={control}
          label="Duration"
          onChange={setDurationValue}
          fullWidth
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel htmlFor="Select-duration">Select Duration</InputLabel>
              <Select
                {...field}
                required
                label="Select Duration"
                variant="outlined"
                inputProps={{
                  id: 'Select-duration',
                }}
              >
                {props.durationList.map((durationItem: any, i: number) => (
                  <MenuItem
                    key={i}
                    value={durationItem}
                    onClick={() => setDuration(durationItem)}
                  >
                    {durationItem.durationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    );
  };

  const getSaveStatus = () => {
    let isSavedDatesLinked = false;
    if (
      dateInAddMode.duration_value !== undefined &&
      dateInAddMode.duration_value > -1 &&
      dateInAddMode.duration_typeid !== undefined &&
      dateInAddMode.duration_typeid > -1
    ) {
      isSavedDatesLinked = true;
    }
    return isSavedDatesLinked;
  };

  const onCancel = () => {
    props.saveHighlightedDates(null);
    props.saveDateInAddEditMode(null);
    props.saveHighlightedTableCells(null);
    props.saveHighlightedId(null);
    props.editOptionSelected(false);
    props.saveDateEditingStatus(false);
    setDateInAddMode({
      dateId: -1,
      phrase: '',
      paraId: -1,
      rowId: -1,
      columnId: -1,
      duration_value: -1,
      duration_typeid: -1,
    });
    setSelectedOption({
      durationTypeId: -1,
      durationName: '',
      durationType: '',
    });
    props.onClose();
  };

  const onSave = () => {
    const editedDate: editedDates = {
      upsert: [dateInAddMode],
      deleted: [],
      bi: dateBiMap[props.dataPointName],
    };

    const payload = [];
    payload.push({
      date: editedDate.upsert[0].phrase + ' ' + selectedOption.durationName,
      normalized_date: '',
      paraId: editedDate.upsert[0].paraId,
      table: [
        {
          row: editedDate.upsert[0].rowId,
          column: editedDate.upsert[0].columnId,
        },
      ],
    });
    const updates = getClauseDataFormat(
      'add',
      props.clauseType,
      payload[0],
      props.updatedClauseDataByType,
      props.sentenceData
    );

    const diff = changesets.diff(props.clauseDataByType?.raw_content, updates, {
      children: '$index',
    });

    if (diff.length > -1) {
      props.postClauseDataByType(
        props.fileId,
        props.parentClauseType,
        diff,
        updates
      );
    }

    props.saveHighlightedDates(null);
    props.saveDateInAddEditMode(null);
    props.saveHighlightedTableCells(null);
    props.saveHighlightedId(null);
    props.saveDateEditingStatus(false);
    props.editOptionSelected(false);
    setDateInAddMode({
      dateId: -1,
      phrase: '',
      paraId: -1,
      rowId: -1,
      columnId: -1,
      duration_value: -1,
      duration_typeid: -1,
    });
    setSelectedOption({
      durationTypeId: -1,
      durationName: '',
      durationType: '',
    });
    props.onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>

      <Stack className="edit-clause-select" spacing={2}>
        {getDuration()}
        {dateInAddMode !== null && (
          <>
            {props.dateEditingStatus === true &&
              props.dateInAddEditMode !== null &&
              dateInAddMode.dateId === props.dateInAddEditMode.dateId && (
                <>
                  {props.dateInAddEditMode.paraId !== null &&
                  props.dateInAddEditMode.paraId > -1 ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography fontSize="14px" sx={{ color: '#C1C1C1' }}>
                        Edit linked paragraph
                      </Typography>
                      <Typography fontSize="14px">1</Typography>
                    </Stack>
                  ) : (
                    <Stack spacing={1} width="100%">
                      <Typography fontSize="14px" sx={{ color: '#C1C1C1' }}>
                        Link to paragraph
                      </Typography>
                    </Stack>
                  )}
                </>
              )}
            {((props.dateInAddEditMode !== null &&
              props.dateInAddEditMode.dateId !== dateInAddMode.dateId) ||
              props.dateInAddEditMode === null) && (
              <Stack justifyContent="start" direction="row">
                {dateInAddMode.paraId !== null && dateInAddMode.paraId > -1 && (
                  <Button
                    variant="text"
                    sx={{ color: '#88305F', padding: 0 }}
                    onClick={() => editLinkedPara(dateInAddMode)}
                  >
                    Edit linked paragraph
                  </Button>
                )}
                {(dateInAddMode.paraId === null ||
                  dateInAddMode.paraId === -1) && (
                  <Button
                    variant="text"
                    sx={{ color: '#88305F', padding: 0 }}
                    onClick={() => linkToPara(dateInAddMode)}
                  >
                    Link to paragraph
                  </Button>
                )}
              </Stack>
            )}
          </>
        )}
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {props.dataPointName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the duration using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click on `&quot;`Link To Paragraph`&quot;` button to link a text
            from the contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the desired paragraph that contains{' '}
            {props.dataPointName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={!getSaveStatus()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={onCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditDuration;
