import React, { useEffect, useMemo, useState } from 'react';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { Stack } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AboutThisContract from './AboutThisContractTab/AboutThisContract';
import { contractTabs } from './contractStaticData';
import DocumentHeader from './DocumentHeader';
import DocumentInsights from './DocumentInsight';
import ReminderTab from './Reminders/Index';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import { riPrimary } from '../../RiverusUI/Theme/colors';
import Scrollable from '../../UniversalComponents/Scrollable/scrollable';
import SentenceFrameRenderer from '../../UniversalComponents/SentenceRenderer/Component/sentenceFrameRenderer';
import SentenceRenderer from '../../UniversalComponents/SentenceRenderer/Container/sentenceCon';
import {
  BiPointDataMode,
  ContractDetails,
  CurrencyType,
  DurationType,
  InsightsInterface,
  SentencesData,
} from '../State/documentState';

interface Props {
  sentenceData: SentencesData;
  getSentences: (fileId: string) => void;
  insightsData: InsightsInterface[];
  sentenceLoader: boolean;
  editLoader: boolean;
  editSuccessLoader: boolean;
  editFailureLoader: boolean;
  storedBiPointDataModes: BiPointDataMode[];
  clauseModeStatus: boolean;
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  durationList: DurationType[];
  getDurationTypes: () => void;
  currencyList: CurrencyType[];
  getCurrencyTypes: () => void;
  getContractData: (fileId: string) => void;
  contractData: ContractDetails;
  clauseInsightsLoader: boolean;
  clauseData: any;
  updatedClauseData: any;
}

interface PropState {
  dataPoints: string[];
  clickedState: boolean;
  tempParaIndex: number;
  scrolledChildIndex: number;
  superImposedChildIndex: number;
  type: string;
  scrollTop: boolean;
  fileId: string;
  editOptionSelected: boolean;
  selectedInsightPoint: string;
  currentScrollIndex: number;
  tabs: string[];
  hideTaskPage: boolean;
  editMode: boolean;
  selectedClauseText: string;
  requestID: number;
  taskOrigin: string; // to identify if task page is opened through insights or tasks-icon
  windowMenu: {
    topMenu: boolean;
    selectedTopMenu: string;
    rightMenu: boolean;
    selectedRightMenu: string;
  };
  tabValue: number;
  showClauseModal: boolean;
  clauseLibraryLoader: boolean;
  clause_Library_Loader: boolean;
  create_clause: number;
}

const notifyClauseSuccess = () =>
  enqueueSnackbar('Clause Library added Successfully', {
    variant: 'success',
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });

const notifyClauseError = () =>
  enqueueSnackbar('Oops! Something went wrong...', {
    variant: 'error',
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
  });

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  paddingX: '15px',
};

const DocumentView: React.FC<Props> = (props) => {
  const {
    getSentences,
    getContractData,
    getDurationTypes,
    getCurrencyTypes,
    updatedClauseData,
    clauseData,
    sentenceData,
    insightsData,
    sentenceLoader,
    editLoader,
    editSuccessLoader,
    editFailureLoader,
    storedBiPointDataModes,
    clauseModeStatus,
    editDataPoint,
    durationList,
    currencyList,
    contractData,
    clauseInsightsLoader,
  } = props;

  const navigate = useNavigate();
  const params = useParams<any>();
  const location = useLocation();

  const [state, setState] = useState<PropState>({
    dataPoints: [],
    clickedState: false,
    tempParaIndex: 0,
    scrolledChildIndex: 0,
    type: '',
    superImposedChildIndex: 0,
    scrollTop: false,
    fileId: '0',
    editOptionSelected: false,
    selectedInsightPoint: '',
    currentScrollIndex: 0,
    tabs: [],
    hideTaskPage: true,
    editMode: false,

    selectedClauseText: '',
    requestID: 0,
    taskOrigin: '',
    windowMenu: {
      topMenu: true,
      rightMenu: true,
      selectedTopMenu: 'processed',
      selectedRightMenu: 'about-this-contract',
    },
    tabValue: 0,
    showClauseModal: false,
    clauseLibraryLoader: false,
    clause_Library_Loader: false,
    create_clause: -1,
  });

  const toggleGoToTop = () => {
    if (window.pageYOffset > 600) {
      setState((prevState: PropState) => ({ ...prevState, scrollTop: true }));
    }
    if (window.pageYOffset < 600) {
      setState((prevState: PropState) => ({ ...prevState, scrollTop: false }));
    }
  };

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryParamsValue = queryParams.get('isReminder');

  useEffect(() => {
    if (queryParamsValue) {
      setState((prevState: PropState) => ({
        ...prevState,
        windowMenu: {
          topMenu: true,
          rightMenu: true,
          selectedTopMenu: 'processed',
          selectedRightMenu: 'reminders',
        },
        tabValue: 2,
      }));
    }
  }, [queryParamsValue]);

  useEffect(() => {
    const fileId = params.id as string;

    setState((prevState: PropState) => ({
      ...prevState,
      fileId: atob(fileId),
    }));
    getSentences(fileId);
    getContractData(fileId);
    document.addEventListener('scroll', () => toggleGoToTop());
    getDurationTypes();
    getCurrencyTypes();
    if (params.requestid != null) {
      const requestID = params.requestid;

      setState((prevState: PropState) => ({
        ...prevState,
        requestID: parseInt(atob(requestID)),
      }));
      setState((prevState: PropState) => ({
        ...prevState,
        hideTaskPage: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (state.clause_Library_Loader) {
      notifyClauseSuccess();
      setState((prevState: PropState) => ({
        ...prevState,
        clauseLibraryLoader: false,
        create_clause: -1,
      }));
    }
    if (state.clause_Library_Loader) {
      if (state.create_clause === 1) {
        notifyClauseError();
        setState((prevState: PropState) => ({
          ...prevState,
          clauseLibraryLoader: false,
          create_clause: -1,
        }));
      }
    }
  }, [editLoader]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setState((prevState: PropState) => ({
        ...prevState,
        windowMenu: {
          topMenu: true,
          rightMenu: true,
          selectedTopMenu: 'processed',
          selectedRightMenu: 'about-this-contract',
        },
        tabValue: newValue,
      }));
    } else if (newValue === 1) {
      setState((prevState: PropState) => ({
        ...prevState,
        windowMenu: {
          topMenu: true,
          rightMenu: true,
          selectedTopMenu: 'processed',
          selectedRightMenu: 'editInsights',
        },
        tabValue: newValue,
      }));
    } else if (newValue === 2) {
      setState((prevState: PropState) => ({
        ...prevState,
        windowMenu: {
          topMenu: true,
          rightMenu: true,
          selectedTopMenu: 'processed',
          selectedRightMenu: 'reminders',
        },
        tabValue: newValue,
      }));
    }
  };

  const showClauseModal = () => {
    setState((prevState: PropState) => ({
      ...prevState,
      showClauseModal: true,
      clauseLibraryLoader: false,
      create_clause: -1,
    }));
  };

  const {
    fileId,
    editOptionSelected,
    selectedInsightPoint,
    tabValue,
    windowMenu,
  } = state;

  return (
    <>
      <DocumentHeader
        updatedClauseData={updatedClauseData}
        sentenceData={sentenceData}
      />
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          mb: 2,
          pl: 1.5,
          pr: 1.5,
        }}
      >
        <Grid item sm={6} sx={{ paddingRight: '16px' }}>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            width="100%"
            sx={innerTabStyle}
          >
            <Stack spacing={1} direction="row" height="48px">
              <IconButton>
                <ZoomInIcon />
              </IconButton>
              <IconButton>
                <ZoomOutIcon />
              </IconButton>
            </Stack>
          </Stack>
          {tabValue != null ? (
            <div style={{ marginTop: '10px' }}>
              <Scrollable
                maxHeight="100vh"
                minHeight="100vh"
                id="document-view-scroll"
              >
                <div>
                  {clauseModeStatus ? (
                    <SentenceFrameRenderer
                      sentenceData={sentenceData}
                      sentenceLoader={sentenceLoader}
                      setClauseText={(selectedClauseText: string) =>
                        setState((prevState: PropState) => ({
                          ...prevState,
                          selectedClauseText: selectedClauseText,
                        }))
                      }
                      showClauseModal={showClauseModal}
                    />
                  ) : (
                    <>
                      <SentenceRenderer
                        sentenceData={sentenceData}
                        sentenceLoader={sentenceLoader}
                        editOptionSelected={editOptionSelected}
                        selectedInsightPoint={selectedInsightPoint}
                        fileId={fileId}
                        editDataPoint={editDataPoint}
                        insightsData={insightsData}
                      />
                    </>
                  )}
                </div>
              </Scrollable>
            </div>
          ) : null}
        </Grid>
        <Grid item sm={6} sx={{ zIndex: 3 }}>
          <Tabs
            value={state.tabValue}
            onChange={handleChangeTab}
            aria-label="Contracts tabs"
            variant="scrollable"
            scrollButtons={false}
            sx={innerTabStyle}
          >
            {contractTabs?.map((tab: any, index: number) => (
              <Tab
                key={index}
                label={tab.label}
                value={tab.value}
                {...a11yProps(tab.value)}
              />
            ))}
          </Tabs>
          <Stack
            spacing={1}
            sx={{
              backgroundColor: 'riTertiary.50',
              borderRadius: '20px',
              mt: '8px',
              padding: '10px 16px 20px',
            }}
          >
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === 'editInsights' && (
                <Box>
                  <DocumentInsights
                    sentenceData={sentenceData}
                    editLoader={editLoader}
                    editSuccessLoader={editSuccessLoader}
                    editFailureLoader={editFailureLoader}
                    sentenceLoader={sentenceLoader}
                    fileId={atob(params.id as string)}
                    insightsData={insightsData}
                    saveDataPoints={(
                      dataPoints: string[],
                      state: boolean,
                      tempParaIndex: number,
                      type: string
                    ) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        dataPoints: dataPoints,
                        clickedState: state,
                        tempParaIndex: tempParaIndex,
                        type: type,
                      }))
                    }
                    scrolledChildIndex={state.scrolledChildIndex}
                    superImposeChildIndex={(scrolledChildIndex: number) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        superImposedChildIndex: scrolledChildIndex,
                      }))
                    }
                    location={location}
                    navigate={navigate}
                    editOptionSelected={(editOptionSelected: boolean) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        editOptionSelected: editOptionSelected,
                      }))
                    }
                    storedBiPointDataModes={storedBiPointDataModes}
                    saveSelectedInsightPoint={(selectedInsightPoint: string) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        selectedInsightPoint: selectedInsightPoint,
                      }))
                    }
                    setCurrentScrollIndex={(currentScrollIndex: number) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        currentScrollIndex: currentScrollIndex,
                      }))
                    }
                    currentScrollIndex={state.currentScrollIndex}
                    fileEncoded={params.id as string}
                    setTaskPage={(hideTaskPage: boolean) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        hideTaskPage: hideTaskPage,
                      }))
                    }
                    clauseModeStatus={clauseModeStatus}
                    setTaskOrigin={(origin: string) =>
                      setState((prevState: PropState) => ({
                        ...prevState,
                        taskOrigin: origin,
                      }))
                    }
                    durationList={durationList}
                    currencyList={currencyList}
                    contractData={contractData}
                    clauseInsightsLoader={clauseInsightsLoader}
                    clauseData={clauseData}
                    updatedClauseData={updatedClauseData}
                  />
                </Box>
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === 'about-this-contract' && (
                <AboutThisContract fileId={atob(params.id as string)} />
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === 'reminders' && (
                <ReminderTab fileId={atob(params.id as string)} />
              )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentView;
