import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';

import { tableInfo } from '../../../State/documentState';
import { paraLevelComponents } from '../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint?: (highlight?: boolean) => void;
  dataPointName: string;
  enableHighlightOption: boolean;
  highlightedId: number[] | null;
  enableSaveBtn?: boolean;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  previouslyLinkedPara?: number[];
  previouslyLinkedTableCells?: tableInfo[];
  savedHighlightedTableCells?: tableInfo[] | null;
  saveHighlightedTableCells?: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  handleSubmitSelectedUserGroups?: () => void;
  onClose?: any | null;
  editDataPoint?: () => void;
}

const SaveOrCancel: React.FC<Props> = ({
  editOptionSelected,
  editDataPoint,
  saveHighlightedDataPoint,
  dataPointName,
  enableHighlightOption,
  highlightedId,
  enableSaveBtn,
  saveHighlightedId,
  previouslyLinkedPara,
  previouslyLinkedTableCells,
  savedHighlightedTableCells,
  saveHighlightedTableCells,
  handleSubmitSelectedUserGroups,
  onClose,
}) => {
  const selectedCount = () => {
    const highlightData = highlightedId ? highlightedId : [];
    let highlightCount = highlightData.length;
    const previouslyLinkedId = previouslyLinkedPara ? previouslyLinkedPara : [];
    const highlightTableCount = savedHighlightedTableCells
      ? savedHighlightedTableCells
      : [];
    for (let i = 0; i < highlightTableCount.length; i++) {
      if (highlightData.includes(highlightTableCount[i]['paraId'])) {
        //Do Nothing
      } else {
        if (!previouslyLinkedId.includes(highlightTableCount[i]['paraId'])) {
          highlightCount = highlightCount + 1;
        }
      }
    }
    return highlightCount;
  };

  const [highlight, setHighlight] = useState<boolean>(!enableHighlightOption);
  const [highlightCount, setHighlightCount] = useState<number>(selectedCount());

  useEffect(() => {
    setHighlightCount(selectedCount());
    const timer = setTimeout(() => {
      setHighlightCount(selectedCount());
    }, 500);
    return () => clearTimeout(timer);
  }, [highlight, highlightedId, savedHighlightedTableCells]);

  useEffect(() => {
    setHighlight(!enableHighlightOption);
  }, [enableHighlightOption]);

  const showHighlightedCount = () => {
    if (previouslyLinkedPara || previouslyLinkedTableCells) {
      if (
        (previouslyLinkedPara && previouslyLinkedPara.length > 0) ||
        (previouslyLinkedTableCells && previouslyLinkedTableCells.length > 0)
      ) {
        if (
          ((highlightedId && highlightedId.length > 0) ||
            (savedHighlightedTableCells &&
              savedHighlightedTableCells.length > 0)) &&
          highlight === true
        ) {
          return (
            <Stack width="100%" justifyContent="space-between" direction="row">
              <Typography fontSize="13px" style={{ color: '#C1C1C1' }}>
                Edit linked paragraph(s)
              </Typography>
              <Typography fontSize="13px">
                {highlightCount > 0 ? highlightCount - 1 : 0}
              </Typography>
            </Stack>
          );
        } else {
          return (
            <Stack justifyContent="start" direction="row">
              <Button
                variant="text"
                style={{
                  color: '#88305F',
                  padding: 0,
                }}
                onClick={() => editLinkedPara()}
              >
                Edit linked paragraph
                <span style={{ textTransform: 'lowercase' }}>(s)</span>
              </Button>
            </Stack>
          );
        }
      } else {
        if (
          ((highlightedId !== null &&
            highlightedId !== undefined &&
            highlightedId.length > 0) ||
            (savedHighlightedTableCells !== undefined &&
              savedHighlightedTableCells !== null &&
              savedHighlightedTableCells.length > 0)) &&
          highlight === true
        ) {
          return (
            <Stack width="100%" justifyContent="space-between" direction="row">
              <Typography fontSize="14px" style={{ color: '#C1C1C1' }}>
                Edit linked paragraph(s)
              </Typography>
              <Typography fontSize="14px">
                {highlightCount > 0 ? highlightCount - 1 : 0}
              </Typography>
            </Stack>
          );
        }
        if (enableHighlightOption) {
          return (
            <>
              <Stack justifyContent="start" direction="row">
                <Button
                  variant="text"
                  sx={{
                    color: highlight ? '#C1C1C1' : '#88305F',
                  }}
                  onClick={() => saveHighlightedDataPointFunc()}
                >
                  Link to paragraph(s)
                  <span style={{ textTransform: 'lowercase' }}>(s)</span>
                </Button>
              </Stack>
            </>
          );
        }
      }
    } else {
      return (
        <Stack justifyContent="start" direction="row">
          <Button variant="text" onClick={() => editLinkedPara()}>
            Edit linked paragraph
            <span style={{ textTransform: 'lowercase' }}>(s)</span>
          </Button>
        </Stack>
      );
    }
  };

  const editLinkedPara = () => {
    setHighlight(true);
    if (previouslyLinkedPara) {
      saveHighlightedId(previouslyLinkedPara);
    }
    if (previouslyLinkedTableCells && saveHighlightedTableCells) {
      saveHighlightedTableCells(previouslyLinkedTableCells);
    }
    editOptionSelected(true);
    saveHighlightedDataPoint?.(true);
  };

  const saveHighlightedDataPointFunc = () => {
    setHighlight(true);
    editOptionSelected(true);
    saveHighlightedDataPoint?.(true);
  };

  const cancel = () => {
    onClose();
    setHighlight(false);
    editOptionSelected(false);
    saveHighlightedId(null);
    if (saveHighlightedTableCells) {
      saveHighlightedTableCells(null);
    }
  };

  return (
    <>
      {paraLevelComponents.indexOf(dataPointName) > -1 &&
        dataPointName !== 'Start Dates' &&
        dataPointName !== 'End Dates' &&
        dataPointName !== 'Duration' &&
        dataPointName !== 'Lock-in Period' &&
        dataPointName !== 'Effective Dates' &&
        showHighlightedCount()}
      <Stack width="100%" direction="row">
        <Button
          disabled={!enableSaveBtn}
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={() => {
            editDataPoint?.();
            editOptionSelected(false);
            saveHighlightedId(null);
            setHighlight(false);
            handleSubmitSelectedUserGroups?.();
          }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => cancel()}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default SaveOrCancel;
