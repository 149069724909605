import React, { ReactElement } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

export const reminder = [
  { name: 'Don’t remind me', id: 'don’t_remind_me' },
  { name: '1 day before', id: '1_day_before' },
  { name: '3 days before', id: '3_days_before' },
  { name: '5 days before', id: '5_days_before' },
  { name: '7 days before', id: '7_days_before' },
  { name: '10 days before', id: '10_days_before' },
  { name: '15 days before', id: '15_days_before' },
  { name: '30 days before', id: '30_days_before' },
];

export const reminderStatus = [
  { name: 'Not started', id: 'Not Started' },
  { name: 'In progress', id: 'In Progress' },
  { name: 'Completed', id: 'Completed' },
  { name: 'Past due date', id: 'Past Due Date' },
  { name: 'Flagged', id: 'Flagged' },
];

export const handleReminder = (date: string, id: string): string => {
  const currentDate = new Date(date);

  let daysBefore = 0;
  switch (id) {
    case '1 day before':
      daysBefore = 1;
      break;
    case '3 days before':
      daysBefore = 3;
      break;
    case '5 days before':
      daysBefore = 5;
      break;
    case '7 days before':
      daysBefore = 7;
      break;
    case '10 days before':
      daysBefore = 10;
      break;
    case '15 days before':
      daysBefore = 15;
      break;
    case '30 days before':
      daysBefore = 30;
      break;
    default:
      break;
  }
  const reminderDate = new Date(currentDate);
  reminderDate.setDate(currentDate.getDate() - daysBefore);
  return reminderDate.toISOString();
};

export const calculateReminderId = (
  dueDate: string,
  reminderDate: string
): string | null => {
  const dueDateTime = new Date(dueDate);
  const reminderDateTime = new Date(reminderDate);

  const differenceInDays = Math.floor(
    (dueDateTime.getTime() - reminderDateTime.getTime()) / (1000 * 60 * 60 * 24)
  );

  switch (differenceInDays) {
    case 1:
      return '1 day before';
    case 3:
      return '3 days before';
    case 5:
      return '5 days before';
    case 7:
      return '7 days before';
    case 10:
      return '10 days before';
    case 15:
      return '15 days before';
    case 30:
      return '30 days before';
    default:
      return null;
  }
};

export const calculateDayDifference = (dueDate: string): number => {
  const dueDateTime = new Date(dueDate).getTime();
  const currentTime = new Date().getTime();
  return Math.floor((dueDateTime - currentTime) / (1000 * 60 * 60 * 24));
};

export const reminderDaysMap: { [key: string]: number } = {
  '1 day before': 1,
  '3 days before': 3,
  '5 days before': 5,
  '7 days before': 7,
  '10 days before': 10,
  '15 days before': 15,
  '30 days before': 30,
};

export const sortByCreatedDate = (reminders: any[]) => {
  return reminders?.sort((a, b) => {
    const dateA = new Date(a.created.split('/').reverse().join('-'));
    const dateB = new Date(b.created.split('/').reverse().join('-'));
    return dateB.getTime() - dateA.getTime();
  });
};

export const status_icon: Record<string, ReactElement> = {
  'Not Started': <TimerOutlinedIcon />,
  'In Progress': <AccessTimeIcon />,
  Completed: <CheckCircleOutlineIcon />,
  'Past Due Date': <ErrorOutlineIcon />,
};

export const iconColor: Record<string, string> = {
  'Not Started': '#49454F',
  'In Progress': '#866133',
  Completed: '#3F7713',
  'Past Due Date': '#7B0D0D',
};
