import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, IconButton, Typography } from '@mui/material';

interface IProps {
  title: string;
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  width?: string;
}

const DEFAULT_WIDTH = '120px';

export default function TableHeaderWithSearch(props: IProps) {
  const { title, setIsSearch, width = DEFAULT_WIDTH } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ width: width }}>{title}</Typography>
      <IconButton size="small" onClick={() => setIsSearch(true)}>
        <SearchOutlinedIcon aria-label="search" />
      </IconButton>
    </Box>
  );
}
