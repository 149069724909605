import { connect } from 'react-redux';

import { StoreTree } from '../../../Utils/MainReducer/mainReducer';
import HeimdallActionGen from '../../HeimdallChild/Actions/actionGen';
import Toolbar from '../Components/Toolbar';

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    history: ownProps.history,
    platform: appState.heimdall.platform,
  };
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mapDispatchToProps(dispatch: any) {
  return {
    gotoUmbrella: () => dispatch(HeimdallActionGen.gotoUmbrella()),
    gotoStyllus: () => dispatch(HeimdallActionGen.gotoStyllus()),
    gotoAdmin: () => dispatch(HeimdallActionGen.gotoAdmin()),
    gotoReminder: () => dispatch(HeimdallActionGen.gotoReminder()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
