import { authApi } from './authApi';

export const fetchInternalUsers = async () => {
  return await authApi.get('users/groups/users/?group_type=internal');
};

export const fetchExternalUsers = async (): Promise<any> => {
  return await authApi.get(`users/?assignee_type=external`);
};

export const share = async (payload: any) => {
  return await authApi.post('share/', payload);
};

export const addExternalUser = async (payload: any) => {
  return await authApi.post('users/', payload);
};
