import axios from 'axios';

import { getLocalStorage } from '../Authentication/Actions/authentication';

const APIPaths = {
  getAssignee: process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/',
  updateAssignee: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

export const fetchAssigneeList = async () => {
  const res = await fetch(`${APIPaths.getAssignee}`, APIConfig());
  return res.json();
};

export const updateAssigneeList = (payload: any) => {
  const response = axios.patch(
    `${APIPaths?.updateAssignee}${payload.id}/`,
    payload.body,
    APIConfig()
  );
  return response;
};
