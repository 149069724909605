import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import dayjs from 'dayjs';

interface IProps {
  title: string;
  date?: string;
  subTitle?: string;
  icon?: React.ReactElement<SvgIconProps>;
}

const AssigneeWithDate: FC<IProps> = ({ title, date, subTitle, icon }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {icon && React.cloneElement(icon, { fontSize: 'small' })}{' '}
      <Typography variant="subtitle2" fontWeight={700}>
        {title}
      </Typography>
      <Typography variant="subtitle2">
        {date ? dayjs(date).format('DD-MM-YYYY') : subTitle}
      </Typography>
    </Stack>
  );
};

export default AssigneeWithDate;
