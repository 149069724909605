import React from 'react';

import { SvgIcon } from '@mui/material';

const WebLogoIcon: React.FC = () => {
  return (
    <SvgIcon style={{ fontSize: 40, width: 120 }} viewBox="0 0 508 118">
      <path
        d="M161 35h9v47h-9zm73 0-24 47h-9l-24-47h11l18 36 18-36h10zm64 9h-57v-9h57v9Zm0 19h-57v-9h57v9Zm0 19h-57v-9h57v9Zm16 0h-9V35h57v9h-48v38zm55 0V35h9v38h38V35h10v47h-57zm64 0v-9h47V63h-47V35h56v9h-47v10h47v28h-56zM97 53h56v9H97zm0-18h56v9H97z"
        fill="#003a95"
      />
      <path
        d="M97 35h9v47h-9zm47 0h9v26h-9zm-4 48-27-23 13-1 27 24h-13z"
        fill="#003a95"
      />
      <path d="M25 24h29v29H25z" fill="#ff8b00" transform="rotate(-45 39 39)" />
      <path d="m19 79 20-20 21 20-21 20z" fill="#003a95" />
      <path d="M45 44h29v29H45z" fill="#ea0080" transform="rotate(-45 60 59)" />
    </SvgIcon>
  );
};

export default WebLogoIcon;
