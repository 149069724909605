export const contractTabs = [
  {
    label: 'About this contract',
    value: 0,
  },
  {
    label: 'Insights',
    value: 1,
  },
  {
    label: 'Reminders',
    value: 2,
  },
];
