/* eslint-disable no-undef */
const AUTH0 = {
  apiBasePath: process.env.REACT_APP_RIVERUS_CLM_API,
  localBasePath: process.env.REACT_APP_RIVERUS_CLM_APP_HOST,
  refreshTokenInterval:
    process.env.REACT_APP_RIVERUS_CLM_APP_TOKEN_REFRESH_INTERVAL,
  expiryDuration: process.env.REACT_APP_RIVERUS_CLM_APP_TOKEN_EXPIRY_DURATION,
  keyCloakUrl: process.env.REACT_APP_RIVERUS_CLM_AUTH_KEYCLOAK_URL,
  keyCloakId: process.env.REACT_APP_RIVERUS_CLM_AUTH_KEYCLOAK_CLIENTID,
};

export default AUTH0;
