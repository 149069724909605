export function truncateFileName(name: string) {
  const str1 = name.slice(0, 12);
  const len = name.length;
  const str2 = name.slice(len - 4, len);
  if (len > 15) {
    return str1 + '....' + str2;
  } else {
    return name;
  }
}

export function truncateString(name: string, size: number) {
  const str1 = name.slice(0, size + 1);
  const len = name.length;
  if (len > size) {
    return str1 + '...';
  } else {
    return name;
  }
}

export function getTodaysDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const todaysDate = dd + '/' + mm + '/' + yyyy;
  return todaysDate;
}

export const camelize = (str: any) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[.\s]+/g, ''); // Added condition to replace dots and spaces
};
