import React, { useCallback, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from '../../../../Authentication/Actions/authentication';
import {
  filterInsights,
  getAllFilterInsights,
} from '../../../../Services/DocumentLibrary';
import { ClauseType, ClauseTypeMap } from '../../../Constants/ClauseType';
import { getChildHighlights } from '../../Utils/docUtils';
import ChangeClause from '../ChangeClause';
import ChangeClauseCount from '../ChangeClauseCount';
import ConfidentialityClause from '../ConfidentialityClause';
import ConsentClause from '../ConsentClause';
import DataBreachClause from '../DataBreachClause';
import DisputeClause from '../DisputeClause';
import EffectiveClause from '../EffectiveClause';
import EventClause from '../EventClause';
import ExclusivityClause from '../ExclusivityClause';
import ForceClause from '../ForceClause';
import GeneralClause from '../GeneralClause';
import GoverningClause from '../GoverningClause';
import IndemnityClause from '../Indemnity';
import InsuranceClause from '../InsuranceClause';
import { ClauseComponentInterface } from '../interfaces/ClauseComponentInterface';
import LimitationClause from '../LimitationClause';
import NonCompeteClause from '../NonCompeteClause';
import NonSolicitationClause from '../NonSolicitationClause';
import NoticeClause from '../NoticeClause';
import OtherObligationClause from '../OtherObligationClause';
import PaymentObligationsClause from '../PaymentObligations';
import RenewalClause from '../RenewalClause';
import SublettingClause from '../SublettingClause';
import TermClause from '../TermClause';
import TerminationClause from '../TerminationClause';

interface Props
  extends Omit<ClauseComponentInterface, 'hasData' | 'onClickHighlight'> {
  insightsData: any[];
  scrollToChildDataPoint: any;
  scrollToDatapoint: any;
  getInsights: (fileId: string) => void;
  otherClauses: (type: string) => void;
}

export const ClauseNameMap: Record<string, string> = {
  term: 'Term Clause',
  renewal: 'Renewal Clause',
  payment: 'Payment Obligations',
  indemnity: 'Indemnity/Reimbursements/Costs',
  confidentiality: 'Confidentiality Clause',
  change_of_control: 'Change Of Control',
  termination: 'Termination Clause',
  insurance: 'Insurance Clause',
  notice: 'Notice Obligation',
  events_of_default: 'Events Of Default',
  limited_liability: 'Limitation Of Liability',
  force_majeure: 'Force Majeure',
  governing_law: 'Governing Law Clause',
  dispute_resolution: 'Dispute Resolution',
  non_compete: 'Non-Compete Clause',
  non_solicitation: 'Non-Solicitation Clause',
  subletting: 'Subletting/Assignment',
  consent: 'Consent Clause',
  general_definitions: 'General Definitions',
  data_breach: 'Data Breach',
  effective_date: 'Effective Dates',
  exclusivity: 'Exclusivity',
  obligation_statements: 'Other Obligation',
  termination_at_convenience: 'Termination at convenience',
  locked_in_period: 'Lock In Period',
  assignment: 'Assignment',
  contracting_parties: 'Contracting Parties',
};

const clauseComponents: any = {
  term: TermClause,
  renewal: RenewalClause,
  payment: PaymentObligationsClause,
  indemnity: IndemnityClause,
  confidentiality: ConfidentialityClause,
  change_of_control: ChangeClause,
  termination: TerminationClause,
  insurance: InsuranceClause,
  notice: NoticeClause,
  events_of_default: EventClause,
  limited_liability: LimitationClause,
  force_majeure: ForceClause,
  governing_law: GoverningClause,
  dispute_resolution: DisputeClause,
  non_compete: NonCompeteClause,
  non_solicitation: NonSolicitationClause,
  subletting: SublettingClause,
  consent: ConsentClause,
  general_definitions: GeneralClause,
  data_breach: DataBreachClause,
  effective_date: EffectiveClause,
  exclusivity: ExclusivityClause,
  obligation_statements: OtherObligationClause,
};

export const CLAUSE_RENDERING_ORDER: ClauseType[] = [
  'term',
  'termination',
  'renewal',
  'governing_law',
  'payment',
  'indemnity',
  'confidentiality',
  'change_of_control',
  'insurance',
  'notice',
  'events_of_default',
  'limited_liability',
  'force_majeure',
  'dispute_resolution',
  'non_compete',
  'non_solicitation',
  'subletting',
  'consent',
  'general_definitions',
  'data_breach',
  // "effective_date",
  'exclusivity',
  'obligation_statements',
];

export default function ClauseComponent(props: Props) {
  const {
    insightsData,
    fileId,
    sentenceData,
    clauseData,
    updatedClauseData,
    scrollToDatapoint,
    getInsights,
  } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [counter, setCounter] = useState(1);

  const toggleAccordion =
    (clause: any, panel: string) => (_: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setCounter(0);
      props.onClickDataPoint(clause, ClauseNameMap[panel]);
    };

  React.useEffect(() => {
    getInsights(fileId);
  }, [fileId]);

  const clauseElements: any = [];

  const scrollToChildDataPoint = useCallback(
    (insight: string, clause: any, key: number, label: any) => {
      const clauseArray: any = [];
      clauseArray.push(clause);
      props.scrollToChildDataPoint(
        insight,
        label,
        getChildHighlights(clauseArray, label),
        0,
        false
      );
    },
    [props]
  );

  const onClickHighlight = React.useCallback(
    (key: number, clause: any, childAlias: string) => {
      if (childAlias === 'Clauses') {
        scrollToDatapoint('bi', clause.dataPoints, key);
      } else {
        scrollToChildDataPoint('bi', clause, key, childAlias);
      }
    },
    [scrollToChildDataPoint, scrollToDatapoint]
  );

  const user_id = getLocalStorage('user_id') || '';
  const realm_name = getKeyCloakRealmFromLS();
  const queryClient = useQueryClient();

  const { data: filterInsightList } = useQuery({
    queryKey: ['get_all_filtered_insights', user_id, fileId],
    queryFn: async () => {
      const response = await getAllFilterInsights(fileId, user_id);
      return response;
    },
    enabled: !!(fileId && user_id),
  });

  const { mutate: createFilterInsights } = useMutation({
    mutationKey: ['create_filter_insights'],
    mutationFn: async (payload: any) => filterInsights(payload),
    onSuccess: () => {
      enqueueSnackbar('filter insights created successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_all_filtered_insights', user_id, fileId],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to create filter insights!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    const defaultShowClauses = [
      'term',
      'termination',
      'renewal',
      'governing_law',
    ];
    let nodes: any = {};
    CLAUSE_RENDERING_ORDER?.map(
      (item: string) =>
        (nodes = {
          ...nodes,
          [item]: defaultShowClauses?.includes(item) ? true : false,
        })
    );
    if (filterInsightList?.length === 0) {
      const payload = {
        contract_id: fileId,
        user_id: user_id,
        body: {
          realm: realm_name,
          nodes: nodes,
          user: user_id,
          contract: fileId,
        },
      };
      createFilterInsights(payload);
    }
  }, [createFilterInsights, fileId, filterInsightList, realm_name, user_id]);

  const updatedList = React.useMemo(() => {
    return CLAUSE_RENDERING_ORDER.map((item: string) => {
      if (insightsData.includes(item)) {
        return {
          name: item,
          hasData: true,
        };
      } else {
        return {
          name: item,
          hasData: false,
        };
      }
    });
  }, [insightsData]);

  updatedList.forEach((clauseName, index) => {
    const ClauseComponent = clauseComponents[clauseName.name];
    const data = updatedClauseData?.[ClauseTypeMap[clauseName.name]];
    const clause = data?.[clauseName.name] || [];

    if (ClauseComponent && filterInsightList?.[0]?.nodes[clauseName.name]) {
      clauseElements.push(
        <Accordion
          key={index}
          expanded={expanded === clauseName.name}
          onChange={toggleAccordion(data, clauseName.name)}
          className="according-class"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="clause-heading"
          >
            <Stack
              direction="row"
              sx={{
                width: '97%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  flex: 1,
                }}
              >
                {ClauseNameMap[clauseName.name]}
              </Typography>
              {expanded === clauseName.name ? (
                <ChangeClauseCount
                  counter={counter}
                  setCounter={setCounter}
                  onScroll={props.onScroll}
                  clause={clause}
                />
              ) : (
                <Typography
                  variant="caption"
                  align="right"
                  sx={{
                    fontWeight: '500',
                    flex: 1,
                  }}
                >
                  {clause?.length || 0}
                </Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, mt: 0 }}>
            <ClauseComponent
              fileId={fileId}
              sentenceData={sentenceData}
              key={clauseName.name}
              onScroll={props.onScroll}
              hasData={clauseName.hasData}
              onClickDataPoint={props.onClickDataPoint}
              onEdit={props.onEdit}
              toBeEdited={props.toBeEdited}
              childInEditId={props.childInEditId}
              durationList={props.durationList}
              currencyList={props.currencyList}
              contractData={props.contractData}
              editOptionSelected={props.editOptionSelected}
              onClickHighlight={onClickHighlight}
              getClauseDataByType={props.getClauseDataByType}
              clauseData={clauseData}
              updatedClauseData={updatedClauseData}
              postClauseDataByType={props.postClauseDataByType}
              otherClauses={props.otherClauses}
            />
          </AccordionDetails>
        </Accordion>
      );
    }
  });
  return <Box>{clauseElements}</Box>;
}

export const handleAddEdit = (
  props: any,
  clauseType: string,
  data: any,
  setShowAdd: any
) => {
  props.onEdit(clauseType, 0, false, [], -1, '', data);
  setShowAdd(true);
};
