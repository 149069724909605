import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { fetchExternalSupportDocsDownloadUrl } from '../../../ExternalUserFlow/Services/Draft';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { fetchSupportDocsDownloadUrl } from '../../../Services/Draft';
import PdfView from '../PdfView';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  documentId: string;
  isExternal?: boolean;
}
const ViewSupportDoc: React.FC<Props> = ({
  open,
  onClose,
  documentId,
  isExternal,
}) => {
  const [instance, setInstance] = useState<any>();

  const { data: viewDocumentData, isLoading: viewLoading } = useQuery({
    queryKey: ['get_access_url', documentId],
    queryFn: async () =>
      isExternal
        ? await fetchExternalSupportDocsDownloadUrl(documentId)
        : await fetchSupportDocsDownloadUrl(documentId),
    enabled: !!documentId,
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '960px',
        },
      }}
    >
      {viewLoading ? (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      ) : (
        <Stack spacing={2} width="900px" marginX="15px">
          <Box display="flex" alignItems="center" width="900px">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography>View Document</Typography>
          </Box>
          <PdfView
            draftData={viewDocumentData}
            fileLink={viewDocumentData?.location}
            instance={instance}
            setInstance={setInstance}
          />
        </Stack>
      )}
    </Drawer>
  );
};

export default ViewSupportDoc;
