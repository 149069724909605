import React from 'react';

import { Button, Stack, Typography } from '@mui/material';

import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  type: string;
  typeName: string;
}

const ContractsConfirm: React.FC<Props> = ({
  open,
  onClose,
  type,
  typeName,
}) => {
  return (
    <CustomModal
      title={`Show contracts with ${type} “${typeName}”`}
      open={open}
      handleClose={onClose}
    >
      <Stack spacing={2}>
        <Typography pt={3}>
          Do you want to view all contracts with the {type} “{typeName}”? This
          will open dashboard with the selected filter in a new tab.
        </Typography>

        <Stack direction="row">
          <Button
            variant="contained"
            onClick={() => {
              window.open(
                `/documentlibrary?type=${type}&name=${typeName}`,
                '_blank'
              );
            }}
          >
            Yes, open in new tab
          </Button>
          <Button variant="outlined" onClick={onClose}>
            No, go back
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default ContractsConfirm;
