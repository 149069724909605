import {
  dataForMap,
  dateBiMap,
  paraBiMap,
  paraLevelComponents,
  phraseBiMap,
  phraseLevelComponents,
  sentenceBiMap,
  sentenceLevelComponents,
  singleParaLinking,
} from './docUtils';
import {
  dateInfo,
  eventInfo,
  LinkDateRequest,
  LinkEventRequest,
  LinkParaRequest,
  LinkPhraseRequest,
  LinkSentenceRequest,
  paraInfo,
  phraseInfo,
  sentenceInfo,
} from '../../State/documentState';
import { assignmentInfoBiValue } from '../EditFeature/Component/CustomAddEdit/EditAddAssignmentInfo';
import { regulatoryMap } from '../EditFeature/Component/CustomAddEdit/EditAddConsent';

export function deleteInsight(clause: any, childLabel: string) {
  if (clause !== undefined) {
    if (childLabel === 'General Definitions') {
      const tempDefinitionClause: phraseInfo[] = [];
      tempDefinitionClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        startSentenceId: clause.dataPoints.startSentenceId,
        endSentenceId: clause.dataPoints.endSentenceId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
        startWordId: clause.dataPoints.startWordId,
        endWordId: clause.dataPoints.endWordId,
        phrase: clause.dataPoints.highlightedText,
        definedword: clause.dataPoints.definedWord,
      });

      const deleteRequest: LinkPhraseRequest = {
        mode: 'manual',
        editedPhrases: {
          upsert: [],
          deleted: tempDefinitionClause,
          bi: '',
        },
      };
      return deleteRequest;
    } else if (childLabel === 'Consent Clause') {
      const tempConsentClause: sentenceInfo[] = [];
      let regulatory = 'No';
      let authorityText = '';
      if (
        clause.dataPoints.groupedBiData[0].childLabel === 'Consent Regulatory'
      ) {
        const regulatoryInfo = clause.dataPoints.groupedBiData[0].childValue;
        if (regulatoryInfo.name !== undefined) {
          regulatory = regulatoryInfo[0].name;
        }
      }
      if (
        clause.dataPoints.groupedBiData[1].childLabel === 'Consent Authority'
      ) {
        const authorityInfo = clause.dataPoints.groupedBiData[1].childValue;
        if (authorityInfo.name !== undefined) {
          authorityText = authorityInfo[0].name;
        }
      }

      tempConsentClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        sentenceId: clause.dataPoints.startSentenceId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
        startWordId: -1,
        endWordId: -1,
        typestring: regulatoryMap[regulatory],
        phrase: authorityText,
      });
      const deleteRequest: LinkSentenceRequest = {
        mode: 'manual',
        editedSentences: {
          upsert: [],
          deleted: tempConsentClause,
          dataFor: dataForMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (
      childLabel === 'Subletting/Assignment Notice Info' ||
      childLabel === 'Subletting/Assignment Consent Info' ||
      childLabel === 'Exception to Confidentiality'
    ) {
      const tempAssignmentInfoClause: sentenceInfo[] = [];
      tempAssignmentInfoClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        sentenceId: clause.dataPoints.startSentenceId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
        requirement: clause.dataPoints.requirement,
      });
      const deleteRequest: LinkSentenceRequest = {
        mode: 'manual',
        editedSentences: {
          upsert: [],
          deleted: tempAssignmentInfoClause,
          bi: assignmentInfoBiValue[childLabel],
        },
      };
      return deleteRequest;
    } else if (phraseLevelComponents.indexOf(childLabel) > -1) {
      const tempPhraseClause: phraseInfo[] = [];
      tempPhraseClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        startWordId: clause.dataPoints.startWordId,
        endWordId: clause.dataPoints.endWordId,
        startSentenceId: clause.dataPoints.startSentenceId,
        endSentenceId: clause.dataPoints.endSentenceId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
        phrase: clause.dataPoints.highlightedText,
      });

      const deleteRequest: LinkPhraseRequest = {
        mode: 'manual',
        editedPhrases: {
          upsert: [],
          deleted: tempPhraseClause,
          bi: phraseBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (sentenceLevelComponents.indexOf(childLabel) > -1) {
      const tempSentenceClause: sentenceInfo[] = [];
      tempSentenceClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        sentenceId: clause.dataPoints.startSentenceId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
      });

      const deleteRequest: LinkSentenceRequest = {
        data: 'Yes',
        mode: 'manual',
        editedSentences: {
          upsert: [],
          deleted: tempSentenceClause,
          bi: sentenceBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (
      paraLevelComponents.indexOf(childLabel) > -1 &&
      singleParaLinking.indexOf(childLabel) < 0
    ) {
      const tempParaClause: paraInfo[] = [];
      tempParaClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
      });
      const deleteRequest: LinkParaRequest = {
        data: 'Yes',
        mode: 'manual',
        editedParas: {
          upsert: [],
          deleted: tempParaClause,
          bi: paraBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (
      childLabel === 'Start Dates' ||
      childLabel === 'End Dates' ||
      childLabel === 'Effective Dates'
    ) {
      const tempDateClause: dateInfo[] = [];
      tempDateClause.push({
        id: clause.dataPoints.id,
        dateId: -1,
        phrase: clause.dataPoints.headerData.date,
        paraId: clause.dataPoints.paraId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
      });

      const deleteRequest: LinkDateRequest = {
        data: 'Yes',
        mode: 'manual',
        editedDates: {
          upsert: [],
          deleted: tempDateClause,
          bi: dateBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (childLabel === 'Duration') {
      const tempDateClause: dateInfo[] = [];
      tempDateClause.push({
        id: clause.dataPoints.id,
        dateId: -1,
        phrase: clause.name,
        paraId: clause.dataPoints.paraId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
        duration_value: clause.dataPoints.headerData.durationValue,
        duration_typeid: clause.dataPoints.headerData.durationTypeId,
      });
      const deleteRequest: LinkDateRequest = {
        data: 'Yes',
        mode: 'manual',
        editedDates: {
          upsert: [],
          deleted: tempDateClause,
          bi: dateBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (childLabel === 'Lock-in Period') {
      const templockinperiodClause: paraInfo[] = [];
      templockinperiodClause.push({
        id: clause.dataPoints.id,
        paraId: clause.dataPoints.paraId,
        rowId: clause.dataPoints.rowId,
        columnId: clause.dataPoints.columnId,
      });
      const deleteRequest: LinkParaRequest = {
        data: 'Yes',
        mode: 'manual',
        editedParas: {
          upsert: [],
          deleted: templockinperiodClause,
          bi: paraBiMap[childLabel],
        },
      };
      return deleteRequest;
    } else if (childLabel === 'Termination Event') {
      const tempterminationeventClause: eventInfo = {
        eventHighlightId: 1,
        eventId: clause.eventId,
        eventName: clause.dataPoints.highlightedText,
        paraId: clause.dataPoints.paraId,
        startWordId: clause.dataPoints.startWordId,
        endWordId: clause.dataPoints.endWordId,
        rowId: -1,
        columnId: -1,
        phrase: clause.dataPoints.highlightedText,
        sentenceId: -1,
        endSentenceId: -1,
        id: clause.dataPoints.id,
      };
      const deleteRequest: LinkEventRequest = {
        mode: 'manual',
        editedEvents: {
          upsert: [],
          deleted: [tempterminationeventClause],
          bi: paraBiMap[childLabel],
        },
      };
      return deleteRequest;
    }
  }
  return null;
}
