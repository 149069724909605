import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';

import { sentenceInfo, tableInfo } from '../../../../State/documentState';
import { sentenceLevelComponents } from '../../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  savedPresent: string;
  dataPointName: string;
  savedPresentData: any;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
}

const EditLinkPresentSentence: React.FC<Props> = (props) => {
  const [highlight, setHighlight] = useState(false);

  const linkToSentences = () => {
    props.editOptionSelected(true);
    props.saveHighlightedDataPoint(props.dataPointName);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
    setHighlight(true);
  };

  const showLinkToSentence = () => {
    const { savedHighlightedTableCells, savedHighlightedSentences } = props;
    const sentenceLength: number =
      savedHighlightedSentences !== null && savedHighlightedSentences.length > 0
        ? savedHighlightedSentences.length
        : 0;
    const tableLength: number =
      savedHighlightedTableCells !== null &&
      savedHighlightedTableCells.length > 0
        ? savedHighlightedTableCells.length
        : 0;
    const sentencesLength = sentenceLength + tableLength;

    if (
      ((savedHighlightedSentences !== null &&
        savedHighlightedSentences !== undefined &&
        savedHighlightedSentences.length > 0) ||
        (savedHighlightedTableCells !== null &&
          savedHighlightedTableCells !== undefined &&
          savedHighlightedTableCells.length > 0)) &&
      highlight === true
    ) {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography fontSize="14px">Edit linked sentence(s)</Typography>
          <Typography fontSize="14px">{sentencesLength}</Typography>
        </Stack>
      );
    } else {
      if (highlight === true) {
        return (
          <Stack width="100%">
            <Typography fontSize="14px">Link to sentences(s)</Typography>
            <Typography fontSize="14px">
              Please highlight the sentences on the document, you wish to link
              to this data point and click on save.
            </Typography>
          </Stack>
        );
      } else {
        return (
          <Button variant="text" onClick={linkToSentences}>
            Link to sentences`&apos;`s
          </Button>
        );
      }
    }
  };

  const cancel = () => {
    props.onClose();
    setHighlight(false);
    props.editOptionSelected(false);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
  };

  const onSave = () => {
    setHighlight(false);
    props.editOptionSelected(false);
    props.saveHighlightedSentences(null);
    props.saveHighlightedTableCells(null);
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {props.dataPointName}
      </Typography>
      <Stack spacing={2}>
        {sentenceLevelComponents.indexOf(props.dataPointName) > -1 &&
          showLinkToSentence()}
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={
              (props.savedHighlightedSentences === null &&
                !props.savedHighlightedSentences) ||
              (props.savedHighlightedTableCells === null &&
                !props.savedHighlightedTableCells)
            }
          >
            Save
          </Button>
          <Button variant="outlined" onClick={cancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkPresentSentence;
