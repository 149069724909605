import React, { useState } from 'react';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import { ReactComponent as RiverusLogo } from '../../RiverusUI/Components/Icons/web-menubar-logo.svg';

interface IFormInput {
  realmName: string;
}

interface IProps {
  handleKeycloakRealmNameChange: (realmName: string) => void;
  keycloakRealmName: string;
  handleKeycloakRedirection: VoidFunction;
}

const RedirectToKeycloak: React.FC<IProps> = ({
  handleKeycloakRealmNameChange,
  keycloakRealmName,
  handleKeycloakRedirection,
}) => {
  const methods = useForm<IFormInput>();
  const { handleSubmit } = methods;

  const [isOrgAvailable, setIsOrgAvailable] = useState(true);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (isOrgAvailable) {
      handleKeycloakRedirection();
    } else {
      handleKeycloakRealmNameChange(data.realmName);
      setIsOrgAvailable(true);
    }
  };

  return (
    <Box className="cover-background">
      <Grid container sx={{ height: '100%', padding: '20px 50px' }}>
        <Grid item sm={8} className="image-background" />
        <Grid
          item
          sm={4}
          sx={{ maxWidth: '480px', alignSelf: 'center', position: 'right' }}
        >
          <Box
            sx={{
              alignContent: 'center',
              margin: '0 auto 30px',
              width: '180px',
            }}
          >
            <RiverusLogo />
          </Box>
          <Box
            sx={{
              marginBottom: 4,
              padding: '32px',
              background: 'white',
              borderRadius: '6px',
              boxShadow: '8px 8px 16px 0px rgba(8, 13, 37, 0.25)',
            }}
          >
            <FormProvider {...methods}>
              <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit(onSubmit)}
              >
                {isOrgAvailable ? (
                  <Box>
                    <KeyboardBackspaceIcon
                      onClick={() => setIsOrgAvailable(false)}
                    />
                    <Typography sx={{ color: '#2b3c7f' }}>
                      You are logging onto
                    </Typography>
                    <Typography sx={{ color: '#2b3c7f' }}>
                      {keycloakRealmName}
                    </Typography>
                    <Typography
                      sx={{ textDecoration: 'underline', color: '#818181' }}
                      onClick={() => {
                        setIsOrgAvailable(false);
                      }}
                    >
                      Change organization?
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: '#2B3C7F', fontWeight: 600 }}
                    >
                      Name of Organization
                    </Typography>
                    <ControlledTextField
                      name="realmName"
                      size="small"
                      fullWidth={true}
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </Box>
                )}
                <Button
                  type="submit"
                  className="org-continue-btn"
                  sx={{ width: '100%', borderRadius: '5px', color: 'white' }}
                >
                  Continue
                </Button>
              </Stack>
            </FormProvider>
          </Box>
          <Typography
            component={Stack}
            spacing={2}
            direction="row"
            sx={{ color: '#818181' }}
          >
            <Link href="https://www.riverus.co" target="_blank">
              Home
            </Link>
            <Link href="https://www.riverus.co/privacy-policy" target="_blank">
              Terms of Service
            </Link>
            <Link href="https://www.riverus.co/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RedirectToKeycloak;
