import * as React from 'react';

import { Stack, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

const DateRangePicker = (props: DateRangePickerProps) => {
  const { value, onChange, ...rest } = props;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <DatePicker
        value={value?.start || null}
        onChange={(start) => {
          onChange({ ...value, start: start as Date });
        }}
        {...rest}
      />
      <Typography> - </Typography>
      <DatePicker
        value={value?.end || null}
        onChange={(start) => {
          onChange({ ...value, end: start as Date });
        }}
        {...rest}
      />
    </Stack>
  );
};

type DateRangePickerValueType = {
  start?: any;
  end?: any;
};

interface DateRangePickerProps extends Omit<DatePickerProps<any>, 'value'> {
  value: DateRangePickerValueType | null;
  onChange: (value: DateRangePickerValueType) => void;
}

export default DateRangePicker;
