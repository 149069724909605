import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';

import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleDeleteChecklist: (id: string) => void;
  deleteChecklistId: string;
  loadingDeleteItem: boolean;
  name: string;
}

const DeleteDialog: React.FC<Props> = ({
  open,
  onClose,
  handleDeleteChecklist,
  deleteChecklistId,
  loadingDeleteItem,
  name,
}) => {
  const deleteChecklist = () => {
    handleDeleteChecklist(deleteChecklistId);
  };

  return (
    <CustomModal
      title={`Delete item ${name}`}
      open={open}
      handleClose={onClose}
    >
      <Stack spacing={2} margin="15px">
        <Typography fontSize="14px" pt="20px">
          Are you sure? The selected item will be removed permanently along with
          the uploaded attachment.
        </Typography>
        <Typography fontSize="14px">
          Note: This action is not reversible.
        </Typography>
        <Stack alignItems="start" direction="row">
          <LoadingButton
            loading={loadingDeleteItem}
            loadingPosition="start"
            variant="contained"
            onClick={() => deleteChecklist()}
          >
            Yes, Delete
          </LoadingButton>
          <Button variant="outlined" onClick={() => onClose()} size="small">
            No, Go Back
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DeleteDialog;
