import React from 'react';

import { Stack } from '@mui/material';

import LinesLoader from '../../Loader/linesLoader';

export default function SentenceLoader() {
  return (
    <Stack mt={2} mb={2}>
      <LinesLoader
        animatedLines={[
          { width: 50, height: 20 },
          { width: 20, height: 8 },
          { width: 20, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
      <LinesLoader
        animatedLines={[
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
      <LinesLoader
        animatedLines={[
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
      <LinesLoader
        animatedLines={[
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
      <LinesLoader
        animatedLines={[
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
      <LinesLoader
        animatedLines={[
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
          { width: 100, height: 8 },
        ]}
      />
    </Stack>
  );
}
