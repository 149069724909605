import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';
import { useForm } from 'react-hook-form';

import RadioButtonGroup from '../../../../../../RiverusUI/Components/RadioButtonGroup';
import { ClauseType } from '../../../../../Constants/ClauseType';
import {
  editedSentences,
  LinkSentenceRequest,
  SentencesData,
} from '../../../../../State/documentState';
import { getClauseDataFormat } from '../../../../ClauseComponent/utils/ClauseTypeUtils';
import { dataForMap } from '../../../../Utils/docUtils';
import { regulatoryMap } from '../EditAddConsent';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  dataPointName: string;
  savedInsightChild: any;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

const EditAddConsentRegulatory: React.FC<Props> = (props) => {
  const {
    editOptionSelected,
    dataPointName,
    savedInsightChild,
    fileId,
    clauseType,
    postClauseDataByType,
    sentenceData,
    clauseDataByType,
    updatedClauseDataByType,
    parentClauseType,
    clauseItem,
    onClose,
  } = props;
  const [regulatory, setRegulatory] = useState<string>(
    regulatoryMap[clauseItem?.type]
  );

  const { control, watch } = useForm({
    defaultValues: {
      isPresent: 'Yes',
    },
  });

  const isPresent = watch('isPresent') || '';

  useEffect(() => {
    setRegulatory(isPresent);
  }, [isPresent]);

  useEffect(() => {
    if (
      savedInsightChild &&
      savedInsightChild.length > 0 &&
      regulatory !== savedInsightChild[0].name
    ) {
      setRegulatory(savedInsightChild[0].name);
    }
  }, [savedInsightChild]);

  const clauseOptions = [
    {
      value: 'Yes',
      title: `Regulatory is present`,
    },
    {
      value: 'No',
      title: `Regulatory is not present`,
    },
  ];

  const addOrRemoveRegulatory = (action: string): LinkSentenceRequest => {
    let tempPresentList: LinkSentenceRequest = { data: '', mode: '' };

    const mergeEditedSentences: editedSentences = {
      upsert: [
        {
          paraId: clauseItem.para_id,
          sentenceId: clauseItem.sentence_id,
          rowId: -1,
          columnId: -1,
          startWordId: -1,
          endWordId: -1,
          phrase: clauseItem.sentence,
          typestring: regulatoryMap[regulatory],
        },
      ],
      deleted: [],
      dataFor: dataForMap[dataPointName],
    };

    if (action === 'add') {
      tempPresentList = {
        mode: 'manual',
        editedSentences: mergeEditedSentences,
      };
    }
    return tempPresentList;
  };

  const editPresent = (addSentenceRequest: any) => {
    let newData = updatedClauseDataByType;

    if (addSentenceRequest?.editedSentences?.upsert) {
      const addedData = addSentenceRequest.editedSentences.upsert;
      for (let i = 0; i < addedData.length; i++) {
        if (regulatory === 'Yes') {
          addedData[i].typestring = 'Regulatory';
        } else {
          addedData[i].typestring = 'Non-Regulatory';
        }
        newData = getClauseDataFormat(
          'add',
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData,
          clauseItem
        );
      }
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
  };

  const onSave = () => {
    const addSentenceRequest = addOrRemoveRegulatory('add');
    editOptionSelected(false);
    editPresent(addSentenceRequest);
    onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600}>
        Add / Edit {dataPointName} Clause
      </Typography>
      <Stack direction="column" className="edit-clause-select" spacing={2}>
        <RadioButtonGroup
          row
          required
          name="isPresent"
          options={clauseOptions}
          valueKey="value"
          control={control}
        />
        <Stack width="100%">
          <Typography variant="body2" fontWeight={700}>
            1). Select `&quot;`Regulatory Is Present`&quot;`.
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            2). Click on Save.
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={
              savedInsightChild.length === 0 &&
              regulatory === savedInsightChild[0].name
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
              editOptionSelected(false);
            }}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditAddConsentRegulatory;
