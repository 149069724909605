import React, { useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { deleteSingleColumn, getPara } from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function EventClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddEvents, setShowAddEvents] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(props.fileId, 'events_of_default');
  }, [hasData, fileId]);

  const data = React.useMemo(
    () => clauseData?.eventsOfDefault && clauseData.eventsOfDefault,
    [clauseData?.eventsOfDefault]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.eventsOfDefault || {},
    [updatedClauseData]
  );

  const eventsOfDefault = useMemo(() => {
    if (updates?.events_of_default) {
      const sortData = updates.events_of_default.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const events = useMemo(() => {
    if (updates?.events) {
      const sortData = updates.events.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let events_of_default: any;
      let eventsData: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        events_of_default = deleteSingleColumn(eventsOfDefault, tableData);
        eventsData = deleteSingleColumn(events, tableData);
      } else {
        events_of_default = eventsOfDefault?.filter(
          (data: any) => data.para_id !== item.para_id
        );

        eventsData = events?.filter(
          (data: any) => data.para_id !== item.para_id
        );
      }

      const updatedData = {
        ...updates,
        events_of_default,
        events: eventsData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'events_of_default', diff, updatedData);
      }
    },
    [
      eventsOfDefault,
      events,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteEvents = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let newEvents: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        newEvents = deleteSingleColumn(events, tableData);
      } else {
        newEvents = events?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id ||
            data.start_word_id !== item.start_word_id
        );
      }

      const updatedData = {
        ...updates,
        events: newEvents,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'events_of_default', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, events, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="event of default"
          onClick={() =>
            handleAddEdit(
              props,
              'Events of Default',
              eventsOfDefault,
              setShowAdd
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="events_of_default"
          savedInsight={eventsOfDefault ? eventsOfDefault : []}
          savedParentClauseDataPoint={eventsOfDefault}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      <Scrollable maxHeight={200}>
        {eventsOfDefault?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'events_of_default', index });
              props.onClickHighlight(index, item, 'events_of_default');
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'events_of_default' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      </Scrollable>
      {!showAddEvents ? (
        <ClauseHeader
          title="Event"
          buttonText="event"
          onClick={() =>
            handleAddEdit(
              props,
              'Events Of Default Event',
              events,
              setShowAddEvents
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Events Of Default Event"
          savedInsight={events ? events : []}
          savedParentClauseDataPoint={events}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddEvents(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="events_of_default"
          canAddTags
        />
      )}
      <Scrollable maxHeight={200}>
        {events?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'events', index });
              props.onClickHighlight(index, item, 'events');
            }}
            index={index}
            clauseItem={item}
            sentenceData={props.sentenceData}
            isActive={
              activeClause?.type === 'events_of_default' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteEvents(item)}
            para={
              item.events ||
              getPara(
                props?.sentenceData,
                item.para_id,
                item.sentence_id,
                item.table && item.table[0]?.row,
                item.table && item.table[0]?.column
              )
            }
            subTitle={item?.eventName}
          />
        ))}
      </Scrollable>
    </>
  );
}
