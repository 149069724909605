import React, { Component } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';

import { ClauseType } from '../../../../Constants/ClauseType';
import {
  durationInfo,
  editedDuration,
  LinkDurationRequest,
  SentencesData,
  tableInfo,
} from '../../../../State/documentState';
import {
  getClauseDataFormat,
  getClauseObjects,
} from '../../../ClauseComponent/utils/ClauseTypeUtils';
import { getSingleText } from '../../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseData: any;
}

interface State {
  highlight: boolean;
  editHighlight: boolean;
  durationText: string;
  durationParaId: number;
  durationTableCell: tableInfo | null;
  origData: any;
  present: string;
}

export default class EditSinglePara extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      highlight: false,
      editHighlight: false,
      durationText: this.props.savedPresentData[0]?.phrase
        ? this.props.savedPresentData[0].phrase
        : '',
      durationParaId:
        this.props.savedPresentData[0]?.para_id > -1
          ? this.props.savedPresentData[0].para_id
          : -1,
      durationTableCell: this.props.savedPresentData[0]?.table
        ? this.props.savedPresentData[0].table
        : null,
      origData: props.clauseDataByType,
      present: 'Yes',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.highlightedId !== nextProps.highlightedId) {
      if (
        nextProps.highlightedId === null ||
        nextProps.highlightedId.length === 0
      ) {
        this.setState({ durationParaId: -1 });

        if (
          this.props.highlightedId !== null &&
          this.props.highlightedId.length > 0
        ) {
          //clear old highlighted background from document
          const paraId = 'p' + this.props.highlightedId[0];
          const paraHeader = document.getElementById(paraId);
          if (paraHeader !== undefined && paraHeader !== null) {
            paraHeader.style.background = 'none';
          }
        }

        if (nextProps.savedHighlightedTableCells === null) {
          this.setState({
            durationText: '',
            highlight: false,
            editHighlight: false,
          });
          nextProps.saveHighlightedDataPoint('');
          //*** */
          this.setState({ durationParaId: -1, durationTableCell: null });
        }
      } else if (
        nextProps.highlightedId !== null &&
        nextProps.highlightedId.length > 0
      ) {
        this.setState({
          durationParaId: nextProps.highlightedId[0],
          editHighlight: true,
          highlight: false,
        });
      }
    }

    if (
      this.props.savedHighlightedTableCells !==
      nextProps.savedHighlightedTableCells
    ) {
      if (
        nextProps.savedHighlightedTableCells === null ||
        nextProps.savedHighlightedTableCells.length === 0
      ) {
        this.setState({ durationTableCell: null });

        if (
          this.props.savedHighlightedTableCells !== null &&
          this.props.savedHighlightedTableCells.length > 0
        ) {
          // clear old table cell
          const tableCellId =
            'p' +
            this.props.savedHighlightedTableCells[0].paraId +
            ';r' +
            this.props.savedHighlightedTableCells[0].rowId +
            ';c' +
            this.props.savedHighlightedTableCells[0].columnId;
          const tableCellHeader = document.getElementById(tableCellId);
          if (tableCellHeader !== undefined && tableCellHeader !== null) {
            tableCellHeader.style.background = 'none';
          }
        }

        if (
          nextProps.highlightedId === null ||
          nextProps.highlightedId.length === 0
        ) {
          //if para and table cell not selected, stop editing mode and reset durationText
          this.setState({
            durationText: '',
            highlight: false,
            editHighlight: false,
          });
          nextProps.saveHighlightedDataPoint('');
        }
      } else if (
        nextProps.savedHighlightedTableCells !== null &&
        nextProps.savedHighlightedTableCells.length > 0
      ) {
        this.setState({
          durationTableCell: nextProps.savedHighlightedTableCells[0],
          editHighlight: true,
          highlight: false,
        });
      }
    }
  }

  render() {
    return (
      <Box
        sx={{
          background: '#FFECF1',
          boxShadow: 'none',
          borderRadius: '15px',
          padding: '10px 16px',
        }}
      >
        <Typography fontWeight={600} mb={1}>
          Add/edit {this.props.dataPointName}
        </Typography>
        <Stack className="edit-clause-select" spacing={2}>
          {this.showDuration()}
          <Stack width="100%">
            <Typography fontSize="14px" fontWeight={700}>
              How to add a Lock-in Period?
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              1). Input the text using the above field.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              2). Click on `&quot;`Link To Paragraph`&quot;` button to link a
              text from the contract.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              3). Hover over the text in the contract on left.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              4). Click on the desired paragraph.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              5). Confirm your selection using the Save button below.
            </Typography>
          </Stack>
          {this.saveOrCancelSinglePara()}
        </Stack>
      </Box>
    );
  }

  showDuration() {
    if (this.state.highlight === true) {
      return (
        <Stack width="100%" spacing={1}>
          <textarea
            rows={2}
            cols={40}
            name="text"
            maxLength={50}
            className="tag-input"
            value={this.state.durationText}
            style={{ minHeight: '60px', lineHeight: '15px' }}
          />
          <Typography fontSize="14px" color="#C1C1C1">
            Link to paragraph
          </Typography>
        </Stack>
      );
    } else if (this.state.editHighlight === true) {
      return (
        <Stack width="100%" spacing={1}>
          <textarea
            rows={2}
            cols={40}
            name="text"
            maxLength={50}
            className="tag-input"
            value={this.state.durationText}
            style={{ minHeight: '60px', lineHeight: '15px' }}
            readOnly
          />
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography fontSize="14px" color="#C1C1C1">
              Edit linked paragraph
            </Typography>
            <Typography fontSize="14px">
              {this.state.durationParaId > 0 ||
              this.state.durationTableCell !== null
                ? 1
                : 0}
            </Typography>
          </Stack>
        </Stack>
      );
    }

    if (
      (this.state.durationParaId !== -1 ||
        this.state.durationTableCell !== null) &&
      this.state.durationText !== ''
    ) {
      //Previous para, can be edited/deleted
      return (
        <Stack width="100%" spacing={1} alignItems="start">
          <textarea
            rows={2}
            cols={40}
            name="text"
            maxLength={50}
            className="tag-input"
            value={this.state.durationText}
            style={{ minHeight: '60px', lineHeight: '15px' }}
            readOnly
          />
          <Button
            style={{ color: '#88305F', padding: 0 }}
            onClick={() => this.editPara()}
          >
            Edit linked paragraph
          </Button>
        </Stack>
      );
    } else if (
      this.state.durationParaId === -1 ||
      this.state.durationTableCell === null
    ) {
      return (
        <Stack width="100%" spacing={1} alignItems="start">
          <textarea
            rows={2}
            cols={40}
            name="text"
            maxLength={50}
            className="tag-input"
            value={this.state.durationText}
            placeholder={'Please type in lock in period here'}
            style={{ minHeight: '60px', lineHeight: '15px' }}
            onChange={(e) => this.handleChange(e)}
          />

          {this.state.durationText !== '' && (
            <Button
              style={{ color: '#88305F', padding: 0 }}
              onClick={() => this.linkToPara()}
            >
              Link to paragraph
            </Button>
          )}
        </Stack>
      );
    }
  }

  saveOrCancelSinglePara() {
    return (
      <Stack direction="row">
        <Button
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={() => this.onSave()}
          disabled={
            this.state.durationText !== '' &&
            this.state.durationParaId === -1 &&
            this.state.durationTableCell === null
          }
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => this.onCancel()}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </Stack>
    );
  }

  handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ durationText: e.target.value });
  }

  linkToPara() {
    const { saveHighlightedDataPoint, dataPointName } = this.props;
    this.setState({ highlight: true, editHighlight: false });

    //set table edit option true
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedId(null);

    this.props.editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  }

  editPresent(linkDurationRequest: LinkDurationRequest) {
    const {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      postClauseDataByType,
      updatedClauseDataByType,
      parentClauseType,
    } = this.props;
    const updatedData = updatedClauseDataByType;
    let newData = updatedData;

    if (this.state.present === 'Yes') {
      if (linkDurationRequest.editedParas?.upsert) {
        const addedData = linkDurationRequest.editedParas.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            'add',
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
        }
      }
      if (linkDurationRequest.editedParas?.upsert?.length === 0) {
        newData = getClauseDataFormat(
          'add',
          clauseType as ClauseType,
          null,
          newData,
          sentenceData
        );
      }
      if (linkDurationRequest?.editedParas?.deleted) {
        const deletedData = linkDurationRequest.editedParas.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            'remove',
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
  }

  editPara() {
    const { saveHighlightedDataPoint, dataPointName } = this.props;
    const { durationParaId, durationTableCell } = this.state;
    if (durationParaId !== null && durationParaId > -1) {
      this.props.saveHighlightedId([durationParaId]);
      //scroll into para
      const paraId = 'p' + durationParaId;
      const paraHeader = document.getElementById(paraId);
      if (paraHeader !== null && paraHeader !== undefined) {
        paraHeader.scrollIntoView({ block: 'center' });
      }
    } else if (durationTableCell !== null) {
      this.props.saveHighlightedTableCells([durationTableCell]);
      //scroll into table cell
      const tableCellId =
        'p' +
        durationTableCell.paraId +
        ';r' +
        durationTableCell.rowId +
        ';c' +
        durationTableCell.columnId;
      const tableCellHeader = document.getElementById(tableCellId);
      if (tableCellHeader !== null && tableCellHeader !== undefined) {
        tableCellHeader.scrollIntoView({ block: 'center' });
      }
    }

    this.setState({ highlight: false, editHighlight: true });
    this.props.editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  }

  onSave() {
    const linkDurationRequest = this.addOrRemoveDuration();
    this.props.editOptionSelected(false);
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedId(null);
    this.editPresent(linkDurationRequest);
    this.setState({
      highlight: false,
      editHighlight: false,
      durationParaId: -1,
      durationText: '',
      durationTableCell: null,
    });
    this.props.onClose();
  }

  onCancel() {
    this.props.onClose();
    this.props.editOptionSelected(false);
    this.props.saveHighlightedId(null);
    this.props.saveHighlightedTableCells(null);
    this.setState({
      highlight: false,
      editHighlight: false,
      durationParaId: -1,
      durationText: '',
      durationTableCell: null,
    });
  }

  addOrRemoveDuration() {
    const { savedHighlightedTableCells } = this.props;
    let tempPresentList: LinkDurationRequest = {
      data: '',
      mode: '',
      editedParas: { upsert: [], deleted: [] },
    };
    //let previousParas = getSingleParaIdFromChild(this.props.savedPresentData);
    const previousParas = -1;
    const changedParas: number[] =
      this.props.highlightedId !== null ? this.props.highlightedId : [];

    // let previousTableCells: tableInfo | null = getSingleTableCellFromChild(
    //   this.props.savedPresentData
    // );
    const previousTableCells = null;
    const changedTableCells: tableInfo | null =
      savedHighlightedTableCells !== null &&
      savedHighlightedTableCells.length > 0
        ? savedHighlightedTableCells[0]
        : null;

    const editedDuration: editedDuration = this.getAddedAndDeletedSinglePara(
      previousParas,
      changedParas
    );
    const editedTableCells: editedDuration = this.getAddedAndDeletedTableCell(
      previousTableCells,
      changedTableCells
    );

    const mergedEditedDuration: editedDuration = this.mergeEditedDuration(
      editedDuration,
      editedTableCells
    );

    tempPresentList = {
      data: 'Yes',
      mode: 'manual',
      editedParas: mergedEditedDuration,
    };

    return tempPresentList;
  }

  getAddedAndDeletedSinglePara(previousParas: number, changedParas: number[]) {
    const upsertParas: durationInfo[] = [];
    const deletedParas: durationInfo[] = [];
    if (previousParas === -1) {
      if (changedParas.length > 0) {
        //newly added
        upsertParas.push({
          phrase: this.state.durationText,
          paraId: this.state.durationParaId,
          rowId: -1,
          columnId: -1,
        });
      }
    } else {
      if (changedParas.length > 0) {
        //edited
        upsertParas.push({
          phrase: this.state.durationText,
          paraId: this.state.durationParaId,
          rowId: -1,
          columnId: -1,
        });
        deletedParas.push({
          phrase: getSingleText(this.props.savedPresentData),
          // paraId: getSingleParaIdFromChild(this.props.savedPresentData),
          paraId: -1,
          rowId: -1,
          columnId: -1,
        });
      } else {
        //deleted old
        deletedParas.push({
          phrase: getSingleText(this.props.savedPresentData),
          // paraId: getSingleParaIdFromChild(this.props.savedPresentData),
          paraId: -1,
          rowId: -1,
          columnId: -1,
        });
      }
    }

    const changedDuration = {
      upsert: upsertParas,
      deleted: deletedParas,
    };

    return changedDuration;
  }

  getAddedAndDeletedTableCell(
    previousTableCell: tableInfo | null,
    changedTableCell: tableInfo | null
  ) {
    let addedTableCell: durationInfo[] = [];
    let deletedTableCell: durationInfo[] = [];
    const { durationText } = this.state;

    if (previousTableCell !== null) {
      if (changedTableCell !== null) {
        //edited - old deleted, new added
        if (
          previousTableCell.paraId !== changedTableCell.paraId ||
          previousTableCell.rowId !== changedTableCell.rowId ||
          previousTableCell.columnId !== changedTableCell.columnId
        ) {
          deletedTableCell.push({
            paraId: previousTableCell.paraId,
            rowId: previousTableCell.rowId,
            columnId: previousTableCell.columnId,
            phrase: getSingleText(this.props.savedPresentData),
          });
          addedTableCell.push({
            paraId: changedTableCell.paraId,
            rowId: changedTableCell.rowId,
            columnId: changedTableCell.columnId,
            phrase: durationText,
          });
        }
      } else if (changedTableCell === null) {
        //old deleted, nothing new added
        deletedTableCell.push({
          paraId: previousTableCell.paraId,
          rowId: previousTableCell.rowId,
          columnId: previousTableCell.columnId,
          phrase: getSingleText(this.props.savedPresentData),
        });
        addedTableCell = [];
      }
    } else {
      //new added, nothing deleted
      if (changedTableCell !== null) {
        addedTableCell.push({
          paraId: changedTableCell.paraId,
          rowId: changedTableCell.rowId,
          columnId: changedTableCell.columnId,
          phrase: durationText,
        });
        deletedTableCell = [];
      }
    }

    const editedTableCells: editedDuration = {
      upsert: addedTableCell,
      deleted: deletedTableCell,
    };

    return editedTableCells;
  }

  mergeEditedDuration(
    firstEditedDuration: editedDuration,
    secondEditedDuration: editedDuration
  ) {
    const mergeEditedDuration: editedDuration = {
      upsert: firstEditedDuration.upsert.concat(secondEditedDuration.upsert),
      deleted: firstEditedDuration.deleted.concat(secondEditedDuration.deleted),
    };
    return mergeEditedDuration;
  }
}
