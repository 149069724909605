import React, { FC, useMemo } from 'react';

import { Card, LinearProgress, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Control } from 'react-hook-form';

import { filterHeaderStyle, scrollContainerSx } from './StaticData';
import CheckboxButtonGroup from '../../RiverusUI/Components/CheckboxButtonGroup';
import CheckBoxFilled from '../../RiverusUI/Components/Icons/CheckboxFilled';
import {
  getConfidentialityDurationFilters,
  getDataBreachNotifyFilters,
  getDataBreachRegulationFilters,
  getForceMajeureFilters,
  getLimitedLiabilityFilters,
  getPaymentTagsFilters,
} from '../../Services/search';

interface IProps {
  label: string;
  clause: string;
  control: Control;
  searchResultIds: string[];
}

const ChildFilter: FC<IProps> = ({
  label,
  clause,
  control,
  searchResultIds,
}) => {
  const { data } = useQuery({
    queryKey: ['get_all_events', clause],
    queryFn: () => {
      switch (clause) {
        case 'data_breach_notify':
          return getDataBreachNotifyFilters();
        case 'data_breach_regulation':
          return getDataBreachRegulationFilters();
        case 'payment':
          return getPaymentTagsFilters();
        case 'confidentiality':
          return getConfidentialityDurationFilters();
        case 'limited_liability':
          return getLimitedLiabilityFilters();
        case 'force_majeure':
          return getForceMajeureFilters();
      }
    },
  });

  const clauseData = useMemo(() => {
    const childClauseData: any = data ? [] : null;
    if (data) {
      for (const key in data) {
        const newObj = {
          label: key,
          count: data[key],
        };
        childClauseData.push(newObj);
      }
    }
    const sortedData = childClauseData?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [data]);

  if (clauseData?.length) {
    return (
      <Card>
        <Typography sx={filterHeaderStyle}>{label}</Typography>
        <Stack gap={1} sx={scrollContainerSx}>
          <CheckboxButtonGroup
            name={`${clause}`}
            control={control}
            options={clauseData || []}
            labelKey="label"
            valueKey="label"
            checkBoxProps={{
              checkedIcon: (
                <CheckBoxFilled
                  style={{ fill: 'none !important' }}
                  color="#88305F"
                />
              ),
            }}
            renderCustomComponent={(value) => (
              <LinearProgress
                variant="determinate"
                sx={{
                  background: 'transparent',
                  borderBottom: '1px solid #D1D3D4',
                }}
                value={(value / searchResultIds?.length) * 100}
              />
            )}
            labelProps={{
              sx: {
                padding: '2px 2px 2px 10px',
              },
            }}
          />
        </Stack>
      </Card>
    );
  }
  return null;
};

export default ChildFilter;
