export const formTypeOptions = [
  {
    value: 'template',
    title: 'Template',
    description: 'Use a system template',
    disabled: false,
  },
  {
    value: 'draft',
    title: 'Upload a draft',
    description: 'Use your own contract',
    disabled: false,
  },
];

export const draftTypeOptions = [
  {
    value: 'counter_party',
    title: 'Counterparty draft',
    description: 'Use a draft received from other party',
  },
  {
    value: 'request_draft',
    title: 'Request a draft',
    description: 'Request to create a draft with reference notes and docs',
  },
];
