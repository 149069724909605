import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Dialog, Typography } from '@mui/material';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  isExternal?: boolean;
}

const SignedConfirmation: React.FC<IProps> = ({
  open,
  onClose,
  isExternal,
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <Box
        sx={{
          p: 4,
          textAlign: 'center',
        }}
      >
        <Button
          disableRipple
          variant="contained"
          sx={{
            bgcolor: 'success.main',
            borderRadius: '50%',
            mb: 2,
            padding: '10px',
            '&:hover': {
              bgcolor: 'success.main',
            },
          }}
        >
          <CheckIcon sx={{ color: 'white', fontSize: 50 }} />
        </Button>
        <Typography id="modal-description" variant="body1" sx={{ mb: 4 }}>
          Thank you for signing the draft. Your signature has been placed
          successfully.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          {isExternal ? (
            <Typography id="modal-description" variant="body1" sx={{ mb: 4 }}>
              You can close the window.
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ px: 4 }}
              onClick={onClose}
            >
              Go Back
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default SignedConfirmation;
