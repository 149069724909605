import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

interface Props extends DrawerProps {
  drawerHeader: string;
  open: boolean;
  onClose: VoidFunction;
  renderAction?: React.ReactNode;
  children: React.ReactNode;
}

const DrawerComponent: React.FC<Props> = ({
  open,
  drawerHeader,
  onClose,
  children,
  renderAction,
  ...props
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor="right" {...props}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="10px 0"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: '#88305F' }} />
          </IconButton>
          <Typography>{drawerHeader}</Typography>
        </Stack>
        {renderAction && renderAction}
      </Box>
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
