import React from 'react';

import { Box } from '@mui/material';

import CheckListComponent from '../DraftChecklist/CheckListComponent';

interface Props {
  draftData: any;
  isExternal?: boolean;
  templateUserIds?: any;
}

const ChecklistTab: React.FC<Props> = ({
  draftData,
  isExternal,
  templateUserIds,
}) => {
  return (
    <Box
      sx={{
        marginY: '15px',
      }}
    >
      {draftData && (
        <CheckListComponent
          selectedDraftData={draftData}
          isExternal={isExternal}
          templateUserIds={templateUserIds}
        />
      )}
    </Box>
  );
};

export default ChecklistTab;
