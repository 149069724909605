import { authApi } from './authApi';

export const getSearchResult = async (payload: any): Promise<any> => {
  return await authApi.post('contracts/search-es/', payload);
};

export const getFilterResults = async (payload: any): Promise<any> => {
  return await authApi.post('contracts/filter-es/', payload);
};

export const getClauseType = async (): Promise<any> => {
  return await authApi.post('contracts/filter/clause_types/');
};

export const getForceMajeureFilters = async (): Promise<any> => {
  return await authApi.get('contracts/events-es/');
};

export const getDataBreachNotifyFilters = async (): Promise<any> => {
  return await authApi.get('contracts/notify-es/');
};

export const getDataBreachRegulationFilters = async (): Promise<any> => {
  return await authApi.get('contracts/regulation-es/');
};

export const getLimitedLiabilityFilters = async (): Promise<any> => {
  return await authApi.get('contracts/limited-liability-es/');
};

export const getConfidentialityDurationFilters = async (): Promise<any> => {
  return await authApi.get('contracts/confidentiality-es/');
};

export const getPaymentTagsFilters = async (): Promise<any> => {
  return await authApi.get('contracts/payment-es/');
};

export const getInsightsES = async (payload: any): Promise<any> => {
  return await authApi.post('contracts/insights-es/', payload);
};
