import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import {
  deleteClauseParaLevel,
  deleteClausePhraseLevel,
  deleteClauseSentenceLevel,
} from './helper';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { CLAUSE_DATA } from './utils/ClauseTypeUtils';
import EditFeature from '../EditFeature/Container/cont';

export default function RenewalClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    durationList,
    hasData,
  } = props;

  const data = React.useMemo(
    () => clauseData?.renewal && clauseData.renewal,
    [clauseData?.renewal]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'renewal');
  }, [fileId, hasData]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddAutoRenewal, setShowAddAutoRenewal] = useState(false);
  const [showAddAutoNoticeRenewal, setShowAddAutoNoticeRenewal] =
    useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [showAddDate, setShowAddDate] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  const updates = useMemo(
    () => updatedClauseData?.renewal || {},
    [updatedClauseData]
  );

  const renewal = useMemo(() => {
    if (updates?.renewal) {
      const sortData = updates.renewal.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const autoRenewal = useMemo(() => {
    if (updates?.auto_renewal) {
      const sortData = updates.auto_renewal.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const noticeAutoRenewal = useMemo(() => {
    if (updates?.notice_auto_renewal) {
      const sortData = updates.notice_auto_renewal.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const referenceDate = useMemo(() => {
    if (updates?.reference_date) {
      const sortData = updates.reference_date.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const renewalDuration = useMemo(() => {
    if (updates?.renewal_duration) {
      const sortData = updates.renewal_duration.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationType === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationType;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [durationList, updates.renewal_duration]);

  const deleteClause = useCallback(
    (item: any) => {
      const renewal_parent = deleteClauseParaLevel(renewal, item);

      const renewal_duration = deleteClauseParaLevel(renewalDuration, item);

      const reference_date = deleteClauseParaLevel(referenceDate, item);

      const auto_renewal = deleteClauseParaLevel(autoRenewal, item);

      const notice_auto_renewal = deleteClauseParaLevel(
        noticeAutoRenewal,
        item
      );

      const updatedData = {
        ...updates,
        renewal: renewal_parent,
        auto_renewal,
        notice_auto_renewal,
        reference_date,
        renewal_duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'renewal', diff, updatedData);
      }
    },
    [
      renewal,
      data,
      renewalDuration,
      referenceDate,
      autoRenewal,
      noticeAutoRenewal,
      updates,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteAutoRenewal = useCallback(
    (item: any) => {
      const auto_renewal = deleteClauseSentenceLevel(autoRenewal, item);

      const updatedData = {
        ...updates,
        auto_renewal,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'renewal', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, autoRenewal, data, fileId]
  );

  const deleteNoticeAutoRenewal = useCallback(
    (item: any) => {
      const notice_auto_renewal = deleteClausePhraseLevel(
        noticeAutoRenewal,
        item
      );

      const updatedData = {
        ...updates,
        notice_auto_renewal,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'renewal', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, noticeAutoRenewal, data, fileId]
  );

  const deleteDuration = useCallback(
    (item: any) => {
      const renewal_duration = deleteClausePhraseLevel(renewalDuration, item);

      const updatedData = {
        ...updates,
        renewal_duration,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'renewal', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, renewalDuration, data, fileId]
  );

  const deleteDate = useCallback(
    (item: any) => {
      const reference_date = deleteClausePhraseLevel(referenceDate, item);

      const updatedData = {
        ...updates,
        reference_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'renewal', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, referenceDate, data, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="renewal clause"
          onClick={() => {
            handleAddEdit(props, 'Renewal clause', renewal, setShowAdd);
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="Renewal clause"
          savedInsight={renewal ? renewal : []}
          savedParentClauseDataPoint={renewal}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          updatedClauseDataByType={updates}
          clauseDataByType={data}
          parentClauseType={'renewal'}
        />
      )}
      {renewal &&
        renewal?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'renewal', index });
              props.onClickHighlight(
                index,
                item,
                CLAUSE_DATA.renewal_clause['heading']
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'renewal' && activeClause?.index === index
            }
            deleteClause={() => deleteClause(item)}
          />
        ))}
      {!showAddAutoRenewal ? (
        <ClauseHeader
          title="Auto-Renewal"
          buttonText="auto-renewal"
          onClick={() => {
            handleAddEdit(
              props,
              'Auto-Renewal',
              autoRenewal,
              setShowAddAutoRenewal
            );
          }}
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={'Auto-Renewal'}
          savedInsight={autoRenewal ? autoRenewal : []}
          savedParentClauseDataPoint={autoRenewal}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddAutoRenewal(false);
          }}
          updatedClauseDataByType={updates}
          clauseDataByType={data}
          parentClauseType={'renewal'}
        />
      )}
      {autoRenewal &&
        autoRenewal?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'auto_renewal', index });
              props.onClickHighlight(
                index,
                item,
                CLAUSE_DATA.renewal_clause['auto_renewal']
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'auto_renewal' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteAutoRenewal(item)}
          />
        ))}
      {!showAddAutoNoticeRenewal ? (
        <ClauseHeader
          title="Notice for Auto-renewal"
          buttonText="notice"
          onClick={() =>
            handleAddEdit(
              props,
              'Renewal clause',
              noticeAutoRenewal,
              setShowAddAutoNoticeRenewal
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={'Notice for Auto-Renewal'}
          savedInsight={noticeAutoRenewal ? noticeAutoRenewal : []}
          savedParentClauseDataPoint={noticeAutoRenewal}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddAutoNoticeRenewal(false);
          }}
          updatedClauseDataByType={updates}
          clauseDataByType={data}
          parentClauseType={'renewal'}
          phraseLevel
        />
      )}
      {noticeAutoRenewal &&
        noticeAutoRenewal?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'notice_auto_renewal', index });
              props.onClickHighlight(
                index,
                item,
                CLAUSE_DATA.renewal_clause['notice_auto_renewal']
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'notice_auto_renewal' &&
              activeClause?.index === index
            }
            phraseLevel
            deleteClause={() => deleteNoticeAutoRenewal(item)}
          />
        ))}

      {!showAddDate ? (
        <ClauseHeader
          title={CLAUSE_DATA.renewal_clause['notice_reference_date']}
          buttonText="reference date"
          onClick={() =>
            handleAddEdit(
              props,
              'Renewal Notice Reference Date',
              referenceDate,
              setShowAddDate
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={'Renewal Notice Reference Date'}
          savedInsight={referenceDate ? referenceDate : []}
          savedParentClauseDataPoint={referenceDate}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddDate(false);
          }}
          updatedClauseDataByType={updates}
          clauseDataByType={data}
          parentClauseType={'renewal'}
        />
      )}
      {referenceDate &&
        referenceDate?.map((item: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'notice_reference_date', index });
              props.onClickHighlight(
                index,
                item,
                CLAUSE_DATA.renewal_clause['notice_reference_date']
              );
            }}
            index={index}
            clauseItem={item}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'notice_reference_date' &&
              activeClause?.index === index
            }
            deleteClause={() => deleteDate(item)}
            phraseLevel
            subTitle={item?.date || '_'}
          />
        ))}

      {!showAddDuration ? (
        <ClauseHeader
          title="Duration"
          buttonText="duration"
          onClick={() =>
            handleAddEdit(
              props,
              'Renewal Notice Duration',
              renewalDuration,
              setShowAddDuration
            )
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited={'Renewal Notice Duration'}
          savedInsight={renewalDuration ? renewalDuration : []}
          savedParentClauseDataPoint={renewalDuration}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAddDuration(false);
          }}
          updatedClauseDataByType={updates}
          clauseDataByType={data}
          parentClauseType={'renewal'}
        />
      )}
      {renewalDuration &&
        renewalDuration.map((durationData: any, index: number) => (
          <ClauseCard
            key={index}
            onClick={() => {
              setActiveClause({ type: 'duration', index });
              props.onClickHighlight(
                index,
                durationData,
                CLAUSE_DATA.renewal_clause['duration']
              );
            }}
            index={index}
            clauseItem={durationData}
            sentenceData={props?.sentenceData}
            isActive={
              activeClause?.type === 'duration' && activeClause?.index === index
            }
            deleteClause={() => deleteDuration(durationData)}
            phraseLevel
            subTitle={`${durationData.duration_value || '_'} ${
              durationData.duration_type || '_'
            }`}
          />
        ))}
    </>
  );
}
