import React from 'react';

import { Stack } from '@mui/material';

const PageNotFound = () => {
  return (
    <Stack
      justifyContent="center"
      sx={{
        backgroundImage: "url('/static_images/004.jpg')",
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginTop: '9%',
      }}
      width="100%"
      height="80vh"
    />
  );
};

export default PageNotFound;
