import React, { Component } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';

import { ClauseType } from '../../../../Constants/ClauseType';
import {
  dateInfo,
  editedDates,
  tableInfo,
} from '../../../../State/documentState';
import { getClauseDataFormat } from '../../../ClauseComponent/utils/ClauseTypeUtils';
import {
  dateBiMap,
  deleteDateFromDateArray,
  getDatesFromChild,
  getFilteredDateParas,
  getFilteredDatePhrases,
  getFilteredDateTableCells,
} from '../../../Utils/docUtils';

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  savedDates: any;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedHighlightedDates: dateInfo[] | null;
  saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) => void;
  dateInAddEditMode: dateInfo | null;
  saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) => void;
  dateEditingStatus: boolean;
  saveDateEditingStatus: (dateEditingStatus: boolean) => void;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  onClose: () => void;
  updatedClauseDataByType: any;
  sentenceData: any;
  clauseDataByType: any;
  parentClauseType: any;
  clauseData: any;
  updatedClauseData: any;
}

interface State {
  dateInAddMode: dateInfo | null;
  editMode: boolean;
  defaultDate: string;
}

export default class EndDate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateInAddMode: null,
      editMode: false,
      defaultDate: '',
    };
  }
  componentDidMount() {
    const endDates = getDatesFromChild(this.props.savedDates);
    this.props.saveHighlightedDates(endDates);
  }
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.dateInAddEditMode !== nextProps.dateInAddEditMode) {
      if (
        nextProps.dateInAddEditMode !== null &&
        nextProps.dateInAddEditMode.dateId === -1
      ) {
        if (
          this.state.dateInAddMode !== null &&
          (nextProps.dateInAddEditMode.paraId !==
            this.state.dateInAddMode.paraId ||
            nextProps.dateInAddEditMode.rowId !==
              this.state.dateInAddMode.rowId ||
            nextProps.dateInAddEditMode.columnId !==
              this.state.dateInAddMode.columnId)
        ) {
          this.setState({ dateInAddMode: nextProps.dateInAddEditMode });
        }
      }
    }
  }
  render() {
    return (
      <Box
        sx={{
          background: '#FFECF1',
          boxShadow: 'none',
          borderRadius: '15px',
          padding: '10px 16px',
        }}
      >
        <Typography fontWeight={600} mb={1}>
          Add / Edit Term End Date
        </Typography>

        <Stack spacing={2} m="0 10px">
          {this.getEndDates()}

          <Stack width="100%">
            <Typography fontSize="14px" fontWeight={700}>
              How to add an end date?
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              1). Input the date using the field above.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              2). Click on `&quot;`Link To Paragraph`&quot;` button to link a
              text from the contract.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              3). Hover over the text in the contract on left.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              4). Click on the desired paragraph that contains end date.
            </Typography>
            <Typography fontSize="14px" fontWeight={700}>
              5). Confirm your selection using the Save button below.
            </Typography>
          </Stack>

          {this.saveOrCancel()}
        </Stack>
      </Box>
    );
  }

  selectDate = (event: any, editType: string) => {
    if (editType == 'edit') {
      this.setState({ defaultDate: event.currentTarget.value });
      this.props.saveHighlightedDates([]);
      this.setState({ editMode: true });
    } else {
      this.setState({ editMode: false });
    }
    event.preventDefault();
    const selectedDate = event.currentTarget.value;
    const tempDateInAddMode: dateInfo = {
      dateId: -1,
      phrase: selectedDate,
      paraId: -1,
      rowId: -1,
      columnId: -1,
    };
    this.setState({ dateInAddMode: tempDateInAddMode });
    this.props.saveDateEditingStatus(false);
    this.props.saveHighlightedId(null);
    this.props.saveHighlightedTableCells(null);
    this.props.saveDateInAddEditMode(null);
  };

  editLinkedPara(dateInfo: dateInfo) {
    const { saveHighlightedDataPoint, dataPointName } = this.props;

    if (
      dateInfo.paraId !== null &&
      dateInfo.paraId !== -1 &&
      dateInfo.rowId !== null &&
      dateInfo.rowId !== -1 &&
      dateInfo.columnId !== null &&
      dateInfo.columnId !== -1
    ) {
      const tempTableCell = {
        paraId: dateInfo.paraId,
        rowId: dateInfo.rowId,
        columnId: dateInfo.columnId,
      };
      const scrollToTableCellId = document.getElementById(
        'p' + dateInfo.paraId + ';r' + dateInfo.rowId + ';c' + dateInfo.columnId
      );
      scrollToTableCellId !== undefined &&
        scrollToTableCellId !== null &&
        scrollToTableCellId.scrollIntoView({ block: 'center' });
      this.props.saveHighlightedTableCells([tempTableCell]);
      this.props.saveHighlightedId(null);
    } else if (
      dateInfo.paraId !== null &&
      dateInfo.paraId !== -1 &&
      (dateInfo.rowId === null || dateInfo.rowId === -1) &&
      (dateInfo.columnId === null || dateInfo.columnId === -1)
    ) {
      const scrollToParaId = document.getElementById('p' + dateInfo.paraId);
      scrollToParaId !== undefined &&
        scrollToParaId !== null &&
        scrollToParaId.scrollIntoView({ block: 'center' });
      this.props.saveHighlightedId([dateInfo.paraId]);
      this.props.saveHighlightedTableCells(null);
    }
    this.props.saveDateInAddEditMode(dateInfo);
    saveHighlightedDataPoint(dataPointName);
    this.props.saveDateEditingStatus(true);
    this.props.editOptionSelected(true);
  }

  linkToPara(dateInfo: dateInfo) {
    this.props.saveDateEditingStatus(true);
    this.props.saveHighlightedId(null);
    this.props.saveHighlightedTableCells(null);
    this.props.editOptionSelected(true);
    this.props.saveHighlightedDataPoint(this.props.dataPointName);
    this.props.saveDateInAddEditMode(dateInfo);
  }

  getEndDates() {
    const { dateInAddMode } = this.state;
    const { savedHighlightedDates } = this.props;
    if (savedHighlightedDates !== null && savedHighlightedDates.length > 0) {
      return (
        <>
          {savedHighlightedDates.map(
            (date, i) =>
              i === 0 && (
                <Stack key={i} width="100%" alignItems="start" spacing={1}>
                  <input
                    type="date"
                    className="tag-input"
                    value={date.phrase}
                    style={{ width: '100%' }}
                    // readOnly
                    onChange={(e) => this.selectDate(e, 'edit')}
                    defaultValue={this.state.defaultDate}
                  />
                  {this.linkParaToDate(date)}
                </Stack>
              )
          )}
        </>
      );
    } else {
      return (
        <Stack alignItems="start" width="100%" spacing={1}>
          <input
            type="date"
            className="tag-input"
            placeholder="Enter text"
            onChange={(e) => this.selectDate(e, 'insert')}
            style={{ width: '100%' }}
            defaultValue={this.state.defaultDate}
          />
          {this.linkParaToDate(dateInAddMode)}
        </Stack>
      );
    }
  }

  linkParaToDate = (dateString: dateInfo | null) => {
    const { dateInAddEditMode, dateEditingStatus } = this.props;
    if (dateString !== null) {
      if (
        dateEditingStatus === true &&
        dateInAddEditMode !== null &&
        dateString.dateId === dateInAddEditMode.dateId
      ) {
        if (
          dateInAddEditMode.paraId !== null &&
          dateInAddEditMode.paraId > -1
        ) {
          return (
            <Stack
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
            >
              <Typography fontSize="14px" sx={{ color: '#C1C1C1' }}>
                Edit linked paragraph
              </Typography>
              <Typography fontSize="14px">1</Typography>
            </Stack>
          );
        } else {
          return (
            <Stack spacing={1} width="100%">
              <Typography fontSize="14px" sx={{ color: '#C1C1C1' }}>
                Link to paragraph
              </Typography>
            </Stack>
          );
        }
      }

      if (
        (dateInAddEditMode !== null &&
          dateInAddEditMode.dateId !== dateString.dateId) ||
        dateInAddEditMode === null
      ) {
        if (
          dateString.paraId !== null &&
          dateString.paraId > -1 &&
          dateString.phrase !== ''
        ) {
          return (
            <Button
              variant="text"
              sx={{ color: '#88305F', padding: 0 }}
              onClick={() => this.editLinkedPara(dateString)}
            >
              Edit linked paragraph
            </Button>
          );
        } else if (
          dateString.phrase !== '' &&
          (dateString.paraId === null || dateString.paraId === -1)
        ) {
          return (
            <Button
              variant="text"
              sx={{ color: '#88305F', padding: 0 }}
              onClick={() => this.linkToPara(dateString)}
            >
              Link to paragraph
            </Button>
          );
        }
      }
    }
  };

  saveOrCancel = () => {
    return (
      <Stack direction="row">
        <Button
          variant="contained"
          type="submit"
          startIcon={<CheckIcon />}
          onClick={() => this.onSave()}
          disabled={!this.getSaveStatus()}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => this.onCancel()}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </Stack>
    );
  };

  getSaveStatus() {
    const { savedHighlightedDates } = this.props;
    const { dateInAddMode } = this.state;
    let isSavedDatesLinked = false;
    if (savedHighlightedDates !== null && savedHighlightedDates.length > 0) {
      if (
        savedHighlightedDates.findIndex(
          (date) => date.paraId === null || date.paraId === -1
        ) === -1
      ) {
        isSavedDatesLinked = true;
      }
    } else if (
      savedHighlightedDates === null ||
      savedHighlightedDates.length === 0
    ) {
      isSavedDatesLinked = true;
    }
    let isNewDateLinked = false;
    if (dateInAddMode !== null) {
      if (dateInAddMode.paraId !== null && dateInAddMode.paraId !== -1) {
        isNewDateLinked = true;
      }
    } else if (dateInAddMode === null) {
      isNewDateLinked = true;
    }
    if (isSavedDatesLinked === true && isNewDateLinked === true) {
      return true;
    }
    return false;
  }

  onCancel() {
    this.props.saveHighlightedDates(null);
    this.props.saveDateInAddEditMode(null);
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedId(null);
    this.props.editOptionSelected(false);
    this.props.saveDateEditingStatus(false);
    this.setState({ dateInAddMode: null });
    this.props.onClose();
  }

  onSave() {
    const { dateInAddMode } = this.state;
    const { savedHighlightedDates, dataPointName } = this.props;
    const tempHighlightedDates =
      savedHighlightedDates !== null ? savedHighlightedDates : [];
    if (dateInAddMode !== null && dateInAddMode.phrase !== '') {
      tempHighlightedDates.push(dateInAddMode);
    }
    const oldDates = getDatesFromChild(this.props.savedDates);
    let upsertDates: dateInfo[] = [];
    let deletedDates: dateInfo[] = [];
    let editedDates: editedDates = { upsert: [], deleted: [], bi: '' };
    if (oldDates.length === 0) {
      if (
        savedHighlightedDates === null ||
        savedHighlightedDates.length === 0
      ) {
        //nothing added nor deleted
        editedDates.upsert = [];
        editedDates.deleted = [];
      } else if (
        savedHighlightedDates !== null &&
        savedHighlightedDates.length > 0
      ) {
        //new dates added
        upsertDates = [];
        savedHighlightedDates.forEach((date) => upsertDates.push(date));
        editedDates.upsert = upsertDates;
        editedDates.deleted = [];
      }
    } else if (
      oldDates.length > 0 &&
      (savedHighlightedDates === null || savedHighlightedDates.length === 0)
    ) {
      //all old dates deleted
      deletedDates = [];
      oldDates.forEach((date) => deletedDates.push(date));
      editedDates.upsert = [];
      editedDates.deleted = deletedDates;
    } else {
      const oldDatePhrases = getFilteredDatePhrases(oldDates);
      const changedDatePhrases = getFilteredDatePhrases(tempHighlightedDates);
      const oldDateParas = getFilteredDateParas(oldDates);
      const changedDateParas = getFilteredDateParas(tempHighlightedDates);
      const oldDateTableCells = getFilteredDateTableCells(oldDates);
      const changedDateTableCells =
        getFilteredDateTableCells(tempHighlightedDates);

      const editedDatePhrases = this.getAddedDeletedDatePhrases(
        oldDatePhrases,
        changedDatePhrases
      );
      const editedDateParas = this.getAddedDeletedDateParas(
        oldDateParas,
        changedDateParas
      );
      const editedDateTableCells = this.getAddedDeletedDateTableCells(
        oldDateTableCells,
        changedDateTableCells
      );

      editedDates = this.mergeAddedDeletedDates(
        editedDatePhrases,
        editedDateParas,
        editedDateTableCells
      );
    }

    editedDates.bi = dateBiMap[dataPointName];

    const payload = [];
    for (let i = 0; i < editedDates.upsert.length; i++) {
      payload.push({
        date: new Date(editedDates.upsert[i].phrase).toDateString(),
        normalized_date: editedDates.upsert[i].phrase,
        paraId: editedDates.upsert[i].paraId,
        table: [
          {
            row: editedDates.upsert[i].rowId,
            column: editedDates.upsert[i].columnId,
          },
        ],
      });
    }

    const updates = getClauseDataFormat(
      'add',
      this.props.clauseType,
      payload[0],
      this.props.updatedClauseDataByType,
      this.props.sentenceData
    );

    const diff = changesets.diff(
      this.props.clauseDataByType?.raw_content,
      updates,
      {
        children: '$index',
      }
    );

    if (diff.length > -1) {
      this.props.postClauseDataByType(
        this.props.fileId,
        this.props.parentClauseType,
        diff,
        updates
      );
    }

    this.props.saveHighlightedDates(null);
    this.props.saveDateInAddEditMode(null);
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedId(null);
    this.props.saveDateEditingStatus(false);
    this.props.editOptionSelected(false);
    this.setState({ dateInAddMode: null });
    this.props.onClose();
  }

  addOrRemoveDates(action: string, dateInfo: dateInfo | null) {
    if (dateInfo !== null) {
      const { dateInAddMode } = this.state;
      const { savedHighlightedDates } = this.props;
      if (action === 'add') {
        if (dateInAddMode !== null) {
          let lastDateLength = -1;
          if (savedHighlightedDates !== null) {
            lastDateLength = savedHighlightedDates.length;
          } else {
            lastDateLength = 0;
          }
          const tempDate = dateInAddMode;
          tempDate.dateId = lastDateLength;
          const tempDates =
            savedHighlightedDates !== null ? savedHighlightedDates : [];
          tempDates.push(tempDate);
          this.props.saveHighlightedDates(tempDates);
          this.setState({ dateInAddMode: null });
          this.props.saveDateInAddEditMode(null);
          this.props.saveHighlightedId(null);
          this.props.saveHighlightedTableCells(null);
          this.props.saveDateEditingStatus(false);
        }
      } else if (action === 'remove') {
        if (savedHighlightedDates !== null) {
          const tempFilteredDates = deleteDateFromDateArray(
            dateInfo,
            savedHighlightedDates
          );
          this.props.saveHighlightedDates(tempFilteredDates);
          this.props.saveDateInAddEditMode(null);
          this.props.saveHighlightedTableCells(null);
          this.props.saveHighlightedId(null);
          this.props.saveDateEditingStatus(false);
        }
      }
    }
  }

  getAddedDeletedDateParas(
    previousDateParas: dateInfo[],
    changedDateParas: dateInfo[]
  ): editedDates {
    const addedDateParas: dateInfo[] = [];
    const deletedDateParas: dateInfo[] = [];
    if (previousDateParas.length > 0) {
      if (changedDateParas.length > 0) {
        //newly added
        for (let i = 0; i < changedDateParas.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDateParas.length; j++) {
            if (
              changedDateParas[i].phrase === previousDateParas[j].phrase &&
              changedDateParas[i].paraId === previousDateParas[j].paraId
            ) {
              addedExists = true;
              break;
            }
          }
          if (addedExists === false) {
            addedDateParas.push(changedDateParas[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousDateParas.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDateParas.length; j++) {
            if (
              previousDateParas[i].phrase === changedDateParas[j].phrase &&
              previousDateParas[i].paraId === changedDateParas[j].paraId
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDateParas.push(previousDateParas[i]);
          }
        }
      } else {
        //all old deleted
        for (let i = 0; i < previousDateParas.length; i++) {
          deletedDateParas.push(previousDateParas[i]);
        }
      }
    } else {
      if (changedDateParas.length > 0) {
        //newly added
        for (let i = 0; i < changedDateParas.length; i++) {
          addedDateParas.push(changedDateParas[i]);
        }
      }
    }

    const editedDateParas: editedDates = {
      upsert: addedDateParas,
      deleted: deletedDateParas,
      bi: '',
    };
    return editedDateParas;
  }

  getAddedDeletedDateTableCells(
    previousDateTableCells: dateInfo[],
    changedDateTableCells: dateInfo[]
  ) {
    const addedDateTableCells: dateInfo[] = [];
    const deletedDateTableCells: dateInfo[] = [];
    if (previousDateTableCells.length > 0) {
      if (changedDateTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedDateTableCells.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDateTableCells.length; j++) {
            if (
              changedDateTableCells[i].phrase ===
                previousDateTableCells[j].phrase &&
              changedDateTableCells[i].paraId ===
                previousDateTableCells[j].paraId &&
              changedDateTableCells[i].rowId ===
                previousDateTableCells[j].rowId &&
              changedDateTableCells[i].columnId ===
                previousDateTableCells[j].columnId
            ) {
              addedExists = true;
              break;
            }
            if (addedExists === false) {
              addedDateTableCells.push(changedDateTableCells[i]);
            }
          }
        }
        //deleted elements
        for (let i = 0; i < previousDateTableCells.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDateTableCells.length; j++) {
            if (
              previousDateTableCells[i].phrase ===
                changedDateTableCells[j].phrase &&
              previousDateTableCells[i].paraId ===
                changedDateTableCells[j].paraId &&
              previousDateTableCells[i].rowId ===
                changedDateTableCells[j].rowId &&
              previousDateTableCells[i].columnId ===
                changedDateTableCells[j].columnId
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDateTableCells.push(previousDateTableCells[i]);
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousDateTableCells.length; i++) {
          deletedDateTableCells.push(previousDateTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedDateTableCells.length > 0) {
        for (let i = 0; i < changedDateTableCells.length; i++) {
          addedDateTableCells.push(changedDateTableCells[i]);
        }
      }
    }

    const editedDateTableCells: editedDates = {
      upsert: addedDateTableCells,
      deleted: deletedDateTableCells,
      bi: '',
    };
    return editedDateTableCells;
  }

  getAddedDeletedDatePhrases(
    previousDatePhrases: dateInfo[],
    changedDatePhrases: dateInfo[]
  ) {
    const addedDatePhrases: dateInfo[] = [];
    const deletedDatePhrases: dateInfo[] = [];

    if (previousDatePhrases.length > 0) {
      if (changedDatePhrases.length > 0) {
        //newly added
        for (let i = 0; i < changedDatePhrases.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousDatePhrases.length; j++) {
            if (
              changedDatePhrases[i].phrase === previousDatePhrases[j].phrase
            ) {
              addedExists = true;
              break;
            }
          }
          if (addedExists === false) {
            addedDatePhrases.push(changedDatePhrases[i]);
          }
        }
        //deleted elements
        for (let i = 0; i < previousDatePhrases.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedDatePhrases.length; j++) {
            if (
              previousDatePhrases[i].phrase === changedDatePhrases[j].phrase
            ) {
              deletedExists = true;
              break;
            }
          }
          if (deletedExists === false) {
            deletedDatePhrases.push(previousDatePhrases[i]);
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousDatePhrases.length; i++) {
          deletedDatePhrases.push(previousDatePhrases[i]);
        }
      }
    } else {
      if (changedDatePhrases.length > 0) {
        //all newly added
        for (let i = 0; i < changedDatePhrases.length; i++) {
          addedDatePhrases.push(changedDatePhrases[i]);
        }
      }
    }

    const editedDatePhrases: editedDates = {
      upsert: addedDatePhrases,
      deleted: deletedDatePhrases,
      bi: '',
    };
    return editedDatePhrases;
  }

  mergeAddedDeletedDates(
    firstEditedDates: editedDates,
    secondEditedDates: editedDates,
    thirdEditedDates: editedDates
  ) {
    const upsertDates = firstEditedDates.upsert
      .concat(secondEditedDates.upsert)
      .concat(thirdEditedDates.upsert);
    const deletedDates = firstEditedDates.deleted
      .concat(secondEditedDates.deleted)
      .concat(thirdEditedDates.deleted);

    const mergedEditedDates = {
      upsert: upsertDates,
      deleted: deletedDates,
      bi: '',
    };
    return mergedEditedDates;
  }
}
