import React, { Dispatch, SetStateAction } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';

import DeviationCard from './DeviationCard';
import { riPrimary } from '../../../RiverusUI/Theme/colors';

const allTypes = [
  { value: 'show_all', label: 'Show all' },
  { value: 'deviant', label: 'Deviant' },
  { value: 'compliant', label: 'Compliant' },
  { value: 'unrecognized', label: 'Unrecognized' },
];

const status = [
  { value: 'default', label: 'Default' },
  { value: 'approval_pending', label: 'Approval pending' },
  { value: 'approved', label: 'Approved' },
];

interface Props {
  instance: WebViewerInstance | null;
  handleOpenClauseLibraryDrawer: VoidFunction;
  userIsOwner: boolean;
  selectedType: string;
  setSelectedType: Dispatch<SetStateAction<string>>;
  selectedStatus: string;
  setSelectedStatus: Dispatch<SetStateAction<string>>;
  filteredDeviation: any[];
  combinedApproversIds: any[];
  isDraftDropped?: boolean;
}

const Policies: React.FC<Props> = ({
  instance,
  handleOpenClauseLibraryDrawer,
  userIsOwner,
  selectedType,
  setSelectedType,
  selectedStatus,
  setSelectedStatus,
  filteredDeviation,
  combinedApproversIds,
  isDraftDropped,
}) => {
  const handleTypes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(event.target.value as string);
  };

  const handleStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as string);
  };

  return (
    <Box>
      <Stack spacing={2} direction="row" width="100%">
        <TextField
          sx={{ minWidth: '150px' }}
          select
          value={selectedType}
          onChange={handleTypes}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Type:</InputAdornment>
            ),
          }}
        >
          {allTypes?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{
            minWidth: '200px',
            background: selectedStatus ? riPrimary[500] : '',
            borderRadius: '5px',
            '& .MuiSelect-select:focus': {
              color: '#fff',
            },
            '& .MuiSelect-select': {
              color: selectedStatus ? '#fff' : 'black',
            },
          }}
          select
          value={selectedStatus}
          onChange={handleStatus}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ color: selectedStatus ? '#fff' : 'black' }}
              >
                {selectedStatus && (
                  <IconButton
                    onClick={() => {
                      setSelectedStatus('');
                    }}
                    style={{ color: selectedStatus ? '#fff' : 'black' }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                Status:
              </InputAdornment>
            ),
          }}
        >
          {status?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {filteredDeviation?.map((list: any, index: number) => (
        <DeviationCard
          key={index}
          cardData={list}
          instance={instance}
          handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
          userIsOwner={userIsOwner}
          combinedApproversIds={combinedApproversIds}
          isDraftDropped={isDraftDropped}
        />
      ))}
      {filteredDeviation?.length === 0 && (
        <Typography marginTop="15px">No issue found</Typography>
      )}
    </Box>
  );
};

export default Policies;
