import React, { useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import CustomModal from '../RiverusUI/Components/CustomModal';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import { updateStampPaper } from '../Services/Stamp';

interface UploadStampDialogProps {
  open: boolean;
  handleCloseModal: VoidFunction;
  stampData: any;
  stampFileName: any;
  s3BucketLoading: any;
  handleFileChange: any;
  selectedFile: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6D264C' : '#308fe8',
  },
}));

const UploadStampDialog: React.FC<UploadStampDialogProps> = ({
  open,
  handleCloseModal,
  stampData,
  stampFileName,
  s3BucketLoading,
  handleFileChange,
  selectedFile,
}) => {
  const { control } = useForm();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const { mutate: updateStampPaperMutation } = useMutation({
    mutationKey: ['update_stamp_paper'],
    mutationFn: updateStampPaper,
    onSuccess: () => {
      handleCloseDeleteModal();
      handleCloseModal();

      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-by-state'] });

      enqueueSnackbar({
        message: 'Stamp paper updated successfully!',
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleDeleteSupportDocs = () => {
    const payload = {
      id: stampData?.id,
      body: {
        link: null,
      },
    };
    updateStampPaperMutation(payload);
  };

  return (
    <React.Fragment>
      <CustomModal
        open={open}
        handleClose={handleCloseModal}
        title="Upload Stamp Paper"
      >
        <Stack spacing={2} mt="25px" width="550px">
          <Controller
            name="upload_contract"
            control={control}
            render={() => (
              <TextField
                variant="outlined"
                label="Upload file"
                fullWidth
                disabled
                helperText="Supported file formats: PDF"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor={`upload-doc`}>
                        <Tooltip
                          placement="bottom"
                          title="Replace attached stamp paper"
                        >
                          <IconButton component="label">
                            <FileUploadOutlinedIcon fontSize="small" />
                            <input
                              type="file"
                              hidden
                              accept="application/pdf"
                              onChange={(event) =>
                                handleFileChange(event, stampData?.id)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {s3BucketLoading && (
            <Box sx={{ width: '100%', padding: '15px 0' }}>
              <LinearProgress />
            </Box>
          )}

          <Stack direction="column" spacing={1} paddingBottom="15px">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="17px" fontWeight={600}>
                {selectedFile?.name || stampFileName?.file_name}
              </Typography>
              <Tooltip placement="bottom" title="Delete attached stamp paper">
                <LoadingButton size="small" onClick={handleOpenDeleteModal}>
                  <DeleteOutlineIcon />
                </LoadingButton>
              </Tooltip>
            </Stack>
            <BorderLinearProgress variant="determinate" value={100} />
          </Stack>

          <Stack>
            <LoadingButton
              variant="outlined"
              onClick={handleCloseModal}
              disabled={s3BucketLoading}
              loadingPosition="end"
              sx={{ width: 'fit-content' }}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </Stack>
      </CustomModal>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => handleDeleteSupportDocs()}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default UploadStampDialog;
