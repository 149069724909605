import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const SettingIcon = ({ props }: any) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.254 14.475a3.5 3.5 0 1 0 4.95-4.95 3.5 3.5 0 0 0-4.95 4.95ZM17.23 12c0 .33-.03.65-.07.97l2.11 1.66c.19.15.24.42.12.64l-2 3.46c-.12.22-.39.3-.61.22l-2.49-1.01c-.52.4-1.06.73-1.69.99l-.37 2.65c-.04.24-.25.42-.5.42h-4c-.25 0-.46-.18-.5-.42l-.37-2.65c-.63-.25-1.17-.59-1.69-.99l-2.49 1.01c-.22.08-.49 0-.61-.22l-2-3.46a.493.493 0 0 1 .12-.64l2.11-1.66a7.77 7.77 0 0 1-.07-.97c0-.33.03-.66.07-1L.19 9.37a.493.493 0 0 1-.12-.64l2-3.46c.12-.22.39-.31.61-.22l2.49 1c.52-.39 1.06-.73 1.69-.98l.37-2.65c.04-.24.25-.42.5-.42h4a.506.506 0 0 1 .5.42l.37 2.65c.63.25 1.17.59 1.69.98l2.49-1c.22-.09.49 0 .61.22l2 3.46c.12.22.07.49-.12.64L17.16 11c.04.34.07.67.07 1Zm4.271 1L19 11h5l-2.5 2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SettingIcon;
