import React from 'react';

import CreateDraft from './CreateDraft';
import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const CreateDraftDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <CustomModal
      maxWidth="md"
      title="Create Draft"
      open={open}
      handleClose={onClose}
    >
      <CreateDraft onClose={onClose} />
    </CustomModal>
  );
};

export default CreateDraftDialog;
