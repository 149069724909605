import React, { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import LoadingAnim from '../../../Assessments/Components/loadingAnim';
import {
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '../../../Authentication/Actions/authentication';
import { getProfile } from '../../../Services/authApi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserAuthChecker = (ComposedClass: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AuthenticationCheck = (props: any) => {
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const profile = useMemo(() => getSessionStorage('user_profile'), []);

    useQuery({
      queryKey: ['profile'],
      queryFn: async () => {
        setLoading(true);
        const response = await getProfile();

        if (response) {
          const profile = response as any;

          setSessionStorage('user_profile', profile);
          setLocalStorage('user_id', profile.id);
          setLocalStorage(
            'user_name',
            `${profile.first_name} ${profile.last_name}`
          );
          setLoading(false);
        }
      },
      enabled: !profile,
    });

    if (loading) {
      return <LoadingAnim />;
    } else return <ComposedClass {...props} />;
  };

  return AuthenticationCheck;
};

export default UserAuthChecker;
