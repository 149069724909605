export const setLocalStorage = (key, value) => {
  if (window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const setSessionStorage = (key, value) => {
  if (window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeLocalStorage = (key) => {
  if (window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getLocalStorage = (key) => {
  if (window !== 'undefined') {
    if (localStorage.getItem(key) !== 'undefined') {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  }
};

export const getSessionStorage = (key) => {
  if (window !== 'undefined') {
    if (window.sessionStorage.getItem(key) !== 'undefined') {
      return JSON.parse(window.sessionStorage.getItem(key));
    } else {
      return null;
    }
  }
};

export const setKeyCloakRealmOnLS = (realm) => {
  if (realm) {
    setLocalStorage('kcRealm', realm);
  }
};

export const getKeyCloakRealmFromLS = () => {
  const realmName = getLocalStorage('kcRealm');

  return realmName;
};

export const removeKeyCloakRealmOnLS = () => {
  removeLocalStorage('kcRealm');
};

export const setUserId = (key, value) => {
  if (window !== 'undefined') {
    localStorage.setItem(key, value);
  }
};

export const getUserId = (key) => {
  if (window !== 'undefined') {
    return JSON.parse(localStorage.getItem(key));
  }
};
