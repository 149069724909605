import { combineReducers } from 'redux';

import ClauseComponentReducer from '../../DocumentView/Component/ClauseComponent/Reducer/clauseComponentRed';
import ClauseComponentState from '../../DocumentView/Component/ClauseComponent/State/clauseComponentState';
import documentReducer from '../../DocumentView/Reducer/documentRed';
import DocumentState from '../../DocumentView/State/documentState';
import HeimdallReducer from '../../UniversalComponents/HeimdallChild/Reducer/heimdallReducer';
import HeimdallState from '../../UniversalComponents/HeimdallChild/State/heimdallState';

export interface StoreTree {
  [x: string]: any;
  heimdall: HeimdallState;
  document: DocumentState;
  clauseComponent: ClauseComponentState;
}

export const mainReducer = combineReducers({
  heimdall: HeimdallReducer,
  document: documentReducer,
  clauseComponent: ClauseComponentReducer,
});
