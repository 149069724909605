import React, { useCallback } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

import {
  getLocalStorage,
  setLocalStorage,
} from '../../../Authentication/Actions/authentication';

export default function UserInfo() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { keycloak } = useKeycloak();

  const handleLogOut = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
    keycloak.logout();
    setLocalStorage('loginStatus', 'finished');
  }, []);

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{ backgroundColor: '#efefef' }}>
          {getLocalStorage('user_name') || ''}
        </MenuItem>
        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
      </Menu>
    </>
  );
}
