import React, { useEffect, useMemo, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import PaymentTimelineCard from './PaymentTimelineCard';
import { deleteSingleColumn } from './utils/ClauseTypeUtils';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import EditFeature from '../EditFeature/Container/cont';

export default function PaymentObligationsClause({
  getClauseDataByType,
  fileId,
  clauseData,
  updatedClauseData,
  postClauseDataByType,
  durationList,
  sentenceData,
  hasData,
  ...props
}: ClauseComponentInterface) {
  const [showAdd, setShowAdd] = useState(false);
  const [showAddAmount, setShowAddAmount] = useState({
    index: -1,
    canEdit: false,
  });
  const [showAddTimeline, setShowAddTimeline] = useState({
    index: -1,
    canEdit: false,
  });
  const [isChildDeleted, setChildDeleted] = React.useState(true);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'payment');
  }, [fileId, hasData]);

  const data = useMemo(
    () => clauseData?.payment && clauseData.payment,
    [clauseData?.payment]
  );

  const updates = useMemo(
    () => updatedClauseData?.payment || {},
    [updatedClauseData]
  );

  const payment = useMemo(() => {
    if (updates?.payment) {
      const sortData = updates.payment.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const paymentAmounts = useMemo(() => {
    if (updates?.amounts) {
      const sortData = updates.amounts.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const paymentTimelines = useMemo(() => {
    if (updates?.timeline) {
      const sortData = updates.timeline.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationName === data.duartion_type
        );
        if (index > -1) {
          const type = durationList[index].durationName;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [durationList, updates.timeline]);

  const combinedData = useMemo(() => {
    payment.map((data: any) => {
      data.child = { amount: null, timeline: null };
      paymentAmounts.map((amountData: any) => {
        if (
          data.para_id === amountData.para_id &&
          data.start_sentence_id === amountData.start_sentence_id
        ) {
          data.child.amount = amountData;
        }
      });
      paymentTimelines.map((timelineData: any) => {
        if (
          data.para_id === timelineData.para_id &&
          data.start_sentence_id === timelineData.start_sentence_id
        ) {
          data.child.timeline = timelineData;
        }
      });
    });
    return payment;
  }, [payment, paymentAmounts, paymentTimelines]);

  useEffect(() => {
    if (data && data?.edited_content === null && isChildDeleted) {
      let updatedData = {
        ...updates,
        amounts: [],
        timeline: [],
      };
      if (payment && payment.length) {
        paymentAmounts?.map((paymentAmount: any) => {
          const index = payment.findIndex(
            (item: any) =>
              item.para_id !== paymentAmount.para_id ||
              item.sentence_id !== paymentAmount.sentence_id
          );
          if (index > -1) {
            paymentAmounts?.splice(index, 1);
          }
        });
        paymentTimelines?.map((paymentTimeline: any) => {
          const index = payment.findIndex(
            (item: any) =>
              item.para_id !== paymentTimeline.para_id ||
              item.sentence_id !== paymentTimeline.sentence_id
          );
          if (index > -1) {
            paymentTimelines?.splice(index, 1);
          }
        });
        setChildDeleted(false);
        updatedData = {
          ...updates,
          amounts: paymentAmounts,
          timeline: paymentTimelines,
        };
      }
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'payment', diff, updatedData);
        getClauseDataByType(fileId, 'payment');
      }
    }
  }, [
    data,
    updates,
    payment,
    paymentAmounts,
    paymentTimelines,
    isChildDeleted,
    fileId,
    postClauseDataByType,
    getClauseDataByType,
  ]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let _payment: any;
      let amounts: any;
      let timeline: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        _payment = deleteSingleColumn(payment, tableData);

        amounts = deleteSingleColumn(paymentAmounts, tableData);

        timeline = deleteSingleColumn(paymentTimelines, tableData);
      } else {
        _payment = payment?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id
        );

        amounts = paymentAmounts?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id
        );

        timeline = paymentTimelines?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.start_sentence_id !== item.start_sentence_id
        );
      }

      const updatedData = {
        ...updates,
        payment: _payment,
        amounts,
        timeline,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'payment', diff, updatedData);
      }
    },
    [
      payment,
      paymentAmounts,
      paymentTimelines,
      updates,
      data?.raw_content,
      postClauseDataByType,
      fileId,
    ]
  );

  const deleteAmount = React.useCallback(
    (item: any) => {
      const amounts = paymentAmounts?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.end_sentence_id !== item.end_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      const updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'payment', diff, updatedData);
      }
    },
    [updates, postClauseDataByType, paymentAmounts, data, fileId]
  );

  const deleteTimeline = React.useCallback(
    (item: any) => {
      const timeline = paymentTimelines?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.end_sentence_id !== item.end_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      const updatedData = {
        ...updates,
        timeline,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: '$index',
      });
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'payment', diff, updatedData);
      }
    },
    [paymentTimelines, updates, data?.raw_content, postClauseDataByType, fileId]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="payment"
          onClick={() => handleAddEdit(props, 'payment', payment, setShowAdd)}
        />
      ) : (
        <EditFeature
          fileId={fileId}
          toBeEdited="payment"
          savedInsight={payment ? payment : []}
          savedParentClauseDataPoint={payment}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
          parentClauseType="payment"
        />
      )}
      <Scrollable maxHeight={400}>
        {combinedData?.map((item: any, index: number) => (
          <React.Fragment key={`payment-${index}`}>
            <ClauseCard
              onClick={() => {
                setActiveClause({ type: 'payment', index });
                props.onClickHighlight(index, item, 'payment');
              }}
              index={index}
              clauseItem={item}
              sentenceData={sentenceData}
              isActive={
                activeClause?.type === 'payment' &&
                activeClause?.index === index
              }
              deleteClause={() => deleteClause(item)}
              sentenceLevel
            />

            {showAddAmount.index === index ? (
              <EditFeature
                fileId={fileId}
                toBeEdited="Payment Amounts"
                savedInsight={paymentAmounts ? paymentAmounts : []}
                savedParentClauseDataPoint={paymentAmounts}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddAmount({ index: -1, canEdit: false });
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="payment"
                canAddTags
                parentData={item}
              />
            ) : item.child.amount ? (
              <PaymentTimelineCard
                heading="Amounts"
                subHeading={
                  `${item.child.amount?.total} ${item.child.amount?.currency}` ||
                  ''
                }
                index={index}
                text={item.child.amount?.amountString || ''}
                tags={item.child.amount?.tags}
                handleEdit={() => {
                  setActiveClause({ type: 'payment', index });
                  props.onClickHighlight(index, item, 'payment');
                  props.onEdit('Amount', 0, false, [], -1, '', paymentAmounts);
                  setShowAddAmount({ index, canEdit: true });
                }}
                deleteClause={() => deleteAmount(item.child.amount)}
              />
            ) : (
              <ClauseHeader
                title="Amount"
                buttonText="amount"
                onClick={() => {
                  setActiveClause({ type: 'payment', index });
                  props.onClickHighlight(index, item, 'payment');
                  props.onEdit('Amount', 0, false, [], -1, '', paymentAmounts);
                  setShowAddAmount({ index, canEdit: true });
                }}
              />
            )}
            {showAddTimeline.index === index ? (
              <EditFeature
                fileId={fileId}
                toBeEdited="Payment Timeline"
                savedInsight={paymentTimelines ? paymentTimelines : []}
                savedParentClauseDataPoint={paymentTimelines}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddTimeline({ index: -1, canEdit: false });
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="payment"
                parentData={item}
              />
            ) : item.child.timeline ? (
              <PaymentTimelineCard
                heading="Timeline"
                subHeading={
                  `${item.child.timeline?.duration_value} ${item.child.timeline?.duration_type}` ||
                  ''
                }
                index={index}
                text={item.child.timeline?.phrase || ''}
                handleEdit={() => {
                  setActiveClause({ type: 'payment', index });
                  props.onClickHighlight(index, item, 'payment');
                  props.onEdit(
                    'Payment Timeline',
                    0,
                    false,
                    [],
                    -1,
                    '',
                    paymentTimelines
                  );
                  setShowAddTimeline({ index, canEdit: true });
                }}
                deleteClause={() => deleteTimeline(item.child.timeline)}
              />
            ) : (
              <ClauseHeader
                title="Timeline"
                buttonText="timeline"
                onClick={() => {
                  setActiveClause({ type: 'payment', index });
                  props.onClickHighlight(index, item, 'payment');
                  props.onEdit(
                    'Payment Timeline',
                    0,
                    false,
                    [],
                    -1,
                    '',
                    paymentTimelines
                  );
                  setShowAddTimeline({ index, canEdit: true });
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Scrollable>
    </>
  );
}
