import React, { FC, useCallback, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, LinearProgress, Stack, Tooltip } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import StampTable from './StampTable';
import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import RISelectComponent from '../RiverusUI/Components/SelectComponent';
import { upload_file_in_s3_bucket } from '../Services/Draft';
import {
  fetchStamps,
  fetchState,
  postStamp,
  stampFileUpload,
} from '../Services/Stamp';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const SparkMD5 = require('spark-md5');

const CreateStampForm: FC<IProps> = (props) => {
  const { open, onClose } = props;
  const methods = useForm();
  const { handleSubmit, control, watch, resetField } = methods;
  const state = watch('state') || '';
  const [filePath, setFilePath] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: stateData, isLoading: stateLoading } = useQuery({
    queryKey: ['StateList'],
    queryFn: fetchState,
  });

  const { mutate: addStampMutation } = useMutation({
    mutationKey: ['add-stamp'],
    mutationFn: postStamp,
    onSuccess: () => {
      enqueueSnackbar('Stamp paper added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank-list'] });
      queryClient.invalidateQueries({ queryKey: ['stamp-bank'] });
      resetField('serial_number');
      resetField('denomination');
      setFilePath(null);
      setSelectedFile(null);
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to create Stamp paper!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: stampFileUploadMutation } = useMutation({
    mutationKey: ['upload-stamp-file'],
    mutationFn: stampFileUpload,
    onSuccess: (response: any) => {
      const presignedUrl = response?.presigned_url;
      if (presignedUrl) {
        const { url, fields } = presignedUrl;
        const filePath = `${url}${fields?.key}`;
        setFilePath(filePath);
        if (selectedFile) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.presigned_url,
            file: selectedFile,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
        enqueueSnackbar('Stamp paper uploaded successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload Stamp paper!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadFileInS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = selectedFile;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [selectedFile]
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const hexHash = SparkMD5.ArrayBuffer.hash(arrayBuffer);
        const payload = {
          file_name: file.name,
          file_hash: hexHash,
        };
        stampFileUploadMutation(payload);
      };
      reader.readAsArrayBuffer(file);
      event.target.value = '';
    }
  };

  const stateName = useMemo(() => {
    if ((stateData as any)?.results) {
      return (stateData as any).results.filter(
        (data: any) => data.id === state
      )?.[0]?.name;
    } else {
      return '';
    }
  }, [stateData, state]);

  const { data: stampBank, isLoading: stampLoading } = useQuery({
    queryKey: ['stamp-bank-list', stateName],
    queryFn: () => fetchStamps(`?state=${stateName}`),
    enabled: !!stateName,
  });

  const onSubmit = useCallback(
    (data: any) => {
      if (filePath) {
        data.link = filePath;
      }
      addStampMutation(data);
    },
    [addStampMutation, filePath]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Add/Modify stamp-papers"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '600px', padding: '30px 10px' }} gap={2}>
            <RISelectComponent
              required
              name="state"
              control={control}
              label="Select State(province)"
              options={(stateData as any)?.results}
              loading={stateLoading}
            />
            {state && (
              <Stack gap={1}>
                {stampLoading ? (
                  <LinearProgress />
                ) : (
                  <StampTable data={stampBank} isLoading={false} />
                )}
                <Stack direction="row" gap={1}>
                  <ControlledTextField
                    name="serial_number"
                    control={control}
                    placeholder="Serial Number"
                  />
                  <ControlledTextField
                    name="denomination"
                    control={control}
                    placeholder="value"
                    type="number"
                  />
                  <Button component="label">
                    {selectedFile ? (
                      <Tooltip
                        placement="bottom"
                        title="Replace attached stamp paper"
                      >
                        <AttachmentIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip placement="bottom" title="Upload stamp paper">
                        <FileUploadIcon />
                      </Tooltip>
                    )}
                    <input
                      type="file"
                      hidden
                      accept="application/pdf"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    startIcon={<AddIcon />}
                    type="submit"
                  >
                    Add Record
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateStampForm;
