/* eslint-disable @typescript-eslint/no-unused-vars */
import { connect } from 'react-redux';

import { StoreTree } from '../../Utils/MainReducer/mainReducer';
import DocumentActionGenerator from '../Actions/Gen';
import DocumentView from '../Component/DocumentView';

export function mapStateToProps(appState: StoreTree) {
  return {
    sentenceData: appState.document.sentenceData,
    insightsData: appState.document.insightsData,
    sentenceLoader: appState.document.sentenceLoader,
    editLoader: appState.document.editLoader,
    editSuccessLoader: appState.document.editSuccessLoader,
    editFailureLoader: appState.document.editFailureLoader,
    storedBiPointDataModes: appState.document.storedBiPointDataModes,
    clauseModeStatus: appState.document.clauseModeStatus,
    durationList: appState.document.durationList,
    currencyList: appState.document.currencyList,
    contractData: appState.document.contractData,
    clauseInsightsLoader: appState.document.clauseInsightsLoader,
    clauseData: appState.clauseComponent.clauseData,
    updatedClauseData: appState.clauseComponent.updatedClauseData,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getSentences: (fileId: string) =>
      dispatch(DocumentActionGenerator.getSentences(fileId)),
    getContractData: (fileId: string) =>
      dispatch(DocumentActionGenerator.getContractData(fileId)),
    editDataPoint: (
      fileId: string,
      dataType: string,
      dataPointName: any,
      highlightedId?: number | null
    ) =>
      dispatch(
        DocumentActionGenerator.editDataPoint(
          fileId,
          dataType,
          dataPointName,
          highlightedId
        )
      ),
    getDurationTypes: () =>
      dispatch(DocumentActionGenerator.getDurationTypes()),
    getCurrencyTypes: () =>
      dispatch(DocumentActionGenerator.getCurrencyTypes()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentView);
