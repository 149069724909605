import React from 'react';

import { Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import ContractDetails from './ContractDetails';
import OriginalDocuments from './OriginalDocuments';
import SupportDocuments from './SupportDocuments';
import { download_file } from '../../../Draft/Component/Helper';
import {
  fetchContractById,
  fetchDownloadUrl,
} from '../../../Services/DocumentLibrary';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';

interface Props {
  fileId: string;
}

const AboutThisContract: React.FC<Props> = ({ fileId }) => {
  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_by_id'],
    queryFn: async () => await fetchContractById(fileId),
    enabled: !!fileId,
  });

  const { mutate: downloadFile } = useMutation({
    mutationFn: fetchDownloadUrl,
    onSuccess: (response) => {
      download_file(response);
    },
  });

  return (
    <Stack width="100%" spacing={2}>
      <Scrollable maxHeight="95vh" minHeight="95vh">
        <ContractDetails contractData={contract_data} />
        <OriginalDocuments
          fileName={contract_data?.file_name}
          fileId={contract_data?.id}
          amendment={contract_data?.amendment}
          downloadFile={downloadFile}
          contractId={contract_data?.id}
        />
        <SupportDocuments
          supportDocuments={contract_data?.support_document}
          downloadFile={downloadFile}
        />
      </Scrollable>
    </Stack>
  );
};

export default AboutThisContract;
