import { connect } from 'react-redux';

import { StoreTree } from '../../../../Utils/MainReducer/mainReducer';
import DocumentActionGenerator from '../../../Actions/Gen';
import { ClauseType } from '../../../Constants/ClauseType';
import {
  dateInfo,
  phraseInfo,
  sentenceInfo,
  tableInfo,
} from '../../../State/documentState';
import ClauseComponentActionGenerator from '../../ClauseComponent/Actions/Gen';
import EditFeature from '../Component/editFeature';

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    editLoader: appState.document.editLoader,
    editSuccessLoader: appState.document.editSuccessLoader,
    editFailureLoader: appState.document.editFailureLoader,
    insightsLoader: appState.document.insightsLoader,
    highlightedId: appState.document.highlightedId,
    dataPointName: appState.document.dataPointName,
    savedHighlightedSentences: appState.document.savedHighlightedSentences,
    savedHighlightedTableCells: appState.document.savedHighlightedTableCells,
    savedHighlightedDates: appState.document.savedHighlightedDates,
    dateInAddEditMode: appState.document.dateInAddEditMode,
    dateEditingStatus: appState.document.dateEditingStatus,
    savedHighlightedPhrases: appState.document.savedHighlightedPhrases,
    phraseEditOptionSelected: appState.document.phraseEditOptionSelected,
    phraseInDeleteMode: appState.document.phraseInDeleteMode,
    phraseDeleteStatus: appState.document.phraseDeleteStatus,
    phraseInAddMode: appState.document.phraseInAddMode,
    phraseAddStatus: appState.document.phraseAddStatus,
    clauseData: appState.clauseComponent.clauseData,
    updatedClauseData: appState.clauseComponent.updatedClauseData,
    sentenceData: appState.document.sentenceData,
    clauseDataByType: ownProps.clauseDataByType,
    durationList: appState.document.durationList,
    currencyList: appState.document.currencyList,
    parentClauseType: ownProps.parentClauseType,
    toBeEdited: ownProps.toBeEdited,
    canAddTags: ownProps.canAddTags,
    parentData: ownProps.parentData,
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    saveHighlightedDataPoint: (
      dataPointName: string,
      editOptionSelection: boolean
    ) =>
      dispatch(
        DocumentActionGenerator.saveHighlightedDataPoint(
          dataPointName,
          editOptionSelection
        )
      ),
    editDataPoint: (
      fileId: string,
      dataType: string,
      dataPointName: any,
      highlightedId?: number | null
    ) =>
      dispatch(
        DocumentActionGenerator.editDataPoint(
          fileId,
          dataType,
          dataPointName,
          highlightedId
        )
      ),
    saveHighlightedId: (highlightedId: number[] | null) =>
      dispatch(DocumentActionGenerator.saveHighlightedId(highlightedId)),
    saveHighlightedSentences: (
      savedHighlightedSentences: sentenceInfo[] | null
    ) =>
      dispatch(
        DocumentActionGenerator.saveHighlightedSentences(
          savedHighlightedSentences
        )
      ),
    saveHighlightedTableCells: (
      savedHighlightedTableCells: tableInfo[] | null
    ) =>
      dispatch(
        DocumentActionGenerator.saveHighlightedTableCells(
          savedHighlightedTableCells
        )
      ),
    saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) =>
      dispatch(
        DocumentActionGenerator.saveHighlightedDates(savedHighlightedDates)
      ),
    saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) =>
      dispatch(
        DocumentActionGenerator.saveDateInAddEditMode(dateInAddEditMode)
      ),
    saveDateEditingStatus: (dateEditingStatus: boolean) =>
      dispatch(
        DocumentActionGenerator.saveDateEditingStatus(dateEditingStatus)
      ),
    saveHighlightedPhrases: (savedHighlightedPhrases: phraseInfo[] | null) =>
      dispatch(
        DocumentActionGenerator.saveHighlightedPhrases(savedHighlightedPhrases)
      ),
    savePhraseEditOption: (phraseEditOptionSelected: boolean) =>
      dispatch(
        DocumentActionGenerator.saveEditPhraseOption(phraseEditOptionSelected)
      ),
    saveDeletePhrase: (
      phraseInDeleteMode: phraseInfo | null,
      phraseDeleteStatus: boolean
    ) =>
      dispatch(
        DocumentActionGenerator.saveDeletePhrase(
          phraseInDeleteMode,
          phraseDeleteStatus
        )
      ),
    saveAddPhrase: (
      phraseInAddMode: phraseInfo | null,
      phraseAddStatus: boolean
    ) =>
      dispatch(
        DocumentActionGenerator.saveAddPhrase(phraseInAddMode, phraseAddStatus)
      ),
    postClauseDataByType: (
      fileID: string,
      type: ClauseType,
      payload: any,
      updatedObject: any
    ) =>
      dispatch(
        ClauseComponentActionGenerator.postClauseDataByType(
          fileID,
          type,
          payload,
          updatedObject
        )
      ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditFeature);
