import React, { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Divider, Link, Stack, Toolbar, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import FieldForm from './FieldForm';
import InsightsForm from './InsightsForm';
import SectionsList from './SectionsList';
import {
  fetchDictionaryContractTypeById,
  fetchFields,
} from '../../../Services/dictionary';

const DataDictionaryCreateType = () => {
  const { id } = useParams<any>();

  const [activeSection, setActiveSection] = useState<any>('');
  const [activeField, setActiveField] = useState<any>('');
  const [showInsights, setShowInsights] = useState<boolean>(false);

  const { data: sections } = useQuery({
    queryKey: ['dictionary-fields', id],
    queryFn: () => fetchFields(id as string),
  });

  const { data: contractType } = useQuery({
    queryKey: ['contract-type-by-id', id],
    queryFn: () => fetchDictionaryContractTypeById(id as string),
  });

  return (
    <Stack>
      <Box>
        <Toolbar>
          <Link
            href="/admin/data-dictionary"
            color="riPrimary.500"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
            Close
          </Link>

          <Typography
            textAlign="center"
            fontSize="16px"
            fontWeight="500"
            whiteSpace="nowrap"
            width="85%"
          >
            {contractType?.displayName}
          </Typography>
        </Toolbar>
        <Divider />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ padding: '10px', height: '85vh' }}
      >
        <SectionsList
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          setActiveField={setActiveField}
          sections={sections}
          activeField={activeField}
          showInsights={showInsights}
          setShowInsights={setShowInsights}
        />
        <Stack width="-webkit-fill-available">
          {showInsights ? (
            <InsightsForm sectionData={activeSection} />
          ) : activeField ? (
            <FieldForm
              id={activeSection.section_id}
              fieldData={activeField}
              activeSection={activeSection}
            />
          ) : (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <img
                alt="no-section"
                height={250}
                src="/static_images/no_section_background.png"
              />
              <Typography style={{ color: '#818181', fontWeight: 700 }}>
                Click on Section/ Field names to view details
              </Typography>
              <Typography variant="h6">{`It contains ${
                sections?.data?.length || 0
              } sections`}</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DataDictionaryCreateType;
