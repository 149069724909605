import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const CheckBoxFilled = ({ props }: any) => {
  return (
    <SvgIcon sx={{ fill: 'none !important' }} viewBox="0 0 12 12" {...props}>
      <path
        d="M9 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9C10.1046 11 11 10.1046 11 9V3C11 1.89543 10.1046 1 9 1Z"
        stroke="#939598"
        strokeMiterlimit="10"
      />
      <path d="M8.97461 3H2.97461V9H8.97461V3Z" fill="currentColor" />
    </SvgIcon>
  );
};

export default CheckBoxFilled;
