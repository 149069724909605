import React, { useEffect, useState } from 'react';

import * as changesets from 'json-diff-ts';

import ClauseCard from './CLauseCard';
import ClauseHeader from './ClauseHeader';
import { handleAddEdit } from './Components/ClauseComponent';
import { ClauseComponentInterface } from './interfaces/ClauseComponentInterface';
import { deleteSingleColumn, getPara } from './utils/ClauseTypeUtils';
import EditFeature from '../EditFeature/Container/cont';

export default function ExclusivityClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [activeClause, setActiveClause] = useState<{
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    hasData && getClauseDataByType(fileId, 'exclusivity');
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.exclusivity && clauseData.exclusivity,
    [clauseData?.exclusivity]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.exclusivity || {},
    [updatedClauseData]
  );

  const exclusivity = React.useMemo(() => {
    if (updates?.exclusivity) {
      const sortData = updates.exclusivity.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      const tableData = item?.table?.[0];
      let value: any;

      if (tableData?.row > -1 && tableData?.column > -1) {
        value = deleteSingleColumn(exclusivity, tableData);
      } else {
        value = exclusivity?.filter(
          (data: any) =>
            data.para_id !== item.para_id ||
            data.sentence_id !== item.sentence_id
        );
      }
      const updatedData = {
        exclusivity: value,
      };
      const diff = changesets.diff(data?.raw_content, updatedData);
      if (diff.length > -1) {
        postClauseDataByType?.(fileId, 'exclusivity', diff, updatedData);
      }
    },
    [data, fileId, exclusivity, postClauseDataByType]
  );

  return (
    <>
      {!showAdd ? (
        <ClauseHeader
          title="Clause Text"
          buttonText="exclusivity"
          onClick={() =>
            handleAddEdit(props, 'exclusivity', exclusivity, setShowAdd)
          }
        />
      ) : (
        <EditFeature
          fileId={props.fileId}
          toBeEdited="exclusivity"
          savedInsight={exclusivity ? exclusivity : []}
          savedParentClauseDataPoint={exclusivity}
          editOptionSelected={(selected: boolean) => {
            props.editOptionSelected(selected);
          }}
          childInEditId={props.childInEditId}
          onClose={() => {
            setShowAdd(false);
          }}
          clauseDataByType={data}
          updatedClauseDataByType={updates}
        />
      )}
      {exclusivity?.map((item: any, index: number) => (
        <ClauseCard
          key={index}
          onClick={() => {
            setActiveClause({ type: 'exclusivity', index });
            props.onClickHighlight(index, item, 'exclusivity');
          }}
          index={index}
          clauseItem={item}
          sentenceData={props.sentenceData}
          isActive={
            activeClause?.type === 'exclusivity' &&
            activeClause?.index === index
          }
          deleteClause={() => deleteClause(item)}
          para={
            item.sentence ||
            getPara(
              props?.sentenceData,
              item.para_id,
              item.sentence_id,
              item.table && item.table[0]?.row,
              item.table && item.table[0]?.column
            )
          }
        />
      ))}
    </>
  );
}
