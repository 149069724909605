import React from 'react';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Chip, ChipProps } from '@mui/material';

const chipStyle = {
  background: '#FFECF1',
  padding: '15px 10px',
  borderRadius: '5px',
  color: '#6D264C',
};

interface Props extends ChipProps {
  label?: string;
}

const ShareChip = (props: Props) => {
  const { label } = props;
  return (
    <Chip
      icon={<PersonOutlineOutlinedIcon />}
      label={label}
      sx={chipStyle}
      {...props}
    />
  );
};

export default ShareChip;
