import React from 'react';

import { Button, Stack, Typography } from '@mui/material';

import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleRemoveText: (value: string) => void;
  fieldValueId: string;
}

const RemoveTextDialog: React.FC<Props> = ({
  open,
  onClose,
  fieldValueId,
  handleRemoveText,
}) => {
  return (
    <CustomModal
      title="Remove selected text from the Issue"
      open={open}
      handleClose={onClose}
    >
      <Stack margin="20px" spacing={2}>
        <Typography>
          The selected text will be removed from the marked Issue but will
          remain in your draft contract.{' '}
        </Typography>
        <Typography>
          <strong>Note:</strong> This action is not reversible.
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => handleRemoveText(fieldValueId)}
          >
            Yes, Delete
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            No, go back
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default RemoveTextDialog;
