import React from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, Stack, StackProps, Typography } from '@mui/material';

interface IProps extends StackProps {
  heading: string;
  index: number;
  authorityData: any;
  handleEdit: VoidFunction;
}
const ConsentAuthorityCard: React.FC<IProps> = ({
  heading,
  index,
  authorityData,
  handleEdit,
  ...rest
}) => {
  return (
    <Box sx={{ borderLeft: '1px solid #88305F', padding: '0 8px 4px' }}>
      <Stack
        sx={{
          background: '#fff7faad',
          padding: '8px 16px',
          borderRadius: '12px',
        }}
        {...rest}
      >
        <Stack
          direction="row"
          mb="5px"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography fontWeight="600" fontSize="14px">{`#${
            index + 1
          } (${heading})`}</Typography>
          <Button
            sx={{ padding: 0 }}
            onClick={handleEdit}
            startIcon={<EditOutlinedIcon />}
          >
            Edit
          </Button>
        </Stack>
        {Object.keys(authorityData?.extraction).map((keyName, index) => (
          <Typography variant="body1" key={index}>
            {authorityData.extraction[keyName].entity}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default ConsentAuthorityCard;
