import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DeleteIcon = ({ props }: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M15 3v1h5v2h-1v13c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6H4V4h5V3h6ZM7 19h10V6H7v13ZM9 8h2v9H9V8Zm6 0h-2v9h2V8Z" />
    </SvgIcon>
  );
};

export default DeleteIcon;
