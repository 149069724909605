import React, { FC, useMemo } from 'react';

import { Card, LinearProgress, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import FilterWithSearch from './FilterWithSearch';
import { ClauseNameMap } from '../../DocumentView/Component/ClauseComponent/Components/ClauseComponent';
import { CLAUSE_RENDERING_ORDER } from '../../Draft/Component/InsightsTab/constants';
import {
  filterHeaderStyle,
  scrollContainerSx,
} from '../../Filter/Components/StaticData';
import CheckboxButtonGroup from '../../RiverusUI/Components/CheckboxButtonGroup';
import CheckBoxFilled from '../../RiverusUI/Components/Icons/CheckboxFilled';
import ListSkeleton from '../../RiverusUI/Components/Skeleton/ListSkeleton';
import { propertyExists } from '../../RiverusUI/Components/utils';

interface IProps {
  control: Control;
  insightsData: any;
  isLoading: boolean;
}

const ClauseTypeFilter: FC<IProps> = ({ control, insightsData, isLoading }) => {
  const clauseTypeData = useMemo(() => {
    const clauses: any = [];
    if (insightsData) {
      if (propertyExists(insightsData, 'clause_type')) {
        const data: any = [];

        for (const key in insightsData['clause_type']) {
          const newObj = {
            label: key,
            count: insightsData['clause_type'][key].present,
          };
          data.push(newObj);
        }
        CLAUSE_RENDERING_ORDER.map((clause: string) =>
          data?.map((item: any) => {
            if (clause === item.label) {
              const updatedData = {
                ...item,
                label: ClauseNameMap[item.label],
                index: item.label,
              };
              clauses.push(updatedData);
            }
          })
        );
      }
    }
    return clauses;
  }, [insightsData]);

  return (
    <Stack gap={2}>
      <Card>
        <Typography sx={filterHeaderStyle}>Clause Type</Typography>
        {!isLoading ? (
          <Stack gap={1} sx={scrollContainerSx}>
            <CheckboxButtonGroup
              name="clause_type"
              control={control}
              options={clauseTypeData}
              valueKey="index"
              checkBoxProps={{
                checkedIcon: (
                  <CheckBoxFilled
                    style={{ fill: 'none !important' }}
                    color="#88305F"
                  />
                ),
              }}
              returnObject
              renderCustomComponent={(value) => (
                <LinearProgress
                  variant="determinate"
                  sx={{
                    background: 'transparent',
                    borderBottom: '1px solid #D1D3D4',
                  }}
                  value={(value / insightsData.total) * 100}
                />
              )}
              labelProps={{
                sx: {
                  padding: '2px 2px 2px 10px',
                },
              }}
            />
          </Stack>
        ) : (
          <ListSkeleton numberOfLines={6} />
        )}
      </Card>
      <FilterWithSearch
        label="Contract Type"
        control={control}
        filterData={insightsData}
        clause="contract_type"
        propertyKey="contract_type_counts"
      />
      <FilterWithSearch
        label="Groups"
        control={control}
        filterData={insightsData}
        clause="groups"
        propertyKey="groups_counts"
      />

      <FilterWithSearch
        label="Projects"
        control={control}
        filterData={insightsData}
        clause="projects"
        propertyKey="projects_counts"
      />
      <FilterWithSearch
        label="Jurisdiction"
        control={control}
        filterData={insightsData}
        clause="jurisdiction"
        propertyKey="jurisdiction_counts"
      />
      <FilterWithSearch
        label="Contracting Parties"
        control={control}
        filterData={insightsData}
        clause="contracting_parties"
        propertyKey="contracting_parties_counts"
        canSearch
      />
    </Stack>
  );
};

export default ClauseTypeFilter;
