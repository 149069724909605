import {
  BiPointDataMode,
  ContractDetails,
  CurrencyType,
  dateInfo,
  DurationType,
  eventInfo,
  InsightsInterface,
  phraseInfo,
  sentenceInfo,
  SentencesData,
  tableInfo,
} from '../State/documentState';

export const GETSENTENCES = 'GETSENTENCES';
export type GETSENTENCES = typeof GETSENTENCES;
export const GETSENTENCES_SUCCESS = 'GETSENTENCES_SUCCESS';
export type GETSENTENCES_SUCCESS = typeof GETSENTENCES_SUCCESS;
export const GETSENTENCES_FAILURE = 'GETSENTENCES_FAILURE';
export type GETSENTENCES_FAILURE = typeof GETSENTENCES_FAILURE;

export const GETINSIGHTS = 'GETINSIGHTS';
export type GETINSIGHTS = typeof GETINSIGHTS;
export const GETINSIGHTS_SUCCESS = 'GETINSIGHTS_SUCCESS';
export type GETINSIGHTS_SUCCESS = typeof GETINSIGHTS_SUCCESS;
export const GETINSIGHTS_FAILURE = 'GETINSIGHTS_FAILURE';
export type GETINSIGHTS_FAILURE = typeof GETINSIGHTS_FAILURE;
export const EDITDATAPOINT_FAILURE = 'EDITDATAPOINT_FAILURE';
export type EDITDATAPOINT_FAILURE = typeof EDITDATAPOINT_FAILURE;

export const GETCONTRACTDATA = 'GETCONTRACTDATA';
export type GETCONTRACTDATA = typeof GETCONTRACTDATA;
export const GETCONTRACTDATA_SUCCESS = 'GETCONTRACTDATA_SUCCESS';
export type GETCONTRACTDATA_SUCCESS = typeof GETCONTRACTDATA_SUCCESS;
export const GETCONTRACTDATA_FAILURE = 'GETCONTRACTDATA_FAILURE';
export type GETCONTRACTDATA_FAILURE = typeof GETCONTRACTDATA_FAILURE;

export const EDITDATAPOINT = 'EDITDATAPOINT';
export type EDITDATAPOINT = typeof EDITDATAPOINT;
export const EDITDATAPOINT_SUCCESS = 'EDITDATAPOINT_SUCCESS';
export type EDITDATAPOINT_SUCCESS = typeof EDITDATAPOINT_SUCCESS;

export const SAVEHIGHLIGHTS = 'SAVEHIGHLIGHTS';
export type SAVEHIGHLIGHTS = typeof SAVEHIGHLIGHTS;

export const SAVEHIGHLIGHTEDDATAPOINT = 'SAVEHIGHLIGHTEDDATAPOINT';
export type SAVEHIGHLIGHTEDDATAPOINT = typeof SAVEHIGHLIGHTEDDATAPOINT;

export const SAVEHIGHLIGHTEDID = 'SAVEHIGHLIGHTEDID';
export type SAVEHIGHLIGHTEDID = typeof SAVEHIGHLIGHTEDID;

export const SAVEBIPOINTDATAMODE = 'SAVEBIPOINTDATAMODE';
export type SAVEBIPOINTDATAMODE = typeof SAVEBIPOINTDATAMODE;

export const SAVEHIGHLIGHTEDSENTENCES = 'SAVEHIGHLIGHTEDSENTENCES';
export type SAVEHIGHLIGHTEDSENTENCES = typeof SAVEHIGHLIGHTEDSENTENCES;

export const SAVEHIGHLIGHTEDPHRASES = 'SAVEHIGHLIGHTEDPHRASES';
export type SAVEHIGHLIGHTEDPHRASES = typeof SAVEHIGHLIGHTEDPHRASES;

export const SAVEEDITPHRASEOPTION = 'SAVEEDITPHRASEOPTION'; //used for adding phrase
export type SAVEEDITPHRASEOPTION = typeof SAVEEDITPHRASEOPTION;

export const SAVEDELETEPHRASE = 'SAVEDELETEPHRASE';
export type SAVEDELETEPHRASE = typeof SAVEDELETEPHRASE;

export const SAVEADDPHRASE = 'SAVEADDPHRASE';
export type SAVEADDPHRASE = 'SAVEADDPHRASE';

export const SAVEHIGHLIGHTEDTABLECELLS = 'SAVEHIGHLIGHTEDTABLECELLS';
export type SAVEHIGHLIGHTEDTABLECELLS = typeof SAVEHIGHLIGHTEDTABLECELLS;

export const SAVEHIGHLIGHTEDDATES = 'SAVEHIGHLIGHTEDDATES';
export type SAVEHIGHLIGHTEDDATES = typeof SAVEHIGHLIGHTEDDATES;

export const SAVEDATEINADDEDITMODE = 'SAVEDATEINADDEDITMODE';
export type SAVEDATEINADDEDITMODE = typeof SAVEDATEINADDEDITMODE;

export const SAVEDATEEDITINGSTATUS = 'SAVEDATEINEDITINGSTATUS';
export type SAVEDATEEDITINGSTATUS = typeof SAVEDATEEDITINGSTATUS;

export const SAVEHIGHLIGHTEDEVENTS = 'SAVEHIGHLIGHTEDEVENTS';
export type SAVEHIGHLIGHTEDEVENTS = typeof SAVEHIGHLIGHTEDEVENTS;

export const SAVEEVENTINADDEDIT = 'SAVEEVENTINADDEDIT';
export type SAVEEVENTINADDEDIT = typeof SAVEEVENTINADDEDIT;

export const SAVEEVENTEDITINGSTATUS = 'SAVEEVENTEDITINGSTATUS';
export type SAVEEVENTEDITINGSTATUS = typeof SAVEEVENTEDITINGSTATUS;

export const SAVECLAUSEMODESTATUS = 'SAVECLAUSEMODESTATUS';
export type SAVECLAUSEMODESTATUS = typeof SAVECLAUSEMODESTATUS;

export const SAVEINSIGHTTODELETE = 'SAVEINSIGHTTODELETE';
export type SAVEINSIGHTTODELETE = typeof SAVEINSIGHTTODELETE;

export const GETDURATIONTYPES = 'GETDURATIONTYPES';
export type GETDURATIONTYPES = typeof GETDURATIONTYPES;
export const GETDURATIONTYPES_SUCCESS = 'GETDURATIONTYPES_SUCCESS';
export type GETDURATIONTYPES_SUCCESS = typeof GETDURATIONTYPES_SUCCESS;
export const GETDURATIONTYPES_FAILURE = 'GETDURATIONTYPES_FAILURE';
export type GETDURATIONTYPES_FAILURE = typeof GETDURATIONTYPES_FAILURE;

export const GETCURRENCYTYPES = 'GETCURRENCYTYPES';
export type GETCURRENCYTYPES = typeof GETCURRENCYTYPES;
export const GETCURRENCYTYPES_SUCCESS = 'GETCURRENCYTYPES_SUCCESS';
export type GETCURRENCYTYPES_SUCCESS = typeof GETCURRENCYTYPES_SUCCESS;
export const GETCURRENCYTYPES_FAILURE = 'GETCURRENCYTYPES_FAILURE';
export type GETCURRENCYTYPES_FAILURE = typeof GETCURRENCYTYPES_FAILURE;

export interface SaveHighlightedDataPoint {
  type: SAVEHIGHLIGHTEDDATAPOINT;
  payload: {
    dataPointName: string;
    editOptionSelection: boolean;
  };
}

export interface SaveHighlightedId {
  type: SAVEHIGHLIGHTEDID;
  payload: {
    highlightedId: number[] | null;
  };
}

export interface GetSentences {
  type: GETSENTENCES;
  payload: {
    fileId: string;
  };
}

export interface GetSentencesSuccess {
  type: GETSENTENCES_SUCCESS;
  payload: {
    sentenceData: SentencesData;
  };
}

export interface GetSentencesFailure {
  type: GETSENTENCES_FAILURE;
}

export interface GetInsights {
  type: GETINSIGHTS;
  payload: {
    fileId: string;
  };
}

export interface GetContractData {
  type: GETCONTRACTDATA;
  payload: {
    fileId: string;
  };
}

export interface GetContractSuccess {
  type: GETCONTRACTDATA_SUCCESS;
  payload: {
    contractData: ContractDetails;
  };
}

export interface GetContractDataFailure {
  type: GETCONTRACTDATA_FAILURE;
}

export interface GetInsightsSuccess {
  type: GETINSIGHTS_SUCCESS;
  payload: {
    insightsData: InsightsInterface[];
  };
}

export interface GetInsightsFailure {
  type: GETINSIGHTS_FAILURE;
}

export interface EditDataPointFailure {
  type: EDITDATAPOINT_FAILURE;
}

export interface EditDataPoint {
  type: EDITDATAPOINT;
  payload: {
    fileId: string;
    dataType: string;
    dataPointName: any;
    highlightedId?: number | null;
  };
}

export interface EditDataPointSuccess {
  type: EDITDATAPOINT_SUCCESS;
}

export interface SaveBiPointDataMode {
  type: SAVEBIPOINTDATAMODE;
  payload: {
    storedBiPointDataModes: BiPointDataMode[];
  };
}
export interface SaveHighlightedSentences {
  type: SAVEHIGHLIGHTEDSENTENCES;
  payload: {
    savedHighlightedSentences: sentenceInfo[] | null;
  };
}
export interface SaveHighlightedPhrases {
  type: SAVEHIGHLIGHTEDPHRASES;
  payload: {
    savedHighlightedPhrases: phraseInfo[] | null;
  };
}
export interface SaveEditPhraseOption {
  type: SAVEEDITPHRASEOPTION;
  payload: {
    phraseEditOptionSelected: boolean;
  };
}

export interface SaveDeletePhrase {
  type: SAVEDELETEPHRASE;
  payload: {
    phraseInDeleteMode: phraseInfo | null;
    phraseDeleteStatus: boolean;
  };
}

export interface SaveAddPhrase {
  type: SAVEADDPHRASE;
  payload: {
    phraseInAddMode: phraseInfo | null;
    phraseAddStatus: boolean;
  };
}

export interface SaveHighlightedTableCells {
  type: SAVEHIGHLIGHTEDTABLECELLS;
  payload: {
    savedHighlightedTableCells: tableInfo[] | null;
  };
}

export interface SaveHighlightedDates {
  type: SAVEHIGHLIGHTEDDATES;
  payload: {
    savedHighlightedDates: dateInfo[] | null;
  };
}

export interface SaveDateInAddEditMode {
  type: SAVEDATEINADDEDITMODE;
  payload: {
    dateInAddEditMode: dateInfo | null;
  };
}

export interface SaveDateEditingStatus {
  type: SAVEDATEEDITINGSTATUS;
  payload: {
    dateEditingStatus: boolean;
  };
}

export interface SaveHighlightedEvents {
  type: SAVEHIGHLIGHTEDEVENTS;
  payload: {
    savedHighlightedEvents: eventInfo[] | null;
  };
}

export interface SaveEventInAddEdit {
  type: SAVEEVENTINADDEDIT;
  payload: {
    eventInAddEdit: eventInfo | null;
  };
}

export interface SaveEventEditingStatus {
  type: SAVEEVENTEDITINGSTATUS;
  payload: {
    eventEditingStatus: boolean;
  };
}

export interface SaveClauseModeStatus {
  type: SAVECLAUSEMODESTATUS;
  payload: {
    clauseModeStatus: boolean;
  };
}

export interface SaveInsightToDelete {
  type: SAVEINSIGHTTODELETE;
  payload: {
    insightToDelete: any;
    childLabelToDelete: string;
  };
}

export interface GetDurationTypes {
  type: GETDURATIONTYPES;
}

export interface GetDurationTypesSuccess {
  type: GETDURATIONTYPES_SUCCESS;
  payload: {
    durationList: DurationType[];
  };
}

export interface GetDurationTypesFailure {
  type: GETDURATIONTYPES_FAILURE;
}

export interface GetCurrencyTypes {
  type: GETCURRENCYTYPES;
}

export interface GetCurrencyTypesSuccess {
  type: GETCURRENCYTYPES_SUCCESS;
  payload: {
    currencyList: CurrencyType[];
  };
}

export interface GetCurrencyTypesFailure {
  type: GETCURRENCYTYPES_FAILURE;
}

export type DocumentAction =
  | GetSentences
  | GetSentencesSuccess
  | GetSentencesFailure
  | GetInsights
  | GetInsightsSuccess
  | GetInsightsFailure
  | EditDataPoint
  | EditDataPointSuccess
  | SaveHighlightedId
  | SaveHighlightedDataPoint
  | SaveBiPointDataMode
  | SaveHighlightedSentences
  | SaveHighlightedPhrases
  | SaveEditPhraseOption
  | SaveDeletePhrase
  | SaveAddPhrase
  | SaveHighlightedTableCells
  | SaveHighlightedDates
  | SaveDateInAddEditMode
  | SaveDateEditingStatus
  | SaveHighlightedEvents
  | SaveEventInAddEdit
  | SaveEventEditingStatus
  | SaveClauseModeStatus
  | SaveInsightToDelete
  | GetDurationTypes
  | GetDurationTypesSuccess
  | GetDurationTypesFailure
  | GetCurrencyTypes
  | GetCurrencyTypesSuccess
  | GetCurrencyTypesFailure
  | EditDataPointFailure
  | GetContractData
  | GetContractSuccess;
