import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { GroupOutlined, PersonOffOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import DropdownButton from './DropdownButton';

interface MenuOption {
  value: string;
  label: string;
  startIcon?: any;
  isLocked?: boolean;
}

const collaborateMenu: MenuOption[] = [
  {
    value: 'collaborating',
    label: 'Collaborating',
    startIcon: <GroupOutlined />,
  },
  {
    value: 'editing',
    label: 'Editing',
    startIcon: <PersonOffOutlined />,
    isLocked: false,
  },
];

interface Props {
  canDownload?: boolean;
  collaborating?: boolean;
  goBackUrl?: string;
  renderAction: () => React.ReactNode;
  version: number;
  name?: string;
  setCanEdit?: Dispatch<SetStateAction<boolean>>;
  handleDownload?: VoidFunction;
  collaboratorDisabled?: boolean;
  canEdit?: boolean;
  isLocked?: boolean;
  showLockedModal?: VoidFunction;
}

const DraftHeader: React.FC<Props> = ({
  collaborating = false,
  canDownload = false,
  renderAction,
  version,
  goBackUrl,
  name,
  setCanEdit,
  handleDownload,
  collaboratorDisabled = false,
  canEdit = false,
  isLocked = false,
  showLockedModal,
}) => {
  const [collaboratingValue, setCollaboratingValue] = useState<string>();

  React.useEffect(() => {
    if (collaboratingValue === 'Editing') {
      setCanEdit?.(true);
    } else {
      setCanEdit?.(false);
    }
  }, [collaboratingValue, isLocked, setCanEdit, showLockedModal]);

  useEffect(() => {
    if (isLocked) {
      collaborateMenu.map((data) => {
        if (data.value === 'editing') {
          data.isLocked = true;
        }
      });
    }
  }, [isLocked]);

  return (
    <Box>
      <Toolbar className="header-wrapper">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {goBackUrl && (
              <Link
                href={goBackUrl}
                color="riPrimary.500"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
                Close
              </Link>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="center"
            className="title-wrapper"
          >
            <Typography
              marginRight={2}
              marginLeft={1}
              fontSize="14px"
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {name} {version > -1 && `(V${version})`}
            </Typography>
            {collaborating && (
              <>
                <Divider orientation="vertical" flexItem />
                <Box marginLeft={0.5}>
                  <DropdownButton
                    changeButtonText
                    options={collaborateMenu}
                    setValue={(value: string) => {
                      if (isLocked && value === 'Editing') {
                        showLockedModal?.();
                        setCollaboratingValue('Collaborating');
                      } else {
                        setCollaboratingValue(value);
                      }
                    }}
                    disabled={collaboratorDisabled}
                    buttonName={canEdit ? 'Editing' : 'Collaborating'}
                  />
                </Box>
              </>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {canDownload && (
              <Button
                onClick={handleDownload}
                variant="text"
                startIcon={<SaveAltIcon />}
              >
                Download
              </Button>
            )}
            {renderAction()}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
    </Box>
  );
};

export default DraftHeader;
