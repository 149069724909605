import React, { PureComponent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Stack } from '@mui/material';

import { getSessionStorage } from '../../../Authentication/Actions/authentication';

interface Props {
  visibleSetting: () => void;
}

export default class MobSetting extends PureComponent<Props> {
  render() {
    const { visibleSetting } = this.props;
    return (
      <Box>
        <Box id="mob-setting-container">
          <Grid>
            <Grid
              item
              sm={9}
              className="scale-up-hor-left"
              id="mob-setting-inner-container"
            >
              <Box id="mob-setting-top-layer">
                <Stack direction="row" gap={2} alignItems="center">
                  <CloseIcon onClick={visibleSetting} />
                  <Stack
                    direction="row"
                    p={2}
                    mt={3}
                    alignItems="center"
                    gap={2}
                  >
                    <img
                      src="/static_images/riverus-round-logo.svg"
                      alt="icon"
                    />

                    <Box id="setting-detail-container">
                      {getSessionStorage('user_profile').username}
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              <Box id="mob-setting-top-layer-2">
                <Box id="setting-mob-tab-active">Settings</Box>
              </Box>
              <Box id="mob-setting-middle-layer" py={2}>
                <img src="/static_images/signout-icon-mob.svg" alt="icon" />
                Sign Out
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="scale-up-hor-left" id="mob-setting-bottom-layer">
          Contact us at &nbsp; hello@riverus.com
        </Box>
      </Box>
    );
  }
}
