import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Badge,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ReminderConfirmationModal from './ReminderConfirmationModal';
import {
  calculateDayDifference,
  calculateReminderId,
  handleReminder,
  reminder,
  reminderDaysMap,
  reminderStatus,
} from './ReminderStatic';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import UploadSupportFile from '../../../DocumentLibrary/Component/TableComponents/UploadSupportFile';
import ViewDocumentDrawer from '../../../DocumentLibrary/Component/TableComponents/ViewDocumentDrawer';
import {
  download_file,
  formatPayloadDate,
  sortListAlphabetical,
} from '../../../Draft/Component/Helper';
import FilterInput from '../../../Reminder/FilterInput';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import SelectElement from '../../../RiverusUI/Components/SelectElement';
import {
  createReminder,
  editReminder,
  fetchDownloadUrl,
} from '../../../Services/DocumentLibrary';
import { fetchCollaborators } from '../../../Services/Draft';

interface Props {
  onClose: VoidFunction;
  contractData: any;
  reminderEditData: any;
  setViewOnly: Dispatch<SetStateAction<boolean>>;
  viewOnly: boolean;
  user_data: any;
  queryParamsValue: any;
  handleButtonClick: any;
  setIsButtonClicked: Dispatch<SetStateAction<boolean>>;
  isButtonClicked: boolean;
  userIsCoOwner: any;
}

const ReminderForm: React.FC<Props> = ({
  onClose,
  contractData,
  reminderEditData,
  setViewOnly,
  viewOnly,
  user_data,
  queryParamsValue,
  handleButtonClick,
  setIsButtonClicked,
  isButtonClicked,
  userIsCoOwner,
}) => {
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(false);
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [showRadioGroup, setShowRadioGroup] = useState(
    !!reminderEditData?.recurring_type
  );
  const [selectedValue, setSelectedValue] = useState(
    reminderEditData?.recurring_type
  );

  const handleCloseSupportDoc = () => {
    setIsShowUploadField(false);
  };

  const defaultReminderIds = reminder
    ?.filter((reminderOption) => reminderOption.name !== 'Don’t remind me')
    ?.map((reminderOption) => reminderOption.name);

  const reminderOption = useMemo(() => {
    const calculatedReminderId =
      calculateReminderId(
        reminderEditData?.due_date,
        reminderEditData?.when_to_remind
      ) || '';

    return defaultReminderIds?.find((id) => id === calculatedReminderId) || '';
  }, [
    reminderEditData?.due_date,
    reminderEditData?.when_to_remind,
    defaultReminderIds,
  ]);

  const methods = useForm({
    defaultValues: {
      ...reminderEditData,
      users: reminderEditData?.users?.map((user: any) => user?.id),
      support_documents: reminderEditData?.support_documents?.[0]?.id,
      when_to_remind:
        reminderEditData?.due_date && reminderEditData?.when_to_remind
          ? [reminderOption]
          : reminderEditData?.due_date && !reminderEditData?.when_to_remind
            ? ['Don’t remind me']
            : [],
    },
  });
  const { handleSubmit, control, reset, setValue, watch } = methods;
  const queryClient = useQueryClient();
  const selectedApprovers = watch('users');
  const selectedRemindMe = watch('when_to_remind');
  const dueDate = watch('due_date');

  useEffect(() => {
    setValue('contract', contractData?.title);
  }, [contractData, setValue]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: async () => {
      const response = await fetchCollaborators();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: `${data.first_name} ${data.last_name}`,
      }));
      return groups;
    },
  });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
      reset();
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = (data: any) => {
    let highestReminderOption: string | undefined;
    const validReminders = data?.when_to_remind?.filter(
      (reminderOption: string) => reminderOption !== 'Don’t remind me'
    );

    if (validReminders && validReminders.length > 0) {
      highestReminderOption = validReminders?.reduce(
        (maxOption: string, currentOption: string) => {
          return reminderDaysMap[currentOption] > reminderDaysMap[maxOption]
            ? currentOption
            : maxOption;
        }
      );
    }

    let payload = {
      ...data,
      support_documents: data?.support_documents
        ? [data?.support_documents]
        : [],
      contract: contractData?.id,
      app: 'Umbrella',
      when_to_remind: highestReminderOption
        ? handleReminder(data?.due_date, highestReminderOption)
        : null,
    };

    if (selectedValue) {
      payload = {
        ...payload,
        recurring_type:
          selectedValue === 'last_day_of_month'
            ? 'last_day_of_month'
            : selectedValue === 'day_of_month'
              ? 'day_of_month'
              : selectedValue === 'recreate_every'
                ? 'recreate_every'
                : null,
        valid_till: formatPayloadDate(new Date(data?.valid_till)),
      };

      if (
        selectedValue === 'day_of_month' ||
        selectedValue === 'recreate_every'
      ) {
        payload.days_value = data?.days_value;
      }

      if (selectedValue === 'recreate_every') {
        payload.duration = data?.duration;
      }
    }

    if (reminderEditData?.id) {
      delete payload['id'];
      delete payload['comments'];
      delete payload['created'];
      delete payload['draft'];
      delete payload['external_user'];
      delete payload['checklist'];
      delete payload['draft_item'];
      delete payload['deviation'];

      payload = {
        id: reminderEditData?.id,
        body: {
          ...payload,
        },
      };
      update_reminder(payload);
    } else {
      createReminderMutation(payload);
    }
  };

  const { mutate: downloadFile } = useMutation({
    mutationFn: fetchDownloadUrl,
    onSuccess: (response: any) => {
      download_file(response);
    },
  });

  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
    setViewDocumentId('');
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['get_contract_data_by_id'] });
  };

  const isReadOnly = useMemo(
    () =>
      reminderEditData?.id &&
      (viewOnly ||
        !(
          userIsCoOwner?.id === user_data?.id ||
          reminderEditData?.created_by?.id === user_data?.id
        )),
    [reminderEditData, user_data?.id, viewOnly, userIsCoOwner]
  );

  const disabledFields = useMemo(
    () =>
      reminderEditData?.id &&
      (reminderEditData?.created_by?.id !== user_data?.id || !viewOnly),
    [reminderEditData, user_data?.id, viewOnly]
  );

  const onButtonClick = useCallback(() => {
    if (isButtonClicked) {
      setIsButtonClicked(false);
    } else {
      setIsButtonClicked(true);
    }
    handleButtonClick(reminderEditData);
  }, [handleButtonClick, reminderEditData, isButtonClicked]);

  // Function to toggle the radio visibility
  const handleToggleRadioGroup = () => {
    setShowRadioGroup((prev) => {
      if (prev) {
        setSelectedValue(null);
      }
      return !prev;
    });
  };

  // Function to handle radio selection
  const handleRadioChange = (event: any) => {
    setValue('days_value', '');
    setValue('duration', '');
    setValue('valid_till', '');
    setSelectedValue(event.target.value);
  };

  /// when to remind option disable logic
  const dayDiff = calculateDayDifference(dueDate);
  const dontRemindMeSelected = selectedRemindMe?.includes('Don’t remind me');

  const disableOptionCondition = (option: any) => {
    const optionDayValue = parseInt(option?.name?.split(' ')[0], 10);
    if (dontRemindMeSelected) {
      return option?.name !== 'Don’t remind me';
    }
    if (selectedRemindMe?.length > 0 && option?.name === 'Don’t remind me') {
      return true;
    }
    return optionDayValue > dayDiff;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '700px', padding: '30px 10px 10px' }}>
          <Grid container gap={2}>
            <Grid item sm={12}>
              <ControlledTextField
                name="name"
                control={control}
                label="Reminder Name"
                fullWidth
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Stack width="100%">
              <Stack spacing={2} direction="row">
                <Controller
                  name="due_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="date"
                      label="Due Date"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={isReadOnly}
                      inputProps={{
                        min: new Date().toISOString().split('T')[0],
                      }}
                    />
                  )}
                />
                <Stack width="100%">
                  <FilterInput
                    name="when_to_remind"
                    control={control}
                    label="Remind When"
                    options={reminder}
                    isMultiselect={true}
                    valueKey="name"
                    readOnly={isReadOnly || !dueDate}
                    renderCustomComponent={(value: any, props) => (
                      <CustomChip {...props} label={value?.name} />
                    )}
                    disableOptionCondition={disableOptionCondition}
                  />
                  <Typography
                    variant="caption"
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', margin: '3px 14px 0' }}
                  >
                    Select the due date first to enable only valid options
                  </Typography>
                </Stack>
              </Stack>
              {!reminderEditData?.parent && (
                <FormControl fullWidth>
                  <FormLabel sx={{ width: ' fit-content' }}>
                    {/* <Button
                      startIcon={
                        showRadioGroup ? (
                          <RemoveCircleOutlineIcon
                            sx={{ width: '1.5rem', height: '1.5rem' }}
                          />
                        ) : (
                          <AddCircleOutlineRoundedIcon
                            sx={{ width: '1.5rem', height: '1.5rem' }}
                          />
                        )
                      }
                      onClick={handleToggleRadioGroup}
                      sx={{
                        padding: 0,
                        margin: '0 4px',
                        '&:hover': { background: 'unset' },
                      }}
                      disabled={viewOnly || isReadOnly}
                    >
                      Make This A Recurring Reminder
                    </Button> */}
                  </FormLabel>

                  {showRadioGroup && (
                    <Stack spacing={2} marginBottom="0.5rem">
                      <RadioGroup
                        row
                        name="recurring_type"
                        value={selectedValue}
                        onChange={handleRadioChange}
                        sx={{ justifyContent: 'space-between' }}
                      >
                        {[
                          'Last day of a month',
                          'Fixed day of a month',
                          'Recreate every',
                        ].map((label, index) => {
                          const value = [
                            'last_day_of_month',
                            'day_of_month',
                            'recreate_every',
                          ][index];
                          return (
                            <FormControlLabel
                              key={value}
                              value={value}
                              control={<Radio sx={{ padding: '9px' }} />}
                              label={label}
                              disabled={isReadOnly}
                              sx={{
                                textTransform: 'initial',
                                '& .MuiFormControlLabel-label': {
                                  color:
                                    selectedValue === value
                                      ? '#6D264C'
                                      : 'inherit',
                                  fontWeight:
                                    selectedValue === value ? 700 : 'inherit',
                                  margin: 0,
                                },
                              }}
                            />
                          );
                        })}
                      </RadioGroup>

                      <Stack spacing={1}>
                        <Stack direction="row" spacing={1.5}>
                          {selectedValue === 'day_of_month' && (
                            <Controller
                              name="days_value"
                              control={control}
                              rules={{
                                validate: (value) => {
                                  if (value > 31) {
                                    return 'This value is invalid';
                                  }
                                  if (value > 28) {
                                    return true;
                                  }
                                  return true;
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <TextField
                                    {...field}
                                    type="number"
                                    label="Enter value"
                                    sx={{ width: '50%' }}
                                    disabled={isReadOnly}
                                    error={
                                      fieldState.error?.message ===
                                      'This value is invalid'
                                    }
                                    helperText={
                                      fieldState.error?.message ===
                                      'This value is invalid'
                                        ? 'This value is invalid'
                                        : field?.value > 28
                                          ? 'This value may not be available in every month'
                                          : ''
                                    }
                                  />
                                </>
                              )}
                            />
                          )}
                          {selectedValue === 'recreate_every' && (
                            <Stack
                              direction="row"
                              alignItems="center"
                              width="50%"
                              spacing={1.5}
                            >
                              <ControlledTextField
                                name="days_value"
                                control={control}
                                type="number"
                                label="Enter value"
                                disabled={isReadOnly}
                              />
                              <Controller
                                name="duration"
                                control={control}
                                render={({ field }) => (
                                  <FormControl sx={{ width: '50%' }}>
                                    <InputLabel>Duration</InputLabel>
                                    <Select
                                      {...field}
                                      label="Duration"
                                      variant="outlined"
                                      disabled={isReadOnly}
                                    >
                                      {['Days', 'Months', 'Years'].map(
                                        (duration) => (
                                          <MenuItem
                                            key={duration}
                                            value={duration.toLowerCase()}
                                          >
                                            {duration}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            </Stack>
                          )}
                          {selectedValue && (
                            <Controller
                              name="valid_till"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type="date"
                                  label="Valid Till"
                                  sx={{
                                    width:
                                      selectedValue === 'last_day_of_month'
                                        ? '100%'
                                        : '50%',
                                  }}
                                  required
                                  disabled={isReadOnly}
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                  }}
                                />
                              )}
                            />
                          )}
                        </Stack>
                        {reminderEditData?.recurring_type !== selectedValue &&
                          reminderEditData?.children_count > 0 && (
                            <Typography
                              variant="caption"
                              color="rgba(0, 0, 0, 0.7)"
                              fontWeight={600}
                            >
                              Existing selection includes &quot;
                              {reminderEditData?.children_count}{' '}
                              {reminderEditData?.children_count === 1
                                ? 'Recurring Reminder'
                                : 'Recurring Reminders'}
                              &quot;. Switching to another recurring type will
                              trigger future recurring reminders based on the
                              new selection.
                            </Typography>
                          )}
                      </Stack>
                    </Stack>
                  )}
                </FormControl>
              )}
            </Stack>
            <SelectElement
              fullWidth
              name="status"
              control={control}
              label="Status"
              options={sortListAlphabetical(reminderStatus)}
              labelKey="name"
              disabled={viewOnly}
            />

            <Stack width="100%" spacing={2}>
              <Typography fontWeight={'700'}>Share this reminder</Typography>
              <RISelectComponent
                readOnly={isReadOnly}
                name="users"
                control={control}
                label="Select Users"
                options={sortListAlphabetical(approversData)}
                loading={approversLoading}
                isMultiselect={true}
                renderCustomComponent={(value: any, props) => (
                  <ShareChip key={value?.id} {...props} label={value?.name} />
                )}
                textFieldProps={{
                  helperText: 'you can select multiple users',
                }}
              />
            </Stack>

            {viewOnly && reminderEditData?.support_documents?.length > 0 ? (
              <Stack spacing={1} width="100%">
                <Typography fontWeight={'700'}>Support document</Typography>
                {reminderEditData?.support_documents?.map(
                  (item: any, index: number) => (
                    <Stack
                      key={index}
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography fontWeight={600}>
                        {item?.file_name}
                      </Typography>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <IconButton onClick={() => downloadFile(item?.id)}>
                          <FileDownloadOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpenViewDrawer(true);
                            setViewDocumentId(item?.id);
                          }}
                        >
                          <VisibilityOffIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  )
                )}
              </Stack>
            ) : (
              <Stack width="100%" spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={'700'} mt={0}>
                    Associated document
                  </Typography>
                  {!viewOnly && (
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={() => setIsShowUploadField(true)}
                      disabled={viewOnly}
                    >
                      Upload Support document
                    </Button>
                  )}
                </Stack>
                <RISelectComponent
                  readOnly={viewOnly}
                  name="support_documents"
                  control={control}
                  label="Support document"
                  valueKey="id"
                  labelKey="file_name"
                  options={sortListAlphabetical(
                    contractData?.support_document,
                    'file_name'
                  )}
                  renderCustomComponent={(value: any, props) => (
                    <Chip
                      style={{
                        background: '#FFECF1',
                        padding: '15px 10px',
                        borderRadius: '5px',
                        color: '#6D264C',
                      }}
                      icon={<FolderOutlinedIcon style={{ color: '#6D264C' }} />}
                      label={value?.title}
                      {...props}
                    />
                  )}
                  textFieldProps={{
                    helperText: 'Select or upload support document',
                  }}
                />
              </Stack>
            )}
            {queryParamsValue && (
              <Stack
                width="100%"
                sx={{
                  border: '2px solid #6D264C',
                  borderStyle: 'dashed',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  alignItems: 'center',
                  '&:hover': {
                    border: '2px solid #6D264C',
                  },
                }}
                onClick={onButtonClick}
              >
                <Badge
                  badgeContent={reminderEditData?.comments?.length}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#A0597F',
                      color: '#fff',
                      top: '0.8rem',
                      right: '1rem',
                    },
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      textTransform: 'unset',
                      height: '40px',
                      alignSelf: 'center',
                      width: 'auto',
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    }}
                    startIcon={<ChatBubbleOutlineIcon />}
                  >
                    Comments
                  </Button>
                </Badge>
              </Stack>
            )}

            <Stack spacing={0} width="100%">
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="row">
                  {!reminderEditData?.id && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px', width: 'max-content' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Create Reminder
                    </Button>
                  )}

                  {reminderEditData?.id && viewOnly && (
                    <Button
                      variant="contained"
                      sx={{ height: '40px' }}
                      onClick={() => setViewOnly(false)}
                    >
                      Edit
                    </Button>
                  )}

                  {reminderEditData?.id && !viewOnly && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                    sx={{ height: '40px' }}
                  >
                    Cancel
                  </Button>
                </Stack>

                {reminderEditData?.id && (
                  <Stack justifyContent="center" width="100%" alignItems="end">
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<NotificationsIcon />}
                      disabled={disabledFields}
                      onClick={() => setOpenForm(true)}
                    >
                      Send a reminder now
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </form>

      {isShowUploadField && (
        <UploadSupportFile
          open={isShowUploadField}
          onClose={handleCloseSupportDoc}
          handleRefresh={handleRefresh}
          selectedRow={[contractData?.id]}
          allContracts={[contractData]}
        />
      )}
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
      {openForm && (
        <ReminderConfirmationModal
          open={openForm}
          handleClose={() => setOpenForm(false)}
          reminderData={reminderEditData}
          onClose={onClose}
        />
      )}
    </FormProvider>
  );
};

export default ReminderForm;
