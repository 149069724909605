import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

interface IProps {
  title: string;
}

const AssigneePopoverTitle: React.FC<IProps> = ({ title }) => {
  return (
    <Typography
      variant="subtitle2"
      fontWeight={700}
      sx={{ textDecoration: 'underline' }}
    >
      <CircleIcon sx={{ fontSize: '8px' }} /> {title}
    </Typography>
  );
};

export default AssigneePopoverTitle;
