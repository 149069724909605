export type ClauseType =
  | 'contracting_parties'
  | 'non_compete'
  | 'change_of_control'
  | 'confidentiality'
  | 'consent'
  | 'data_breach'
  | 'dispute_resolution'
  | 'effective_date'
  | 'events_of_default'
  | 'exclusivity'
  | 'force_majeure'
  | 'general_definitions'
  | 'governing_law'
  | 'indemnity'
  | 'insurance'
  | 'limited_liability'
  | 'term'
  | 'renewal'
  | 'payment'
  | 'termination'
  | 'notice'
  | 'non_solicitation'
  | 'subletting'
  | 'obligation_statements'
  | 'Renewal clause'
  | 'termination_at_convenience'
  | 'assignment'
  | 'locked_in_period'
  | 'premise';

export const ClauseTypeMap: Record<string, string> = {
  contracting_parties: 'contractingParties',
  non_compete: 'nonCompete',
  change_of_control: 'changeOfControl',
  confidentiality: 'confidentiality',
  consent: 'consent',
  data_breach: 'dataBreach',
  dispute_resolution: 'disputeResolution',
  effective_date: 'effectiveDate',
  events_of_default: 'eventsOfDefault',
  exclusivity: 'exclusivity',
  force_majeure: 'forceMajeure',
  general_definitions: 'generalDefinitions',
  governing_law: 'governingLaw',
  indemnity: 'indemnity',
  insurance: 'insurance',
  limited_liability: 'limitedLiability',
  term: 'term',
  renewal: 'renewal',
  payment: 'payment',
  termination: 'termination',
  notice: 'notice',
  non_solicitation: 'nonSolicitation',
  subletting: 'subletting',
  obligation_statements: 'obligationStatements',
  'Renewal clause': 'Renewal clause',
  termination_at_convenience: 'terminationAtConvenience',
  assignment: 'assignment',
  locked_in_period: 'lockedInPeriod',
  premise: 'premise',
};
